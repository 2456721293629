import React from "react";
import { makeStyles } from "@mui/styles";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { BotBackground } from "../../components/common/BotBackground";
import { AdminStudentsPage } from "../../components/AdminPage/AdminStudentsPage";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminStudents = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Alumnos" search />
      <AdminStudentsPage />
      <BotBackground logined />
    </div>
  );
};
