import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helper = {};

helper.putTeacher = async (id, config) => {
  // console.log(config);
  const res = await axios.put(`${apiUrl}teacherTrainings/${id}`, config);
  console.log(res.data);
  // return res.data;
};

helper.getTeachers = async (state) => {
  const getTeacherInformations = async () => {
    const resp = await axios.get(`${apiUrl}view/viewTeachersInformation`);
    // console.log(resp.data);
    const rows = resp.data.resultTeacher;
    const data = rows.filter((el) => el.status === state);
    return data.length > 0 ? data : [];
  };

  const getLevelsByUserId = async (userId) => {
    const resp = await axios.get(
      `${apiUrl}view/viewLevelsByUserId/ByIdUser/${userId}`
    );
    return resp.data.levelByUserId;
  };

  const teacherInformations = await getTeacherInformations();
  let allTeachers = teacherInformations.map(async (teacherInformation) => {
    /* const teacherAverageReview = await getTeacherAverageReview(teacherInformation.userId); */
    const levelsByUser = await getLevelsByUserId(teacherInformation.userId);
    return {
      ...teacherInformation,
      levels: levelsByUser,
    };
  });

  allTeachers = await Promise.all(allTeachers);
  console.log(allTeachers);
  return allTeachers;
};
