import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyle = makeStyles({
  root: {
    width: "100%",
    maxWidth: "457px",
    height: "618px",
    borderRadius: "30px",
    background: "rgba(216, 231, 228, 0.4)",
    backdropFilter: "blur(20px)",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
export const LogoCard = (props) => {
  const { image, title } = props;
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        sx={{ color: "#505050", ml: 10, mr: 10, display: "block" }}
      >
        {title}
      </Typography>
      <br />
      <br />
      <div>
        <img src={image} alt={image} />
      </div>
    </div>
  );
};
