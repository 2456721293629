import { Avatar, Button, Grid, Menu, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserMenu } from './userInfo/UserMenu';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyle = makeStyles({});
export const UserCol = () => {
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const classes = useStyle();
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const history = useHistory();

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const onClicMessages = () => {
    switch (user.rol) {
      case 'student':
        if (history.location.pathname !== '/student/chat') history.push('/student/chat');
        break;
      case 'admin':
        if (history.location.pathname !== '/admin/chat') history.push('/admin/chat');
        break;
      case 'teacher':
        if (history.location.pathname !== '/teacher/chat') history.push('/teacher/chat');
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" spacing={4}>
        {/* <Grid item sx={{ zIndex: 100 }}>
          <IconButton aria-label="bell" sx={{ width: 50, height: 50 }}>
            <img width="70%" src="https://img.icons8.com/ios/40/000000/appointment-reminders--v1.png" alt="reminer" />
          </IconButton>
        </Grid> */}
        <Grid item sx={{ zIndex: 100 }}>
          <IconButton aria-label="messages" sx={{ width: 50, height: 50 }} onClick={onClicMessages}>
            <img width="70%" src="https://img.icons8.com/pastel-glyph/40/000000/new-post--v2.png" alt="post" />
          </IconButton>
        </Grid>
        <Grid item>
          <Button endIcon={<KeyboardArrowDownIcon />} onClick={handleClick}>
            <Avatar sx={{ width: 40, height: 40 }} src={user.img} />
          </Button>
        </Grid>
        <Menu
          open={open}
          anchorEl={anchor}
          onClose={() => setAnchor(null)}
          PaperProps={{
            style: {
              borderRadius: '20px',
              background: 'rgba(255, 255, 255, 0.7)',
              border: '1px solid #D8E7E4',
              backdropFilter: 'blur(30px)',
            },
          }}
          // sx={{
          //   /* green2 */

          //   // border: "1px solid #D8E7E4",
          //   // backdropFilter: "blur(30px)",
          //   /* Note: backdrop-filter has minimal browser support */

          //   borderRadius: "20px",
          // }}
        >
          <UserMenu handleClose={handleClose} />
        </Menu>
      </Grid>
    </div>
  );
};
