import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Collapse,
} from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import { helper } from "../../../services/helperSchools";
// import { helperSubjects } from "../../../services/helperSubjects";
import * as fuseActions from "../../../../store/actions/fuse";
import { helperTeacherTraining } from "../../../../services/helperTeacherTraining";
import { helperViewCourses } from "../../../../services/helperViewCourses";
import { helperSubjects } from "../../../../services/helperSubjects";
import * as homeActions from "../../../HomePage/store/actions";

export default function FormDialogAddSchools({
  isOpen,
  setOpenModalSchools,
  defaultLevel,
  allAdminSchools,
  newSubject,
  setNewSubject,
  newSchool,
  setNewSchool,
  schoolId,
  setSchoolsForTeacher,
  defaultLevelId,
  // schoolsReady,
  schoolsForTeacher,
  nameSchool,
}) {
  let user = useSelector(({ loginReducer }) => loginReducer.login.user);

  // console.log(schoolsReady);
  // console.log("schools for teacher =>", schoolsForTeacher);

  const allSchools = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataViewCourseByUser.reqViewCourseByUser
  );
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = useState([0]);
  // const [teacherTraining, setTeacherTraining] = useState(null)
  const [teacherTrainingId, setTeacherTrainingId] = useState(null);
  const [dataToSave, setDataToSave] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const getTeacherTraining = async () => {
      // console.log(user);
      const resTeacherTraining =
        await helperTeacherTraining.getTeacherTrainingByIdUser(user.id);
      // console.log(resTeacherTraining);
      setTeacherTrainingId(resTeacherTraining ? resTeacherTraining.id : null);
    };

    getTeacherTraining();
  }, []);

  useEffect(() => {
    setDataToSave([]);
    const aux = checked.filter((el, index) => index !== 0);
    const rows = aux.map((el) => {
      return { teacherTrainingId, subjectId: el.id };
    });
    // console.log('aux ==>', aux);
    setDataToSave(rows);
  }, [checked]);

  const handleClose = () => {
    setOpenModalSchools(false);
    setOpen(false);
    setNewSubject(null);
    setNewSchool(null);
    setChecked([0]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClickSave = async () => {
    if (dataToSave.length > 0) {
      let validate = false;
      let validData = [];
      if (defaultLevelId !== 4) {
        let newData = dataToSave.map(async (el) => {
          validate = false;
          const aux = schoolsForTeacher.map((item) => {
            if (el.subjectId === item.schoolId) {
              validate = true;
            }
          });
          if (!validate) {
            const getSubjects = await helperSubjects.getSubjectsBySchoolId(
              el.subjectId
            );
            const subject = getSubjects[0];
            return {
              ...el,
              subjectId: subject.id,
            };
          }
        });
        newData = await Promise.all(newData);
        // console.log("new data", newData);
        validData = newData.filter((el) => el && el);
        // console.log("valid data", validData);
      } else {
        // console.log('entra aca');
        validate = false;
        validData = [];
        let newData = dataToSave.map((el) => {
          validate = false;
          const aux = schoolsForTeacher.map((item) => {
            const aux2 = item.subjects.map((element) => {
              if (el.subjectId === element.subjectId) {
                validate = true;
              }
            });
          });
          if (!validate) {
            return el;
          }
        });
        newData = await Promise.all(newData);
        // console.log("new data =>", newData);
        validData = newData.filter((el) => el && el);
      }
      if (validData.length > 0) {
        const array = validData;
        await helperViewCourses.postUserCourse({ array });
        // setSavedChanges(true);
        const getData = await helperViewCourses.getViewCourseByUserId(user.id);
        // dispatch(homeActions.getViewCourseByUserId(user.id));
        const activeData = getData.filter(
          (el) => el.levelId === defaultLevelId
        );
        setSchoolsForTeacher(activeData);
      }
    }
    // dispatch(homeActions.getViewCourseByUserId(user.id));
    handleClose();
  };

  function getSubject(item, labelId, index) {
    // console.log("entra materia");
    // console.log("item ==>", item);
    // console.log("index ==>", index);
    const subject = (
      <ListItem key={item.id} disablePadding>
        <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(item) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={item.nameSchool || item.nameSubject}
          />
        </ListItemButton>
      </ListItem>
    );
    if (defaultLevelId !== 4) {
      return subject;
    } else {
      if (index > 0) {
        return subject;
      } else {
        return null;
      }
    }
  }

  function getSubjectsBySchool(item) {
    return (
      <>
        {item.subjects
          ? item.subjects.map((subject, index) => {
              if (
                subject.schoolId === schoolId &&
                subject.nameSubject !== nameSchool
              ) {
                return getSubject(subject, `labelSubjects${index}`, index);
              } else {
                return null;
              }
            })
          : null}
      </>
    );
  }

  function getSchool(item, labelId) {
    // console.log("entra escuela");
    // console.log("item ==>", item);
    return (
      <Accordion key={item.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{item.nameSchool}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.subjects
            ? item.subjects.map((subject, index) =>
                getSubject(subject, `labelItem${index}`, index)
              )
            : null}
        </AccordionDetails>
      </Accordion>
      // <ListItem key={item.id} disablePadding>
      //   <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
      //     <ListItemIcon>
      //       <Checkbox
      //         edge="start"
      //         checked={checked.indexOf(item) !== -1}
      //         tabIndex={-1}
      //         disableRipple
      //         inputProps={{ "aria-labelledby": labelId }}
      //       />
      //     </ListItemIcon>
      //     <ListItemText
      //       id={labelId}
      //       primary={item.nameSchool || item.nameSubject}
      //     />
      //   </ListItemButton>
      // </ListItem>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        SELECCIONAR{" "}
        {defaultLevel !== "Educación Superior" && newSubject
          ? "MATERIAS"
          : !newSubject && defaultLevel === "Educación Superior" && newSchool
          ? "ESCUELAS"
          : "MATERIAS"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "transparent" }}>
          Seleccione todas las materias que desea agregar
        </DialogContentText>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {allAdminSchools.map((item) => {
            const labelId = `checkbox-list-label-${item.id}`;

            let getSchoolComp = null;
            if (
              defaultLevel !== "Educación Superior" &&
              newSubject &&
              !newSchool
            ) {
              // console.log('1')
              getSchoolComp = getSubject(item, labelId);
            } else if (
              newSubject === null &&
              defaultLevel === "Educación Superior" &&
              newSchool === true
            ) {
              // console.log('2')
              getSchoolComp = getSchool(item, labelId);
            } else if (
              newSubject === true &&
              defaultLevel === "Educación Superior" &&
              newSchool === null
            ) {
              // console.log('3')
              getSchoolComp = getSubjectsBySchool(item);
            }
            return getSchoolComp;
          })}
        </List>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="error">
			Cancelar
		</Button> */}
        <Button onClick={handleClickSave} variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
