import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentTeacherPreparingPage } from '../../components/StudentPage/StudentTeacherPreparingPage';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const StudentTeacherPreparingScreen = () => {
  const classes = useStyles();

  const {  nameSubject } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <LoginedTopBackground title={nameSubject} />
      <StudentTeacherPreparingPage />
      <BotBackground logined />
    </div>
  );
};
