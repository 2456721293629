import React, { useEffect } from "react";
import { Divider, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HistoryUserState } from "./HistoryUserState";
import * as homeActions from "../HomePage/store/actions";
import { helperReqClass } from "../../services/helperReqClass";
// import user1 from "../../assets/image/user1.png";
// import user2 from "../../assets/image/user2.png";

import { useDispatch, useSelector } from "react-redux";

const useStyle = makeStyles({
  root: {
    margin: "20px 40px",
  },
});
export const HistoryPage = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const teacherTraining = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataTeacherTraining.requestTeacherTraining
  );

  const homeClasses = useSelector(
    ({ homeReducer }) => homeReducer.homeData.requestClasses
  );

  const viewReqHomeworks = useSelector(
    ({ homeReducer }) => homeReducer.homeDataViewHomeworks.reqViewHomeworks
  );

  useEffect(() => {
    dispatch(homeActions.getViewRequestClass(user.id));
    dispatch(homeActions.getTeacherTrainingByUserId(user.id));
  }, [dispatch, user.id]);

  useEffect(() => {
    dispatch(homeActions.getHomeworksByTeacherTrainingId(teacherTraining.id));
  }, [dispatch, teacherTraining]);

  const classes = useStyle();

  return (
    <div className={classes.root}>
      {homeClasses.length > 0 &&
        homeClasses.map((item, index) => {
          // console.log("item -->", item);
          // console.log(item.stateRequestClass);
          return (
            <Box key={index}>
              {item.stateRequestClass === "accepted" ||
              item.stateRequestClass === "completed" ? (
                <>
                  <HistoryUserState
                    avatar={item.img}
                    key={index}
                    name={item.nameStudent}
                    nameSubject={item.nameSubject}
                    nameLevel={item.nameLevel}
                    date={item.date}
                    price={item.price}
                    hoursNumber={item.hoursNumber}
                    reqClassId={item.requestClassId}
                    schedule={item.schedule}
                    completed={item.stateRequestClass}
                  />
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
                  />
                </>
              ) : null}
            </Box>
          );
        })}
      {viewReqHomeworks &&
        viewReqHomeworks.map((item, index) => {
          // console.log(item);
          return (
            <Box key={index}>
              <HistoryUserState
                avatar={item.imgStudent}
                // key={index}
                name={item.student}
                nameSubject={item.nameSubject}
                nameLevel={item.nameLevel}
                date={item.date}
                purchase={true}
                detailHomework={item.detailHomework}
                classReq={item.class}
                fileUrl={
                  item.fileUrl && item.fileUrl.trim().length > 0
                    ? item.fileUrl
                    : null
                }
                timeHomework={item.timeHomework}
                completed={item.state}
                // price={item.price}
                // hoursNumber={item.hoursNumber}
              />
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
              />
            </Box>
          );
        })}
      {/* <HistoryUserState avatar={user1} name=" Amanda Castro" completed />
      <Divider />
      <HistoryUserState avatar={user2} name="German Sánchez" /> */}
      <Divider />
    </div>
  );
};
