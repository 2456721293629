import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProfessorProfileScreen } from '../ProfessorScreen/ProfessorProfileScreen';
import { StudentChatScreen } from './StudentChatScreen';
import { StudentHistoryScreen } from './StudentHistoryScreen';
import { StudentHomeScreen } from './StudentHomeScreen';
import { StudentHomeworkRequestScreen } from './StudentHomeworkRequestScreen';
import { StudentMainScreen } from './StudentMainScreen';
import { StudentMenuScreen } from './StudentMenuScreen';
import { StudentOfferScreen } from './StudentOfferScreen';
import { StudentOffersScreen } from './StudentOffersScreen';
import { StudentPaymentScreen } from './StudentPaymentScreen';
import { StudentRateTeacherScreen } from './StudentRateTeacherScreen';
import { StudentResumeScreen } from './StudentResumeScreen';
import { StudentScheduleScreen } from './StudentScheduleScreen';
import { StudentSubjectItemScreen } from './StudentSubjectItemScreen';
import { StudentSubjectScreen } from './StudentSubjectScreen';
import { StudentTeacherPreparingScreen } from './StudentTeacherPreparingScreen';
import { StudentUrgentTeachearsScreen } from './StudentUrgentTeachearsScreen';

export const StudentScreen = () => {
  return (
    <>
      <Switch>
        <Route path="/student/home" component={StudentHomeScreen} />
        <Route path="/student/main" component={StudentMainScreen} />
        <Route path="/student/menu" component={StudentMenuScreen} />
        <Route path="/student/help" component={StudentHomeworkRequestScreen} />
        <Route path="/student/subjects" component={StudentSubjectScreen} />
        <Route path="/student/subject/:id" component={StudentSubjectItemScreen} />
        <Route path="/student/urgent-teachers" component={StudentUrgentTeachearsScreen} />
        <Route path="/student/urgent/preparing" component={StudentTeacherPreparingScreen} />
        <Route path="/student/payment" component={StudentPaymentScreen} />
        <Route path="/student/profile" component={ProfessorProfileScreen} />
        <Route path="/student/history" component={StudentHistoryScreen} />
        <Route path="/student/resume" component={StudentResumeScreen} />
        <Route path="/student/rate" component={StudentRateTeacherScreen} />
        <Route path="/student/schedule" component={StudentScheduleScreen} />
        <Route path="/student/chat" component={StudentChatScreen} />
        <Route path="/student/offer" component={StudentOfferScreen} />
        <Route path="/student/offers" component={StudentOffersScreen} />
        <Redirect from="/student" to="student/home" />
      </Switch>
    </>
  );
};
