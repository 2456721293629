import React from 'react';
import { Box, Divider, Fab, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TeacherCard } from '../../common/TeacherCard';
import moment from 'moment';
import { StdPayDetailCard } from '../StudentPaymentPage/StdPayTeacherInfo/StdPayDetailCard';
import { TeacherCardHorizontal } from '../../common/TeacherCardHorizontal';
import { ScheduleResumeStudent } from './ScheduleResumeStudent';
import { useHistory } from 'react-router-dom';

export const StudentResumePage = ({ avatar, emergency, name, speciality, university, date, schedule, total, hoursNumber, checked, img }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Grid container columns={16} spacing={8} justifyContent="center">
        <Grid item md={4}>
          <div className={classes.centerItemGrid}>
            <TeacherCard name={name} speciality={speciality} university={university} img={avatar} />
            <StdPayDetailCard date={date} hour={schedule} total={total} hoursNumber={hoursNumber} />
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={classes.containerResumen}>
            <div className={classes.titles}>
              <Typography variant="h3" color="initial">
                Resumen
              </Typography>
              <Typography variant="h5" color="initial" /* sx={{ mb: 4 }} */>
                Información del Pedido
              </Typography>
            </div>
            <div style={{ width: '100%' }}>
              <TeacherCardHorizontal img={avatar} name={name} speciality={speciality} university={university} />
            </div>

            <div className={classes.titles}>
              <Typography variant="h5" color="initial">
                Información del Pedido
              </Typography>
            </div>

            <Box className={classes.paymentSelector}>
              {/* <img src={bank} alt="bank" /> */}
              {checked === 2 ? (
                <Typography variant="h5" color="initial">
                  Paypal
                </Typography>
              ) : (
                <Typography variant="h5" color="initial">
                  Transferencia bancaria
                </Typography>
              )}
            </Box>
            {checked === 1 && (
              <Box
                sx={{
                  bgcolor: 'rgba(2, 76, 138, 0.1)',
                  borderRadius: '16px',
                  padding: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {img ? <img src={img} width={400} /> : <></>}
              </Box>
            )}
          </div>
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={4}>
          <div className={classes.centerItemGrid}>
            {!emergency && <ScheduleResumeStudent date={moment().format('DD/MM/YYYY')} hour={moment().format('HH:mm')} />}
            {emergency && (
              <Fab
                variant="extended"
                sx={{
                  height: 60,
                  textTransform: 'capitalize',
                  fontFamily: 'Poppins',
                  width: '100%',
                }}
                color="primary"
              >
                <Typography>Chat con el tutor</Typography>
              </Fab>
            )}
          </div>
        </Grid>
        <Fab
          onClick={() => history.replace('/')}
          variant="extended"
          sx={{
            mt: 4,
            height: 60,
            width: '30%',
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            alignItems: 'center',
          }}
          color="primary"
        >
          <Typography>Listo</Typography>
        </Fab>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: 30, marginRight: 30, marginBottom: 60 },
  paymentSelector: {
    backgroundColor: '#95f8d4',
    borderRadius: '16px',
    padding: '18px',
    marginBottom: '30px',
    width: '100%',
  },
  centerItemGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    zIndex: 2,
  },
  containerResumen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    zIndex: 2,
  },
  titles: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
