import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fab, FormControl, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, TextField, Typography, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import upload from '../../../../assets/icon/upload.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectsSchool, setNameLevel } from '../../../../store/actions/students/subjectsAction';
import { selectLevel } from '../../../../store/actions/students/studentActions';
import { uploadImg } from '../../../../services/partials/uploadImg';
import { useUpLoadHomework } from '../../../../hooks/useUpLoadHomework';
import { useHistory } from 'react-router-dom';
import * as fuseActions from '../../../../store/actions/fuse';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 10px 0px 80px' },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
    },
  },
}));

export const RequestDetail = ({ levels = [], idLevel }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    url: '',
    level: '',
    school: '',
    area: '',
    subjectId: 0,
    detail: '',
    delivery_time: '',
  });

  const history = useHistory();

  const { addHomeWorkStudent, isLoading } = useUpLoadHomework();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const { schools } = useSelector((state) => state.studentReducers.studentReducer);
  const { subjects } = useSelector((state) => state.studentReducers.subjectReducer);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const dispatch = useDispatch();

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose1 = (value) => {
    setAnchorEl1(null);
    console.log('levels=>', value);
    if (value.name) {
      setValues({ ...values, level: value.name, school: '' });
      const selectedLevel = levels.find((item) => item.id === value.idLevel);
      console.log('selectedLevel', selectedLevel);
      dispatch(selectLevel(selectedLevel.id, selectedLevel.nameLevel, selectedLevel.schools));
    } else {
      getNameLevel();
    }
  };

  const handleClose2 = (value) => {
    setAnchorEl2(null);
    console.log('schools=>', value);
    if (value.name) {
      dispatch(getSubjectsSchool(value.id, value.name));
      setValues({ ...values, school: value.name, area: '' });
    }
  };

  const handleClose3 = (value) => {
    setAnchorEl3(null);
    console.log('subjects=>', value);
    if (value.name) {
      setValues({ ...values, area: value.name, subjectId: value.id });
    }
  };

  const handleChangeDetail = (e) => {
    setValues({ ...values, detail: e.target.value });
  };

  const handleChangeTime = (e) => {
    if (e.target.name === 'delivery_time') {
      if (isNumber) {
        setValues({ ...values, delivery_time: e.target.value });
      }
    }
  };

  useEffect(() => {
    getNameLevel();
  }, []);

  const getNameLevel = () => {
    if (levels.length > 0) {
      const level = levels.find((item) => item.id === idLevel);
      setValues({ ...values, level: level.nameLevel });
    }
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setValues({ ...values, url: url });
  };

  const onSubmit = async () => {
    if (values.url === '') {
      dispatch(
        fuseActions.showMessage({
          message: 'Debe subir un archivo de la tarea',
          variant: 'warning',
        })
      );
    } else if (values.subjectId === 0) {
      dispatch(
        fuseActions.showMessage({
          message: 'Seleccione una area de conocimiento',
          variant: 'warning',
        })
      );
    } else if (values.detail === '') {
      dispatch(
        fuseActions.showMessage({
          message: 'Debe llenar el campo Detalles sobre la tarea',
          variant: 'warning',
        })
      );
    } else if (values.delivery_time === '') {
      dispatch(
        fuseActions.showMessage({
          message: 'Debe llenar el campo Tiempo de entrega',
          variant: 'warning',
        })
      );
    } else {
      await addHomeWorkStudent(values.url, values.subjectId, values.detail, values.delivery_time, 'nignuno', '0', 'pending', user.id, '');
      history.replace('/student/home');
    }
  };

  var isNumber = null;

  function valideKey(evt) {
    let code = evt.key;
    if (code === 'Backspace') {
      isNumber = true;
      return true;
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      isNumber = true;
      return true;
    } else {
      isNumber = false;
      return false;
    }
  }

  return (
    <div className={classes.root}>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Sube tu tarea</InputLabel>
        <OutlinedInput
          disabled
          label="Sube tu tarea"
          sx={{ mb: 6 }}
          className={classes.textField}
          value={values.url}
          endAdornment={
            <InputAdornment position="end">
              <Fab color="secondary" size="small" edge="end">
                <label htmlFor="icon-button-file">
                  <input accept="application/pdf, application/docx" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeFile(e)} />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <img src={upload} alt="upload" />
                  </IconButton>
                </label>
              </Fab>
            </InputAdornment>
          }
        />
        {/* <span>Sube tu tarea en pdf, docx o como imagen.</span> */}
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Nivel</InputLabel>
        <OutlinedInput
          label="Nivel"
          sx={{ mb: 6 }}
          value={values.level}
          onMouseDown={handleClick1}
          className={classes.textField}
          endAdornment={
            <InputAdornment position="end">
              <Fab onClick={handleClick1} color="secondary" size="medium" edge="end">
                {anchorEl1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Fab>
            </InputAdornment>
          }
        />
        <Menu id="basic-menu" anchorEl={anchorEl1} open={open1} onClose={handleClose1} PaperProps={{ sx: { width: '30%' } }}>
          {levels.length &&
            levels.map((item, idx) => (
              <MenuItem key={idx} onClick={() => handleClose1({ name: item.nameLevel, idLevel: item.id })}>
                {item.nameLevel}
              </MenuItem>
            ))}
        </Menu>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Escuela</InputLabel>
        <OutlinedInput
          label="Escuela"
          sx={{ mb: 6 }}
          value={values.school}
          onMouseDown={handleClick2}
          className={classes.textField}
          endAdornment={
            <InputAdornment position="end">
              <Fab onClick={handleClick2} color="secondary" size="medium" edge="end">
                {anchorEl2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Fab>
            </InputAdornment>
          }
        />
        <Menu id="basic-menu" anchorEl={anchorEl2} open={open2} onClose={handleClose2} PaperProps={{ sx: { width: '30%' } }}>
          {schools.length > 0 &&
            schools.map((item, idx) => (
              <MenuItem key={idx} onClick={() => handleClose2({ name: item.nameSchool, id: item.schoolId })}>
                {item.nameSchool}
              </MenuItem>
            ))}
        </Menu>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Área de Conocimiento</InputLabel>
        <OutlinedInput
          label="Área de Conocimiento"
          sx={{ mb: 6 }}
          value={values.area}
          onMouseDown={handleClick3}
          className={classes.textField}
          endAdornment={
            <InputAdornment position="end">
              <Fab onClick={handleClick2} color="secondary" size="medium" edge="end">
                {anchorEl2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Fab>
            </InputAdornment>
          }
        />
        <Menu id="basic-menu" anchorEl={anchorEl3} open={open3} onClose={handleClose3} PaperProps={{ sx: { width: '30%' } }}>
          {subjects.length > 0 &&
            subjects.map((item, idx) => (
              <MenuItem key={idx} onClick={() => handleClose3({ name: item.nameSubject, id: item.subjectId })}>
                {item.nameSubject}
              </MenuItem>
            ))}
        </Menu>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Detalles sobre la tarea</InputLabel>
        <OutlinedInput placeholder="Detalles sobre la tarea" label="Detalles sobre la tarea" value={values.detail} multiline rows={4} onChange={handleChangeDetail} sx={{ mb: 6 }} className={classes.textField} />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Tiempo de entrega</InputLabel>
        <OutlinedInput placeholder="Tiempo de entrega" label="Tiempo de entrega" value={values.delivery_time} onKeyDown={(e) => valideKey(e)} onChange={handleChangeTime} name="delivery_time" sx={{ mb: 6 }} className={classes.textField} />
      </FormControl>
      <Fab variant="extended" sx={{ textTransform: 'capitalize', width: '100%', height: '70px', mb: 2 }} color="primary" onClick={onSubmit}>
        <Typography variant="body1"> Enviar solicitud</Typography>
      </Fab>
    </div>
  );
};
