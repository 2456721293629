import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { AdminProfessorsPage } from '../../components/AdminPage/AdminProfessorsPage';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdminProfessors = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoginedTopBackground search title="Profesores" />
      <AdminProfessorsPage />
      <BotBackground logined />
    </div>
  );
};
