import React from 'react';
import { makeStyles } from '@mui/styles';
import { AdminProfessorInfoCard } from '../../../AdminPage/AdminProfessorPage/AdminProfessorInfo/AdminProfessorInfoCard';
import { Chip, Typography } from '@mui/material';
import { colorsList } from '../../../../utils/colorsList';
const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px' },
}));
export const StdPreparingInfo = ({ professionalDescription = '', subjects = [], name = '', price, img, status }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AdminProfessorInfoCard name={name} price={price} img={img} status={status} />
      <Typography variant="h5" sx={{ fontWeight: 400, my: 4 }}>
        {professionalDescription}
      </Typography>
      {subjects.length > 0 && subjects.map((item, idx) => <Chip key={idx} label={<Typography sx={{ fontSize: '14px' }}>{item.nameSubject}</Typography>} sx={{ bgcolor: colorsList[idx], margin: 0.5 }} />)}
    </div>
  );
};
