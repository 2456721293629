import React from 'react';
import { LoginButton } from './LoginButton';
import { LogoIlustroImg } from './LogoIlustroImg';
import { RegisterButton } from './RegisterButton';
// import { RegisterLaterLink } from "./RegisterLaterLink";
// import { SocialContainer } from "./SocialContainer";

const startContainerStyle = {
  display: "flex",
  height: "60vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'center',
  // backgroundColor: 'red',
};

export const StartContainer = () => {
  return (
    <div style={startContainerStyle}>
      <LogoIlustroImg />
      <RegisterButton />
      <LoginButton />
      {/* <RegisterLaterLink /> */}
      {/* <SocialContainer /> */}
    </div>
  );
};
