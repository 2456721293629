import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  professor: {
    backgroundColor: theme.palette.warning.secondary,
    borderRadius: "10px",
    cursor:'pointer',
    padding: "30px 60px",
    marginTop: "20px",
  },
  student: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: "10px",
    cursor:'pointer',
    padding: "30px 60px",
    marginTop: "20px",
  },
  level: {
    backgroundColor: theme.palette.error.main,
    borderRadius: "10px",
    cursor:'pointer',
    padding: "30px 60px",
    marginTop: "20px",
  },
  parameter: {
    backgroundColor: theme.palette.success.main,
    borderRadius: "10px",
    cursor:'pointer',
    padding: "30px 60px",
    marginTop: "20px",
  },
}));
export const RoleCard = (props) => {
  const { icon, title, reverse, role, route } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <div
      className={clsx(classes[role])}
      onClick={() => history.push(`/admin/${route}`)}
    >
      <Grid container alignItems="center">
        {reverse ? (
          <>
            <Grid item md={6}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {title}
              </Typography>
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
              <img src={icon} alt={icon} style={{}} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={3}>
              <img src={icon} alt={icon} style={{}} />
            </Grid>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {title}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
