import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTeacherInfoSelected } from '../../../../../store/actions/students/teachersActions';
import { useInvoices } from '../../../../../hooks/useInvoices';
import moment from 'moment';
import { showPayment } from '../../../../../store/actions/students/uiPaymentAction';
import { getRequestClassById, setIdRequestClass, setRequestType } from '../../../../../store/actions/students/requestClassActions';
import { getInvoicesById, setResumen, setResumenPrice } from '../../../../../store/actions/students/resumenActions';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid black',
    marginBottom: '30px',
    paddingTop: 15,
    paddingBottom: 15,
  },
}));
export const StdMainRequestItem = ({ obj, icon, name, subject, response, status, img, typeRequest, date }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { getInvoiceByRequestAndType } = useInvoices();

  const renderStatusRequest = () => {
    switch (status) {
      case 'pending':
        return <Typography style={{ color: '#FEB098', fontSize: 15 }}>Solicitud enviada</Typography>;
      case 'accepted':
        return <Typography style={{ color: '#10E296', fontSize: 15 }}>Solicitud aceptada</Typography>;
      case 'waiting':
        return <Typography style={{ color: '#CAA5FE', fontSize: 15 }}>Pago en revisión</Typography>;
      case 'rejected':
        return <Typography style={{ color: '#EECE25', fontSize: 15 }}>Solicitud rechazada</Typography>;
      case 'completed':
        return <Typography style={{ color: '#65A2B6', fontSize: 15 }}>Completado</Typography>;

      default:
        return <></>;
    }
  };

  const handleNavigation = async (state) => {
    if (typeRequest == 'class') {
      switch (state) {
        case 'pending':
          break;

        case 'accepted':
          console.log('Solicitud aceptada');
          dispatch(getTeacherInfoSelected(obj.teacherId, 1, 1));
          dispatch(showPayment(true));
          dispatch(getRequestClassById(obj.requestClassId));
          dispatch(setRequestType('class'));
          history.push('/student/payment');
          break;

        case 'waiting':
          const respWaiting = await getInvoiceByRequestAndType(obj.requestClassId, 'class');
          dispatch(getTeacherInfoSelected(obj.teacherId, 1, 1));
          history.push('/student/resume', { idRequest: obj.requestClassId, idInvoice: respWaiting });
          break;

        case 'rejected':
          break;

        case 'completed':
          const resp = await getInvoiceByRequestAndType(obj.requestClassId, 'class');
          dispatch(getTeacherInfoSelected(obj.teacherId, 1, 1));
          history.push('/student/resume', { idRequest: obj.requestClassId, idInvoice: resp });
          break;

        default:
          return 1;
      }
    } else {
      switch (state) {
        case 'pending':
          console.log('Solicitud aceptada', obj);
          history.push('/student/offer', { idRequest: obj.idRequest, time: obj.timeHomework, nameLevel: obj.nameLevel, titleNameSchool: obj.nameSubject, fileUrl: obj.fileUrl });
          break;

        case 'accepted':
          console.log('Solicitud aceptada', obj);
          // // const respAccepted = await getInvoiceByRequestAndType(obj.requestClassId, 'class');
          // dispatch(getTeacherInfoSelected(obj.teacherId, 1, 1));
          // dispatch(showPayment(true));
          // dispatch(getRequestClassById(obj.requestClassId));
          // history.push('/student/payment');
          break;

        case 'waiting':
          console.log('waiting', obj);
          // const respwaiting = await getInvoiceByRequestAndType(obj.idRequest, 'homework');
          // if (respwaiting) {
          //   dispatch(getInvoicesById(respwaiting.id));
          // }
          dispatch(getTeacherInfoSelected(obj.teacherId, obj.levelId, 1));
          dispatch(setResumenPrice(obj.price));
          dispatch(setResumen(obj.timeHomework, obj.date, moment(obj.date).format('HH:mm')));
          history.push('/student/resume');
          break;

        case 'rejected':
          break;

        case 'completed':
          console.log('completed', obj);
          // const respcompleted = await getInvoiceByRequestAndType(obj.idRequest, 'homework');
          // if (respcompleted) {
          //   dispatch(getInvoicesById(respcompleted.id));
          // }
          if (obj.teacherId !== 0) {
            dispatch(getTeacherInfoSelected(obj.teacherId, obj.levelId, 1));
          }
          dispatch(setResumen(obj.timeHomework, obj.date, moment(obj.date).format('HH:mm')));
          dispatch(setResumenPrice(obj.price));
          history.push('/student/resume');
          break;

        default:
          return 1;
      }
    }
  };

  return (
    <div className={classes.root} onClick={() => handleNavigation(status)}>
      <Grid container alignItems="center" justifyContent="space-around" spacing={4} sx={{ px: 2 }}>
        <Grid item xs={2}>
          <Avatar sx={{ width: '3rem', height: '3rem' }} src={img} />
        </Grid>
        <Grid item xs={8}>
          <Typography>{name}</Typography>
          <Typography sx={{ color: '#6F6F6F', fontWeight: 400, fontSize: '15px' }}>{moment(new Date(date)).format('LLL')}</Typography>
          {subject && <Typography sx={{ color: '#6F6F6F', fontWeight: 400, fontSize: '15px' }}>{subject}</Typography>}

          {response && <Typography sx={{ fontSize: '12px', color: '#FEB098' }}>{response}</Typography>}
          {typeRequest === 'class' ? renderStatusRequest() : renderStatusRequest()}
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          <img src={icon} alt="intro" />
        </Grid>
      </Grid>
    </div>
  );
};
