import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TeacherCard } from '../../../common/TeacherCard';
import { StdPayDetailCard } from './StdPayDetailCard';
import { Box, Grid, Typography, IconButton, iconButtonClasses, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { calculatePrice, getRequestClassById, setHoursNumberRequestClass, setIdRequestClass, setPriceRequestClass } from '../../../../store/actions/students/requestClassActions';
import { usePayments } from '../../../../hooks/usePayments';
import { useLevels } from '../../../../hooks/useLevels';
import { useClass } from '../../../../hooks/useClass';
import { useInvoices } from '../../../../hooks/useInvoices';
import { showPayment } from '../../../../store/actions/students/uiPaymentAction';
import * as fuseActions from '../../../../store/actions/fuse';

import trLocale from 'moment/locale/es';
import moment from 'moment';
import { useChat } from '../../../../hooks/useChat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const StdPayTeacherInfo = ({ img, name = '', speciality = '', university = '', maxHours = 1, price = 0 }) => {
  const classes = useStyles();
  const [max, setmax] = useState(1);

  const { getAllPayments } = usePayments();
  const { getLevelById } = useLevels();
  const { postClass } = useClass();
  const { addInvoicesBackEnd } = useInvoices();
  const { postChannel } = useChat();
  const history = useHistory();

  const { date, schedule, hoursNumber, priceTotal } = useSelector(({ studentReducers }) => studentReducers.requestClassReducer);
  const dispatch = useDispatch();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const requestClass = useSelector(({ studentReducers }) => studentReducers.requestClassReducer);
  const level = useSelector(({ studentReducers }) => studentReducers.studentReducer);
  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);
  const { showPaymentPart } = useSelector(({ studentReducers }) => studentReducers.uiPaymentReducer);

  console.log(hoursNumber)

  const setPriceAndHoursNumber = () => {
    // dispatch(setHoursNumberRequestClass(max));
    dispatch(setPriceRequestClass(price * hoursNumber));
  };

  const post = async () => {
    // const teacher = await getUserById(userId);
    let userCourseId = teacherSelected.userCourseId;
    let studentIdR = user.id + '';
    let studentId = user.id;
    let date = requestClass.date;
    let schedule = requestClass.schedule;
    let hoursNumber = requestClass.hoursNumber;
    let price = requestClass.priceTotal;
    let stateRequestClass = 'pending';
    const getLevel = await getLevelById(level.idLevel);
    let percentPrice = (parseInt(getLevel.percentTutorial + '') * price) / 100;

    const respRequestClass = await postClass(userCourseId, studentId, date, schedule, hoursNumber, price, percentPrice, stateRequestClass);

    dispatch(showPayment(true));
    dispatch(getRequestClassById(respRequestClass.id));
    // dispatch(setIdRequestClass(respRequestClass.id));
    history.replace('/student/history');

    if (studentIdR > teacherSelected.userId) {
      postChannel(`${teacherSelected.userId},${studentId}`);
    } else {
      postChannel(`${studentId},${teacherSelected.userId}`);
    }
    // let trLocale = require('moment/locale/es');
    moment.updateLocale('es', trLocale);
    const dateFormatt = new Date(requestClass.date);
    let result = moment(dateFormatt).format('dddd D MMMM YYYY');
    // dispatch(
    //   fuseActions.showMessage({
    //     message: `Solicituda de tutoría para el dia ${result}`,
    //     variant: 'success',
    //   })
    // );
    // await sendMesaage([teacher.expoToken], `Solicituda de tutoría para el dia ${result}`)
    // navigator.dispatch(CommonActions.navigate({
    //     name: 'SentRequestsScreen',
    // }))
  };

  return (
    <div className={classes.root}>
      <TeacherCard img={img} name={name} speciality={speciality} university={university} />
      <StdPayDetailCard date={date} hour={schedule} total={priceTotal} hoursNumber={hoursNumber} />
      {!showPaymentPart && (
        <div>
          <Grid container sx={{ mt: 4 }} columns={16}>
            <Grid item md={8}>
              <Typography>Elige el número de horas de clase</Typography>
            </Grid>
            <Grid item md={8} container justifyContent="space-between" alignItems="center">
              <Grid item>
                <IconButton
                  aria-label="less"
                  onClick={() => {
                    if (hoursNumber > 1) {
                      dispatch(calculatePrice(price, hoursNumber - 1));
                    }
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: 'rgba(255, 236, 136, 0.4)',
                    borderRadius: '4px',
                    border: '0.5px solid black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{hoursNumber}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="plus"
                  onClick={() => {
                    if (hoursNumber < maxHours) {
                      dispatch(calculatePrice(price, hoursNumber + 1));
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Typography sx={{ mt: 4, color: 'rgba(235, 55, 0, 1)', fontWeight: 300 }}>*Este profesor acepta un número max de {maxHours > 1 ? `${maxHours} horas` : `${maxHours} hora`}</Typography>
        </div>
      )}
      <Fab
        onClick={post}
        variant="extended"
        sx={{
          display: !showPaymentPart ? 'flex' : 'none',
          height: 60,
          ml: 4,
          mt: 4,
          textTransform: 'capitalize',
          fontFamily: 'Poppins',
        }}
        color="primary"
      >
        <Typography>Confirmar solicitud</Typography>
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px', display: 'flex', justifyContent: 'center', flexDirection: 'column' },
}));
