import React, { useState,} from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@mui/material";
import { AdminProfessorStateList } from "./AdminProfessorStateList";
import { AdminProfessorsList } from "./AdminProfessorsList";
import { useTeachers } from "../../../hooks/useTeachers";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminProfessorsPage = () => {
  const classes = useStyles();

  const { teachers } = useTeachers();
  const [defaultState, setDefaultState] = useState("pending");

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={3}>
          <AdminProfessorStateList
            teachers={teachers}
            defaultState={defaultState}
            setDefaultState={setDefaultState}
          />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={8}>
          <AdminProfessorsList
            AllTeachers={teachers}
            defaultState={defaultState}
            setDefaultState={ setDefaultState}
          />
        </Grid>
      </Grid>
    </div>
  );
};
