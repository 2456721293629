import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px",
    background: "rgba(196, 196, 196, 0.2)",
    borderRadius: 10,
    marginBottom: "50px",
  },
}));
export const ProfitsInfoItem = (props) => {
  const classes = useStyles();
  const { bankname, address, cardNumber } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h5" sx={{ fontSize: "25px" }}>
        {bankname}
      </Typography>
      <br />
      <Typography>{address}</Typography>
      <br />
      <Typography>{cardNumber}</Typography>
    </div>
  );
};
