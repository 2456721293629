import { makeStyles } from '@mui/styles';
import { Avatar, Badge, Chip, Grid, Rating, Typography } from '@mui/material';
import user1 from '../../../../../assets/image/user1.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { colorsList } from '../../../../../utils/colorsList';
import { useDispatch } from 'react-redux';
import { getTeacherInfoSelected } from '../../../../../store/actions/students/teachersActions';
import { getTeacherInfoSelectedAdmin } from '../../../../../store/actions/admin/teachersActions';
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FCFCFC',
    border: '1.5px solid #D8E7E4',
    padding: '16px',
    borderRadius: '20px',
    position: 'relative',
    marginBottom: '30px',
    '&:hover': {
      borderColor: '#95F8D4',
      cursor: 'pointer',
    },
  },
  price: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
}));

export const ProfessorItem = ({ admin = false, price = 0, status, subjects = [], name, img, review, subject, desc, university, role, certifications, teacherTrainingId, userId, levelId }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectTeacher = () => {
    if (!admin) {
      dispatch(getTeacherInfoSelected(userId, levelId));
    }
    if (admin) {
      dispatch(getTeacherInfoSelectedAdmin(userId));
      // history.replace(`/admin/professor/${name}`, { name, review, subject, price, img, desc, university, role, certifications, status, teacherTrainingId, userId });
    }
  };

  return (
    <div className={classes.root} onClick={() => selectTeacher()}>
      {!admin && (
        <Typography className={classes.price} sx={{ color: '#A5A5A5' }}>
          ${price}/h
        </Typography>
      )}
      <Grid container alignItems="center">
        <Grid item md={3}>
          {/* <Badge
            overlap="circular"
            badgeContent=" "
            // color={status == 'accepted' ? 'success' : status == 'rejected' ? 'secondary' : 'warning'}
            // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          > */}
            <Avatar src={img} sx={{ width: 86, height: 86 }} />
          {/* </Badge> */}
        </Grid>
        <Grid item md={9}>
          <Typography>{name}</Typography>
          <Rating value={review} readOnly />
          <br />
          {subjects.length > 0 &&
            subjects.map((item, idx) => {
              return <Chip key={idx} label={<Typography sx={{ fontSize: '14px' }}>{item.nameSubject}</Typography>} sx={{ bgcolor: colorsList[idx], margin: 0.5 }} />;
            })}
        </Grid>
      </Grid>
    </div>
  );
};
