import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../styles/theme';
import '../styles/StartStyle.css';
import { Provider } from 'react-redux';
import store from '../store/store';
import FuseMessage from '../components/common/FuseMessage/FuseMessage';

import { AuthProvider } from '../auth/AuthProvider';
import { AppRouter } from './AppRouter';
import { MobileScreen } from '../screens/MobileScreen';

export default function App() {
  const [isMobile, setIsMobile] = useState(false);

  const detectarMovil = () => {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    detectarMovil();
  }, []);

  console.log('isMobile', isMobile);

  return (
    <div>
      {!isMobile ? (
        <Provider store={store}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <AppRouter />
              <FuseMessage />
            </ThemeProvider>
          </AuthProvider>
        </Provider>
      ) : (
        <MobileScreen />
      )}
    </div>
  );
}
