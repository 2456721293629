import { Avatar, Badge, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';

export const ItemChat = ({ name, status, img, func }) => {
  const classes = useStyles();
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  return (
    <div className={classes.root} onClick={func}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item md={3}>
          <Badge overlap="circular" badgeContent=" "/*  color={user.rol === 'admin' ? (status === 'teacher' ? 'success' : status === 'admin' ? 'warning' : status === 'student' ? 'warning' : 'success') : status === 'teacher' ? 'success' : status === 'admin' ? 'warning' : 'success' }*/>
            <Avatar src={img} sx={{ width: 80, height: 80 }} />
          </Badge>
        </Grid>
        <Grid item md={9}>
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FCFCFC',
    border: '1.5px solid #D8E7E4',
    padding: 10,
    borderRadius: 20,
    position: 'relative',
    marginBottom: 20,
    marginRight: 10,
    '&:hover': {
      borderColor: '#95F8D4',
      cursor: 'pointer',
    },
  },
}));
