import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useClass } from '../../hooks/useClass';
import { useUpLoadHomework } from '../../hooks/useUpLoadHomework';
import { StdMainRequestItem } from './StudentMainPage/StdMainRequestList/StdMainRequestItem';
import introIcon from '../../assets/icon/introIcon.svg';
import filterIcon from '../../assets/icon/filterIcon.svg';
import { EmptyState } from '../../components/common/EmptyState';
import { setInitStateResume } from '../../store/actions/students/requestClassActions';

export const StudentHistoryPage = () => {
  const classes = useStyles();
  const { getClassForReview } = useClass();
  const { getWorkStudentBackEnd, isLoading } = useUpLoadHomework();
  const [requestClass, setRequestClass] = useState([]);
  const [requestHomeworks, setRequestHomeworks] = useState([]);
  const [arrayRequest, setArrayRequest] = useState([]);
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const dispatch = useDispatch();

  useEffect(() => {
    getRequestClasses();

    getRequestHomeworks();
    dispatch(setInitStateResume());
  }, []);

  const getRequestHomeworks = async () => {
    if (user) {
      const resp = await getWorkStudentBackEnd(user.id);
      setRequestHomeworks(resp);
    }
  };

  const getRequestClasses = async () => {
    const resp = await getClassForReview();
    if (user) {
      const filterClass = resp.filter((item) => item.studentId === user.id);
      setRequestClass(filterClass);
    }
  };

  // console.log('classReq==>', requestClass);
  // console.log('requestHomeworks==>', requestHomeworks);

  return (
    <div className={classes.root}>
      {requestClass.length > 0 || requestHomeworks.length > 0 ? (
        <Grid container spacing={4} justifyContent="center">
          <Grid item md={6} xs={12}>
            <div className={classes.typeReq}>
              <Typography variant="h3" color="initial" sx={{ mb: '2rem' }}>
                Clases
              </Typography>
              {requestClass.length > 0 && requestClass.map((item, idx) => <StdMainRequestItem obj={item} key={idx} name={item.nameLevel} subject={item.nameSubject} status={item.stateRequestClass} icon={introIcon} img={item.img} typeRequest="class" date={item.date} />)}
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.typeReq}>
              <Typography variant="h3" color="initial" sx={{ mb: '2rem' }}>
                Tareas
              </Typography>
              {requestHomeworks.length > 0 && requestHomeworks.map((item, idx) => <StdMainRequestItem obj={item} key={idx} name={item.nameLevel} subject={item.nameSubject} status={item.state} icon={filterIcon} img={item.imgStudent} typeRequest="homework" date={item.date} />)}
            </div>
          </Grid>
        </Grid>
      ) : (
        <EmptyState title="No hay solicitudes" />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: 40, marginRight: 40 },
  typeReq: { width: '100%' },
}));
