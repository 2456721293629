import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ProfitsItem } from "./ProfitsItem";
import { Divider, FormControl, MenuItem, Select, Box } from "@mui/material";
// import axios from 'axios';
// import { apiUrl } from '../../../../config/apiConfig';
// import { EmptyState } from '../../../common/EmptyState';

// import { useTotalService } from "../../../../hooks/useTotalService";
// import useForm from "../../../../hooks/useForm";
// import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: { padding: "0px 100px", marginTop: "6rem" },
}));

export const ProfitsList = ({ month, year, total, percent, monthSelected }) => {
  // const [monthlyHomeworks, setMonthlyHomeworks] = useState([]);

  // const getTotalMonthlyHomeroksByUserId = async () => {
  //   const resp = await axios.get(`${apiUrl}view/getTotalMonthlyHomeroksByUserId/${userId}`);
  //   if (resp.status === 200) {
  //     if (resp.data.msg) setMonthlyHomeworks(resp.data.DataBanks);
  //   }
  // };

  // useEffect(() => {
  //   getTotalMonthlyHomeroksByUserId();
  // }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ProfitsItem
        // service="Total Servicios Realizados"
        // price="$300"
        // date="Abril 2021"
        month={month}
        year={year}
        total={total}
        percent={percent}
        monthSelected={monthSelected}
      />
    </div>
  );
};
