import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { AdProHistoryItem } from "./AdProHistoryItem";
import { Divider, FormControl, MenuItem, Select } from "@mui/material";
import user1 from "../../../assets/image/user1.png";
import user2 from "../../../assets/image/user2.png";
import { apiUrl } from "../../../config/apiConfig";
import axios from "axios";
import moment from "moment";
import { EmptyState } from "../../common/EmptyState";
import { useSelector, useDispatch } from "react-redux";
import { useTotalService } from "../../../hooks/useTotalService";
import useForm from "../../../hooks/useForm";

const useStyles = makeStyles((theme) => ({
  root: { padding: "0px 60px", position: "relative" },
  filter: {
    position: "absolute",
    top: "0px",
    right: "60px",
    width: 200,
    height: 50,
  },
}));

export const AdminProfessorHistoryPage = ({ trainingId }) => {
  const classes = useStyles();

  const [reqHomeworks, setReqHomeworks] = useState([]);

  const [monthSelected, setMonthSelected] = useState("January");
  const { monthSearch, onChange } = useForm({ monthSearch: "January" });

  const search = (month) => {
    let aux = "";
    if (month === "January") {
      aux = "Enero";
    } else if (month === "February") {
      aux = "Febrero";
    } else if (month === "March") {
      aux = "Marzo";
    } else if (month === "April") {
      aux = "Abril";
    } else if (month === "May") {
      aux = "Mayo";
    } else if (month === "June") {
      aux = "Junio";
    } else if (month === "July") {
      aux = "Julio";
    } else if (month === "August") {
      aux = "Agosto";
    } else if (month === "September") {
      aux = "Septiembre";
    } else if (month === "October") {
      aux = "Octubre";
    } else if (month === "November") {
      aux = "Noviembre";
    } else if (month === "December") {
      aux = "Diciembre";
    }
    setMonthSelected(aux);
    // getHomeworks();
    console.log("first");
  };

  const getHomeworks = async () => {
    const resp = await axios.get(
      `${apiUrl}view/RequestHomeworks/${trainingId}`
    );
    if (resp.status === 200) {
      if (resp.data.msg)
        setReqHomeworks(
          resp.data.resultH.filter(
            (item) => moment(item.date).format("MMMM") === monthSearch
          )
        );
    }
  };

  useEffect(() => {
    getHomeworks();
  }, [monthSearch]);

  useEffect(() => {
    getHomeworks();
  }, []);

  // console.log(reqHomeworks);

  return (
    <div className={classes.root}>
      <div className={classes.filter}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monthSearch}
            sx={{
              borderRadius: 6,
              background: "#F4F7F5",
              border: "none",
              height: 50,
              width: "70%",
              textAlign: "center",
              transform: "translatey(-5rem)",
            }}
            onChange={(e) => {
              onChange(e.target.value, "monthSearch");
              if (e.target.value !== "Select") {
                search(e.target.value);
              }
            }}
          >
            <MenuItem value="Select" key="Select">
              Seleccione un mes
            </MenuItem>
            <MenuItem value="January" key="January">
              Enero
            </MenuItem>
            <MenuItem value="February" key="February">
              Febrero
            </MenuItem>
            <MenuItem value="March" key="March">
              Marzo
            </MenuItem>
            <MenuItem value="April" key="April">
              Abril
            </MenuItem>
            <MenuItem value="May" key="May">
              Mayo
            </MenuItem>
            <MenuItem value="June" key="June">
              Junio
            </MenuItem>
            <MenuItem value="July" key="July">
              Julio
            </MenuItem>
            <MenuItem value="August" key="August">
              Agosto
            </MenuItem>
            <MenuItem value="September" key="September">
              Septiembre
            </MenuItem>
            <MenuItem value="October" key="October">
              Ocutubre
            </MenuItem>
            <MenuItem value="November" key="November">
              Noviembre
            </MenuItem>
            <MenuItem value="December" key="December">
              Diciembre
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {reqHomeworks.length > 0 ? (
        reqHomeworks.map((item, idx) => (
          <div key={idx}>
            <AdProHistoryItem
              name={item.student}
              date={moment(item.date).locale("es").format("LL")}
              time={moment(item.date).format("h:mm")}
              price={item.price || 0}
              status={item.state}
              url={item.fileUrl}
              period={`${item.timeHomework}h`}
              avatar={item.imgStudent || user1}
              reqHomeworkId={item.requestHomeworkId}
            />
            <Divider sx={{ my: 4 }} />
          </div>
        ))
      ) : (
        <EmptyState title="No existe un historial" />
      )}
    </div>
  );
};
