import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Button,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as loginActions from "./store/actions";

const useStyle = makeStyles({
  logContent: {
    minWidth: "90%",
    marginTop: "90px",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: "20px",
    },
  },
});
export const LoginContent = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    password: "",
    email: "",

    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = () => {
    const userData = {
      email: values.email,
      password: values.password,
      history,
    };
    dispatch(loginActions.loginUser(userData));
  };
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center">
        <div className={classes.logContent}>
          <Grid container direction="column" alignItems="center">
            <Typography variant="h3" sx={{ mb: 8 }}>
              Iniciar Sesión
            </Typography>
            <TextField
              sx={{ mb: 8 }}
              placeholder="E-mail"
              label="E-mail"
              onChange={handleChange("email")}
              value={values.email}
              className={classes.textField}
              fullWidth
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                placeholder="Contraseña"
                label="Contraseña"
                sx={{ mb: 8 }}
                onChange={handleChange("password")}
                value={values.password}
                className={classes.textField}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Fab
              variant="extended"
              sx={{
                textTransform: "capitalize",
                width: "100%",
                height: "70px",
                mb: 2,
              }}
              color="primary"
              onClick={onSubmit}
            >
              <Typography variant="body1"> Iniciar Sesión</Typography>
            </Fab>
            <Button onClick={() => history.push("/register")}>
              <Typography
                sx={{ textTransform: "capitalize", color: "#7E7E7E" }}
              >
                Registrarme
              </Typography>{" "}
            </Button>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
