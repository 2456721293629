import React, { useState, useRef, useEffect } from "react";
import { Typography, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { helperLevels } from "../../../services/helperLevels";
import FormDialog from "./Modals/index";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },
}));

const bkgDefault = "rgba(216, 231, 228, 1)";
const bkgEducacionFisica = "rgba(149, 248, 212, 1)";
const bkgEducacionSecundaria = "rgba(213, 184, 253, 1)";
const bkgBachillerato = "rgba(255, 236, 136, 1)";
const bkgEducacionSuperior = "rgba(164, 204, 223, 1)";

const bkgActive = "#FFEC88";

export const LevelSort = ({
  allLevels,
  defaultLevel,
  setDefaultLevel,
  level,
  teacherActive,
  setDefaultLevelId,
}) => {
  const classes = useStyles();

  const [firstLoad, setFirstLoad] = useState("Educación Básica");
  const [openModal, setOpenModal] = useState(false);

  let refBoxEducacionBasica = useRef();
  let refBoxEducacionSecundaria = useRef();
  let refBoxBachillerato = useRef();
  let refBoxEducacionSuperior = useRef();
  let refBoxDefaultSchool = useRef();

  const getBkg = (nameLevel) => {
    const BKG_LEVELS = {
      "Educación Básica": () => bkgEducacionFisica,
      "Educación Secundaria": () => bkgEducacionSecundaria,
      "Bachillerato ": () => bkgBachillerato,
      "Educación Superior": () => bkgEducacionSuperior,
      defaultSchool: () => bkgDefault,
    };

    const bkgLevel = BKG_LEVELS[nameLevel] || BKG_LEVELS.defaultSchool;
    return bkgLevel();
  };

  const getReferenceBox = (level) => {
    // console.log(level);
    const REF_LEVELS = {
      "Educación Básica": () => refBoxEducacionBasica,
      "Educación Secundaria": () => refBoxEducacionSecundaria,
      "Bachillerato ": () => refBoxBachillerato,
      "Educación Superior": () => refBoxEducacionSuperior,
      defaultSchool: () => refBoxDefaultSchool,
    };

    const refLevel = REF_LEVELS[level] || REF_LEVELS.defaultSchool;
    // console.log(typeof(refLevel));
    return refLevel();
  };

  const LEVEL_HANDLER = {
    "Educación Básica": (level) => {
      refBoxEducacionBasica.current.style.background = getBkg(level);
      refBoxEducacionSecundaria.current.style.background = bkgDefault;
      refBoxBachillerato.current.style.background = bkgDefault;
      refBoxEducacionSuperior.current.style.background = bkgDefault;
    },

    "Educación Secundaria": (level) => {
      refBoxEducacionSecundaria.current.style.background = getBkg(level);
      refBoxEducacionBasica.current.style.background = bkgDefault;
      refBoxBachillerato.current.style.background = bkgDefault;
      refBoxEducacionSuperior.current.style.background = bkgDefault;
    },
    "Bachillerato ": (level) => {
      refBoxBachillerato.current.style.background = getBkg(level);
      refBoxEducacionBasica.current.style.background = bkgDefault;
      refBoxEducacionSecundaria.current.style.background = bkgDefault;
      refBoxEducacionSuperior.current.style.background = bkgDefault;
    },
    "Educación Superior": (level) => {
      refBoxEducacionSuperior.current.style.background = getBkg(level);
      refBoxEducacionBasica.current.style.background = bkgDefault;
      refBoxEducacionSecundaria.current.style.background = bkgDefault;
      refBoxBachillerato.current.style.background = bkgDefault;
    },
    defaultSchool: (level) => {
      refBoxDefaultSchool.current.style.background = bkgDefault;
      refBoxEducacionSecundaria.current.style.background = bkgDefault;
      refBoxBachillerato.current.style.background = bkgDefault;
      refBoxEducacionSuperior.current.style.background = bkgDefault;
    },
  };

  const handleClickLevel = (level, id) => {
    const lhandler = LEVEL_HANDLER[level] || LEVEL_HANDLER.defaultSchool;
    setDefaultLevel(level);
    if (teacherActive) {
      setDefaultLevelId(id);
    }
    setFirstLoad("");
    lhandler(level);
  };

  const loadStyles = (e) => {
    refBoxEducacionBasica.current.style.background = bkgEducacionFisica;
  };

  // console.log(allLevels);

  const handleClickPlusLevel = async () => {
    console.log("nuevo nivel");
    setOpenModal(true);
  };

  function getBtnAdd() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
          position: "relative",
          width: "100%",
        }}
      >
        <Fab
          variant="extended"
          sx={{
            width: 460,
            height: 50,
            background: bkgActive,
          }}
          onClick={() => handleClickPlusLevel()}
        >
          <Add />
        </Fab>
      </Box>
    );
  }

  // console.log(allLevels);

  return (
    <div className={classes.root} onLoad={(e) => loadStyles(e)}>
      <FormDialog
        isOpen={openModal}
        newLevel={true}
        selectedLevel={null}
        setOpenModal={setOpenModal}
        allLevels={allLevels}
      />
      {allLevels.map((item) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: teacherActive ? "80%" : "100%",
            maxWidth: "100%",
            height: "6rem",
            borderRadius: 4,
            mb: 4,
            cursor: "pointer",
            backgroundColor: bkgDefault,
            position: "relative",
          }}
          key={item.id}
          ref={getReferenceBox(item.nameLevel)}
          id={`level${item.id}`}
          onClick={() => handleClickLevel(item.nameLevel, item.id)}
        >
          <Typography>{item.nameLevel}</Typography>
        </Box>
      ))}
      {teacherActive ? null : getBtnAdd()}
    </div>
  );
};

// getReferenceBox(item.nameLevel)

// "Educación Básica": () => refBoxEducacionBasica,
// "Educación Secundaria": () => refBoxEducacionSecundaria,
// "Bachillerato ": () => refBoxBachillerato,
// "Educación Superior": () => refBoxEducacionSuperior
