import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { LogoCard } from "../common/LogoCard";
import { LoginDescription } from "../LoginPage/LoginDescription";
import registerLogo from "../../assets/image/registerLogo.png";
import { RegisterContent } from "./RegisterContent";

const useStyle = makeStyles({
  root: {
    margin: "20px",
  },
});
export const RegisterPage = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item md={9}>
          <Grid container justifyContent="space-around">
            <Grid item md={5}>
              <LogoCard title="Bienvenido " image={registerLogo} />
            </Grid>
            <Grid item md={5}>
              <RegisterContent />
            </Grid>
            <Divider
              orientation="vertical"
              sx={{ boder: "1px solid #A5A5A5", mb: -10 }}
              flexItem
            />
          </Grid>
        </Grid>
        <Grid item md={3}>
          <LoginDescription />
        </Grid>
      </Grid>
    </div>
  );
};
