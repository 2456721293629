import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperLevels = {};

helperLevels.postLevel = async (data) => {
  const res = await axios.post(`${apiUrl}levels`, data);
  // console.log("res data post level -->", res.data);
  return res.data.Levels;
};

// helperLevels.putLevel = async (id, data) => {
//   const res = await axios.put(`${apiUrl}subjects/${id}`, data);
//   // console.log("res data put -->", res.data);
//   return res.data.LevelDB;
// };

// helperLevels.deleteLevel = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };

// helperLevels.getLevelsBySchoolId = async (id) => {
//   const res = await axios.get(`${apiUrl}subjects/schoolId/${id}`);
//   // console.log("res get subjects by school id -->", res.data.subjects);
//   return res.data.subjects;
// };

helperLevels.getLevelById = async (id) => {
  const res = await axios.get(`${apiUrl}levels/${id}`);
  // console.log("res get level by id -->", res.data.levels);
  return res.data.levels;
};
