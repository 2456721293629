import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { ProfessorContent } from '../../AdminPage/AdminProfessorPage/ProfessorContent';
import { StdPreparingInfo } from './StdPreparingInfo';
import { StdPreparingCancel } from './StdPreparingCancel';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentTeacherPreparingPage = () => {
  const classes = useStyles();

  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4}>
          <StdPreparingInfo  professionalDescription={teacherSelected.professionalDescription} subjects={teacherSelected.levels} price={teacherSelected.price} status={teacherSelected.status} name={teacherSelected.fullName} img={teacherSelected.img}/>
        </Grid>
        <Grid item md={0.5} container justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item md={4}>
          <StdPreparingCancel />
        </Grid>
        <Grid item md={3}>
          <ProfessorContent price={teacherSelected.price} certifications={teacherSelected.otherCertifications} role={teacherSelected.specialty}/>
        </Grid>
      </Grid>
    </div>
  );
};
