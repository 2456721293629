import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyle = makeStyles({
  root: {
    margin: "180px 30px",
  },
});
export const LoginDescription = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="caption">
            Bienvenido a Ilustro
            <br />
            <br /> Inicia sesión para tener ayuda en tus tareas y tutorías con
            profesionales calificados que iluminarán el camino.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
