import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  Fab,
  Grid,
  Typography,
  IconButton,
  // Input,
} from '@mui/material';
import { Add, Delete, Edit, PlusOne } from '@mui/icons-material';
// import PhotoCamera from "@mui/icons-material/PhotoCamera";
import deleteicon from '../../../assets/icon/delete.svg';
// import operator from "../../../assets/icon/operator.svg";
import dots from '../../../assets/icon/dots.svg';
import edit from '../../../assets/icon/edit.svg';
// import blade from "../../../assets/icon/blade.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { helper } from '../../../services/helperSchools';
import { helperSubjects } from '../../../services/helperSubjects';
import { helperDevSchools } from '../../../services/helperDevSchool';
import { helperDevSubject } from '../../../services/helperDevSubject';
import { helperViewCourses } from '../../../services/helperViewCourses';
import { uploadImg } from '../../../services/partials/uploadImg';
import FormDialog from '../Modals';
import FormDialogAddSchools from './Modals';
import * as fuseActions from '../../../store/actions/fuse';

import * as homeActions from '../../HomePage/store/actions';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 20px',
  },
}));

const bkgEducacionFisica = 'rgba(149, 248, 212, 1)';
const bkgActive = '#FFEC88';
const bkgDeactivate = '#D8E7E4';

export const LevelDescription = (props) => {
  let user = useSelector(({ loginReducer }) => loginReducer.login.user);

  let schoolsReady = [];

  const classes = useStyles();
  const { level, defaultLevel, setDefaultLevel, teacherActive, defaultLevelId } = props;

  const [subjects, setSubjects] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalSchools, setOpenModalSchools] = useState(false);
  const [newSchool, setNewSchool] = useState(null);
  const [newSubject, setNewSubject] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [idLevel, setIdLevel] = useState(1);
  const [fileImg, setFileImg] = useState(null);
  const [pathImg, setPathImg] = useState(null);
  const [schoolsForTeacher, setSchoolsForTeacher] = useState([]);
  const [allAdminSchools, setAllAdminSchools] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [auxSchools, setAuxSchools] = useState([]);
  const [nameSchool, setNameSchool] = useState(null);
  // const [schoolsReady, setSchoolsReady] = useState([]);
  // const [iconSchoolUrl, setIconSchoolUrl] = useState(null);
  // const [subjectsBySchool, setSubjectsBySchool] = useState([]);

  // console.log("level -->", level);

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("sch", schoolsReady);
    // setAuxSchools(schoolsReady);
  }, [setAuxSchools]);

  const allSchoolsByCourse = useSelector(({ homeReducer }) => homeReducer.homeDataViewCourseByUser.reqViewCourseByUser);

  useEffect(() => {
    const loadData = async () => {
      const dato = level[0];
      if (dato) setIdLevel(dato.id);

      const getSchoolsByLevelId = async (id) => {
        if (teacherActive) {
          // const getData = await helperViewCourses.getViewCourseByUserId(
          //   user.id
          // );
          dispatch(homeActions.getViewCourseByUserId(user.id));
          // console.log(allSchoolsByCourse);
          const activeData = allSchoolsByCourse.filter((el) => el.levelId === defaultLevelId);
          // console.log("acitve data", activeData);
          setSchoolsForTeacher(activeData);
        }
        const schools = await helper.getSchoolsByLevelId(id);
        setAllAdminSchools(schools);
        // console.log(schools);
        return schools;
      };

      if (dato) {
        const getSchools = await getSchoolsByLevelId(dato.id);
        if (firstLoad) {
          const boxLevelEduBasica = document.getElementById('level1');
          try {
            boxLevelEduBasica.style.background = bkgEducacionFisica;
          } catch (error) {}
        }
        setFirstLoad(false);
        setSubjects(getSchools);
      } else {
        setSubjects([]);
      }
    };
    loadData();
  }, [level, teacherActive, user.id, firstLoad]);

  // console.log("subjects ==>", subjects);

  const handleClickPlusSchool = async (item) => {
    // console.log("item plus school ---> ", item);
    const schools = await helper.getSchoolsByLevelId(idLevel);
    setAllAdminSchools(schools);
    if (teacherActive) {
      setOpenModalSchools(true);
      if (defaultLevel === 'Educación Superior' && item) {
        setNewSubject(true);
        setNameSchool(item.nameSchool);
      } else if (defaultLevel !== 'Educación Superior') {
        setNewSubject(true);
        setNewSchool(null);
      } else if (defaultLevel === 'Educación Superior' && !item) {
        setNewSchool(true);
      }
      if (item) {
        setSchoolId(item.schoolId);
      }
      return;
    }
    setSelectedRow(null);
    setNewSubject(item ? item : null);
    setNewSchool(true);
    setOpenModal(true);
  };

  const handleClickUpdateSchool = (item) => {
    // console.log("item updata ----> ", item);
    const dataSubject = {
      idLevel: level[0].id,
      nameSchool: item.nameSchool,
      id: item.id,
    };

    const dataSubjectSchool = {
      nameSubject: item.nameSubject,
      schoolId: item.schoolId,
      id: item.id,
    };
    setSelectedRow(item.nameSchool ? dataSubject : dataSubjectSchool);
    setNewSubject(false);
    setNewSchool(false);
    setOpenModal(true);
  };

  const handleClickDeleteSchool = async (item) => {
    // console.log("item ==>", item);
    if (teacherActive) {
      if (item.subjects) {
        item.subjects.map(async (el) => {
          await helperViewCourses.deleteCourseById(el.id);
          const getData = await helperViewCourses.getViewCourseByUserId(user.id);
          // dispatch(homeActions.getViewCourseByUserId(user.id));
          const activeData = getData.filter((el) => el.levelId === defaultLevelId);
          setSchoolsForTeacher(activeData);
        });
      } else {
        await helperViewCourses.deleteCourseById(item.id);
        const getData = await helperViewCourses.getViewCourseByUserId(user.id);
        // dispatch(homeActions.getViewCourseByUserId(user.id));
        const activeData = getData.filter((el) => el.levelId === defaultLevelId);
        setSchoolsForTeacher(activeData);
      }
    } else {
      if (subjects[0].subjects && item.nameSubject) {
        await helperSubjects.deleteSubject(item.id);
        let newData = subjects.map(async (subject) => {
          if (subject.id === item.schoolId) {
            const rows = subject.subjects.filter((el) => el.id !== item.id);
            return {
              ...subject,
              subjects: rows,
            };
          } else {
            return subject;
          }
        });
        newData = await Promise.all(newData);
        setSubjects(newData);
      } else {
        if (item.subjects) {
          await helper.deleteSchool(item.id);
          item.subjects.map(async (el) => {
            await helperSubjects.deleteSubject(el.id);
          });
        } else {
          await helper.deleteSchool(item.id);
          const getSubjects = await helperSubjects.getSubjectsBySchoolId(item.id);
          const subject = getSubjects[0];
          await helperSubjects.deleteSubject(subject.id);
        }
        const newData = subjects.filter((el) => el.id !== item.id);
        setSubjects(newData);
      }
    }
  };

  function getDeleteBtn(item) {
    return (
      <IconButton key={item.id} sx={{ boxShadow: 0 }} onClick={() => handleClickDeleteSchool(item)}>
        <img src={deleteicon} alt=" delete" />
      </IconButton>
    );
  }

  function getEditBtn(item) {
    return (
      <IconButton sx={{ boxShadow: 0 }} onClick={() => handleClickUpdateSchool(item)}>
        <img src={edit} alt="edit" />
      </IconButton>
    );
  }

  function handleIconBtn(item) {
    const handleIcon = async (e) => {
      const file = e.target.files[0];

      if (file.type === 'image/png') {
        setFileImg(file);
        const f = new FormData();
        f.append('file', file);
        const url = await uploadImg('schools', f);
        setPathImg(url);

        console.log(url);

        const data = {
          iconUrl: url,
        };

        const id = selectedItem.id;
        const updatedSchool = await helperDevSchools.putSchool(id, data);
        console.log(updatedSchool);

        if (updatedSchool) {
          const subjectsBySchool = await helperSubjects.getSubjectsBySchoolId(id);
          subjectsBySchool.map(async (subject) => {
            const updatedSubject = await helperDevSubject.putSubject(subject.id, data);
          });

          let rows = subjects.map(async (subject) => {
            if (subject.id === selectedItem.id) {
              return {
                ...subject,
                iconUrl: updatedSchool.iconUrl,
              };
            } else {
              return subject;
            }
          });

          rows = await Promise.all(rows);
          setSubjects(rows);
          console.log('new rows --->', rows);
        }
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Solo puede subir imagenes .png',
            variant: 'error',
          })
        );
      }
    };

    const handleClickIcon = async () => {
      setSelectedItem(item);
    };

    return (
      <Box>
        <label htmlFor="icon-button-file">
          <input accept=".png" id="icon-button-file" name="icon-button-file" type="file" sx={{ display: 'none' }} className="school-upload-icon" onChange={(e) => handleIcon(e)} hidden={true} />
          <IconButton color="primary" aria-label="upload picture" component="span" sx={{ boxShadow: 0 }} onClick={handleClickIcon}>
            <img
              style={{ maxWidth: '25px' }}
              src={item.iconUrl ? item.iconUrl : dots}
              // src={pathImg ? pathImg : dots}
              alt={fileImg ? fileImg.name : 'operador'}
            />
          </IconButton>
        </label>
      </Box>
    );
  }

  function getCrudButtons(item) {
    return (
      <>
        {teacherActive ? (
          getDeleteBtn(item)
        ) : (
          <Grid item md={3} container justifyContent="space-around" key={item.id}>
            {getEditBtn(item)}
            {getDeleteBtn(item)}
            {handleIconBtn(item)}
          </Grid>
        )}
      </>
    );
  }

  function getSubject(item, index, nameSchool) {
    // console.log(item);
    // console.log(nameSchool);
    const subject = (
      <div key={item.id}>
        <Grid container justifyContent="center">
          <Grid item container justifyContent="space-between" md={12} sx={{ mt: 5, mb: 5 }}>
            {item.nameSchool ? (
              <Typography>{item.nameSchool}</Typography>
            ) : item.nameSubject ? (
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '2rem',
                  color: 'text.primary',
                  fontWeight: 500,
                }}
              >
                {item.nameSubject}
              </Typography>
            ) : null}
            {item.nameSchool ? (
              getCrudButtons(item)
            ) : (
              <Box>
                {teacherActive ? (
                  getDeleteBtn(item)
                ) : (
                  <>
                    {getEditBtn(item)}
                    {getDeleteBtn(item)}
                  </>
                )}
                {/* {getEditBtn(item)}
                {getDeleteBtn(item)} */}
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider />
      </div>
    );

    if (defaultLevel === 'Educación Superior') {
      if (index > 0) {
        return subject;
      } else {
        if (index === 0 && item.nameSubject === nameSchool) {
          return null;
        } else {
          return subject;
        }
      }
    } else {
      return subject;
    }
  }

  function getSchool(item) {
    // console.log(item);
    return (
      <Accordion key={item.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{item.nameSchool}</Typography>
          {getCrudButtons(item)}
        </AccordionSummary>
        <AccordionDetails>
          {item.subjects ? item.subjects.map((subject, index) => getSubject({ ...subject, nameSchool: null }, index, item.nameSchool)) : null}
          {getBtnAdd(item)}
        </AccordionDetails>
      </Accordion>
    );
  }

  function getBtnAdd(item) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Fab
          variant="extended"
          sx={{
            width: 460,
            height: 50,
            background: bkgActive,
          }}
          onClick={() => handleClickPlusSchool(item)}
        >
          <Add />
        </Fab>
      </Box>
    );
  }

  return (
    <div style={{ width: teacherActive ? '90%' : '100%' }}>
      <FormDialog
        selectedRow={selectedRow}
        newSchool={newSchool}
        newSubject={newSubject}
        isOpen={openModal}
        idLevel={idLevel}
        setOpenModal={setOpenModal}
        setSubjects={setSubjects}
        subjects={subjects}
        // defaultLevel={defaultLevel}
        // setDefaultLevel={setDefaultLevel}
      />
      <FormDialogAddSchools
        isOpen={openModalSchools}
        setOpenModalSchools={setOpenModalSchools}
        allAdminSchools={allAdminSchools}
        setAllAdminSchools={setAllAdminSchools}
        defaultLevel={defaultLevel}
        newSubject={newSubject}
        setNewSubject={setNewSubject}
        newSchool={newSchool}
        setNewSchool={setNewSchool}
        schoolId={schoolId}
        setSchoolsForTeacher={setSchoolsForTeacher}
        defaultLevelId={defaultLevelId}
        // schoolsReady={auxSchools}
        nameSchool={nameSchool}
        schoolsForTeacher={schoolsForTeacher}
      />
      <div className={classes.root}>
        {teacherActive ? schoolsForTeacher.map((item) => (defaultLevel === 'Educación Superior' ? getSchool(item) : getSubject(item))) : subjects.map((item) => (defaultLevel === 'Educación Superior' ? getSchool(item) : getSubject(item)))}
        {/* {subjects.map((item) =>
          defaultLevel === "Educación Superior"
            ? getSchool(item)
            : getSubject(item)
        )} */}
        {defaultLevel !== 'Educación Superior' ? getBtnAdd() : null}
        {defaultLevel === 'Educación Superior' && (
          <Box sx={{ marginTop: '2.5rem', display: 'flex' }}>
            <Typography variant="h5">Añadir nueva escuela</Typography>
            <IconButton
              sx={{
                background: bkgDeactivate,
                borderRadius: '',
                marginLeft: '2rem',
                color: '#000',
                transform: 'translateY(-0.3rem)',
                boxShadow: 3,
              }}
              onClick={() => handleClickPlusSchool()}
            >
              <PlusOne />
            </IconButton>
          </Box>
        )}
      </div>
    </div>
  );
};
