import { combineReducers } from 'redux';
import countryReducers from '../../components/RegisterPage/store/reducers';
import loginReducer from '../../components/LoginPage/store/reducers';
import homeReducer from '../../components/HomePage/store/reducers';
import fuse from './fuse';
import { studentReducers } from '../reducers/student/index';
import { adminReducers } from './admin/index';


const createReducer = (asyncReducers) =>
  combineReducers({
    countryReducers,
    loginReducer,
    homeReducer,
    fuse,
    studentReducers,
    adminReducers,
    ...asyncReducers,
  });

export default createReducer;
