import { types } from '../../../types/types';

const initialState = {
  emergency: false,
  invoice: {
    id: 0,
    requestId: 0,
    requestType: '',
    payMethodId: 0,
    total: 0,
    imageVoucherUrl: 'https://fileVoucherUrl',
    status: 0,
    createdAt: '0',
    updatedAt: '0',
  },
  requestClass: {
    id: 0,
    userCourseId: 0,
    studentId: 0,
    hoursNumber: 0,
    date: '',
    price: 0,
    percentPrice: 0,
    schedule: '',
    stateRequestClass: '',
    studentReview: 0,
    teacherReview: 0,
    status: 0,
    createdAt: '',
    updatedAt: '',
  },
};

export const resumenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setIvoiceInfo:
      return {
        ...state,
        invoice: action.payload.invoice,
      };
    case types.setRequestClass:
      return {
        ...state,
        requestClass: action.payload.requestClass,
      };
    case types.setEmergency:
      return {
        ...state,
        emergency: action.payload.emergency,
      };
    case types.setResumen:
      return {
        ...state,
        requestClass: {
          ...state.requestClass,
          hoursNumber: action.payload.hoursNumber,
          date: action.payload.date,
          schedule: action.payload.schedule,
        },
      };
    case types.setResumenPrice:
      console.log(action.payload.price);
      return {
        ...state,
        requestClass: {
          ...state.requestClass,
          price: action.payload.price,
        },
      };

    default:
      return state;
  }
};
