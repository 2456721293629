import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Badge, Fab, Grid, Typography, Box } from "@mui/material";
import { AdProfessorsItemDescription } from "./AdProfessorsItemDescription";
import empty_state from "../../../../../assets/image/empty_state.svg";

import { helper } from "../../../../../services/helperTeachers";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminProfessorsItem = (props) => {
  const {
    userAvatar,
    name,
    role,
    university,
    price,
    review,
    subject,
    description,
    certifications,
    status,
    userId,
    teacherTrainingId
  } = props;
  const {
    teacher,
    teachers,
    defaultState,
    setDefaultState,
    empty,
    setTeachers,
  } = props;
  const classes = useStyles();

  const [hidden, setHidden] = useState(false);

  const hanldeClickDelete = () => {
    // console.log(teachers);
    const data = {
      status: "rejected",
    };
    updateData(data);
    setHidden(true);
  };
  const handleClickAcept = () => {
    const data = {
      status: "accepted",
    };
    updateData(data);
    setHidden(true);
  };

  const updateData = async (data) => {
    await helper.putTeacher(teacher.teacherTrainingId, data);
    // setTeachers(
    // });
    const rows = teachers.map((el) => {
      if (el.teacherTrainingId === teacher.teacherTrainingId) {
        el.status = data.status;
      }
      return el;
    });
    setDefaultState("pending");
    setDefaultState(defaultState);
    setTeachers(rows);
  };

  function emptyData() {
    console.log(status);
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <img
            width="100%"
            src={empty_state}
            alt="Imagen de no hay solicitudes"
          ></img>
        </Box>
        <Box> </Box>
        <Box>
          <Typography variant="h3">
            No hay solicitudes{" "}
            {status === "pending"
              ? "Pendientes"
              : status === "rejected"
              ? "Rechazadas"
              : status === "accepted"
              ? "Aceptadas"
              : ""}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {empty ? (
        emptyData()
      ) : (
        <div className={classes.root} hidden={hidden}>
          <Grid container alignItems="center">
            <Grid item md={2}>
              <Badge
                overlap="circular"
                badgeContent=" "
                // color={
                //   status === "accepted"
                //     ? "success"
                //     : status === "rejected"
                //     ? "secondary"
                //     : "warning"
                // }
                // anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Avatar src={userAvatar} sx={{ width: 132, height: 132 }} />
              </Badge>
            </Grid>
            <Grid item md={7}>
              <AdProfessorsItemDescription
                name={name}
                role={role}
                university={university}
                price={price}
                review={review}
                subject={subject}
                img={userAvatar}
                desc={description}
                certifications={certifications}
                status={status}
                // teacherTrainingId={teacherTrainingId}
                userId={userId}
                teacher={teacher}
                teachers={teachers}
                teacherTrainingId={teacher.teacherTrainingId}
              />
            </Grid>
            <Grid item md={3}>
              {teacher.status === "rejected" && (
                <Fab
                  variant="extended"
                  color="primary"
                  sx={{ width: 160, height: 60, my: 1 }}
                  onClick={handleClickAcept}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Aceptar
                  </Typography>
                </Fab>
              )}
              {teacher.status === "accepted" && (
                <Fab
                  variant="extended"
                  color="secondary"
                  sx={{ width: 160, height: 60, my: 1 }}
                  onClick={hanldeClickDelete}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Eliminar
                  </Typography>
                </Fab>
              )}
              {teacher.status === "pending" && (
                <>
                  <Fab
                    variant="extended"
                    color="secondary"
                    sx={{ width: 160, height: 60, my: 1 }}
                    onClick={hanldeClickDelete}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Eliminar
                    </Typography>
                  </Fab>
                  <Fab
                    variant="extended"
                    color="primary"
                    sx={{ width: 160, height: 60, my: 1 }}
                    onClick={handleClickAcept}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Aceptar
                    </Typography>
                  </Fab>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
