import { Divider, Fab, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

const initState = {
  priceOffer: 0,
  timeOffer: 0,
};

export const ConfirmDialog = ({ handleClose, func }) => {
  const [formOffer, setFormOffer] = useState(initState);

  const classes = useStyles();

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleClickSendOffer = () => {
    func(formOffer);
    handleClose();
    setFormOffer(initState);
  };

  const handleInputChange = (e) => {
    setFormOffer({
      ...formOffer,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.closeButton}>
        <CancelIcon fontSize="large" onClick={handleCloseDialog} />
      </div>
      <div style={{ width: '100%', height: '20px', borderBottom: '1px solid black', textAlign: 'left', marginBottom: '60px' }}>
        <span style={{ backgroundColor: '#F3F5F6', padding: '0 10px ', fontSize: '30px', fontFamily: 'Poppins', fontWeight: 800 }}>Tu Oferta</span>
      </div>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item md={6}>
          <TextField type="number" sx={{ mb: 8 }} placeholder="Precio " value={formOffer.priceOffer} name="priceOffer" onChange={handleInputChange} label="Precio " className={classes.textField} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
        </Grid>
        <Grid item md={6}>
          <TextField type="number" sx={{ mb: 8 }} placeholder="Entrega" value={formOffer.timeOffer} name="timeOffer" onChange={handleInputChange} label="Entrega" className={classes.textField} fullWidth />
        </Grid>
      </Grid>
      <Fab variant="extended" sx={{ textTransform: 'capitalize', width: '100%', height: '70px', mb: 2 }} color="primary" onClick={handleClickSendOffer}>
        <Typography variant="body1">Enviar</Typography>
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '70px',
    width: '419px',
    position: 'relative',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
}));
