import React from 'react';

import { makeStyles } from '@mui/styles';
import { ItemChat } from './ItemChat';
import { InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { selectPersonChat } from '../../../store/actions/students/chatActions';
import { SkeletonCardChat, SkeletonCardTeacher } from '../SkeletonCardTeacher';
import { scrollToBottom } from '../../../utils/scrollToBottom';

export const PeopleActiveChatList = ({ chatlist, funcFilter, isLoading }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectChat = (user) => {
    scrollToBottom('messages');
    dispatch(selectPersonChat(user));
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerInput}>
        <TextField
          onChange={(e) => funcFilter(e.target.value)}
          fullWidth
          className={classes.textField}
          placeholder="Buscar una persona"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchOutlined sx={{ mr: 4 }} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.containerList}>{!isLoading ? chatlist.length > 0 && chatlist.map((item, idx) => <ItemChat key={idx} name={item.fullName} status={item.rol} img={item.img} func={() => selectChat(item)} />) : <SkeletonCardChat />}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 45,
    },
    [`& fieldset`]: {
      borderRadius: '20px',
      background: '#F4F7F5',
      zIndex: '-1000',
    },

    '&:focus': {
      outline: 'none',
    },
  },

  containerInput: {
    marginTop: 20,
    marginBottom: 20,
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 400,
    maxHeight: 400,
    overflow: 'auto',
  },
}));
