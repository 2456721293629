import { types } from '../../../types/types';

const initialState = {
  idSubject: 0,
  nameSubject: '',
  teachers: [],
  teacherSelected: {},
  isLoading: true,
};

export const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setAllteachersReducer:
      return {
        idSubject: action.payload.idSubject,
        nameSubject: action.payload.nameSubject,
        teachers: action.payload.teachers,
        teacherSelected: action.payload.teacherSelected,
        isLoading: action.payload.isLoading,
      };
    case types.setNameAndIdSubject:
      return {
        ...state,
        idSubject: action.payload.idSubject,
        nameSubject: action.payload.nameSubject,
      };
    case types.setTeachers:
      return {
        ...state,
        teachers: action.payload.teachers,
        isLoading: action.payload.isLoading,
      };
    case types.setTeacherSelected:
      return {
        ...state,
        teacherSelected: action.payload.teacher,
        isLoading: action.payload.isLoading,
      };

    default:
      return state;
  }
};
