import { Fab, FormControl, InputLabel, OutlinedInput, Rating, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { TeacherCardHorizontal } from '../common/TeacherCardHorizontal';

export const StudentRateTeacherPage = ({ img, name = '', speciality = '', university = '', func }) => {
  const classes = useStyles();

  const [formRateTeacher, setFormRateTeacher] = useState({
    rating: 0,
    review: '',
    urlVideo: '',
  });

  const onChange = (e) => {
    setFormRateTeacher({
      ...formRateTeacher,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setFormRateTeacher({
      rating: 0,
      review: '',
      urlVideo: '',
    });
    func(formRateTeacher);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <TeacherCardHorizontal background="#D5B8FD" img={img} name={name} speciality={speciality} university={university} />
        <div className={classes.itemForm}>
          <Rating
            size="large"
            value={formRateTeacher.rating}
            onChange={(event, newValue) => {
              setFormRateTeacher({ ...formRateTeacher, rating: newValue });
            }}
          />
        </div>
        <div className={classes.itemForm}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Reseña</InputLabel>
            <OutlinedInput placeholder="Reseña" label="Reseña" name="review" value={formRateTeacher.review} multiline rows={4} onChange={onChange} sx={{ mb: 6 }} className={classes.textField} />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Video de la clase</InputLabel>
            <OutlinedInput placeholder="Video de la clase" label="Video de la clase" name="urlVideo" value={formRateTeacher.urlVideo} onChange={onChange} sx={{ mb: 6 }} className={classes.textField} />
          </FormControl>
        </div>
        <div className={classes.buttonSubmit}>
          <Fab
            variant="extended"
            sx={{
              textTransform: 'capitalize',
              height: 70,
              width: '100%',
            }}
            color="primary"
            onClick={onSubmit}
          >
            <Typography variant="body1">Calificar</Typography>
          </Fab>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { marginRight: 10, marginLeft: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' },
  container: { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
    },
  },
  itemForm: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonSubmit: { width: '100%' },
}));
