import jwtDecode from "jwt-decode";
import React from "react";
import { useDispatch } from "react-redux";
import * as authActions from "../components/LoginPage/store/actions";
export const AuthProvider = (props) => {
  const dispatch = useDispatch();

  const token = sessionStorage.getItem("jwt");
  if (token) {
    const user = jwtDecode(token);
    dispatch(authActions.setUser(user));

    // dispatch(authActions.setUser(user));
  }
  return <div>{props.children}</div>;
};
