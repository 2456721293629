import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const helperDataBank = {};

helperDataBank.postDataBank = async (nameDatabank, accountNumber, ownerBank, userId, dni, email, accountType, phoneNumber) => {
  const res = await axios.post(`${apiUrl}databanks`, {
    nameDatabank,
    accountNumber,
    ownerBank,
    userId,
    dni,
    email,
    accountType,
    phoneNumber,
  });
  return res.data.DataBanks;
};

helperDataBank.putDataBank = async (id, data) => {
  const res = await axios.put(`${apiUrl}databanks/${id}`, data);
  return res.data;
};

// helperDataBank.deleteDataBank = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };

// helperDataBank.getDataBanksBySchoolId = async (id) => {
//   const res = await axios.get(`${apiUrl}subjects/schoolId/${id}`);
//   // console.log("res get subjects by school id -->", res.data.subjects);
//   return res.data.subjects;
// };

helperDataBank.getDataBankByUserId = async (id) => {
  console.log(id)
  const res = await axios.get(`${apiUrl}databanks/byiduser/${id}`);
  return res.data.DataBanks;
};
