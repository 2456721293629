import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const usePayments = () => {
  const getAllPayments = async () => {
    const resp = await axios.get(`${apiUrl}paymentMethods`);
    return resp.data.PaymentMethodBD.filter((payment) => payment.status === 1);
  };

  return { getAllPayments };
};
