import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { WalletUserState } from "./WalletUserState";
import { Divider, FormControl, MenuItem, Select } from "@mui/material";
import user1 from "../../../assets/image/user1.png";
import user2 from "../../../assets/image/user2.png";
import { Box } from "@mui/system";
// import { helperReqHomeworks } from "../../../services/helperReqHomeworks";
import { useSelector, useDispatch } from "react-redux";
import moment, { months } from "moment";
import * as homeActions from "../../HomePage/store/actions";
import { EmptyState } from "../../common/EmptyState";

const useStyles = makeStyles((theme) => ({
  root: { padding: "40px" },
  sortSelect: {
    ".MuiSelect-Outlined-root": {
      borderRadius: "10px",
    },
  },
}));
export const WalletUserList = ({ monthSearch, onChange, search, user }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [listData, setListData] = useState([]);

  const teacherTraining = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataTeacherTraining.requestTeacherTraining
  );

  const homeClasses = useSelector(
    ({ homeReducer }) => homeReducer.homeData.requestClasses
  );

  const homeHomeworks = useSelector(
    ({ homeReducer }) => homeReducer.homeDataViewHomeworks.reqViewHomeworks
  );

  useEffect(() => {
    dispatch(homeActions.getViewRequestClass(user.id));
    dispatch(homeActions.getTeacherTrainingByUserId(user.id));
  }, []);

  useEffect(() => {
    dispatch(homeActions.getHomeworksByTeacherTrainingId(teacherTraining.id));
  }, [teacherTraining]);


  useEffect(() => {
    dispatch(homeActions.getViewRequestClass(user.id));
    // dispatch(homeActions.getTeacherTrainingByUserId(user.id));
    dispatch(homeActions.getHomeworksByTeacherTrainingId(teacherTraining.id));
    setListData([...homeClasses, ...homeHomeworks]);
    // console.log("aceptado");
    // return () => {
    //   setListData([]);
    // };
    filterByMonth(monthSearch);
  }, [monthSearch]);

  // console.log("lista", listData);
  // console.log(listData[0].date);

  const filterByMonth = (month) => {
    // console.log("mont", month);
    const tempHomeHomeworks = homeHomeworks.filter(
      (el) => moment(el.date).format("MMMM") === month
    );

    const tempHomeClasses = homeClasses.filter(
      (el) => moment(el.date).format("MMMM") === month
    );

    // console.log("home =>", tempHomeHomeworks);
    // console.log("class =>", tempHomeClasses);
    setListData([...tempHomeHomeworks, ...tempHomeClasses]);
  };

  return (
    <div className={classes.root}>
      <Box sx={{ float: "right", width: 160, height: 60 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monthSearch}
            sx={{
              borderRadius: 6,
              background: "#F4F7F5",
              border: "none",
              height: 50,
            }}
            onChange={(e) => {
              onChange(e.target.value, "monthSearch");
              if (e.target.value !== "Select") {
                search(e.target.value);
              }
            }}
          >
            <MenuItem value="Select" key="Select">
              Seleccione
            </MenuItem>
            <MenuItem value="January" key="January">
              Enero
            </MenuItem>
            <MenuItem value="February" key="February">
              Febrero
            </MenuItem>
            <MenuItem value="March" key="March">
              Marzo
            </MenuItem>
            <MenuItem value="April" key="April">
              Abril
            </MenuItem>
            <MenuItem value="May" key="May">
              Mayo
            </MenuItem>
            <MenuItem value="June" key="June">
              Junio
            </MenuItem>
            <MenuItem value="July" key="July">
              Julio
            </MenuItem>
            <MenuItem value="August" key="August">
              Agosto
            </MenuItem>
            <MenuItem value="September" key="September">
              Septiembre
            </MenuItem>
            <MenuItem value="October" key="October">
              Ocutubre
            </MenuItem>
            <MenuItem value="November" key="November">
              Noviembre
            </MenuItem>
            <MenuItem value="December" key="December">
              Diciembre
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {listData.length > 0 ? (
        <>
          {homeClasses.map((el) => {
            if (
              el.stateRequestClass === "completed" &&
              moment(el.date).format("MMMM") === monthSearch
            ) {
              return (
                <WalletUserState
                  key={el.requestClassId}
                  avatar={el.img || user1}
                  name={el.nameStudent}
                  date={el.date}
                  schedule={el.schedule}
                  price={el.price}
                  isClass={true}
                />
              );
            }
            return null;
          })}
          {homeHomeworks.map((el, index) => {
            if (
              el.state === "completed" &&
              moment(el.date).format("MMMM") === monthSearch
            ) {
              return (
                <WalletUserState
                  key={index}
                  avatar={el.imgStudent || user2}
                  name={el.student}
                  date={el.date}
                  // schedule={el.schedule}
                  // price={el.price}
                  purchase={true}
                  classReq={el.class}
                  requestHomeworkId={el.requestHomeworkId}
                  // price={el.price}
                />
              );
            }
            return null;
          })}
        </>
      ) : (
        <EmptyState title="NO EXISTEN REGISTROS EN ESTE MES" />
      )}

      {/* {homeClasses && homeClasses.length > 0
        ? homeClasses.map((el) => {
            if (
              el.stateRequestClass === "completed" &&
              moment(el.updatedAt).format("MMMM") === monthSearch
            ) {
              return (
                <WalletUserState
                  key={el.requestClassId}
                  avatar={el.img || user1}
                  name={el.nameStudent}
                  date={el.date}
                  schedule={el.schedule}
                  price={el.price}
                  isClass={true}
                />
              );
            }
            return null;
          })
        : null}

      {homeHomeworks && homeClasses.length > 0
        ? homeHomeworks.map((el, index) => {
            if (
              el.state === "completed" &&
              moment(el.updatedAt).format("MMMM") === monthSearch
            ) {
              return (
                <WalletUserState
                  key={index}
                  avatar={el.imgStudent || user2}
                  name={el.student}
                  date={el.date}
                  // schedule={el.schedule}
                  // price={el.price}
                  purchase={true}
                  classReq={el.class}
                  requestHomeworkId={el.requestHomeworkId}
                  // price={el.price}
                />
              );
            }
            return <EmptyState title="NO EXISTEN REGISTROS" />;
          })
        : null} */}
      {/* <WalletUserState avatar={user1} name=" Amanda Castro" /> */}

      {/* <WalletUserState avatar={user2} name="German Sánchez" /> */}
      {/* <Divider /> */}
    </div>
  );
};
