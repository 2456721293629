import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { EmptyState } from '../../common/EmptyState';
import { Loading } from '../../common/Loading';
import { TeacherItem } from './TeacherItem';

export const TeachersList = ({ teachers, isLoading }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loading titleLoading="Cargando profesores" />
      ) : teachers.length > 0 ? (
        teachers.map((teacher, idx) => (
          <div key={idx}>
            <TeacherItem teacher={teacher} />
            <Divider sx={{ my: 2 }} />
          </div>
        ))
      ) : (
        <EmptyState title="No existen profesores" />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
