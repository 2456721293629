import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/apiConfig';
import { useDispatch } from 'react-redux';
import * as fuseActions from '../store/actions/fuse';

export const useOrders = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    loadInvoices();
    return async () => {
      loadInvoices();
      await getAllInvoice();
      await getAllInvoiceHomeWork();
    };
  }, []);

  const loadInvoices = async () => {
    const invoices = await getAllInvoice();
    const invoicesHomework = await getAllInvoiceHomeWork();
    setInvoices([...invoices, ...invoicesHomework]);
    setLoading(false);
  };

  const getAllInvoice = async () => {
    try {
      const resp = await axios.get(`${apiUrl}view/getAllInvoice`);
      return resp.data.AllInvoice;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllInvoiceHomeWork = async () => {
    const resp = await axios.get(`${apiUrl}view/getAllInvoiceHomeworks`);
    return resp.data.AllInvoiceHomework;
  };

  const updateClass = async (classId, status) => {
    const resp = await axios.put(`${apiUrl}requestClasses/${classId}`, {
      stateRequestClass: status,
    });
    if (resp.status === 200) {
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: 'Solicitud confirmada correctamente',
            variant: 'success',
          })
        );
        loadInvoices();
        return resp.data;
      }
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
    loadInvoices();
    return resp.data;
  };

  const updateHomework = async (classId, status) => {
    const resp = await axios.put(`${apiUrl}requestHomeworks/${classId}`, {
      stateRequestHomework: status,
    });
    if (resp.status === 200) {
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: 'Solicitud confirmada correctamente',
            variant: 'success',
          })
        );
        loadInvoices();
        return resp.data;
      }
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }

    loadInvoices();
    return resp.data;
  };

  return {
    invoices,
    loading,
    updateClass,
    updateHomework,
  };
};
