import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { TopLogo } from './TopLogo';
import { SearchOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { UserCol } from './UserCol';
import { Sort } from './Sort';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const background = '/assets/loginedTop.svg';

const styleTopImg = {
  position: 'absolute',
  top: '0px',
  width: '100%',
  height: '70%',
  marginTop: '-5%',
  marginBottom: '-10%',
  padding: '0%',
  zIndex: -1,
};
const useStyle = makeStyles({
  regContent: {
    minWidth: '90%',
    marginTop: '30px',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 45,
    },
    [`& fieldset`]: {
      borderRadius: '20px',
      background: '#F4F7F5',
      zIndex: '-1000',
    },

    '&:focus': {
      outline: 'none',
    },
  },
});

export const LoginedTopBackground = ({ title, search, showSort, dataSort, disabledSort }) => {
  const classes = useStyle();
  const history = useHistory();

  const { user, isAuthenticated } = useSelector(({ loginReducer }) => loginReducer.login);

  return (
    <div style={{ marginBottom: 100 }}>
      <TopLogo />
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 3, pr: 4 }}>
        <Grid item md={2}></Grid>
        {history.location.pathname === '/home' ? null : (
          <Grid item md={3}>
            <Sort show={showSort} sort={dataSort} disabledSort={disabledSort} />
          </Grid>
        )}
        <Grid item sx={{ visibility: isAuthenticated ? 'visible' : 'hidden' }}>
          {<UserCol />}
        </Grid>
      </Grid>
      <div
        style={{
          width: '100%',
          height: '70px',
          background: 'rgba(216, 231, 228, 0.3)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          zIndex: 10000,
        }}
      >
        <Grid container>
          <Grid item md={4} alignItems="center" justifyContent="flex-start" container>
            <Typography variant="h6" sx={{ fontSize: 20, ml: 10 }}>
              {title}
            </Typography>
          </Grid>
          {/* <Grid item md={4} justifyContent="center" alignItems="center" container>
            {search ? (
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="Filtra por Materia o Nivel"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined sx={{ mr: 4 }} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            ) : null}
          </Grid> */}
          <Grid item md={4}></Grid>
        </Grid>
      </div>
      <img src={background} style={styleTopImg} alt="Fondo Superior"></img>
    </div>
  );
};
