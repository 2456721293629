import React from "react";
import { AgendaPage } from "../../components/AgendaPage";
import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";

export const AgendaScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Agenda" />
      <AgendaPage />
      <BotBackground />
    </div>
  );
};
