import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentMainPage } from '../../components/StudentPage/StudentMainPage';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLevels } from '../../store/actions/students/levelsActions';
import { selectLevel } from '../../store/actions/students/studentActions';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentMainScreen = () => {
  const classes = useStyles();
  
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { levels } = useSelector((state) => state.studentReducers.levelsReducer);
  const { idLevel } = useSelector((state) => state.studentReducers.studentReducer);

  useEffect(() => {
    getAllLevels();
  }, []);

  const getAllLevels = async () => {
    await dispatch(getLevels());
  };

  useEffect(() => {
    if (idLevel) {
      dispatch(selectLevel(state.idLevel, state.nameLevel, state.schools));
    }
    if (levels.length > 0) {
      getAllLevels();
    }
  }, []);

  return (
    <div className={classes.root}>
      <LoginedTopBackground showSort={true} dataSort={levels} stateSort={idLevel} />
      <StudentMainPage />
      <BotBackground />
    </div>
  );
};
