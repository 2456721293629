import * as Actions from "../actions";
const initialState = {
  country: [],
  province: [],
  city: [],
  avatar: "",
};

export const countries = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_COUNTRY: {
      return {
        ...state,
        country: action.payload.CountryBD,
      };
    }
    case Actions.GET_PROVINCE_BY_ID: {
      console.log(action.payload)
      return {
        ...state,
        province: action.payload,
      };
    }
    case Actions.GET_CITY_BY_ID: {
      return {
        ...state,
        city: action.payload,
      };
    }
    case Actions.UPLOAD_USER_AVATAR: {
      return {
        ...state,
        avatar: action.payload.url,
      };
    }

    default:
      return state;
  }
};
