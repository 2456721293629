import { combineReducers } from 'redux';
import { studentReducer } from './studenReducer';
import { levelsReducer } from './levelsReducer';
import { subjectReducer } from './subjectReducer';
import { teachersReducer } from './teachersReducer';
import { requestClassReducer } from './requestClassReducer';
import { chatReducer } from './chatReducer';
import { uiPaymentReducer } from './uiPaymentReducer';
import { resumenReducer } from './resumenReducer';

export const studentReducers = combineReducers({
  studentReducer,
  levelsReducer,
  subjectReducer,
  teachersReducer,
  requestClassReducer,
  chatReducer,
  uiPaymentReducer,
  resumenReducer,
});
