import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiUrl } from '../config/apiConfig';
import * as fuseActions from '../store/actions/fuse';

export const useOffer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const postOffer = async (price, requestHomerokId, statusRequestTeacher, teacherId, hours) => {
    const resp = await axios.post(`${apiUrl}offers`, {
      price,
      requestHomerokId,
      statusRequestTeacher,
      teacherId,
      hours,
    });

    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: 'Oferta enviada',
            variant: 'success',
          })
        );
        return resp.data.Offers;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const getDetailOffer = async (idRequest, idUser) => {
    const resp = await axios.get(`${apiUrl}view/getviewOfferDetail/${idUser}/${idRequest}`);
    return resp.data.OffersDetail;
  };

  const getAllOffers = async () => {
    const resp = await axios.get(`${apiUrl}offers`);
    return resp.data.OfferBD;
  };

  return { postOffer, isLoading, getDetailOffer, getAllOffers };
};
