import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { ProfessorList } from './ProfessorList';
import { AdminProfessorInfo } from './AdminProfessorInfo';
import { ProfessorContent } from './ProfessorContent';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '-50px', marginTop: '-40px' },
}));
export const AdminProfessorPage = () => {
  const classes = useStyles();

  const { teacherSelected } = useSelector(({ adminReducers }) => adminReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4}>
          <ProfessorList />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item md={4}>
          {/* <AdminProfessorInfo name={name} review={review} subject={subject} price={price} img={img} desc={desc} teacher={teacher} status={status} teacherTrainingId={teacherTrainingId} university={university} role={role} certifications={certifications} userId={userId} /> */}
          <AdminProfessorInfo name={teacherSelected.fullName} review={teacherSelected.starReview} subject={teacherSelected.levels} price={teacherSelected.price} img={teacherSelected.img} desc={teacherSelected.professionalDescription} status={teacherSelected.status} teacherTrainingId={teacherSelected.id} university={teacherSelected.university} role={teacherSelected.role} certifications={teacherSelected.otherCertifications} userId={teacherSelected.userId} />
        </Grid>
        <Grid item md={3}>
          {/* <ProfessorContent university={university} role={role} price={price} certifications={certifications} userId={userId} teacherTrainingId={teacherTrainingId} admin /> */}
          <ProfessorContent university={teacherSelected.university} role={teacherSelected.specialty} price={teacherSelected.price} certifications={teacherSelected.otherCertifications} userId={teacherSelected.userId} teacherTrainingId={teacherSelected.id} admin />
        </Grid>
      </Grid>
    </div>
  );
};
