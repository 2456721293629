import { types } from '../../../types/types';

const initialState = {
  idschol: 1,
  nameSchool: '',
  subjects: [],
  isLoading: true,
};

export const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getSubjectsBySchool:
      return {
        idschool: action.payload.idschool,
        nameSchool: action.payload.nameSchool,
        subjects: action.payload.subjects,
        isLoading:action.payload.isLoading,
      };

    default:
      return state;
  }
};