import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { StdUrgentTeacearsList } from './StdUrgentTeacearsList';
import { StdUrgentTeacherInfo } from './StdUrgentTeacherInfo';
import { ProfessorContent } from '../../AdminPage/AdminProfessorPage/ProfessorContent';
import { useDispatch, useSelector } from 'react-redux';
import { getTeachersByIdSubjetct } from '../../../store/actions/students/teachersActions';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const StudentUrgentTeachearsPage = ({ idSubject }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [teachers, setTeachers] = useState([]);

  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  useEffect(() => {
    dispatch(getTeachersByIdSubjetct(idSubject));
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4}>
          <StdUrgentTeacearsList />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item md={4}>
          <StdUrgentTeacherInfo review={teacherSelected.starReview} professionalDescription={teacherSelected.professionalDescription} subjects={teacherSelected.levels} price={teacherSelected.price} status={teacherSelected.status} name={teacherSelected.fullName} img={teacherSelected.img} />
        </Grid>
        <Grid item md={3}>
          <ProfessorContent price={teacherSelected.price} certifications={teacherSelected.otherCertifications} role={teacherSelected.specialty} />
        </Grid>
      </Grid>
    </div>
  );
};
