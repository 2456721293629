import React from "react";
import { TopBackground } from "../components/common/TopBackground";
import { StartContainer } from "../components/StartScreen/StartContainer";
import { BotBackground } from "../components/common/BotBackground";

const botBackGroundStyle = {
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: 'blue',
};

export const StartScreen = () => {
  return (
    <div style={botBackGroundStyle}>
      <TopBackground />
      <StartContainer />
      <BotBackground />
    </div>
  );
};
