import axios from "axios";
import { apiUrl } from "../../../../config/apiConfig";
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const GET_PROVINCE_BY_ID = "GET_PROVINCE_BY_ID";
export const GET_CITY_BY_ID = "GET_CITY_BY_ID";
export const UPLOAD_USER_AVATAR = "UPLOAD_USER_AVATAR";

export const getAllCountry = () => {
  const request = axios.get(`${apiUrl}countries`);
  return (dispatch) =>
    request
      .then((res) => dispatch({ type: GET_ALL_COUNTRY, payload: res.data }))
      .catch((err) => console.log("err =", err));
};

export const getProvinceById = (countryId) => {
  // const request = axios.get(`${apiUrl}provinces/${countryId}`);
  const request = axios.get(`${apiUrl}provinces`);
  
  return (dispatch) =>
    request
      .then((res) => {
        dispatch({ type: GET_PROVINCE_BY_ID, payload: res.data.Provices.filter(item=> item.countryId == countryId) })
      })
      .catch((err) => console.log("err =", err));
};

export const getCityById = (provinceId) => {
  // const request = axios.get(`${apiUrl}cities/${provinceId}`);
  const request = axios.get(`${apiUrl}cities`);
  return (dispatch) =>
    request
      .then((res) => {
        console.log(res)
        dispatch({ type: GET_CITY_BY_ID, payload: res.data.Citys.filter(item=> item.provinceId == provinceId) })
      })
      .catch((err) => console.log("err =", err));
};

export const uploadAvatar = (file) => {
  const request = axios.post(`${apiUrl}uploads/user`, file);
  return (dispatch) =>
    request
      .then((res) =>{ dispatch({ type: UPLOAD_USER_AVATAR, payload: res.data })})
      .catch((err) => console.log("err =", err));
};
