import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiUrl } from '../config/apiConfig';
import * as fuseActions from '../store/actions/fuse';

export const useUpLoadHomework = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const getWorkStudentBackEnd = async (studentId) => {
    const resp = await axios.get(`${apiUrl}view/getviewRequestHomeworksByStudentId/${studentId}`);
    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        return resp.data.RequestHomeworks;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const addHomeWorkStudent = async (fileUrl, subjectId, detailHomework, timeHomework, videoClassUrl, price, stateRequestHomework, studentId, percentPrice) => {
    const resp = await axios.post(`${apiUrl}requestHomeworks`, {
      fileUrl: fileUrl,
      subjectId: subjectId,
      detailHomework: detailHomework,
      timeHomework: timeHomework,
      videoClassUrl: videoClassUrl,
      price: price,
      stateRequestHomework: stateRequestHomework,
      studentId: studentId,
      percentPrice: percentPrice,
    });

    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: 'Datos de la tarea guardadados',
            variant: 'success',
          })
        );
        // return resp.data.RequestHomeworks;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const putHomeWorkBackEnd = async (id, statusRequestTeacher) => {
    const resp = await axios.put(`${apiUrl}offers/${id}`, { statusRequestTeacher: statusRequestTeacher });

    return resp.data;
  };
  //edit request homework
  const putRequestHomeWorkBackEnd = async (id, stateRequestHomework, teacherId, price, percentPrice) => {
    const resp = await axios.put(`${apiUrl}requestHomeworks/${id}`, { stateRequestHomework: stateRequestHomework, teacherId: teacherId, price: price, percentPrice: percentPrice });
    return resp.data;
  };

  return { getWorkStudentBackEnd, isLoading, addHomeWorkStudent, putHomeWorkBackEnd, putRequestHomeWorkBackEnd };
};
