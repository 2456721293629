import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperLocation = {};

helperLocation.getCityById = async (id) => {
  const res = await axios.get(`${apiUrl}cities/${id}`);
  return res.data.Citys;
};

helperLocation.getProvinceById = async (id) => {
  const res = await axios.get(`${apiUrl}provinces/${id}`);
  return res.data.Provices;
};

helperLocation.getCountryById = async (id) => {
  const res = await axios.get(`${apiUrl}countries/${id}`);
  return res.data.Countrys;
};

helperLocation.getAllLocation = async (idCity) => {
  // console.log('idCity ==>', idCity);
  const city = await helperLocation.getCityById(idCity);
  // console.log('city ==>', city);
  const province = await helperLocation.getProvinceById(city.provinceId);
  const country = await helperLocation.getCountryById(province.countryId);
  return { cityId: idCity, provinceId: province.id, countryId: country.id };
};
