import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Badge, Divider, Fab, Grid, Rating, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdStudentItem = (props) => {
  const { student, renderAgain } = props;
  const classes = useStyles();
  const history = useHistory();

  const { updateUserById } = useUser();

  const onRejectStudent = (newStatus) => {
    if (student.id) {
      updateUserById(student.id, newStatus);
      renderAgain();
    }
  };

  const onAcceptStudent = (newStatus) => {
    if (student.id) {
      updateUserById(student.id, newStatus);
      renderAgain();
    }
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" sx={{ gap: '1rem', mt: 5, mb: 3 }}>
        <Grid item md={2}>
          <Badge
            overlap="circular"
            badgeContent=" "
            // color={student.status === 1 ? 'success' : 'primary'}
            // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Avatar src={student.img} sx={{ width: 132, height: 132 }} />
          </Badge>
        </Grid>
        <Grid item md={6}>
          <Typography>{student.fullName}</Typography>
          <br />
          <Grid container>
            <div style={{ flexDirection: 'column' }}>
              <div style={{ flexDirection: 'row' }}>
                {/* {student.rating && <Rating value={student.rating} />}
                {student.review && (
                  <Typography sx={{ cursor: 'pointer' }} onClick={() => history.push('/admin/student/1/review')}>
                    ({student.review})
                  </Typography>
                )} */}

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Rating value={0} readOnly />
                  <div>
                    <Typography  onClick={() => history.push(`/admin/student/${student.id}/review`)}>
                      (0)
                    </Typography>
                  </div>
                </div>
              </div>

              <div style={{ flexDirection: 'column', marginTop: 10 }}>
                <Typography sx={{ cursor: 'pointer' }}>{student.phoneNumber}</Typography>
                <Typography sx={{ cursor: 'pointer' }}>{student.email}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item md={2}>
          {student.status === 1 ? (
            <Fab variant="extended" color="secondary" sx={{ width: 160, height: 60, mx: 1, marginTop: 2 }} onClick={() => onRejectStudent(0)}>
              <Typography sx={{ textTransform: 'capitalize' }}>Eliminar</Typography>
            </Fab>
          ) : (
            <Fab variant="extended" color="primary" sx={{ width: 160, height: 60, mx: 1, marginTop: 2 }} onClick={() => onAcceptStudent(1)}>
              <Typography sx={{ textTransform: 'capitalize' }}>Aceptar</Typography>
            </Fab>
          )}
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};
