import React from "react";
import { makeStyles } from "@mui/styles";
import urgent from "../../../../assets/icon/urgent.svg";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "320px",
    height: "440px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "16px",
    background: "#FEB098CC",
  },
}));
export const UrgentHelpCard = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={urgent}
        alt="urgent"
        onClick={() => history.push("/student/urgent-teachers")}
      />
      <div>
        <Typography sx={{ mx: 6, my: 4 }}>
          No hay tiempo, Solicita un profesor al azar que te atendenrá de
          inmediato.
        </Typography>
      </div>
    </div>
  );
};
