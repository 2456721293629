import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const helperPrices = {};

// helperPrices.postLevel = async (data) => {
//   const res = await axios.post(`${apiUrl}levels`, data);
//   // console.log("res data post level -->", res.data);
//   return res.data.Levels;
// };

helperPrices.putUserPriceById = async (data) => {
  const res = await axios.put(`${apiUrl}userprices/`, data);
  console.log(res.data);
  // return res.data.LevelDB;
};

// helperPrices.deleteLevel = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };

// helperPrices.getLevelsBySchoolId = async (id) => {
//   const res = await axios.get(`${apiUrl}subjects/schoolId/${id}`);
//   // console.log("res get subjects by school id -->", res.data.subjects);
//   return res.data.subjects;
// };

helperPrices.getPricesByUserIdAndLevelId = async (userId, levelId) => {
  const res = await axios.get(`${apiUrl}userprices/${userId}/${levelId}`);
  return res.data.userUserPrices;
};

helperPrices.postUserPrices = async (data) => {
  const res = await axios.post(`${apiUrl}userprices/`, data);
  return res.data.msg;
};
