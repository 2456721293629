import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import urgentOnly from "../../../../assets/icon/urgentOnly.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FEB098",
    border: "1.5px solid #D8E7E4",
    padding: "16px",
    borderRadius: "20px",
    position: "relative",
    marginBottom: "30px",
  },
}));
export const UrgentMark = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item md={9}>
          <Typography sx={{ mx: 5, my: 3 }}>
            No hay tiempo, ¡Necesito un profesor ahora!
          </Typography>
        </Grid>
        <Grid item md={3} container justifyContent="center">
          <img src={urgentOnly} alt="urgentOnly" />
        </Grid>
        {/* <Grid item md={2}>
          <Typography>$5/h</Typography>
        </Grid> */}
      </Grid>
    </div>
  );
};
