import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const setSubjectNameAndId = (idSubject, nameSubject) => {
  return {
    type: types.setNameAndIdSubject,
    payload: { idSubject, nameSubject },
  };
};

const getTeacherByIdSubject = async (idSubject) => {
  const resp = await axios.get(`${apiUrl}view/getTeacherBySubjectId/${idSubject}`);
  if (resp.status === 200) {
    if (resp.data.msg) {
      return resp.data.TeacherBySubject;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const getPriceByUserIdAndLevelId = async (userId, levelId) => {
  const resp = await axios.get(`${apiUrl}userprices/${userId}/${levelId}`);
  if (resp.status === 200) {
    if (resp.data.msg) {
      return resp.data.userUserPrices[0];
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const getLevelsByUserId = async (userId) => {
  const resp = await axios.get(`${apiUrl}view/viewLevelsByUserId/ByIdUser/${userId}`);
  return resp.data.levelByUserId;
};
const getInfoUserByIdUser = async (userId) => {
  const resp = await axios.get(`${apiUrl}users/${userId}`);
  return resp.data.usuario;
};

const getTeacherInfo = async (idUserId) => {
  try {
    const resp = await axios.get(`${apiUrl}teacherTrainings/byUser/${idUserId}`);
    const teacherAverageReview = await getTeacherAverageReview(idUserId);
    // const { price } = await getPriceByUserIdAndLevelId(idUserId, levelId);
    const levels = await getLevelsByUserId(idUserId);
    const { fullName, img } = await getInfoUserByIdUser(idUserId);
    // const dataBank = await getDataBankByUseId(idUserId);
    if (resp.status === 200) {
      if (resp.data.msg) {
        const teacherInfo = resp.data.teacherTrainings;
        return {
          ...teacherInfo,
          fullName,
          img,
          levels,
          starReview: Number(teacherAverageReview[0].star) || 0,
          // price,
          // dataBank,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
};

const getDataBankByUseId = async (userId) => {
  try {
    const resp = await axios.get(`${apiUrl}databanks/byiduser/${userId}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        return resp.data.DataBanks;
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
};

const getTeacherAverageReview = async (userId) => {
  const resp = await axios.get(`${apiUrl}view/reviewstar/${userId}`);
  return resp.data.star;
};

export const getTeachersByIdSubjetct = (idSubject) => {
  return async (dispatch) => {
    const resp = await getTeacherByIdSubject(idSubject);
    let allTeachers = resp.map(async (teacherInformation) => {
      const levelsByUser = await getLevelsByUserId(teacherInformation.userId);
      const { price } = await getPriceByUserIdAndLevelId(teacherInformation.userId, teacherInformation.levelId);
      return {
        ...teacherInformation,
        price,
        levels: levelsByUser,
      };
    });
    allTeachers = await Promise.all(allTeachers);
    dispatch(setTeachers(allTeachers, false));
  };
};

export const getTeacherInfoSelectedAdmin = (UserId, levelId) => {
  return async (dispatch) => {
    const resp = await getTeacherInfo(UserId, levelId);
    dispatch(setTeacherInfoAdmin(resp, false));
  };
};

const setTeachers = (teachers, isLoading) => {
  return {
    type: types.setAdminTeachers,
    payload: { teachers, isLoading },
  };
};
const setTeacherInfoAdmin = (teacher, isLoading) => {
  return {
    type: types.setAdminTeacherSelected,
    payload: { teacher, isLoading },
  };
};
