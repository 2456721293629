import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Box, List, ListItemText } from '@mui/material';
import academy from '../../../../assets/image/academy.svg';
import certificate from '../../../../assets/icon/certificate.svg';
import reback from '../../../../assets/icon/return.svg';
import camera from '../../../../assets/icon/camera.svg';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 30px',
  },
}));
export const ProfessorContent = ({ admin, university = '', role, price = 0, certifications, userId, teacherTrainingId }) => {
  const classes = useStyles();
  const history = useHistory();

  const [certificates, setCertificates] = useState([]);

  const splitCertifications = () => {
    if (certifications) {
      const array = certifications.split(',');
      setCertificates(array);
    }
  };

  useEffect(() => {
    splitCertifications();
  }, [certifications]);

  return (
    <div className={classes.root}>
      <Box sx={{ borderRadius: 4, py: 4, bgcolor: '#D8E7E4' }}>
        <Grid container justifyContent="center">
          <Grid item md={4}>
            <img src={academy} style={{ marginLeft: '30px' }} alt="academy" />
          </Grid>
          <Grid item md={8}>
            <Typography sx={{ color: '#a5a5a5' }}>
              Educación
              {university}
              <br />
              {role}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ borderRadius: 4, p: 6, bgcolor: '#D8E7E4', my: 4 }}>
        <Grid container>
          <img src={certificate} alt="certificate" style={{ display: 'inline' }} />
          <Typography sx={{ color: '#a5a5a5', ml: 2 }}> Certificados</Typography>
        </Grid>
        <Box sx={{ pl: 2 }}>
          <List>
            {certificates.length > 0 ? (
              certificates.map((item, idx) => (
                <ListItemText key={idx}>
                  <Typography sx={{ color: '#a5a5a5' }}>&#8226; {item}</Typography>
                </ListItemText>
              ))
            ) : (
              <ListItemText>
                <Typography sx={{ color: '#a5a5a5' }}>&#8226; No tiene certificados</Typography>
              </ListItemText>
            )}
          </List>
        </Box>
      </Box>
      {price !== null && !admin && (
        <Typography variant="h5" sx={{ ml: 2 }}>
          Tarifa por clase:
          <br /> ${price} cada hora
        </Typography>
      )}
      {admin && (
        <Box sx={{ mt: 4 }}>
          <Grid container justifyContent="space-around" alignItems="center">
            <img src={reback} alt="return" style={{ cursor: 'pointer' }} onClick={() => history.push(`/admin/professor/${teacherTrainingId}/history`)} title="Historial" />
            <img src={camera} alt="camera" style={{ cursor: 'pointer' }} onClick={() => history.push(`/admin/professor/${userId}/profits`)} title="Datos Bancarios" />
          </Grid>
        </Box>
      )}
    </div>
  );
};
