import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentUrgentTeachearsPage } from '../../components/StudentPage/StudentUrgentTeachearsPage';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentUrgentTeachearsScreen = () => {
  const classes = useStyles();
  const { idSubject, nameSubject } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <LoginedTopBackground title={nameSubject} search />
      <StudentUrgentTeachearsPage idSubject={idSubject} />
      <BotBackground logined />
    </div>
  );
};
