import React from "react";
import { BotBackground } from "../components/common/BotBackground";
import { TopBackground } from "../components/common/TopBackground";
import { TopLogo } from "../components/common/TopLogo";
import { LoginPage } from "../components/LoginPage";

export const LoginScreen = () => {
  return (
    <div>
      <TopBackground />
      <TopLogo />
      <LoginPage />
      <BotBackground />
    </div>
  );
};
