import React from 'react';
import { BotBackground } from '../components/common/BotBackground';
import { TopBackground } from '../components/common/TopBackground';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import LogoGooglePlay from '../assets/icon/google-play.svg';
import LogoAppStore from '../assets/icon/app-store.svg';
import MainLogo from '../assets/image/mainLogo.png';
import MainLogo2 from '../assets/image/logoIlustro2.svg';
import NousLogo from '../assets/icon/logoNous.svg';

export const MobileScreen = () => {
  return (
    <div style={{ display: 'flex', height: '100vh', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div>
        <TopBackground />
      </div>

      <div style={{ display: 'grid', placeItems: 'center', padding: 30 }}>
        <div style={{ marginBottom: 20 }}>
          <img src={MainLogo2} width="100%" alt="ILUSTRO LOGO" />
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Typography variant="h6" color="initial">
            Estamos para iluminar tu camino. <span style={{ fontWeight: 'bold' }}> Descargate la app </span> y encuentra un tutor de inmediato.
          </Typography>
        </div>
        <ButtonsStore store="android" />
        <ButtonsStore />
      </div>

      <div style={{ display: 'grid', placeItems: 'center', padding: 30 }}>
        <img src={NousLogo} />
      </div>
    </div>
  );
};

const ButtonsStore = ({ store = 'android' | 'ios' }) => {
  const classes = useStyle();

  const handleClicAndroid = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ilustro.nous', '_blank');
  };
  const handleClicIOS = () => {
    window.open('https://apps.apple.com/ec/app/ilustro/id1602459091', '_blank');
  };

  return store === 'android' ? (
    <div className={classes.buttonStore} style={{ backgroundColor: '#95F8D4' }} onClick={handleClicAndroid}>
      <Typography variant="subtitle1" color="initial">
        Ilustro para Android
      </Typography>
      <div>
        <img src={LogoGooglePlay} />
      </div>
    </div>
  ) : (
    <div className={classes.buttonStore} style={{ backgroundColor: '#FFEC88' }} onClick={handleClicIOS}>
      <Typography variant="subtitle1" color="initial">
        Ilustro para IOS
      </Typography>
      <div>
        <img src={LogoAppStore} />
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    margin: '20px',
  },

  buttonStore: {
    alignItems: 'center',
    borderRadius: 5,
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    marginBottom: 5,
    marginTop: 5,
    paddingLeft: 30,
    paddingRight: 30,
    width: '80%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
