import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { AdminProfessorHistoryPage } from '../../components/AdminPage/AdminProfessorHistoryPage';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdminProfessorHistory = () => {
  const classes = useStyles();
  const { name } = useParams();

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Historial" search />
      <AdminProfessorHistoryPage trainingId={name} />
      <BotBackground logined />
    </div>
  );
};
