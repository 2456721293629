import React from 'react';
import { Avatar, Box, Typography, Button, FormControl, FormLabel, FormHelperText, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import moment from 'moment';

export const OfferTeacher = ({ avatar, nameTeacher, PriceOffer, hoursOffer, func, date }) => {
  const classes = useStyles();

  return (
    <div style={{ width: '100%', marginRight: 20, marginLeft: 20 }}>
      <Box className={classes.boxContainer}>
        <Box className={classes.boxDetailOffer}>
          <Avatar src={avatar} sx={{ width: 86, height: 86, mr: 3 }} />
          <Box className={classes.boxOffer}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              {nameTeacher}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className={classes.boxItem} sx={{ mr: 2 }}>
                <Typography variant="h6">{moment(new Date(date)).format('LL')}</Typography>
              </Box>

              <Box className={classes.boxItem}>
                <AccessTimeRoundedIcon sx={{ mr: 1 }} />
                <Typography variant="h6">{hoursOffer} h</Typography>
              </Box>
              <Box sx={{ mx: 2 }}>
                <Divider sx={{ border: '1px solid #A5A5A5', mb: -8 }} orientation="vertical" />
              </Box>
              <Box className={classes.boxItem} sx={{ mr: 2, color: '#0AD78D' }}>
                <AttachMoneyRoundedIcon />
                <Typography variant="h6">{PriceOffer}</Typography>
              </Box>
            </div>
          </Box>
        </Box>
        <div style={{ width: '50%' }}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Button sx={{ borderRadius: 20, px: 4 }} variant="contained" onClick={func}>
              Aceptar
            </Button>
          </FormControl>
        </div>
      </Box>
      <Divider sx={{ border: '1px solid #A5A5A5', mb: -8 }} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  boxContainer: {
    alignItems: 'center',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 30,
    marginBottom: 20,
  },
  boxDetailOffer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  boxOffer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  boxItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  buttonAccept: {
    borderRadius: 20,
  },
}));
