import axios from "axios";
import { apiUrl } from "../../../../config/apiConfig";

export const GET_VIEW_REQUEST_CLASS = "GET_VIEW_REQUEST_CLASS";

export const getViewRequestClass = (userid) => {
  const request = axios.get(`${apiUrl}view/RequestClass/${userid}`);
  return (dispatch) =>
    request
      .then((res) => {
        // console.log("result== =====>", res.data);
        if (res.status === 200) {
          dispatch({
            type: GET_VIEW_REQUEST_CLASS,
            payload: res.data.results,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
};

export const GET_TEACHER_TRAINING_BY_USER_ID =
  "GET_TEACHER_TRAINING_BY_USER_ID";

export const getTeacherTrainingByUserId = (userId) => {
  // console.log("user id -->", userId);
  const req = axios.get(`${apiUrl}teacherTrainings/byUser/${userId}`);
  return (dispatch) => {
    req
      .then((res) => {
        // console.log(
        //   "teacher training by user id ==> ",
        //   res.data.teacherTrainings
        // );
        if (res.status === 200) {
          dispatch({
            type: GET_TEACHER_TRAINING_BY_USER_ID,
            payload: res.data.teacherTrainings,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_HOMEWORKS_PENDING_BY_TEACHER_TRAINING_ID =
  "GET_HOMEWORKS_PENDING_BY_TEACHER_TRAINING_ID";

export const getHomeworksPendingByTeacherTrainingId = (teacherTrainingId) => {
  // console.log("teacher training id ==>", teacherTrainingId);
  const req = axios.get(
    `${apiUrl}view/RequestHomeworksPending/${teacherTrainingId}`
  );

  return (dispatch) => {
    req
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_HOMEWORKS_PENDING_BY_TEACHER_TRAINING_ID,
            payload: res.data.resultH,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_VIEW_HOMEWORKS_BY_TEACHER_TRAINING_ID =
  "GET_VIEW_HOMEWORKS_BY_TEACHER_TRAINING_ID";

export const getHomeworksByTeacherTrainingId = (teacherTrainingId) => {
  // console.log("teacher training id ==>", teacherTrainingId);
  const req = axios.get(`${apiUrl}view/RequestHomeworks/${teacherTrainingId}`);

  return (dispatch) => {
    req
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_VIEW_HOMEWORKS_BY_TEACHER_TRAINING_ID,
            payload: res.data.resultH,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_ALL_REQ_HOMEWORKS = "GET_ALL_REQ_HOMEWORKS";

export const getAllReqHomeworks = (teacherTrainingId) => {
  const req = axios.get(`${apiUrl}view/RequestHomeworks/${teacherTrainingId}`);

  return (dispatch) => {
    req
      .then((res) => {
        console.log("get all req homeworks ==> ", res.data.RequestHomeworks);
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_REQ_HOMEWORKS,
            payload: res.data.RequestHomeworks,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const getAllCities = () => {
  const req = axios.get(`${apiUrl}cities`);

  return (dispatch) => {
    req
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_CITIES,
            payload: res.data.Citys,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_ALL_PROVINCES = "GET_ALL_PROVINCES";
export const getAllProvinces = () => {
  const req = axios.get(`${apiUrl}provinces`);

  return (dispatch) => {
    req
      .then((res) => {
        // console.log("🚀 ~ file: homedata.action.js ~ line 169 ~ .then ~ res PROVINCES", res.data.Provices)
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_PROVINCES,
            payload: res.data.Provices,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const getAllCountries = () => {
  const req = axios.get(`${apiUrl}countries`);

  return (dispatch) => {
    req
      .then((res) => {
        // console.log(
        //   "🚀 ~ file: homedata.action.js ~ line 190 ~ .then ~ res COUNTRIES",
        //   res.data.CountryBD
        // );
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_COUNTRIES,
            payload: res.data.CountryBD,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_VIEW_COURSES_BY_USER = "GET_VIEW_COURSES_BY_USER";
export const getViewCourseByUserId = (id) => {
  const req = axios.get(`${apiUrl}view/viewUserCourses/byiduser/${id}`);
  return (dispatch) => {
    req
      .then((res) => {
        if (res.status === 200) {
          const aux = res.data.resultUserCourse;
          // console.log('aux ==>', aux);
          const newRes = aux.map((el) => {
            if (el.levelId === 4) {
              const subjectsBySchool = aux.filter(
                (item) => item.schoolId === el.schoolId
              );
              // console.log("subjects by school", subjectsBySchool);
              return {
                ...el,
                subjects: subjectsBySchool,
              };
            } else {
              return el;
            }
          });

          const setObj = new Set(); // creamos pares de clave y array

          const result = newRes.reduce((acc, school) => {
            if (!setObj.has(school.schoolId)) {
              setObj.add(school.schoolId, school);
              acc.push(school);
            }
            return acc;
          }, []);
          dispatch({
            type: GET_VIEW_COURSES_BY_USER,
            payload: result,
          });
        }
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};

export const GET_SCHEDULES_BY_USER_ID = "GET_SCHEDULES_BY_USER_ID";
export const getSchedulesByUserId = (id) => {
  const req = axios.get(`${apiUrl}schedules/byuserid/${id}`);
  return (dispatch) => {
    req
      .then((res) => {
        dispatch({
          type: GET_SCHEDULES_BY_USER_ID,
          payload: res.data.scheduleDB,
        });
      })
      .catch((err) => {
        console.log("err==", err);
      });
  };
};
