import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import * as homeActions from '../../HomePage/store/actions';
import { helperPrices } from '../../../services/helperPrices';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 10px',
  },
}));

const initialData = {
  id: 0,
  userId: 0,
  levelId: 0,
  price: 0,
};

export const LevelContent = ({ defaultLevelId }) => {
  const classes = useStyles();

  let user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const [priceObj, setPriceObj] = useState(initialData);

  useEffect(() => {
    getData();
  }, [defaultLevelId, user.id]);

  const getData = async () => {
    const prices = await helperPrices.getPricesByUserIdAndLevelId(user.id, defaultLevelId);
    if (prices.length > 0) {
      setPriceObj({
        id: prices[0].id,
        userId: user.id,
        levelId: defaultLevelId,
        price: prices[0].price,
      });
      console.log(prices);
    } else {
      setPriceObj(initialData);
    }
  };

  const handleChange = async (e) => {
    setPriceObj({ ...priceObj, [e.target.name]: e.target.value });
    const { id, levelId, userId } = priceObj;
    if (id !== 0) {
      const array = [{ id, levelId, price: e.target.value, userId }];
      const aux = { array };
      await helperPrices.putUserPriceById(aux);
    } else {
      const array = [{ userId: user.id, levelId: defaultLevelId, status: true, price: e.target.value }];
      const aux = { array };
      await helperPrices.postUserPrices(aux);
      getData();
    }
    // console.log(aux);
  };

  return (
    <div className={classes.root}>
      <Box
        sx={{
          maxWidth: '180px',
          width: '100%',
          height: '417px',
          background: 'rgba(149, 248, 212, 0.3)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: 4,
          padding: '4px 20px',
        }}
      >
        <Typography sx={{ fontWeight: 600, display: 'block' }}>Escribe tu precio de tutoría por hora para este nivel</Typography>
        <br />
        <div style={{ display: 'flex' }}>
          <Typography>$</Typography>
          <input
            type="text"
            style={{
              margin: '0px .5rem',
              maxWidth: '4rem',
              border: 'none',
              outline: 'none',
              background: 'transparent',
              padding: '4px 6px',
              fontSize: '1em',
              borderBottom: '3px solid #34887e',
            }}
            name="price"
            value={priceObj.price}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Box>
    </div>
  );
};
