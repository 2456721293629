import { Box, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

import empty_state from '../../assets/image/empty_state.svg';

export const EmptyState = ({ img = empty_state, title = 'No existe datos' }) => {
  const classes = useStyle();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box>
        <img className={classes.emptyState} src={img} alt={title}></img>
      </Box>
      <Box> </Box>
      <Box>
        <Typography variant="h3" align='center'>{title}</Typography>
      </Box>
    </Box>
  );
};

const useStyle = makeStyles({
  emptyState: {
    width: 350,
  },
});
