import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperReqClass = {};

helperReqClass.putReqClassById = async (id, data) => {
  const res = await axios.put(`${apiUrl}requestClasses/${id}`, data);
  // console.log("res get Req Class by id -->", res.data.RequestClassDB);
  return res.data.RequestClassDB;
};

// helperReqClass.postReqClass = async (data) => {
//   const res = await axios.post(`${apiUrl}subjects`, data);
//   // console.log("res data post -->", res.data);
//   return res.data.subjects;
// };

// helperReqClass.putReqClass = async (id, data) => {
//   const res = await axios.put(`${apiUrl}subjects/${id}`, data);
//   // console.log("res data put -->", res.data);
//   return res.data.SubjectDB;
// };

// helperReqClass.deleteReqClass = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };
