import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@mui/material";
import { AdminProfessorStateList } from "../AdminProfessorsPage/AdminProfessorStateList";
import { AdStudentList } from "./AdStudentsList";
import { useUser } from "../../../hooks/useUser";
import { AdminStudentState } from "./AdminStudentState";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdminStudentsPage = () => {
  const classes = useStyles();
  const [defaultState, setDefaultState] = useState(1);
  const [render, setRender] = useState(false);
  const [studentListAccepted, setStudentListAccepted] = useState([]);
  const [studetListRejected, setStudetListRejected] = useState([]);

  const { getAllUser } = useUser();

  useEffect(() => {
    getStudents();
  }, [defaultState, render]);

  useEffect(() => {
    return () => studentListAccepted;
  }, [studentListAccepted]);

  useEffect(() => {
    return () => studetListRejected;
  }, [studetListRejected]);

  const getStudents = async () => {
    const listUsers = await getAllUser();
    const studentListAccepted = listUsers.filter(
      (s) => s.rol == "student" && s.status == 1
    );
    setStudentListAccepted(studentListAccepted);
    const studentListReject = listUsers.filter(
      (s) => s.rol == "student" && s.status == 0
    );
    setStudetListRejected(studentListReject);
  };

  const renderAgain = async () => {
    setRender(!render);
    await getStudents();
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item md={3} >
          <AdminStudentState
            setDefaultState={setDefaultState}
            defaultState={defaultState}
          />
        </Grid>
        <Grid item md="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={8}>
          <AdStudentList
            students={
              defaultState === 1 ? studentListAccepted : studetListRejected
            }
            renderAgain={renderAgain}
          />
        </Grid>
      </Grid>
    </div>
  );
};
