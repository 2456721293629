import React, { useEffect, useState } from "react";
import { Divider, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { UserState } from "../common/UserState";
// import { HomeTool } from "./HomeTool";
// import user1 from "../../assets/image/user1.png";
// import user2 from "../../assets/image/user2.png";
import { AgendarContent } from "./AgendarContent";
import { AgendaUserState } from "./AgendaUserState";
import { EmptyState } from "../common/EmptyState";
import * as homeActions from "../HomePage/store/actions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const useStyle = makeStyles({
  root: {
    margin: "20px 40px",
  },
});
export const AgendaPage = () => {
  const dispatch = useDispatch();

  // const [datesSelected, setDatesSelected] = useState([]);

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  // const [agendedClasses, setAgendedClasses] = useState([]);
  const [daySearch, setDaySearch] = useState(moment(moment()._d).format("DD"));

  // console.log("==>", moment(moment()._d).format("DD"));

  const teacherTraining = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataTeacherTraining.requestTeacherTraining
  );

  const homeClasses = useSelector(
    ({ homeReducer }) => homeReducer.homeData.requestClasses
  );

  const viewReqHomeworks = useSelector(
    ({ homeReducer }) => homeReducer.homeDataViewHomeworks.reqViewHomeworks
  );

  useEffect(() => {
    dispatch(homeActions.getViewRequestClass(user.id));
    dispatch(homeActions.getTeacherTrainingByUserId(user.id));
  }, []);

  useEffect(() => {
    dispatch(homeActions.getHomeworksByTeacherTrainingId(teacherTraining.id));
  }, [dispatch, teacherTraining]);

  // console.log(homeClasses);
  // console.log(viewReqHomeworks);

  const datesSelected = [];
  const allData = [...homeClasses, ...(viewReqHomeworks || [])];
  const homeworksAndClasses = allData
    .map((item) => {
      if (item.stateRequestClass === "accepted" || item.state === "accepted") {
        datesSelected.push(item.date);
        // if (item.stateRequestClass === "accepted") {
        //   setAgendedClasses([...agendedClasses, item]);
        // }
        return item;
      }
      return null;
    })
    .filter((el) => el && el && moment(el.date).format("DD") === daySearch);

  // console.log(homeworksAndClasses);
  //
  // const agendedClasses = allData.filter(el => el.stateRequestClass === 'accepted');
  const agendedClasses = homeClasses;

  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={8}>
          {homeworksAndClasses.length <= 0 && (
            <EmptyState title="Nada que mostrar el día de hoy" />
          )}
          {homeClasses.length > 0 &&
            homeClasses.map((item, index) => {
              // console.log("item -->", item);
              // console.log(item.stateRequestClass);
              return (
                <Box key={index}>
                  {item.stateRequestClass === "accepted" &&
                  moment(item.date).format("DD") === daySearch ? (
                    <>
                      {/* {datesSelected.push(item.date)} */}
                      <AgendaUserState
                        // setDatesSelected={setDatesSelected}
                        // datesSelected={datesSelected}
                        avatar={item.img}
                        key={index}
                        name={item.nameStudent}
                        nameSubject={item.nameSubject}
                        nameLevel={item.nameLevel}
                        date={item.date}
                        price={item.price}
                        hoursNumber={item.hoursNumber}
                        reqClassId={item.requestClassId}
                        homeClasses={homeClasses}
                        schedule={item.schedule}
                      />
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
                      />
                    </>
                  ) : null}
                </Box>
              );
            })}
          {viewReqHomeworks &&
            viewReqHomeworks.map((item, index) => {
              return (
                <Box key={index}>
                  {item.state === "accepted" &&
                  moment(item.date).format("DD") === daySearch ? (
                    <Box key={index}>
                      {/* {datesSelected.push(item.date)} */}
                      {/* {console.log("item -->,", item)} */}
                      <AgendaUserState
                        avatar={item.imgStudent}
                        // key={index}
                        name={item.student}
                        nameSubject={item.nameSubject}
                        nameLevel={item.nameLevel}
                        date={item.date}
                        purchase={true}
                        detailHomework={item.detailHomework}
                        classReq={item.class}
                        fileUrl={
                          item.fileUrl && item.fileUrl.trim().length > 0
                            ? item.fileUrl
                            : null
                        }
                        timeHomework={item.timeHomework}
                        // price={item.price}
                        // hoursNumber={item.hoursNumber}
                      />
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
                      />
                    </Box>
                  ) : null}
                </Box>
              );
            })}
          {/* <AgendaUserState avatar={user1} name=" Amanda Castro" badge={2} />
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
          />
          <AgendaUserState avatar={user2} name="German Sánchez" />
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ border: "1px solid #A5A5A5", mt: 4, mb: 4 }}
          />
          <AgendaUserState avatar={user2} name="German Sánchez" purchase /> */}
        </Grid>
        <Grid item md={1} container direction="row" justifyContent="center">
          {/* <Divider
            orientation="vertical"
            flexItem
            sx={{ border: "1px solid #A5A5A5", mb: -8 }}
          /> */}
        </Grid>
        <Grid item md={3} container justifyContent="center">
          <AgendarContent
            datesSelected={datesSelected}
            setDaySearch={setDaySearch}
            homeClasses={homeClasses}
            agendedClasses={agendedClasses}
          />
        </Grid>
      </Grid>
    </div>
  );
};
