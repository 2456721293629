import { types } from '../../../types/types';

const initialState = {
  teachers: [],
  teacherSelected: {},
  isLoading: true,
};

export const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setAdminTeachers:
      return {
        ...state,
        teachers: action.payload.teachers,
        isLoading: action.payload.isLoading,
      };
    case types.setAdminTeacherSelected:
      return {
        ...state,
        teacherSelected: action.payload.teacher,
        isLoading: action.payload.isLoading,
      };

    default:
      return state;
  }
};
