import * as loginActions from "../actions";
const initailState = { user: undefined, isAuthenticated: false };
export const login = (state = initailState, action) => {
  switch (action.type) {
    case loginActions.LOGIN: {
      return { ...state, user: action.payload, isAuthenticated: true };
    }
    case loginActions.SET_USER: {
      return { ...state, user: action.payload, isAuthenticated: true };
    }
    case loginActions.LOGOUT: {
      return { ...state, user: undefined, isAuthenticated: false };
    }

    default:
      return state;
  }
};
