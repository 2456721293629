import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentSubjectItemPage } from '../../components/StudentPage/StudentSubjectItemPage';
import { useParams } from 'react-router-dom';
import { useSubjects } from '../../hooks/useSubjects';
import { useDispatch } from 'react-redux';
import { setSubjectNameAndId } from '../../store/actions/students/teachersActions';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentSubjectItemScreen = () => {
  const classes = useStyles();
  const [subject, setSubject] = useState({});
  const dispatch = useDispatch();

  const { getSubjectsById, getTeacherBackend } = useSubjects();

  const { id } = useParams();

  useEffect(() => {
    subjectById();
  }, []);

  const subjectById = async () => {
    if (id) {
      const resp = await getTeacherBackend(id);

      setSubject(resp);

      dispatch(setSubjectNameAndId(id, resp.nameSubject));
    }
  };

  return (
    <div className={classes.root}>
      <LoginedTopBackground title={subject.nameSubject || ''} search />
      <StudentSubjectItemPage idSubject={id} />
      <BotBackground />
    </div>
  );
};
