import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSubjectsSchool } from '../../../../store/actions/students/subjectsAction';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      borderColor: '#95F8D4',
      cursor: 'pointer',
    },
  },
  content: {
    width: '120px',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '14px',
    margin: '20px',
  },
}));
export const StdMenuItem = ({ icon, black, name, id, func}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClic = () => {
    if (!black) {
      dispatch(getSubjectsSchool(id, name));
      history.push('/student/subjects');
    } else {
      func();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content} onClick={() => handleClic()} style={{ backgroundColor: black ? 'black' : '#D8E7E4' }}>
        <img src={icon} alt='iconSchool' width={80}/>
      </div>
      <Typography>{name}</Typography>
    </div>
  );
};
