import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
// import { AdminProfessorStateItem } from "./AdminProfessorStateItem";
import { Typography, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: { padding: "60px 30px" },
}));

const bgkDefault = "rgba(216, 231, 228, 1)";
const bgkPending = "rgba(164, 204, 223, 1)";
const bgkAccepted = "rgba(149, 248, 212, 1)";
const bgkRejected = "rgba(254, 176, 152, 1)";

const styleBox = {
  background: bgkDefault,
  padding: "30px",
  borderRadius: 10,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  marginBottom: "20px",
  cursor: "pointer",
};

const states = ["Pendiente", "Aceptado", "Rechazado"];

export const AdminProfessorStateList = ({
  setTeachersBS,
  setDefaultState,
  defaultState,
}) => {
  const classes = useStyles();

  const [firstLoad, setFirstLoad] = useState("pending");

  let refBoxPending = useRef();
  let refBoxAccepted = useRef();
  let refBoxRejected = useRef();

  useEffect(() => {
    if (firstLoad === "pending") {
      refBoxPending.current.style.backgroundColor = bgkPending;
    }
  }, []);

  const HANDLE_STATE = {
    Pendiente: async (e) => {
      refBoxPending.current.style.backgroundColor = bgkPending;
      refBoxAccepted.current.style.backgroundColor = bgkDefault;
      refBoxRejected.current.style.backgroundColor = bgkDefault;
      setDefaultState("pending");
      setFirstLoad("");
    },
    Aceptado: async (e) => {
      refBoxPending.current.style.backgroundColor = bgkDefault;
      refBoxAccepted.current.style.backgroundColor = bgkAccepted;
      refBoxRejected.current.style.backgroundColor = bgkDefault;
      setDefaultState("accepted");
      setFirstLoad("");
    },
    Rechazado: async (e) => {
      refBoxPending.current.style.backgroundColor = bgkDefault;
      refBoxAccepted.current.style.backgroundColor = bgkDefault;
      refBoxRejected.current.style.backgroundColor = bgkRejected;
      setDefaultState("rejected");
      setFirstLoad("");
    },
  };

  const handleClickState = async (state, e) => {
    const hState = HANDLE_STATE[state] || null;
    await hState(e);
  };

  const loadStyles = (e) => {
    refBoxPending.current.style.backgroundColor = bgkPending;
  };

  let cont = 0;

  return (
    <div className={classes.root} onLoad={(e) => loadStyles(e)}>
      {states.map((state) => {
        const box = (
          <Box
            key={state}
            sx={{
              background: bgkDefault,
            }}
            ref={
              state === "Pendiente"
                ? refBoxPending
                : state === "Aceptado"
                ? refBoxAccepted
                : refBoxRejected
            }
            style={styleBox}
            onClick={(e) => handleClickState(state, e)}
            id={`state${cont}`}
          >
            <Typography>{state}</Typography>
          </Box>
        );
        cont++;
        return box;
      })}
    </div>
  );
};
