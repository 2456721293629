import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { StdMainRequestList } from './StdMainRequestList';
import { StdMainContent } from './StdMainContent';
const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 40px' },
}));
export const StudentMainPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container >
        <Grid item md={3} style={{ zIndex: 2 }}>
          <StdMainRequestList />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={5} container justifyContent="center">
          <StdMainContent />
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </div>
  );
};
