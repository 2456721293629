import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperTeacherTraining = {};

// helperTeacherTraining.postTeacherTraining = async (data) => {
//   const res = await axios.post(`${apiUrl}subjects`, data);
//   // console.log("res data post -->", res.data);
//   return res.data.subjects;
// };

helperTeacherTraining.putTeacherTraining = async (id, data) => {
  const res = await axios.put(`${apiUrl}teacherTrainings/${id}`, data);
  return res.data.TeacherTrainingDB;
};

// helperTeacherTraining.deleteTeacherTraining = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };

helperTeacherTraining.getTeacherTrainingByIdUser = async (id) => {
  const res = await axios.get(`${apiUrl}teacherTrainings/byUser/${id}`);
  return res.data.teacherTrainings;
};
