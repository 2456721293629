import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperViewCourses = {};

// helperViewCourses.getAllUser = async () => {
//   const resp = await axios.get(`${apiUrl}users`);
//   return resp.data;
// };

helperViewCourses.deleteCourseById = async (id) => {
  const resp = await axios.delete(`${apiUrl}userCourses/${id}`);
  return resp.data.UserCourseDB;
};

helperViewCourses.postUserCourse = async (data) => {
  const resp = await axios.post(`${apiUrl}userCourses/`, data);
  console.log(resp.data);
  // return resp.data;
};

helperViewCourses.getViewCourseByUserId = async (id) => {
  const res = await axios.get(`${apiUrl}view/viewUserCourses/byiduser/${id}`);
  const aux = res.data.resultUserCourse;
  // console.log('aux ==>', aux);
  const newRes = aux.map((el) => {
    if (el.levelId === 4) {
      const subjectsBySchool = aux.filter(
        (item) => item.schoolId === el.schoolId
      );
      // console.log("subjects by school", subjectsBySchool);
      return {
        ...el,
        subjects: subjectsBySchool,
      };
    } else {
      return el;
    }
  });

  const setObj = new Set(); // creamos pares de clave y array

  const result = newRes.reduce((acc, school) => {
    if (!setObj.has(school.schoolId)) {
      setObj.add(school.schoolId, school);
      acc.push(school);
    }
    return acc;
  }, []);

  // console.log("registros unicos ==>", result);
  // console.log("new res ==>", newRes);

  // return res.data.resultUserCourse;
  return result;
};
