import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CalendarPicker, LocalizationProvider, PickersDay } from '@mui/lab';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useSchedule } from '../../../hooks/useSchedule';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Fab } from '@mui/material';
import { setManualDateRequestClass } from '../../../store/actions/students/requestClassActions';
import * as fuseActions from '../../../store/actions/fuse';

const initSchedule = {
  date: '',
  bgActive: false,
  hour: '',
  idHour: 0,
};

export const StudentSchedulePage = () => {
  const classes = useStyles();
  const { getScheduleByUserId, getScheduleByDateandUserId } = useSchedule();
  const [date, setDate] = useState(new Date());
  const [daySearch, setDaySearch] = useState(new Date());
  const history = useHistory();
  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);
  const [schedules, setSchedules] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(initSchedule);

  const dispatch = useDispatch();

  const getScheduleByIdUser = async () => {
    const schedules = await getScheduleByUserId(teacherSelected.userId);
    setSchedules(schedules);
  };

  const handleChangeCalendar = (e) => {
    setDate(e);
    setDaySearch(moment(e).format('DD'));
  };

  const handleOnDaySelect = async (e) => {
    setSelectedSchedule(initSchedule);
    setDate(e);
    setDaySearch(moment(e).format('DD'));
    // console.log('===>', moment(e).format('YYYY/MM/DD'), moment(e).format('DD'));
    await getScheduleHoursByDateAndUserId(moment(e).format('YYYY/MM/DD'), teacherSelected.userId);
  };

  const getScheduleHoursByDateAndUserId = async (date, id) => {
    const resp = await getScheduleByDateandUserId({ date: moment(date).format('YYYY/MM/DD'), id: id });
    convertHours(resp);
  };

  const convertHours = (hours) => {
    // console.log('horarios ==>', hours);
    let times = [];
    let timesNumber = [];
    hours &&
      hours.map((s) => {
        let start = new Date(s.startTime).getHours();
        let end = new Date(s.endTime).getHours();
        // console.log('hora number ==>', start, end);
        timesNumber.push({ start, end });

        for (let index = start; index < end; index++) {
          // console.log('element ==>', index);
          const element = [index];
          times.push(element + ':00');
        }
      });
    setTimeList(times);
  };

  const getDayElement = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    let elementDay = <PickersDay key={day} day={day} outsideCurrentMonth={false} onDaySelect={(e) => handleOnDaySelect(e)}></PickersDay>;

    const dayPicker = moment(day).format('DD');
    const dayMonthPicker = moment(day).format('MM');

    const datos = schedules.map((el) => {
      const daySelected = moment(el.startTime).format('DD');
      const dayMonthSelected = moment(el.startTime).format('MM');

      if (dayPicker === daySelected && dayMonthPicker === dayMonthSelected) {
        // dias con horarios
        elementDay = <PickersDay key={day} day={day} outsideCurrentMonth={false} onDaySelect={(e) => handleOnDaySelect(e)} sx={{ background: '#FEB098' }}></PickersDay>;
      }
    });

    if (dayPicker === moment(moment()._d).format('DD')) {
      elementDay = <PickersDay key={day} day={day} outsideCurrentMonth={false} onDaySelect={(e) => handleOnDaySelect(e)} sx={{ background: '#65A2B6' }}></PickersDay>;
    }

    if (dayPicker === daySearch && dayMonthPicker === moment(date).format('MM')) {
      elementDay = <PickersDay key={day} day={day} outsideCurrentMonth={false} onDaySelect={(e) => handleOnDaySelect(e)} sx={{ background: '#FFEC88' }}></PickersDay>;
    }

    // console.log(elementDay);
    return elementDay;
  };

  useEffect(() => {
    getScheduleByIdUser();
  }, []);

  useEffect(() => {
    getScheduleHoursByDateAndUserId(moment(new Date()).format('YYYY/MM/DD'), teacherSelected.userId);
  }, []);

  const handleClick = (id, hour) => {
    setSelectedSchedule({
      date: moment(date).format('YYYY/MM/DD'),
      bgActive: true,
      hour: hour,
      idHour: id,
    });
  };

  const onNextStep = () => {
    // console.log('siguiente->', selectedSchedule);
    if (selectedSchedule.date && selectedSchedule.hour) {
      dispatch(setManualDateRequestClass(selectedSchedule.date, selectedSchedule.hour));
      history.push('/student/payment');
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Seleccione un fecha y un horario',
          variant: 'warning',
        })
      );
    }
  };

  // console.log(timeList);
  return (
    <div className={classes.root}>
      <Grid container spacing={8} justifyContent="center" alignItems="center">
        <Grid item md={6} xs={12}>
          <div className={classes.gridItem}>
            <Typography variant="h6" color="initial">
              Selecciona la fecha de tu clase
            </Typography>
            <div className={classes.containerCalendar}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker minDate disablePast sx={{ width: '100%', heigth: '100%' }} onChange={(e) => handleChangeCalendar(e)} renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)} />
              </LocalizationProvider>
            </div>
            <div className={classes.containerStates}>
              <div className={classes.containerStatesBall}>
                <div className={classes.ball} style={{ backgroundColor: '#FFEC88' }}></div>
                <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                  Día seleccionado
                </Typography>
              </div>
              <div className={classes.containerStatesBall}>
                <div className={classes.ball} style={{ backgroundColor: '#FEB098' }}></div>
                <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                  Días con horarios disponibles
                </Typography>
              </div>
              <div className={classes.containerStatesBall}>
                <div className={classes.ball} style={{ backgroundColor: '#A5A5A5' }}></div>
                <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                  No disponible
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.gridItem}>
            <Typography variant="h6" color="initial">
              Selecciona la hora de tu clase
            </Typography>
            <div className={classes.containerHours}>
              <Typography variant="h5" color="initial">
                Horas
              </Typography>
              <div className={classes.containerChips}>
                {/* <Chip label="16:00" color="secondary" onClick={() => console.log('first')} sx={{ mb: 1, mt: 1, bgcolor: '#FFEC88' }} /> */}
                {timeList.length > 0 ? (
                  timeList.map((item, idx) => <Chip key={idx} label={item} color="secondary" onClick={() => handleClick(idx, item)} sx={{ mb: 1, mt: 1, bgcolor: selectedSchedule.bgActive && selectedSchedule.idHour === idx ? '#FFEC88' : '#fff' }} />)
                ) : (
                  <Typography variant="body2" color="initial" textAlign="center">
                    Selecciona un día con horarios disponibles en el calendario
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.containerStates}>
              <div className={classes.containerStatesBall}>
                <div className={classes.ball} style={{ backgroundColor: '#FFEC88' }}></div>
                <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                  Hora de inicio
                </Typography>
              </div>

              <div className={classes.containerStatesBall}>
                <div className={classes.ball} style={{ backgroundColor: '#A5A5A5' }}></div>
                <Typography variant="body1" color="initial" sx={{ ml: 1 }}>
                  No disponible
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ width: 200 }}>
        <Fab
          variant="extended"
          sx={{
            textTransform: 'capitalize',
            height: 70,
            width: '100%',
          }}
          color="primary"
          onClick={onNextStep}
        >
          <Typography variant="body1">Siguiente</Typography>
        </Fab>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  ball: {
    borderRadius: '100%',
    width: 20,
    height: 20,
  },
  containerStatesBall: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
  },
  containerStates: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1000,
    marginBottom: 100,
  },
  containerCalendar: {
    backgroundColor: '#F6F6F6',
    borderRadius: 20,
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  containerHours: {
    width: 300,
    height: 300,
    backgroundColor: '#F6F6F6',
    borderRadius: 20,
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerChips: {
    position: 'relative',
    overflow: 'auto',

    width: '80%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
  },
}));
