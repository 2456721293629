import React, { useEffect, useState } from 'react';
import { Fab, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { helperDataBank } from '../../../services/helperDataBank';
import { useSelector, useDispatch } from 'react-redux';
import * as fuseActions from '../../../store/actions/fuse';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 100px',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
      border: '1px solid black',
    },
  },
}));

const initialData = {
  ownerBank: '',
  nameDatabank: '',
  accountNumber: '',
  dni: '',
  phoneNumber: '',
  accountType: '',
  email: '',
};

export const ProfessorDataBank = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataBank, setDataBank] = useState(initialData);
  const [dataBankId, setDataBankId] = useState(0);

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);


  useEffect(() => {
    
    getDataBank();
  }, []);

  const getDataBank = async () => {
    console.log('entra==>',user);
    const resDataBank = await helperDataBank.getDataBankByUserId(user.id);
    console.log(resDataBank);
    if (resDataBank) {
      setDataBank({
        ...initialData,
        ownerBank: resDataBank.ownerBank,
        nameDatabank: resDataBank.nameDatabank,
        accountNumber: resDataBank.accountNumber,
        dni: resDataBank.dni,
        phoneNumber: resDataBank.phoneNumber,
        accountType: resDataBank.accountType,
        email: resDataBank.email,
      });
      setDataBankId(resDataBank.id);
    }
  };

  var isNumber = null;

  function valideKey(evt) {
    let code = evt.key;
    if (code === 'Backspace') {
      isNumber = true;
      return true;
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      isNumber = true;
      return true;
    } else {
      isNumber = false;
      return false;
    }
  }

  const handleChangeForm = (e) => {
    if (e.target.name === 'dni' || e.target.name === 'accountNumber') {
      if (e.target.value.trim().length <= 10) {
        if (isNumber) {
          setDataBank({ ...dataBank, [e.target.name]: e.target.value });
        }
      }
    } else if (e.target.name === 'nameDatabank') {
      setDataBank({
        ...dataBank,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    } else {
      setDataBank({ ...dataBank, [e.target.name]: e.target.value });
    }
  };

  const handleClickSave = async (e) => {
    if (dataBankId > 0) {
      const updatedBank = await helperDataBank.putDataBank(dataBankId, dataBank);
      if (updatedBank) {
        dispatch(
          fuseActions.showMessage({
            message: '¡Datos guardados con éxito!',
            variant: 'success',
          })
        );
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Ocurrió un error al guardar los datos - ¡Check it out!',
            variant: 'error',
          })
        );
      }
    } else {
      const newBank = await helperDataBank.postDataBank(dataBank.nameDatabank, dataBank.accountNumber, dataBank.ownerBank, user.id, dataBank.dni, dataBank.email, dataBank.accountType, user.phoneNumber);
      if (newBank) {
        dispatch(
          fuseActions.showMessage({
            message: '¡Datos guardados con éxito!',
            variant: 'success',
          })
        );
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Ocurrió un error al guardar los datos - ¡Check it out!',
            variant: 'error',
          })
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      {user.rol !== 'admin'&& <Typography variant="h5">DATOS BANCARIOS</Typography>}
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item md={6}>
          <FormControl fullWidth>
            {/* <InputLabel>Titular de la cuenta</InputLabel> */}
            <TextField
              type="text"
              placeholder="Titular de la cuenta"
              label="Titular de la cuenta"
              name="ownerBank"
              value={dataBank.ownerBank}
              sx={{ mb: 2 }}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel>E-mail</InputLabel> */}
            <TextField
              type="email"
              placeholder="E-mail"
              label="E-mail"
              name="email"
              sx={{ mb: 2 }}
              value={dataBank.email}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel>Cédula</InputLabel> */}
            <TextField
              type="text"
              placeholder="Cédula"
              label="Cédula"
              onKeyDown={(e) => valideKey(e)}
              sx={{ mb: 2 }}
              name="dni"
              value={dataBank.dni}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel>Nombre del Banco</InputLabel> */}
            <TextField
              type="text"
              placeholder="Nombre del Banco"
              label="Nombre del Banco"
              sx={{ mb: 2 }}
              name="nameDatabank"
              value={dataBank.nameDatabank}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel>Cuenta</InputLabel> */}
            <TextField
              type="text"
              placeholder="Cuenta"
              label="Cuenta"
              onKeyDown={(e) => valideKey(e)}
              sx={{ mb: 2 }}
              name="accountNumber"
              value={dataBank.accountNumber}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <InputLabel>Tipo de cuenta</InputLabel>
            <Select
              label="Tipo de cuenta"
              placeholder="Tipo de cuenta"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="accountType"
              value={dataBank.accountType || ''}
              onChange={(e) => handleChangeForm(e)}
              sx={{
                borderRadius: 6,
              }}
              // onChange={handleChange}
            >
              <MenuItem value={'Ahorros'} key={1}>
                Ahorros
              </MenuItem>
              <MenuItem value={'Corriente'} key={2}>
                Corriente
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Fab
        variant="extended"
        sx={{
          height: 70,
          width: 300,
          textTransform: 'capitalize',
          mt: 2,
          float: 'right',
        }}
        color="primary"
        onClick={handleClickSave}
      >
        <Typography sx={{ fontWeight: 900 }}>Guardar</Typography>
      </Fab>
    </div>
  );
};
