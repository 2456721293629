import { Box, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { UserState } from '../common/UserState';
import { HomeTool } from './HomeTool';
// import user1 from "../../assets/image/user1.png";
// import user2 from "../../assets/image/user2.png";
import * as homeActions from './store/actions';
import { EmptyState } from '../common/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import { helperReqClass } from '../../services/helperReqClass';
// import { homeData } from "./store/reducers/homedata.reducer";

export const HomePage = () => {
  const [filterClasses, setFilterClasses] = useState(true);
  const [filterHomeworksPending, setFilterHomeworksPending] = useState(true);

  const dispatch = useDispatch();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const teacherTraining = useSelector(({ homeReducer }) => homeReducer.homeDataTeacherTraining.requestTeacherTraining);
  const homeClasses = useSelector(({ homeReducer }) => homeReducer.homeData.requestClasses);

  const homeworksPending = useSelector(({ homeReducer }) => homeReducer.homeDataHomeworksPending.reqHomeworksPending);
  const classes = useStyle();
  useEffect(() => {
    dispatch(homeActions.getViewRequestClass(user.id));
    dispatch(homeActions.getTeacherTrainingByUserId(user.id));
  }, []);

  useEffect(() => {
    dispatch(homeActions.getHomeworksPendingByTeacherTrainingId(teacherTraining.id));
  }, [teacherTraining]);

  async function updateReqClass(reqClassId, data) {
    const response = await helperReqClass.putReqClassById(reqClassId, {
      stateRequestClass: data,
    });

    dispatch(homeActions.getViewRequestClass(user.id));
  }

  const allData = [...homeClasses.filter((el) => el.stateRequestClass === 'pending'), ...(homeworksPending || [])] || [];

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={8}>
          {allData.length === 0 && <EmptyState title="No hay solicitudes pendientes" />}
          {filterClasses &&
            homeClasses.length > 0 &&
            homeClasses.map((item, index) => {
              return (
                <div key={index}>
                  {item.stateRequestClass === 'pending' && (
                    <>
                      <UserState avatar={item.img} name={item.nameStudent} nameSubject={item.nameSubject} nameLevel={item.nameLevel} date={item.date} price={item.price} hoursNumber={item.hoursNumber} requestId={item.requestClassId} homeClasses={homeClasses} schedule={item.schedule} updateClass={updateReqClass} />
                      <Divider orientation="horizontal" flexItem sx={{ border: '1px solid #A5A5A5', mt: 4, mb: 4 }} />
                    </>
                  )}
                </div>
              );
            })}
          {filterHomeworksPending &&
            homeworksPending &&
            homeworksPending.map((item, index) => {
              return (
                <div onClick={() => console.log(item)}>
                  <Box key={index}>
                    <UserState avatar={item.imgStudent} name={item.student} nameSubject={item.nameSubject} nameLevel={item.nameLevel} date={item.date} purchase={true} detailHomework={item.detailHomework} classReq={item.class} fileUrl={item.fileUrl && item.fileUrl.trim().length > 0 ? item.fileUrl : null} requestHomeworkId={item.requestHomeworkId} timeHomework={item.timeHomework} />
                    <Divider orientation="horizontal" flexItem sx={{ border: '1px solid #A5A5A5', mt: 4, mb: 4 }} />
                  </Box>
                </div>
              );
            })}
        </Grid>
        <Grid item md={1} container direction="row" justifyContent="center">
          <Divider orientation="vertical" flexItem sx={{ border: '1px solid #A5A5A5', mb: -8 }} />
        </Grid>
        <Grid item md={3}>
          <HomeTool filterClasses={filterClasses} setFilterClasses={setFilterClasses} filterHomeworksPending={filterHomeworksPending} setFilterHomeworksPending={setFilterHomeworksPending} />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    margin: '20px 40px',
  },
});
