import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { ProfessorInfo } from "./ProfessorInfo";
import { ProfessorDataBank } from "./ProfessorDataBank";
import { ProfessorAcademy } from "./ProfessorAcademy";
import ProfessorTool from "./ProfessorTool";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 40px",
  },
}));
export const ProfessorProfilePage = () => {
  const classes = useStyles();

  const [activeSTATE, setActiveSTATE] = useState(0);

  let user = useSelector(({ loginReducer }) => loginReducer.login.user);

  return (
    <div className={classes.root}>
      <Grid container sx={{}}>
        <Grid
          item
          md={user.rol === "teacher" || user.rol === "student" ? 8 : 12}
          sx={{ margin: "auto" }}
        >
          {user.rol === "teacher" && activeSTATE === 0 ? (
            <ProfessorInfo />
          ) : activeSTATE === 1 ? (
            <ProfessorDataBank />
          ) : activeSTATE === 2 ? (
            <ProfessorAcademy />
          ) : (
            <ProfessorInfo />
          )}
        </Grid>
        {user.rol === "teacher" && (
          <>
            <Grid item md={1} container>
              <Divider orientation="vertical" sx={{ mb: -20 }} flexItem />
            </Grid>
            <Grid item md={3}>
              <ProfessorTool setActiveSTATE={setActiveSTATE} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
