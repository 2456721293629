import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as fuseActions from "../../../../../../store/actions/fuse";
import { helperSchedules } from "../../../../../../services/helperSchedules";
import {
  Box,
  createTheme,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider, StaticTimePicker, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import * as homeActions from "../../../../../HomePage/store/actions";
import moment from "moment";
import { useDispatch } from "react-redux";

const themeTimeFieldTxt = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          border: "none",
          outline: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: "none",
          outline: "none",
          width: "30%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            // +          // the result will contain the prefix.
            // +          [`& .${outlinedInputClasses.notchedOutline}`]: {
            //              borderWidth: 1,
          },
        },
      },
    },
  },
});

const initialData = {
  scheduleFrequency: "every-week",
  startTime: moment()._d,
  endTime: moment()._d,
  day: moment(moment()._d).format("dddd"),
  status: true,
};

export default function FormDialogNewSchedule({
  isOpen,
  setIsOpen,
  user,
  selectedDate,
}) {
  const [open, setOpen] = useState(false);
  const [timeStart, setTimeStart] = useState(selectedDate);
  const [timeEnd, setTimeEnd] = useState(selectedDate);
  const [selected, setSelected] = useState({
    ...initialData,
    startTime: selectedDate,
    endTime: selectedDate,
    day: moment(selectedDate).format("dddd"),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setTimeStart(selectedDate);
      setTimeEnd(selectedDate);
      setOpen(true);
      setSelected({
        userId: user.id,
        ...selected,
        startTime: selectedDate,
        endTime: selectedDate,
        day: moment(selectedDate).format("dddd"),
      });
    } else {
      setOpen(false);
    }
  }, [isOpen, selectedDate]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const handleChangeTimeStart = (e) => {
    console.log("start", e);
    setTimeStart(e);
    setSelected({ ...selected, startTime: e });
  };

  const handleChangeTimeEnd = (e) => {
    console.log("end", e);
    setTimeEnd(e);
    setSelected({ ...selected, endTime: e });
  };

  const handleChangeFrecuency = (e) => {
    setSelected({ ...selected, [e.target.name]: e.target.value });
  };

  const handleClickSave = async () => {
    if (timeStart < timeEnd) {
      const array = [selected];
      // console.log(array);
      await helperSchedules.postSchedule({ array });
      dispatch(homeActions.getSchedulesByUserId(user.id));
    } else {
      dispatch(
        fuseActions.showMessage({
          message: "La fecha final no puede ser menor que la de inicio.",
          variant: "error",
        })
      );
    }
    handleClose();
  };

  const getDayName = () => {
    const day = moment(moment().d).format("dddd");
    switch (day) {
      case "lunes":
        return "Lunes";
      case "martes":
        return "Martes";
      case "miercoles" || "miércoles":
        return "Miércoles";
      case "jueves":
        return "Jueves";
      case "viernes":
        return "Viernes";
      case "sábado" || "sabado":
        return "Sábado";
      case "domingo":
        return "Domingo";
      default:
        return "Default";
    }
  };

  const handleAux = (e) => {
    console.log(e);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nuevo horario</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                ampm={true}
                clearable={true}
                value={timeStart}
                onChange={(e) => handleChangeTimeStart(e)}
                renderInput={(params) => (
                  <ThemeProvider theme={themeTimeFieldTxt}>
                    <TextField {...params} sx={{ maxWidth: "max-content" }} />
                  </ThemeProvider>
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                ampm={true}
                clearable={true}
                value={timeEnd}
                onChange={(e) => handleChangeTimeEnd(e)}
                renderInput={(params) => (
                  <ThemeProvider theme={themeTimeFieldTxt}>
                    <TextField {...params} />
                  </ThemeProvider>
                )}
              />
            </LocalizationProvider>
          </Box>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected.scheduleFrequency}
              name="scheduleFrequency"
              sx={{
                borderRadius: 6,
                background: "#F4F7F5",
                border: "none",
                outline: "none",
                height: 50,
                fontWeight: "inherit",
              }}
              onChange={handleChangeFrecuency}
            >
              <MenuItem value={"every-week"} sx={{ fontWeight: "inherit" }}>
                Repetir cada {getDayName()} indefinidamente
              </MenuItem>
              <MenuItem value={"one-week"} sx={{ fontWeight: "inherit" }}>
                Repetir cada {getDayName()} de este mes
              </MenuItem>
              <MenuItem value={"only-day"} sx={{ fontWeight: "inherit" }}>
                Repetir solo el día {getDayName()}
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" size="large">
            Cancelar
          </Button>
          <Button
            onClick={handleClickSave}
            size="large"
            color="info"
            variant="contained"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
