import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const useTotalService = () => {
  const getTotalServiceClassByUser = async (id) => {
    const resp = await axios.get(
      `${apiUrl}view/getTotalMonthlyClassByUserId/${id}/`
    );
    return resp.data.TotalClass;
  };

  const getTotalServiceHomeworkByUser = async (id) => {
    const resp = await axios.get(
      `${apiUrl}view/getTotalMonthlyHomeroksByUserId/${id}`
    );
    return resp.data.TotalHomework;
  };

  return {
    getTotalServiceClassByUser,
    getTotalServiceHomeworkByUser,
  };
};
