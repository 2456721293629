import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const getLevels = () => {
  return async (dispatch) => {
    const { ok, allLevels } = await getAlllevels();
    if (ok) {
      dispatch(setLevels(false, allLevels));
    } else {
      dispatch(setLevels(false, allLevels));
    }
  };
};

const getAlllevels = async () => {
  const request = await axios.get(`${apiUrl}levels`);
  const schools = await getCoursesGroupBackend();
  if (request.status === 200) {
    if (request.data.msg) {
      let allLevels = request.data.LevelsDB.map((level) => {
        let schoolsFiltered = schools.filter((school) => school.levelId === level.id);

        return {
          ...level,
          schools: schoolsFiltered,
        };
      });
      // allLevels = await Promise.all(allLevels);
      return { ok: true, allLevels };
    }
  } else {
    return { ok: false, allLevels: [] };
  }
};

const getSchools = async () => {
  const resp = await axios.get(`${apiUrl}schools`);
  return resp.data.school;
};

const getSchoolsByLevel = async (id) => {
  const AllSchools = await getSchools();
  const schools = AllSchools.filter((school) => school.idLevel === id && school.status === 1);
  return schools;
};

const getCoursesGroupBackend = async () => {
  const resp = await axios.get(`${apiUrl}view/viewUserCoursesGroupBySchool`);
  return resp.data.resultUserCourse;
};

const setLevels = (isLoading, levels) => {
  return {
    type: types.getAllLevels,
    payload: { isLoading, levels },
  };
};
