import React from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
      border: '1px solid black',
    },
  },
}));
export const CustomTextField = React.forwardRef((props, ref) => {
  const { label, value, name, onChange, autoFocus = false, fullWidth, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl fullWidth={fullWidth} variant="outlined">
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          {...rest}
          ref={ref}
          type="number"
          placeholder={label}
          label={label}
          autoFocus={autoFocus}
          name={name}
          // error={error.phoneNumber}
          value={value === '' || value < 0 ? 0 : parseInt(value)}
          onChange={onChange}
          sx={{ mb: 2, width: 180 }}
          className={classes.textField}
        />
      </FormControl>
    </div>
  );
});
