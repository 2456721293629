import axios from 'axios';
import { useDispatch } from 'react-redux';
import * as fuseActions from '../store/actions/fuse';

import { apiUrl } from '../config/apiConfig';
import { useChat } from './useChat';
import { useHistory } from 'react-router-dom';

export const useUser = () => {
  const dispatch = useDispatch();
  const { uploadListChats } = useChat();
  const history = useHistory();

  const getAllUser = async () => {
    const resp = await axios.get(`${apiUrl}users`);
    return resp.data;
  };

  const updateUserById = async (id, user) => {
    const resp = await axios.put(`${apiUrl}users/${id}`, { status: user });
    return resp.data;
  };

  const getUserById = async (id) => {
    const resp = await axios.get(`${apiUrl}users/${id}`);
    return resp.data.usuario;
  };

  const getReviewByUserId = async (id) => {
    const res = await axios.get(`${apiUrl}reviews/`);
    const allReviews = res.data.reviews.filter((el) => el.rolSender === 'teacher');
    const reviewsFiltered = allReviews.filter((el) => el.studentId === id);

    let reviews = reviewsFiltered.map(async (review) => {
      const teacherinfo = await getUserById(review.teacherId);
      return {
        ...review,
        teacherinfo,
      };
    });
    reviews = await Promise.all(reviews);
    return reviews;
  };

  const deleteReviewById = async (idReview) => {
    const resp = await axios.delete(`${apiUrl}reviews/${idReview}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: 'Review ocultado',
            variant: 'success',
          })
        );
      }
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const signUp = async (fullName, email, rol, img, password, gender, dni, cityId, phoneNumber) => {
    console.log('request==>', { fullName, email, rol, img, password, gender, dni, cityId, phoneNumber });
    try {
      const resp = await axios.post(`${apiUrl}users`, { fullName, email, rol, img, password, gender, dni, cityId, phoneNumber });
      console.log('signUp==>', resp);
      const allUsers = await getAllUser();
      const newAllUsers = allUsers.filter((res) => res.rol === 'admin');
      const admin = newAllUsers[0];
      if (admin.id) {
        const channelArray = admin.id < resp.data.usuario.id ? [{ channel: `${admin.id},${resp.data.usuario.id}` }] : [{ channel: `${resp.data.usuario.id},${admin.id}` }];

        await uploadListChats(channelArray);
      }

      dispatch(
        fuseActions.showMessage({
          message: 'Usuario Registrado, Revise su correo',
          variant: 'success',
        })
      );
      return resp.data.usuario;
    } catch (error) {
      console.log(error);
      dispatch(
        fuseActions.showMessage({
          message: 'Revise la informacion',
          variant: 'error',
        })
      );
    }
  };

  const postUserTraining = async (userId, specialty, professionalTittle, professionalAppointment, professionalDescription, otherCertifications, maxHour, language) => {
    console.log(userId, specialty, professionalTittle, professionalAppointment, professionalDescription, otherCertifications, maxHour, language);
    const resp = await axios.post(`${apiUrl}teacherTrainings`, {
      userId,
      specialty,
      professionalTittle,
      professionalAppointment,
      professionalDescription,
      otherCertifications,
      maxHour,
      language,
    });
    console.log(resp);
    if (resp.status === 200) {
      if (resp.data.msg) {
        dispatch(
          fuseActions.showMessage({
            message: resp.data.msg,
            variant: 'success',
          })
        );
        return resp.data.msg;
      }
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  return {
    updateUserById,
    getAllUser,
    getUserById,
    getReviewByUserId,
    deleteReviewById,
    postUserTraining,
    signUp,
  };
};
