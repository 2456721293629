import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { StudentSubjectPage } from '../../components/StudentPage/StudentSubjectPage';
import { BotBackground } from '../../components/common/BotBackground';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const StudentSubjectScreen = () => {
  const classes = useStyles();

  const { nameSchool, subjects } = useSelector((state) => state.studentReducers.subjectReducer);

  return (
    <div className={classes.root}>
      <LoginedTopBackground search title={nameSchool} />
      <StudentSubjectPage subjects={subjects} />
      <BotBackground />
    </div>
  );
};
