import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import * as fuseActions from "../../../../store/actions/fuse";

import { helperLevels } from "../../../../services/helperLevels";

const initialData = {
  nameLevel: "",
  percentTutorial: "0",
  costTutorial: "0",
  percentHomework: "0",
  costHomework: "0",
};

export default function FormDialog({
  isOpen,
  newLevel,
  selectedLevel,
  setOpenModal,
  allLevels,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(initialData);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      setOpen(isOpen);
      if (newLevel) {
        setSelected(initialData);
      } else {
        setSelected(selectedLevel);
      }
    } else {
      setOpen(false);
    }
  }, [isOpen, newLevel, selectedLevel]);

  const handleChange = (e) => {
    setSelected({ ...selected, [e.target.name]: e.target.value });
    if (selected.nameLevel.trim().length > 0) {
    } else {
    }
  };

  const handleClickSave = async () => {
    if (selected.nameLevel.trim().length > 0) {
      const newLevel = await helperLevels.postLevel(selected);
      console.log("new level -- > ", newLevel);
      allLevels.push(newLevel);
    } else {
      dispatch(
        fuseActions.showMessage({
          message: "No puede guardar campos vacios",
          variant: "error",
        })
      );
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Nuevo nivel</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Ingrese el nivel..."
          type="text"
          fullWidth
          name="nameLevel"
          variant="standard"
          defaultValue={selected.nameLevel}
          onChange={(e) => handleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={() => handleClickSave()} variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
