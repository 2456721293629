import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { StdMainRequestItem } from './StdMainRequestItem';
import introIcon from '../../../../assets/icon/introIcon.svg';
import filterIcon from '../../../../assets/icon/filterIcon.svg';
import { useClass } from '../../../../hooks/useClass';
import { useUpLoadHomework } from '../../../../hooks/useUpLoadHomework';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: { marginTop: '50px' },
}));
export const StdMainRequestList = () => {
  const classes = useStyles();
  const { getClassForReview } = useClass();
  const { getWorkStudentBackEnd, isLoading } = useUpLoadHomework();
  const [requestClass, setRequestClass] = useState([]);
  const [requestHomeworks, setRequestHomeworks] = useState([]);
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  useEffect(() => {
    getRequestClasses();

    getRequestHomeworks();
  }, []);

  const getRequestHomeworks = async () => {
    if (user) {
      const resp = await getWorkStudentBackEnd(user.id);
      filterHomerworkPending(resp);
    }
  };
  const getRequestClasses = async () => {
    const resp = await getClassForReview();
    if (user) {
      const filterClass = resp.filter((item) => item.studentId === user.id && item.stateRequestClass === 'pending');
      setRequestClass(filterClass);
    }
  };

  const filterHomerworkPending = (homeworks) => {
    const filterHomeworks = homeworks.filter((item) => item.state === 'pending');
    setRequestHomeworks(filterHomeworks);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" color="initial" sx={{ mb: '2rem' }}>
        Solicitudes Enviadas
      </Typography>
      {requestClass.length > 0 && requestClass.map((item, idx) => <StdMainRequestItem key={idx} obj={item} name={item.nameLevel} subject={item.nameSubject} status={item.stateRequestClass} icon={introIcon} img={item.img} typeRequest="class" date={item.date} />)}
      {requestHomeworks.length > 0 && requestHomeworks.map((item, idx) => <StdMainRequestItem key={idx} obj={item} name={item.nameLevel} subject={item.nameSubject} status={item.state} icon={filterIcon} img={item.imgStudent} typeRequest="homework" date={item.date} />)}
    </div>
  );
};
