import React from 'react';
import { makeStyles } from '@mui/styles';
import { AdStudentItem } from './AdStudentItem';
import user1 from '../../../../assets/image/user1.png';
import { Divider } from '@mui/material';
import { EmptyState } from '../../../common/EmptyState';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdStudentList = ({ students, renderAgain }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {students.length > 0 ? (
        students.map((item, idx) => (
          <div key={idx} style={{ marginTop: 20 }}>
            <AdStudentItem student={item} renderAgain={renderAgain} />
          </div>
        ))
      ) : (
        <EmptyState title="No existe estudiantes" />
      )}
    </div>
  );
};
