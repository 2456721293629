import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Fab, Grid, Rating, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '50px' },
}));

export const AdReviewerItem = (props) => {
  const classes = useStyles();
  const { name, review, rating, userAvatar, func } = props;

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item md={4}>
          <Avatar src={userAvatar} sx={{ width: 144, height: 144 }} />
        </Grid>
        <Grid item md={8}>
          <Typography>{name}</Typography>
          <br />
          <Rating value={rating} />
          <br />
          <Typography>{review}</Typography>
        </Grid>
        <Grid item md={4}>
          <Fab variant="extended" color="primary" sx={{ width: 160, height: 60 }} onClick={func}>
            <Typography sx={{ textTransform: 'capitalize' }}>Ocultar</Typography>
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
};
