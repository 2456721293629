import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { BotBackground } from '../../components/common/BotBackground';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { StudentSchedulePage } from '../../components/StudentPage/StudentSchedulePage/StudentSchedulePage';

export const StudentScheduleScreen = () => {
  const classes = useStyles();
  const { levels } = useSelector((state) => state.studentReducers.levelsReducer);
  const { idLevel } = useSelector(({ studentReducers }) => studentReducers.studentReducer);

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Ayúdame con mi tarea" showSort={true} dataSort={levels} stateSort={idLevel} disabledSort />
      <StudentSchedulePage />
      <BotBackground />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
