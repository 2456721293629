import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { StdSubjectItem } from './StdSubjectItem';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export const StudentSubjectPage = ({ subjects = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-around" alignItems="center" spacing={4}>
        {subjects.length > 0 &&
          subjects.map((item, idx) => (
            <Grid key={idx} item md={3}>
              <StdSubjectItem title={item.nameSubject} idSubject={item.subjectId} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
