import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#272727",
      green1: "#65A2B6",
    },
    secondary: {
      main: "#F4F7F5",
      green2: "#D8E7E4",
    },
    success: {
      main: "#95F8D4",
      gray: "#A4CCDF",
    },
    warning: {
      main: "#D5B8FD",
      secondary: "#FEB098",
    },
    error: {
      main: "#FFEC88",
      secondary: "#A5A5A5",
    },
  },
  typography: {
    fontFamily: "Nunito",
    body1: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "27px",
      letterSpacing: "0em",
    },
    body2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24.55px",
    },
    h6: {
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 800,
      lineHeight: "20px",
    },
    h5: {
      fontSize: "22px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "40.92px",
    },
    caption: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "32px",
    },
    h2: {
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700,
    },
    h4: {
      fontSize: 35,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "47.74px",
    },
  },
});
