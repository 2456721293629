import React from 'react';
import { makeStyles } from '@mui/styles';
import { ProfessorItem } from '../../../AdminPage/AdminProfessorPage/ProfessorList/ProfessorItem';
import { Grid } from '@mui/material';
import { UrgentMark } from './UrgentMark';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px' },
}));
export const StdUrgentTeacearsList = () => {
  const classes = useStyles();

  const { teachers } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <UrgentMark />
      {teachers.length && teachers.map((item, idx) => <ProfessorItem key={idx} review={item.starReview} userId={item.userId} name={item.fullName} img={item.img} subjects={item.levels} price={item.price} levelId={item.levelId} />)}
    </div>
  );
};
