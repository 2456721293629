import { Fab, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles({
  loginButton: {
    marginTop: "10%",
  },
});

export const RegisterButton = () => {
  const classes = useStyle();
  const history = useHistory();
  return (
    <div className={classes.loginButton}>
      <Fab
        variant="extended"
        color="primary"
        sx={{
          width: "464px",
          height: "80px",
          borderRadius: "30px",
          textTransform: "capitalize",
        }}
        onClick={() => history.push("/register")}
      >
        <Typography variant="body1">Registrarse</Typography>
      </Fab>
    </div>
  );
};
