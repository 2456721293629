import React, { useState, useEffect } from "react";
import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { LevelPage } from "../../components/LevelPage/Index";
import { useLevels } from "../../hooks/useLevels";

export const LevelScreen = () => {
  const { AllLevels } = useLevels();

  const [defaultLevel, setDefaultLevel] = useState("Educación Básica");
  const [level, setLevel] = useState({});
  // const [AllLevels, setAllLevels] = useState(AllLevelsList);

  useEffect(() => {
    const getLevel = AllLevels.filter((el) => el.nameLevel === defaultLevel);
    setLevel(getLevel);
  }, [defaultLevel, AllLevels]);

  return (
    <div>
      <LoginedTopBackground title="Editar Niveles" />
      <LevelPage
        allLevels={AllLevels}
        level={level}
        setDefaultLevel={setDefaultLevel}
        defaultLevel={defaultLevel}
        setLevel={setLevel}
        teacherActive={true}
      />
      <BotBackground logined />
    </div>
  );
};
