import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const useTeachers = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    loadData();
    return () => loadData();
  }, [apiUrl]);

  const loadData = async () => {
    const teacherInformations = await getTeacherInformations();
    let allTeachers = teacherInformations.map(async (teacherInformation) => {
      const teacherAverageReview = await getTeacherAverageReview(teacherInformation.userId);
      const levelsByUser = await getLevelsByUserId(teacherInformation.userId);
      // const { price } = await getPriceByUserIdAndLevelId(teacherInformation.userId, teacherInformation.levelId);
      return {
        ...teacherInformation,
        starReview: Number(teacherAverageReview[0].star) || 0,
        levels: levelsByUser,
      };
    });
    allTeachers = await Promise.all(allTeachers);
    // console.log(allTeachers);
    setTeachers(allTeachers);
  };

  const getPriceByUserIdAndLevelId = async (userId, levelId) => {
    const resp = await axios.get(`${apiUrl}userprices/${userId}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        return resp.data.userUserPrices[0];
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  const getTeacherInformations = async () => {
    const resp = await axios.get(`${apiUrl}view/viewTeachersInformation`);
    return resp.data.resultTeacher;
  };

  const getTeacherAverageReview = async (userId) => {
    const resp = await axios.get(`${apiUrl}view/reviewstar/${userId}`);
    return resp.data.star;
  };

  const putTeacher = async (id) => {
    const resp = await axios.put(`${apiUrl}api/users/${id}`);
    console.log(resp);
  };

  const getLevelsByUserId = async (userId) => {
    const resp = await axios.get(`${apiUrl}view/viewLevelsByUserId/ByIdUser/${userId}`);
    return resp.data.levelByUserId;
  };

  return {
    teachers,
  };
};
