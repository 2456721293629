export const types = {
  getSchools: '[Student] getSchools',
  getAllLevels: '[Student] getAllLevels',
  getSubjectsBySchool: '[Student] getSubjectsBySchool',
  changeNameLevel: '[Student] changeNameLevel',
  setAllteachersReducer: '[Student] setAllteachersReducer',
  setNameAndIdSubject: '[Student] setNameAndIdSubject',
  setTeachers: '[Student] setTeachers',
  setTeacherSelected: '[Student] setTeacherSelected',
  setIdRequestClass: '[Student] setIdRequestClass',
  setPriceRequestClass: '[Student] setPriceRequestClass',
  setDateRequestClass: '[Student] setDateRequestClass',
  setScheduleRequestClass: '[Student] setScheduleRequestClass',
  setStateRequestClassRequestClass: '[Student] setStateRequestClassRequestClass',
  setStudentIdRequestClass: '[Student] setStudentIdRequestClass',
  setUserCourseIdRequestClass: '[Student] setUserCourseIdRequestClass',
  setHoursNumberIdRequestClass: '[Student] setHoursNumberIdRequestClass',
  calculatePrice: '[Student] calculatePrice',
  selectUserChat: '[Student] selectUserChat',
  showPaymentScreen: '[Student] showPaymentScreen',
  setIvoiceInfo: '[Student] setIvoiceInfo',
  setRequestClass: '[Student] setRequestClass',
  setEmergency: '[Student] setEmergency',
  setAllRequestClass: '[Student] setAllRequestClass',
  setRequestType: '[Student] setRequestType',
  setLevelId: '[Student] setLevelId',
  setResumen: '[Student] setResumen',
  setResumenPrice: '[Student] setResumenPrice',
  setInitStateResume: '[Student] setInitStateResume',

  setAdminTeachers: '[Admin] setAdminTeachers',
  setAdminTeacherSelected: '[Admin] setAdminTeacherSelected',
};
