import { types } from '../../../types/types';

const initialState = {
  idRequestClass: 0,
  date: '',
  hoursNumber: 1,
  priceTotal: 0,
  schedule: '',
  stateRequestClass: 'pending',
  studentId: 0,
  userCourseId: 0,
  requestType: 'class',
  levelId:0,
  offertId:0,
};

export const requestClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setAllRequestClass:
      return {
        ...state,
        idRequestClass: action.payload.requestClasses.idRequestClass,
        date: action.payload.requestClasses.date,
        hoursNumber: action.payload.requestClasses.hoursNumber,
        priceTotal: action.payload.requestClasses.priceTotal,
        schedule: action.payload.requestClasses.schedule,
        stateRequestClass: action.payload.requestClasses.stateRequestClass,
        studentId: action.payload.requestClasses.studentId,
        userCourseId: action.payload.requestClasses.userCourseId,
      };
    case types.setPriceRequestClass:
      return {
        ...state,
        priceTotal: action.payload.priceTotal,
      };
    case types.setIdRequestClass:
      return {
        ...state,
        idRequestClass: action.payload.idRequestClass,
      };
    case types.setDateRequestClass:
      return {
        ...state,
        date: action.payload.date,
        schedule: action.payload.schedule,
      };
    case types.setScheduleRequestClass:
      return {
        ...state,
        schedule: action.payload.schedule,
      };
    case types.setStateRequestClassRequestClass:
      return {
        ...state,
        stateRequestClass: action.payload.stateRequestClass,
      };
    case types.setStudentIdRequestClass:
      return {
        ...state,
        studentId: action.payload.studentId,
      };
    case types.setUserCourseIdRequestClass:
      return {
        ...state,
        userCourseId: action.payload.userCourseId,
      };
    case types.setHoursNumberIdRequestClass:
      return {
        ...state,
        hoursNumber: action.payload.hoursNumber,
      };
    case types.calculatePrice:
      return {
        ...state,
        priceTotal: action.payload.priceTotal,
        hoursNumber: action.payload.hoursNumber,
      };
    case types.setRequestType:
      return {
        ...state,
        requestType: action.payload.requestType,
      };
    case types.setLevelId:
      return {
        ...state,
        levelId: action.payload.levelId,
        offertId: action.payload.offertId,
      };
    case types.setInitStateResume:
      return {
        ...state,
        idRequestClass: 0,
        date: '',
        hoursNumber: 1,
        priceTotal: 0,
        schedule: '',
        stateRequestClass: 'pending',
        studentId: 0,
        userCourseId: 0,
        requestType: 'class',
        levelId:0,
        offertId:0,
      };

    default:
      return state;
  }
};
