import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { AdminProfessorProfitsPage } from '../../components/AdminPage/AdminProfessorProfitsPage';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdminProfessorProfits = () => {
  const classes = useStyles();
  const { name } = useParams();

  return (
    <div className={classes.root}>
      <LoginedTopBackground search title="Ganancias" />
      <AdminProfessorProfitsPage userId={name} />
      <BotBackground logined />
    </div>
  );
};
