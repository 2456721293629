import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import user1 from '../../../../../assets/image/user1.png';
import { Avatar, Badge, Chip, Grid, Rating, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.warning.main,
    borderRadius: '30px',
    width: '100%',
    padding: '30px 0px',
    position: 'relative',
  },
  price: {
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
}));
export const AdminProfessorInfoCard = ({ admin, name, price = 0, img, status, review }) => {
  const classes = useStyles();

  useEffect(() => {}, [status]);

  return (
    <div className={classes.root}>
      {price !== null && !admin && <Chip className={classes.price} sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)' }} label={<Typography sx={{ color: '#A5A5A5' }}>${price}/h</Typography>} />}
      <Grid container justifyContent="center" direction="column" alignItems="center">
        <Badge
          overlap="circular"
          badgeContent=" "
          // color={status === 'accepted' || status === 1 ? 'success' : status === 'rejected' || status === 0 ? 'secondary' : 'warning'}
        >
          <Avatar src={img} sx={{ width: 132, height: 132 }} />
        </Badge>
        <Typography variant="h5" sx={{ my: 1 }}>
          {name}
        </Typography>
        <Rating value={review || 0} readOnly size="large" />
      </Grid>
    </div>
  );
};
