import { useState } from 'react';

const useForm = (initState) => {
  const [state, setState] = useState(initState);

  const onChange = (value, field) => {
    setState({
      ...state,
      [field]: value,
    });
  };
  const setFormValue = (form) => {
    setState({
      ...state,
      ...form,
    });
  };

  const setFormValueAll = (form) => {
    setState(form);
  };
  return {
    ...state,
    form: state,
    onChange,
    setFormValue,
    setFormValueAll,
  };
};

export default useForm;
