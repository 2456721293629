import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { selectLevel } from '../../store/actions/students/studentActions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  sortSelect: {
    '.MuiSelect-Outlined-root': {
      borderRadius: '10px',
    },
  },
}));

export const Sort = ({ disabledSort = false, sort = [], show = false, stateSort = 0 }) => {
  const { idLevel } = useSelector(({ studentReducers }) => studentReducers.studentReducer);
  const [age, setAge] = useState(idLevel);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setAge(event.target.value);
    getLevel(event.target.value);
  };

  const getLevel = (level) => {
    if (sort.length > 0) {
      const selectedLevel = sort.find((item) => item.id == level);
      dispatch(selectLevel(selectedLevel.id, selectedLevel.nameLevel, selectedLevel.schools));
    }
  };

  useEffect(() => {
    setAge(idLevel);
  }, [idLevel]);

  const classes = useStyles();
  return show ? (
    <div>
      <FormControl>
        <Select
          disabled={disabledSort}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          sx={{
            borderRadius: 6,
            background: '#F4F7F5',
            border: 'none',
            height: 50,
            width: '300px',
          }}
          onChange={handleChange}
        >
          {sort.length > 0 ? (
            sort.map((item, idx) => (
              <MenuItem key={idx} value={item.id} sx={{ textAlign: 'center' }}>
                {item.nameLevel}
              </MenuItem>
            ))
          ) : (
            <div>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </div>
          )}
        </Select>
      </FormControl>
    </div>
  ) : (
    <></>
  );
};
