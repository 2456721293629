import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { UrgentHelpCard } from './UrgentHelpCard';
import { TeachersList } from './TeachersList';
import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { useSubjects } from '../../../hooks/useSubjects';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px' },
}));

export const StudentSubjectItemPage = ({ idSubject }) => {
  const classes = useStyles();
  const [teachers, setTeachers] = useState([]);

  const { isLoading, getTeacherByIdSubject } = useSubjects();

  useEffect(() => {
    getTeachers();
  }, []);

  const getTeachers = async () => {
    const resp = await getTeacherByIdSubject(idSubject);
    setTeachers(resp);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={6}>
        <Grid item md={8}>
          <TeachersList teachers={teachers} isLoading={isLoading} />
        </Grid>
        <Grid item md={4} container justifyContent="center">
          <UrgentHelpCard />
        </Grid>
      </Grid>
    </div>
  );
};
