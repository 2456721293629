import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { ProfitsInfoItem } from './ProfitsInfoItem';

import { apiUrl } from '../../../../config/apiConfig';
import { EmptyState } from '../../../common/EmptyState';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 100px' },
}));

export const ProfitsInfoList = ({ userId }) => {
  const classes = useStyles();
  const [dataBank, setDataBank] = useState({});

  const getDataBankByUseId = async () => {
    const resp = await axios.get(`${apiUrl}databanks/byiduser/${userId}`);
    if (resp.status === 200) {
      if (resp.data.msg) setDataBank(resp.data.DataBanks);
    }
  };

  useEffect(() => {
    getDataBankByUseId();
  }, []);

  return (
    <div className={classes.root}>
      {dataBank ? (
        <>
          <ProfitsInfoItem bankname={dataBank.nameDatabank} address={dataBank.accountType} cardNumber={dataBank.accountNumber} />
          <ProfitsInfoItem bankname={dataBank.ownerBank} address={dataBank.email} cardNumber={dataBank.dni} />
        </>
      ) : (
        <EmptyState title="No existe datos de cuentas bancarias" />
      )}
    </div>
  );
};
