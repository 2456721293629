import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperUser = {};

helperUser.getAllUser = async () => {
  const resp = await axios.get(`${apiUrl}users`);
  return resp.data;
};

helperUser.updateUserById = async (id, data) => {
  const resp = await axios.put(`${apiUrl}users/${id}`, data);
  return resp.data;
};

helperUser.getUserById = async (id) => {
  const resp = await axios.get(`${apiUrl}users/${id}`);
  console.log(resp.data.usuario);
  return resp.data.usuario;
};

helperUser.getReviewByUserId = async (id) => {
  const res = await axios.get(`${apiUrl}reviews/`);
  const allReviews = res.data.reviews.filter(
    (el) => el.rolSender === "teacher"
  );
  return allReviews.filter((el) => el.studentId === id);
};
