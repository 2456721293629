import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helper = {};

helper.getReviewsST = async () => {
  const res = await axios.get(`${apiUrl}reviews/`);
  const rows = res.data.reviews;
  let data = rows.filter((el) => el.rolSender === "student");
  console.log("data ---> ", data);
  const reviews = await getStudents(data);
  //
  data = await Promise.all(reviews);
  // console.log("rev -->", reviews);
  return data;
};

const getStudents = async (data) => {
  return await data.map(async (el) => {
    const resp = await axios.get(`${apiUrl}users/${el.studentId}`);
    // console.log("-->", resp.data);
    el.studentId = resp.data.usuario;
    return el;
  });
  // console.log("rows --> ", rows);
};
