import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import { AdStudentInfo } from './AdStudentInfo';
import { AdStudentReviewList } from './AdStudentReviewList';
import { useUser } from '../../../hooks/useUser';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminStudentReviewPage = ({ userId }) => {
  const classes = useStyles();

  const [reviewsStudet, setreviewsStudet] = useState([]);

  const [infoUser, setInfoUser] = useState({});
  const { getUserById, getReviewByUserId } = useUser();

  const getInfoStudent = async () => {
    if (userId) {
      const resp = await getUserById(userId);

      setInfoUser(resp);

      const reviews = await getReviewByUserId(parseInt(userId));

      setreviewsStudet(reviews);
    }
  };

  useEffect(() => {
    getInfoStudent();
  }, []);

  const { deleteReviewById } = useUser();

  const deleteReview = async (idReview) => {
    await deleteReviewById(idReview);
    getInfoStudent();
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item md={4}>
          <AdStudentInfo infoUser={infoUser} />
          <Typography
            sx={{
              fontFamily: 'Poppins',

              my: 4,
              fontWeight: 500,
              fontSize: '20px',
              textAlign: 'center',
            }}
          >
            {reviewsStudet.length} Solicitudes Realizadas
          </Typography>
        </Grid>
        <Grid item md="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={7}>
          <AdStudentReviewList reviewsStudet={reviewsStudet} func={deleteReview} />
        </Grid>
      </Grid>
    </div>
  );
};
