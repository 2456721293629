import React, { useEffect, useState } from 'react';
import { Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import upload from '../../../assets/icon/upload.svg';
import deleteIcon from '../../../assets/icon/delete.svg';
import { helperTeacherTraining } from '../../../services/helperTeacherTraining';
import { uploadImg } from '../../../services/partials/uploadImg';
import * as fuseActions from '../../../store/actions/fuse';
import { useSelector, useDispatch } from 'react-redux';
import FormDialog from './Modals';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 100px',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
      border: '1px solid black',
    },
  },
}));

const initialData = {
  specialty: '',
  professionalTittle: '',
  professionalAppointment: '',
  language: '',
  maxHour: 5,
  professionalDescription: '',
  otherCertifications: '',
};

export const ProfessorAcademy = () => {
  const dispatch = useDispatch();

  const [teacherTraining, setTeacherTraining] = useState(initialData);
  const [teacherTrainingId, setTeacherTrainingId] = useState(0);
  const [certificates, setCertificates] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  useEffect(() => {
    const getTeacherTraining = async () => {
      const resTeacherTraining = await helperTeacherTraining.getTeacherTrainingByIdUser(user.id);
      setTeacherTraining({
        specialty: resTeacherTraining.specialty,
        professionalTittle: resTeacherTraining.professionalTittle,
        professionalAppointment: resTeacherTraining.professionalAppointment,
        language: resTeacherTraining.language,
        maxHour: resTeacherTraining.maxHour,
        professionalDescription: resTeacherTraining.professionalDescription,
        // otherCertifications: resTeacherTraining.otherCertifications,
      });
      setTeacherTrainingId(resTeacherTraining.id);
      const otherCertificates = resTeacherTraining.otherCertifications;
      // console.log('other certificates', otherCertificates);
      const items = otherCertificates.split(',');
      // console.log('items =>', items);
      const rows = items
        .filter((el) => el.trim().length > 0)
        .map((el, index) => {
          return { name: el.trim(), id: index };
        });
      // console.log("rows =>", rows);
      setCertificates(rows);
    };

    getTeacherTraining();
  }, [user]);

  useEffect(() => {
    let allCertificates = '';
    // console.log(certificates);
    certificates.map((el) => {
      // console.log(el);
      // if (el.name.trim().length > 0) {
      // }
      allCertificates += el.name + ',';
      return el;
    });
    setTeacherTraining({
      ...teacherTraining,
      otherCertifications: allCertificates,
    });
  }, [certificates]);

  const classes = useStyles();

  var isNumber = null;

  function valideKey(evt) {
    let code = evt.key;
    if (code === 'Backspace') {
      isNumber = true;
      return true;
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      isNumber = true;
      return true;
    } else {
      isNumber = false;
      return false;
    }
  }

  const handleChangeForm = (e) => {
    if (e.target.name === 'maxHour') {
      if (isNumber) {
        setTeacherTraining({
          ...teacherTraining,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setTeacherTraining({
        ...teacherTraining,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClickSave = async (e) => {
    const updatedTeacherT = await helperTeacherTraining.putTeacherTraining(teacherTrainingId, teacherTraining);
    if (updatedTeacherT) {
      dispatch(
        fuseActions.showMessage({
          message: '¡Datos guardados con éxito!',
          variant: 'success',
        })
      );
    } else {
      dispatch(
        fuseActions.showMessage({
          message: 'Ocurrió un error al guardar los datos - ¡Check it out!',
          variant: 'error',
        })
      );
    }
  };

  const handleChangeProfTitle = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setTeacherTraining({ ...teacherTraining, professionalTittle: url });
  };
  const handleChangeNomb = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setTeacherTraining({ ...teacherTraining, professionalAppointment: url });
  };

  const handleChangeCertificates = async (e, index) => {
    const aux = certificates.map((el, ind) => {
      if (ind === index) {
        return {
          ...el,
          name: e.target.value,
        };
      } else {
        return el;
      }
    });
    setCertificates(aux);
  };

  const handlePlusCertificate = () => {
    setOpenModal(true);
  };

  const handleChangeDelCert = async (e, index) => {
    const aux = certificates.filter((el, ind) => ind !== index);
    console.log(aux);
    setCertificates(aux);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">FORMACIÓN ACADÉMICA</Typography>
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Título o Especialización</InputLabel>
            <OutlinedInput
              type="text"
              placeholder="Título o Especialización"
              label="Título o Especialización"
              name="specialty"
              sx={{ mb: 2 }}
              value={teacherTraining.specialty}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={teacherTraining.professionalTittle.trim().length > 0 ? 5 : 6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Certificado Profesional</InputLabel>
            <OutlinedInput
              helpertext="Sube el documento en PDF"
              label="Certificado Profesional"
              sx={{ mb: 2 }}
              name="professionalTittle"
              disabled
              value={teacherTraining.professionalTittle}
              onChange={(e) => {
                handleChangeForm(e);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Fab color="secondary" size="small" edge="end">
                    <label htmlFor="icon-button-file-title">
                      <input accept=".pdf" id="icon-button-file-title" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeProfTitle(e)} />
                      <IconButton color="primary" aria-label="upload picture prof title" component="span">
                        <img src={upload} alt="upload" />
                      </IconButton>
                    </label>
                  </Fab>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {teacherTraining.professionalTittle.trim().length > 0 ? (
          <Grid item md={1}>
            <Tooltip title="Ver certificado" placement="top" sx={{ fontSize: 13, fontWeight: 'bold' }}>
              <Fab color="secondary" size="small" edge="end" onClick={() => window.open(teacherTraining.professionalTittle, '_blank')}>
                <IconButton color="primary" aria-label="icon professional title" component="span">
                  <VisibilityIcon />
                </IconButton>
              </Fab>
            </Tooltip>
          </Grid>
        ) : null}

        <Grid item md={teacherTraining.professionalAppointment.trim().length > 0 ? 5 : 6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Nombramiento</InputLabel>
            <OutlinedInput
              helpertext="Sube el documento en PDF"
              label="Nombramiento"
              sx={{ mb: 2 }}
              name="professionalAppointment"
              disabled
              value={teacherTraining.professionalAppointment}
              onChange={(e) => {
                handleChangeForm(e);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Fab color="secondary" size="small" edge="end">
                    <label htmlFor="icon-button-file-appoitment">
                      <input accept=".pdf" id="icon-button-file-appoitment" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeNomb(e)} />
                      <IconButton color="primary" aria-label="upload file prof appoit" component="span">
                        <img src={upload} alt="upload" />
                      </IconButton>
                    </label>
                  </Fab>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {teacherTraining.professionalAppointment.trim().length > 0 ? (
          <Grid item md={1}>
            <Fab color="secondary" size="small" edge="end" onClick={() => window.open(teacherTraining.professionalAppointment, '_blank')}>
              <IconButton color="primary" aria-label="icon professional appoitment" component="span">
                <VisibilityIcon />
              </IconButton>
            </Fab>
          </Grid>
        ) : null}
        <Grid item md={6}>
          <Typography variant="body1" sx={{ color: '#EA9980' }}>
            Sube tu nombramineto de profesor en caso de tener uno
          </Typography>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Idioma</InputLabel>
            <OutlinedInput
              type="text"
              placeholder="Idioma"
              label="Idioma"
              name="language"
              sx={{ mb: 2 }}
              value={teacherTraining.language}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Horas de tutoría por clase</InputLabel>
            <OutlinedInput
              type="text"
              placeholder="Horas de tutoria por clase"
              label="Horas de tutoria por clase"
              name="maxHour"
              onKeyDown={(e) => valideKey(e)}
              sx={{ mb: 2 }}
              value={teacherTraining.maxHour}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Descripción Profesional</InputLabel>
            <OutlinedInput
              type="text"
              placeholder="Descripción Profesional"
              label="Descripción Profesional"
              rows={4}
              multiline
              sx={{ mb: 2 }}
              name="professionalDescription"
              value={teacherTraining.professionalDescription}
              className={classes.textField}
              onChange={(e) => {
                handleChangeForm(e);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
        Otros Certificados
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '.3rem',
          justifyContent: 'space-between',
        }}
      >
        {certificates.map((el, index) => {
          return (
            <FormControl key={index} fullWidth variant="outlined" style={{ maxWidth: '48%' }}>
              <OutlinedInput
                type="text"
                name="name"
                sx={{ mb: 2 }}
                value={el.name}
                className={classes.textField}
                onChange={(e) => {
                  handleChangeCertificates(e, index);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Fab color="secondary" size="small" edge="end" onClick={(e) => handleChangeDelCert(e, index)}>
                      <img src={deleteIcon} alt="delete" />
                    </Fab>
                  </InputAdornment>
                }
              />
            </FormControl>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '2rem 0rem',
        }}
      >
        <Fab
          // color="secondary"
          size="small"
          edge="end"
          variant="extended"
          sx={{
            width: '9rem',
            background: '#D8E7E4',
            transform: 'scale(1.3)',
          }}
          onClick={handlePlusCertificate}
        >
          <IconButton color="primary" component="span">
            <AddRoundedIcon />
          </IconButton>
        </Fab>
      </div>
      <Fab
        variant="extended"
        sx={{
          height: 70,
          width: 300,
          textTransform: 'capitalize',
          mt: 2,
          float: 'right',
        }}
        color="primary"
        onClick={handleClickSave}
      >
        <Typography sx={{ fontWeight: 900 }}>Guardar</Typography>
      </Fab>
      <FormDialog openModal={openModal} setOpenModal={setOpenModal} certificates={certificates} setCertificates={setCertificates} />
    </div>
  );
};
