import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const PrivateRoute = ({ isAuthenticated, role, component: Component, ...rest }) => {
  localStorage.setItem('lastPath', rest.location.pathname);

  const currentPath = useLocation();

  const path = currentPath.pathname.split('/');

  return (
    <Route
      {...rest}
      component={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }
        switch (role) {
          case 'admin':
            if (path[1] !== role) return <Redirect to="/admin" />;
            return <Component {...props} />;
          case 'teacher':
            if (path[1] !== role) return <Redirect to="/teacher" />;

            return <Component {...props} />;
          case 'student':
            if (path[1] !== role) return <Redirect to="/student" />;
            return <Component {...props} />;

          default:
            return <Redirect to="/login" />;
        }
      }}
      // component = { (props) =>(
      //     (isAuthenticated)
      //     ? (<Component {...props} />)
      //     : (<Redirect to="/login" />)

      // )}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
