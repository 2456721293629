import React from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentPaymentPage } from '../../components/StudentPage/StudentPaymentPage';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const StudentPaymentScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Pago" />
      <StudentPaymentPage />
      <BotBackground logined />
    </div>
  );
};
