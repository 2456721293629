import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const useDataBank = () => {
  const getDataBankAdmin = async () => {
    const resp = await axios.get(`${apiUrl}view/getAllDataBank`);
    return resp.data.DataBank;
  };
  return { getDataBankAdmin };
};
