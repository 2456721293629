import React from 'react';
import { makeStyles } from '@mui/styles';
import { Chip, Fab, Grid, Typography } from '@mui/material';
import { AdminProfessorInfoCard } from './AdminProfessorInfoCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { helper } from '../../../../services/helperTeachers';
import { useHistory } from 'react-router-dom';
import { colorsList } from '../../../../utils/colorsList';
import { useDispatch } from 'react-redux';
import { getTeacherInfoSelectedAdmin } from '../../../../store/actions/admin/teachersActions';

const useStyles = makeStyles((theme) => ({
  root: { padding: '10px 30px' },
}));
export const AdminProfessorInfo = ({ name = '', review, subject = [], price, img, desc, status, university, role, certifications, userId, teacherTrainingId }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const hanldeClickDelete = () => {
    const data = {
      status: 'rejected',
    };
    updateData(data);
  };
  const handleClickAcept = () => {
    const data = {
      status: 'accepted',
    };
    updateData(data);
  };

  const updateData = async (data) => {
    await helper.putTeacher(teacherTrainingId, data);
    dispatch(getTeacherInfoSelectedAdmin(userId));
  };

  function AcceptButtonF() {
    return (
      <Fab variant="extended" color="primary" sx={{ width: 160, height: 60 }} onClick={handleClickAcept}>
        <Typography sx={{ textTransform: 'capitalize' }}>Aceptar</Typography>
      </Fab>
    );
  }

  function DeleteButtonF() {
    return (
      <Fab variant="extended" color="secondary" sx={{ width: 160, height: 60 }} onClick={hanldeClickDelete}>
        <Typography sx={{ textTransform: 'capitalize' }}>Eliminar</Typography>
      </Fab>
    );
  }

  // useEffect(() => {
  //   console.log("renderiza");
  // }, [updateData]);

  return (
    <div className={classes.root}>
      <AdminProfessorInfoCard admin name={name} price={price} img={img} status={status} review={review} />
      <Typography variant="h5" sx={{ fontWeight: 400, my: 4 }}>
        {desc}
      </Typography>

      {subject.length > 0 &&
        subject.map((item, idx) => {
          return <Chip key={item.subjectId} label={<Typography sx={{ fontSize: '14px' }}>{item.nameSubject}</Typography>} sx={{ bgcolor: colorsList[idx], margin: 1 }} />;
        })}
      <Grid container justifyContent="space-around" alignItems="center" sx={{ mt: 4 }}>
        {status === 'accepted' ? (
          DeleteButtonF()
        ) : status === 'rejected' ? (
          AcceptButtonF()
        ) : (
          <>
            {DeleteButtonF()}
            {AcceptButtonF()}
          </>
        )}
      </Grid>
    </div>
  );
};
