import axios from "axios";
import { apiUrl } from "../../../../config/apiConfig";
import * as fuseActions from "../../../../store/actions/fuse";
export const ADD_USER = "ADD_USER";
export const addUser = ({
  fullName,
  email,
  rol,
  img,
  cityId,
  password,
  gender,
  dni,
  phoneNumber,
  history,
}) => {
  console.log("user===", dni);
  const request = axios.post(`${apiUrl}users`, {
    fullName,
    email,
    rol,
    img,
    cityId,
    password,
    gender,
    dni,
    phoneNumber,
  });
  return (dispatch) =>
    request
      .then((res) => {
        if (res.status == 200) {
          dispatch(
            fuseActions.showMessage({
              message: "Register Success",
              variant: "success",
            })
          );
          dispatch({ type: ADD_USER, payload: res.data });
          history.push("/login");
        } else {
        }
      })
      .catch((err) => {
        dispatch(
          fuseActions.showMessage({
            message: err.response.data.msg,
            variant: "warning",
          })
        );
      });
};
