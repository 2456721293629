import React from "react";
import { AgendaAvailabilityPage } from "../../components/AgendaPage/AgendaAvailabilityPage";
import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";

export const AgendaAvailabilityScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Disponibilidad de Agenda" />
      <AgendaAvailabilityPage />
      <BotBackground logined />
    </div>
  );
};
