import { combineReducers } from "redux";
import {
  homeData,
  homeDataTeacherTraining,
  homeDataHomeworksPending,
  homeDataHomeworks,
  homeDataViewHomeworks,
  homeDataAllCities,
  homeDataAllProvinces,
  homeDataAllCountries,
  homeDataViewCourseByUser,
  homeDataSchedulesByUserId,
} from "./homedata.reducer";
const homeReducer = combineReducers({
  homeData,
  homeDataTeacherTraining,
  homeDataHomeworksPending,
  homeDataHomeworks,
  homeDataViewHomeworks,
  homeDataAllCities,
  homeDataAllProvinces,
  homeDataAllCountries,
  homeDataViewCourseByUser,
  homeDataSchedulesByUserId,
});

export default homeReducer;
