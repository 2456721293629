import axios from "axios";
import { apiUrl } from "../../../../config/apiConfig";
import * as fuseActions from "../../../../store/actions/fuse";

export const LOGIN = "LOGIN";
export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const loginUser = ({ email, password, history }) => {
  const request = axios.post(`${apiUrl}auth/login`, { email, password });

  return (dispatch) =>
    request
      .then((res) => {
        sessionStorage.setItem("jwt", JSON.stringify(res.data.token));
        sessionStorage.setItem("userinfo", JSON.stringify(res.data.user));
        dispatch({ type: LOGIN, payload: res.data.user });
        dispatch(
          fuseActions.showMessage({
            message: "Ingreso exitoso",
            variant: "success",
          })
        );
        if (res.data.user.rol === "teacher") {
          history.push("/teacher");
        }
        if (res.data.user.rol === "admin") {
          history.replace("/admin");
        }
        if (res.data.user.rol === "student") {
          history.push("/student");
        }
      })
      .catch((err) => {
        dispatch(
          fuseActions.showMessage({
            message: "Error al iniciar sesión",
            variant: "warning",
          })
        );
      });
};

export function setUser(data) {
  console.log("data=", data);
  const user = JSON.parse(sessionStorage.getItem("userinfo"));
  return (dispatch) =>
    dispatch({
      type: SET_USER,
      payload: user,
    });
}
export function logout() {
  sessionStorage.removeItem("jwt");
  sessionStorage.removeItem("userinfo");
  return (dispatch) =>
    dispatch({
      type: LOGOUT,
    });
}
