import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { ParamItem } from '../ParamItem';
import { colorsList } from '../../../../utils/colorsList';
import { Box } from '@mui/system';

export const LevelParams = ({ levels, onChange }) => {
  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item md={4}></Grid>
        <Grid item md={4}>
          <Typography sx={{ fontFamily: 'Poppins', ml: 4, fontSize: '22px', mb: 4, display: { xs: 'none', md: 'block' } }}>Tutoría </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography sx={{ fontFamily: 'Poppins', ml: 4, fontSize: '22px', mb: 4, display: { xs: 'none', md: 'block' } }}>Deberes</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />

      {levels.map((item, idx) => (
        <div key={idx}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '100%',
                  height: '6rem',
                  borderRadius: 4,
                  mb: 4,
                  cursor: 'pointer',
                  backgroundColor: colorsList[idx],
                }}
              >
                <Typography>{item.nameLevel}</Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ justifyContent: 'center' }}>
              <Typography sx={{ fontFamily: 'Poppins', ml: 4, fontSize: '22px', mb: 4, display: { xs: 'block', md: 'none' } }}>Tutoría </Typography>
              <ParamItem percent={item.valuePercentT} cost={item.valueCostT} namePercent={item.namePercentT} nameCost={item.nameCostT} onChange={onChange(idx)} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography sx={{ fontFamily: 'Poppins', ml: 4, fontSize: '22px', mb: 4, display: { xs: 'block', md: 'none' } }}>Deberes</Typography>
              <ParamItem percent={item.valuePercentH} cost={item.valueCostH} namePercent={item.namePercentH} nameCost={item.nameCostH} onChange={onChange(idx)} />
            </Grid>
          </Grid>
          <Divider sx={{ my: 4 }} />
        </div>
      ))}
    </div>
  );
};
