import axios from 'axios';
import { apiUrl } from '../config/apiConfig';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as fuseActions from '../store/actions/fuse';

export const useParams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getLevels = async () => {
    const resp = await axios.get(`${apiUrl}levels`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        setIsLoading(false);
        return resp.data.LevelsDB;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const updateLavel = async (id, percentTutorial, costTutorial, percentHomework, costHomework) => {
    const resp = await axios.put(`${apiUrl}levels/${id}`, {
      percentTutorial,
      costTutorial,
      percentHomework,
      costHomework,
    });
    if (resp.status === 200) {
      if (resp.data.msg) {
        setIsLoading(false);
        dispatch(
          fuseActions.showMessage({
            message: 'Parametros actualizados',
            variant: 'success',
          })
        );
      } else {
        setIsLoading(false);
        dispatch(
          fuseActions.showMessage({
            message: 'No se pudo actualizar',
            variant: 'warning',
          })
        );
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  return { getLevels, isLoading, updateLavel };
};
