import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import { Avatar, Grid, Typography, Divider, Box, Badge } from "@mui/material";
import moment from "moment";
import { helperReqHomeworks } from "../../../services/helperReqHomeworks";

const useStyles = makeStyles((theme) => ({
  root: { margin: "40px 40px" },
}));

export const WalletUserState = (props) => {
  const classes = useStyles();
  const {
    avatar,
    name,
    date,
    schedule,
    price,
    purchase,
    classReq,
    requestHomeworkId,
    isClass,
  } = props;

  const [homework, setHomework] = useState({ price: 0 });

  useEffect(() => {
    const getPrice = async () => {
      const getHomework = await helperReqHomeworks.getReqHomeworkById(
        requestHomeworkId
      );
      setHomework(getHomework);
    };
    getPrice();
  }, [requestHomeworkId]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={3} container>
          <Badge
            overlap="circular"
            // badgeContent=" "
            // color="success"
            // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Avatar src={avatar} sx={{ width: 146, height: 146 }} />
          </Badge>
        </Grid>
        <Grid
          item
          md={9}
          container
          direction="column"
          justifyContent="center"
          // sx={{ transform: "translateX(-2rem)" }}
        >
          <Typography variant="body1" sx={{ display: "block" }}>
            {name}
          </Typography>
          {purchase ? (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <span style={{ color: "#0AD78D", fontSize: 18 }}>
                  {classReq !== 0 && "Tutoría"}
                </span>
                &nbsp;&nbsp;
                {classReq !== 0 && "|"}
                {/* &nbsp;&nbsp; */}
                {/* <img
                  src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                  alt="cc"
                /> */}
                {/* &nbsp;&nbsp; */}
                {isClass ? "Clase |" : "Deber |"}
                {/* {moment(date).format("DD/MM/YYYY")} */}
                &nbsp;&nbsp;
                <span style={{ color: "#0AD78D" }}>${homework.price}</span>
                {/* <img
                      src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                      alt="vv"
                    /> */}
                &nbsp;&nbsp;
                {schedule}
                &nbsp;&nbsp;
              </Typography>
              <br />
              {/* <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel}
                {nameSubject}
              </Typography> */}
              <br />
              {/* <Typography>Detalle: {detailHomework}</Typography> */}
              <Box
                sx={{
                  // border: "2px solid red",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              ></Box>
            </div>
          ) : (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <Box sx={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      // flex: "1",
                    }}
                  >
                    <div>
                      {/* <img
                        src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                        alt="gg"
                      /> */}
                    </div>
                    <div>
                      &nbsp;&nbsp;
                      {/* {moment(date).format("DD/MM/YYYY")} */}
                      {isClass ? "Clase" : "Deber"}
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      // justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      {/* <img
                        src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                        alt="kk"
                      /> */}
                    </div>
                    <div>
                      {/* &nbsp;&nbsp; */}
                      {/* {schedule} */}
                      &nbsp;&nbsp;| &nbsp;&nbsp;
                      <span style={{ color: "#0AD78D" }}>${price}</span>
                    </div>
                  </div>
                </Box>
              </Typography>
              <br />
              {/* <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel} - {nameSubject}
              </Typography> */}
            </div>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "40px" }} />
    </div>
  );
};
