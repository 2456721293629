import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { PaySeletorItem } from './PaySeletorItem';
import { Box, Button, Dialog, DialogActions, DialogContent, Fab, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material';
import upload from '../../../../assets/icon/upload.svg';
import { usePayments } from '../../../../hooks/usePayments';
import { Loading } from '../../../common/Loading';
import { uploadImg } from '../../../../services/partials/uploadImg';
import bank from '../../../../assets/icon/bank.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLevels } from '../../../../hooks/useLevels';
import { useClass } from '../../../../hooks/useClass';
import { useInvoices } from '../../../../hooks/useInvoices';
import { useHistory } from 'react-router-dom';
import { showPayment } from '../../../../store/actions/students/uiPaymentAction';
import axios from 'axios';
import { apiUrl } from '../../../../config/apiConfig';
import * as fuseActions from '../../../../store/actions/fuse/index';
import { helperReqHomeworks } from '../../../../services/helperReqHomeworks';
import { useUpLoadHomework } from '../../../../hooks/useUpLoadHomework';
import { getInvoicesById, setResumen } from '../../../../store/actions/students/resumenActions';

export const StdPayContent = () => {
  const classes = useStyles();
  const [payments, setPayments] = useState([]);
  const [paymentCheked, setPaymentCheked] = useState({});
  const dispatch = useDispatch();
  const { getLevelById } = useLevels();
  const [urlPaypal, setUrlPaypal] = useState('');
  const [openPaypal, setOpenPaypal] = useState(false);

  const history = useHistory();

  const requestClass = useSelector(({ studentReducers }) => studentReducers.requestClassReducer);
  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  const [url, setUrl] = useState('');
  const [value, setValue] = useState(0);

  const { getAllPayments } = usePayments();
  const { addInvoicesBackEnd, putRequestClass } = useInvoices();
  const { putHomeWorkBackEnd, putRequestHomeWorkBackEnd } = useUpLoadHomework();

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const getPaymentMethods = async () => {
    const methods = await getAllPayments();
    setValue(methods[0].id);
    setPaymentCheked(methods[0]);
    setPayments(methods.reverse());
  };

  const handleChangeFile = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    console.log(url);
    setUrl(url);
  };

  const handleChange = (event) => {
    setPaymentCheked(payments.find((element) => element.id === parseInt(event.target.value)));
    setValue(event.target.value);
  };

  const addInvoice = async () => {
    if (paymentCheked.id === 1) {
      const payMethodId = paymentCheked.id;
      const imageVoucherUrl = url;
      if (payMethodId === 0) {
        console.log('entra validacion payMethodId');
        dispatch(
          fuseActions.showMessage({
            message: 'Por favor elige un método de pago',
            variant: 'warning',
          })
        );
      } else if (imageVoucherUrl === '') {
        console.log('entra validacion payMethodId');
        dispatch(
          fuseActions.showMessage({
            message: 'Por favor suba el comprobante de transferencia',
            variant: 'warning',
          })
        );
      } else {
        if (requestClass.idRequestClass > 0) {
          if (requestClass.requestType === 'class') {
            console.log(requestClass.idRequestClass, payMethodId, requestClass.requestType, requestClass.priceTotal, imageVoucherUrl);
            const resp = await addInvoicesBackEnd(requestClass.idRequestClass, payMethodId, requestClass.requestType, requestClass.priceTotal, imageVoucherUrl);
            await putRequestClass(requestClass.idRequestClass, 'waiting');
            dispatch(showPayment(false));
            history.replace('/student/resume', { idRequest: requestClass.idRequestClass, idInvoice: resp.id });
          } else {
            const getLevel = await getLevelById(requestClass.levelId);
            let percentPrice = (parseInt(getLevel.percentTutorial + '') * requestClass.priceTotal) / 100;
            console.log(requestClass.idRequestClass, payMethodId, requestClass.requestType, requestClass.priceTotal, imageVoucherUrl, teacherSelected.userId, percentPrice);
            const resp = await addInvoicesBackEnd(requestClass.idRequestClass, payMethodId, requestClass.requestType, requestClass.priceTotal, imageVoucherUrl);
            await putHomeWorkBackEnd(requestClass.offertId, 'waiting');
            await putRequestHomeWorkBackEnd(requestClass.idRequestClass, 'waiting', teacherSelected.userId, requestClass.priceTotal, percentPrice);

            dispatch(getInvoicesById(resp.id));
            dispatch(setResumen(requestClass.hoursNumber, requestClass.date, requestClass.schedule));
            dispatch(showPayment(false));
            history.replace('/student/resume');
          }
        }
      }
    } else {
      if (requestClass.requestType === 'class') {
        // let total=(priceWork * request.hoursNumber)+'';
        // postpaypal('Tutorías', 'Pago realizado por una tutorías', requestClass.priceTotal, requestClass.idRequestClass, 'class');
      } else {
        let total = requestClass.priceTotal + '';
        // postpaypal('Tutorías', 'Pago realizado por una tarea', total, requestClass.idRequestClass, 'homework');
      }
    }
  };

  const postpaypal = async (item, description, price, idRequest, type) => {
    const resp = await axios.post(`${apiUrl}paypal`, { item, description, price, idRequest, type });
    const urlPaypalRes = resp.data.url;
    setUrlPaypal(urlPaypalRes);
    setOpenPaypal(true);
  };

  const onCancel = () => {
    history.replace('/');
    dispatch(showPayment(false));
  };

  const handleClose = () => {
    setOpenPaypal(false);
  };

  return (
    <div className={classes.root}>
      {payments.length > 0 ? (
        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
          {payments.map((item, idx) => (
            <FormControlLabel className={classes.paymentSelector} key={idx} value={item.id} control={<Radio color="primary" />} label={item.namePayment}>
              <img src={bank} alt="bank" />
            </FormControlLabel>
          ))}
        </RadioGroup>
      ) : (
        <Loading titleLoading="Cargando métodos de pago" />
      )}
      {value == 1 && (
        <>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{url ? `${url}` : 'Suba el comprobante de pago'}</InputLabel>
            <OutlinedInput
              disabled
              label="Suba el comprobante de pago"
              sx={{ mb: 6 }}
              className={classes.textField}
              endAdornment={
                <Fab color="secondary" size="small" edge="end" style={{ zIndex: 2 }} disabled={requestClass.stateRequestClass === 'pending' ? true : false}>
                  <label htmlFor="icon-button-file">
                    <input accept="image/png, image/jpge, , image/jpg" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeFile(e)} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <img src={upload} alt="upload" />
                    </IconButton>
                  </label>
                </Fab>
              }
            />
          </FormControl>
          <Box
            sx={{
              background: 'rgba(2, 76, 138, 0.1)',
              borderRadius: '16px',
              padding: '4  0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {url ? <img src={url} width={250} /> : <></>}
          </Box>
        </>
      )}
      {requestClass.stateRequestClass === 'pending' ? (
        <>
          <Grid container justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
            <Typography variant="h5" color="initial" sx={{ mb: 2 }}>
              El profesor debe aceptar tu solicitud vuelve luego
            </Typography>
            <Fab
              onClick={() => history.replace('/student/history')}
              variant="extended"
              sx={{
                height: 60,
                ml: 4,
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
              }}
              color="primary"
            >
              <Typography>Regresar</Typography>
            </Fab>
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          <Fab
            onClick={onCancel}
            variant="extended"
            sx={{
              height: 60,
              textTransform: 'capitalize',
              fontFamily: 'Poppins',
            }}
            color="secondary"
          >
            <Typography>Cancelar</Typography>
          </Fab>
          {}
          <Fab
            onClick={addInvoice}
            variant="extended"
            sx={{
              height: 60,
              ml: 4,
              textTransform: 'capitalize',
              fontFamily: 'Poppins',
            }}
            color="primary"
          >
            <Typography>Confirmar pago</Typography>
          </Fab>
        </Grid>
      )}

      <Dialog fullWidth maxWidth="sm" open={openPaypal}>
        <iframe src={urlPaypal} style={{ width: '100%', height: '600px' }} />

        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
    },
  },
  paymentSelector: {
    backgroundColor: ' rgba(213, 184, 253, 1)',
    borderRadius: '16px',
    padding: '18px',
    marginBottom: '30px',
  },
}));
