import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { CustomTextField } from '../../../common/CustomTextField';
const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px' },
}));
export const ParamItem = ({ percent, cost, namePercent, nameCost, onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <CustomTextField label="%" value={percent} name={namePercent} onChange={onChange} autoFocus />
        </Grid>
        <Grid item>
          <CustomTextField label="Costo mínimo" value={cost} name={nameCost} onChange={onChange} autoFocus />
        </Grid>
      </Grid>
    </div>
  );
};
