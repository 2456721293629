import React from 'react';
import { Box, Typography } from '@mui/material';
import registerLogo from '../../../../assets/image/registerLogo.png';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { makeStyles } from '@mui/styles';
import { saveAs } from 'file-saver';

export const DetailOfferStudent = ({ nameSchool, nameSubject, numHours, url }) => {
  const classes = useStyles();

  const saveHomework = (fileUrl) => {
    if (fileUrl) {
      saveAs(fileUrl);
    } else {
      alert('Documento vacío');
    }
  };

  return (
    <div>
      <Box className={classes.boxSubject}>
        <Box className={classes.boxNames}>
          <Typography variant="h3" sx={{ mr: 6 }}>
            {nameSchool}
          </Typography>
          <Typography variant="h3" sx={{ mr: 6 }}>
            {nameSubject}
          </Typography>
        </Box>
        <div style={{ position: 'absolute', top: '-40px', left: '40px' }}>
          <img src={registerLogo} alt="registerLogo" />
        </div>
      </Box>
      <Box className={classes.containerBoxes}>
        <Box className={classes.boxOffer}>
          <Box className={classes.containerIcon} sx={{ mr: 1 }}>
            <AccessTimeRoundedIcon fontSize="large" sx={{ borderRadius: 100, padding: 1, mx: 1 }} />
          </Box>
          <Box>
            <Typography variant="h6">Tiempo de entrega:</Typography>
            <Typography variant="h6">{numHours} horas</Typography>
          </Box>
        </Box>
        <Box className={classes.boxOffer} sx={{ cursor: 'pointer', ml: 1 }} onClick={() => saveHomework(url)}>
          <Box>
            <Typography variant="h6">Descarga tu tarea aquí</Typography>
          </Box>
          <Box className={classes.containerIcon}>
            <ArrowDownwardRoundedIcon fontSize="large" sx={{ bgcolor: '#FEB098', borderRadius: 100, padding: 1, mx: 1 }} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  boxSubject: {
    alignItems: 'center',
    backgroundColor: '#FEB098',
    borderRadius: 20,
    display: 'flex',
    height: 200,
    justifyContent: 'flex-end',
    marginBottom: 8,
    position: 'relative',
    width: '100%',
    marginBottom: 30,
  },
  boxOffer: {
    backgroundColor: '#F3F7F6',
    borderRadius: 20,
    width: '50%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxNames: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerBoxes: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
