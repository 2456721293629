import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { History } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const BenefitItem = (props) => {
  const classes = useStyles();
  const { month, year, total, percent, monthSelected } = props;
  return (
    <div className={classes.root}>
      <Box
        sx={{
          border: "1px solid black",
          borderRadius: "15px",
          height: 100,
          padding: 1,
          my: 2,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{ float: "right", display: "block", fontFamily: "Poppins" }}
          >
            {monthSelected} {year}
          </Typography>
          <History sx={{ width: 60, height: 60, color: "warning.main" }} />
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={{ fontFamily: "Poppins", fontSize: ".9rem" }}>
              Ganancia
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 24,
                fontWeight: 700,
                my: 1,
              }}
            >
              $ {percent.toFixed(2)}
            </Typography>

            <Typography sx={{ fontFamily: "Poppins", fontSize: ".9rem" }}>
              Total: {total.toFixed(2)}
            </Typography>
          </Grid>
          {/* <Grid item>
            <History sx={{ width: 60, height: 60, color: "warning.main" }} />
          </Grid> */}
        </Grid>
      </Box>
    </div>
  );
};
