import { types } from '../../../types/types';

const initialState = {
  isLoading: true,
  levels: [],
};

export const levelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getAllLevels:
      return {
        isLoading: action.payload.isLoading,
        levels: action.payload.levels,
      };

    default:
      return state;
  }
};
