import React from "react";
import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { HistoryPage } from "../../components/HistoryPage";

export const HistoryScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Historial" />
      <HistoryPage />
      <BotBackground logined />
    </div>
  );
};
