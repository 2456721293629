import React, { useState, useEffect } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { PickersDay } from "@mui/lab";
import CalendarPicker from "@mui/lab/CalendarPicker";
import { Fab, Grid, Typography, IconButton } from "@mui/material";
import * as homeActions from "../HomePage/store/actions";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// #A4CCDF
// #Feb098

export const AgendarContent = ({
  setDaySearch,
  datesSelected,
  homeClasses,
  agendedClasses,
}) => {
  const [date, setDate] = React.useState(new Date());
  const [selectedDateUser, setSelectedDateUser] = useState(new Date());
  // const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  // console.log(agendedClasses);

  const allSchedules = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataSchedulesByUserId.reqSchedulesByUserId
  );

  useEffect(() => {
    dispatch(homeActions.getSchedulesByUserId(user.id));
  }, []);

  const handleChangeCalendar = (e) => {
    setDate(e);
    setDaySearch(moment(e).format("DD"));
  };

  // console.log("dates selected", datesSelected);

  const handleOnDaySelect = (e) => {
    console.log(e);
    setDate(e);
    setDaySearch(moment(e).format("DD"));
    setSelectedDateUser(e);
  };

  const getDayElement = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    // console.log(isInCurrentMonth);
    let elementDay = (
      <PickersDay
        key={day}
        day={day}
        outsideCurrentMonth={false}
        onDaySelect={(e) => handleOnDaySelect(e)}
            sx={{ fontSize: 15 }}
      ></PickersDay>
    );

    const dayPicker = moment(day).format("DD");
    const dayPickerMonth = moment(day).format("MM");
    const datos = agendedClasses.map((el) => {
      const daySelected = moment(el.date).format("MM");
      const daySelectedDay = moment(el.date).format("DD");
      if (dayPickerMonth === daySelected && dayPicker === daySelectedDay) {
        elementDay = (
          <PickersDay
            key={day}
            day={day}
            outsideCurrentMonth={false}
            onDaySelect={(e) => handleOnDaySelect(e)}
            sx={{ background: "#Feb098", fontSize: 15 }}
          ></PickersDay>
        );
      }
    });

    // if (
    //   dayPicker === moment(selectedDateUser).format("DD") &&
    //   dayPickerMonth === moment(selectedDateUser).format("MM")
    // ) {
    //   elementDay = (
    //     <PickersDay
    //       key={day}
    //       day={day}
    //       outsideCurrentMonth={false}
    //       onDaySelect={(e) => handleOnDaySelect(e)}
    //       sx={{ background: "#f00" }}
    //     ></PickersDay>
    //   );
    // }

    if (
      dayPicker === moment(moment()._d).format("DD") &&
      dayPickerMonth === moment(moment()._d).format("MM")
    ) {
      elementDay = (
        <PickersDay
          key={day}
          day={day}
          outsideCurrentMonth={false}
          onDaySelect={(e) => handleOnDaySelect(e)}
          sx={{ background: "#65A2B6", fontSize: 15 }}
        ></PickersDay>
      );
    }

    // console.log(elementDay);
    return elementDay;
  };

  return (
    <div>
      <div className={""}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CalendarPicker
            sx={{ width: "100%", heigth: "100%" }}
            // date={date}
            onChange={(e) => handleChangeCalendar(e)}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
              getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
            }
          />
        </LocalizationProvider>
      </div>
      <Grid container sx={{ ml: 6 }}>
        <IconButton
          sx={{ background: "#FEB098", mr: 6 }}
          size="large"
        ></IconButton>
        <Typography>Días con clases agendadas</Typography>
      </Grid>
      <Fab
        variant="extended"
        sx={{
          mt: 8,
          textTransform: "capitalize",
          width: "100%",
          height: "70px",
        }}
        color="primary"
        onClick={() => history.push("/teacher/availability")}
      >
        <Typography>Cambiar disponibilidad de Agenda</Typography>
      </Fab>
    </div>
  );
};
