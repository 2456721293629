import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import logLogo from '../../../../assets/image/logLogo.png';
import registerLogo from '../../../../assets/image/registerLogo.png';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StdMainContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();

  return (
    <div className={classes.root}>
      <Box
        sx={{
          width: 550,
          height: 200,
          borderRadius: 10,
          bgcolor: 'success.gray',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
          mb: 8,
          cursor: 'pointer',
        }}
        onClick={() => history.push('/student/menu', { idLevel: state.idLevel, nameLevel: state.nameLevel, schools: state.schools })}
      >
        <Typography variant="h3" sx={{ ml: 6 }}>
          Solicitar Tutor
        </Typography>
        <div style={{ position: 'absolute', top: '-20px', right: '20px' }}>
          <img src={logLogo} alt="logLogo" />
        </div>
      </Box>
      <Box
        sx={{
          width: 550,
          height: 200,
          borderRadius: 10,
          bgcolor: 'warning.secondary',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'relative',
          mb: 8,
          cursor: 'pointer',
        }}
        onClick={() => history.push('/student/help')}
      >
        <Typography variant="h3" sx={{ mr: 6 }}>
          Ayúdame con
          <br /> mi tarea
        </Typography>
        <div style={{ position: 'absolute', top: '-40px', left: '40px' }}>
          <img src={registerLogo} alt="registerLogo" />
        </div>
      </Box>
    </div>
  );
};
