import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    color: theme.palette.primary.main,
  },
}));

export const Loading = ({ titleLoading }) => {
  return (
    <Grid container spacing={2} direction="column" alignItems="center">
      <Grid item>
        <CircularProgress size="100px" style={{ color: "#65A2B6" }} />
      </Grid>
      <Grid item>
        <Typography variant="h5" >{titleLoading}</Typography>
      </Grid>
    </Grid>
  );
};

Loading.propTypes = {
  isAuthenticated: PropTypes.string,
};
