import React from "react";
import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { ProfessorProfilePage } from "../../components/ProfessorProfilePage";

export const ProfessorProfileScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Tu Perfil" />
      <ProfessorProfilePage />
      <BotBackground logined />
    </div>
  );
};
