import { makeStyles } from "@mui/styles";
import React from "react";
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";

const useStyle = makeStyles({
  root: {
    position: "relative",
    margin: "40px 40px",
  },
  close: {
    position: "absolute",
    right: "8px",
    top: "0px",
  },
});

const colorStateCompleted = "#38DEA3";
const colorStateReview = "#A259FF";
const colorStateRejected = "#E89F88";
const colorStatePending = "#FF6C37";

export const HistoryUserState = (props) => {
  const {
    avatar,
    voucher,
    requestId,
    updateClass,
    name,
    purchase,
    admin,
    date,
    hoursNumber,
    price,
    nameSubject,
    nameLevel,
    detailHomework,
    reqClassId,
    schedule,
    classReq,
    fileUrl,
    timeHomework,
    badge,
    completed,
  } = props;

  // const { purchase, completed } = props;

  const [open, setOpen] = React.useState(false);

  const StatusTimeBadge = () => {
    return (
      <div
        style={{
          width: "200px",
          height: "58px",
          borderRadius: "16px",
          background: "#EDEDED",
          display: "flex",
          alignItems: "center",
          padding: "8px",
          margin: "20px",
        }}
      >
        {" "}
        <QueryBuilderIcon
          sx={{ color: "#A5A5A5", mr: 2 }}
          fontSize="large"
        />{" "}
        <Typography sx={{ color: "#A5A5A5", fontSize: 16 }}>
          Tiempo de entrega 24 horas
        </Typography>
      </div>
    );
  };
  const StatusDownBadge = () => {
    return (
      <div
        style={{
          width: "180px",
          height: "58px",
          borderRadius: "16px",
          background: "#EDEDED",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "8px",
          margin: "20px",
        }}
      >
        {" "}
        <Typography sx={{ color: "#A5A5A5", fontSize: 16, ml: 2 }}>
          Descarga la tarea aquí
        </Typography>
        <IconButton sx={{ background: "#FEB098" }}>
          <ArrowDownwardIcon sx={{}} fontSize="large" />{" "}
        </IconButton>
      </div>
    );
  };

  const classes = useStyle();
  return (
    <div className={classes.root}>
      {/* <CloseIcon className={classes.close} /> */}
      <Grid container>
        <Grid item md={3} container>
          {/* <Badge
            overlap="circular"
            badgeContent=" "
            color="success"
            // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          > */}
          <Avatar src={avatar} sx={{ width: 146, height: 146 }} />
          {/* </Badge> */}
        </Grid>
        <Grid item md={6}>
          <Typography variant="body1" sx={{ display: "block" }}>
            {purchase ? nameSubject : name}
          </Typography>
          <br />
          {purchase ? (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <span style={{ color: "#0AD78D", fontSize: 18 }}>
                  {classReq !== 0 && "Tutoría"}
                </span>
                &nbsp;&nbsp;
                {classReq !== 0 && "|"}
                &nbsp;&nbsp;
                <img
                  src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                  alt="cc"
                />
                &nbsp;&nbsp;
                {moment(date).format("DD/MM/YYYY")}
                &nbsp;&nbsp;
                {/* <img
                      src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                      alt="vv"
                    /> */}
                &nbsp;&nbsp;
                {schedule}
                &nbsp;&nbsp;
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel}
              </Typography>
              <br />
              <Typography>Detalle: {detailHomework}</Typography>
              <Box
                sx={{
                  // border: "2px solid red",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {StatusTimeBadge()}
                {StatusDownBadge()}
              </Box>
            </div>
          ) : (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <Box sx={{ display: "flex" }}>
                  <div
                    style={{
                      // border: '2px solid red',
                      display: "flex",
                      flex: "1",
                    }}
                  >
                    <div>
                      <img
                        src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                        alt="gg"
                      />
                    </div>
                    <div>
                      &nbsp;&nbsp;
                      {moment(date).format("DD/MM/YYYY")}
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <img
                        src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                        alt="kk"
                      />
                    </div>
                    <div>
                      &nbsp;&nbsp;
                      {schedule}
                      &nbsp;&nbsp;| &nbsp;&nbsp;
                      <span style={{ color: "#0AD78D" }}>${price}</span>
                    </div>
                  </div>
                </Box>
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#FEB098" }}>
                Horas de clase: {hoursNumber}
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel} - {nameSubject}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item md={3} container justifyContent="center" alignItems="center">
          <Typography
            sx={{
              color:
                completed === "accepted"
                  ? colorStateReview
                  : completed === "completed"
                  ? colorStateCompleted
                  : completed === "rejected"
                  ? colorStateRejected
                  : completed === "pending"
                  ? colorStatePending
                  : "#000",
            }}
          >
            {completed === "accepted"
              ? "En revisión"
              : completed === "completed"
              ? "Completado"
              : completed === "rejected"
              ? "Rechazado"
              : completed === "pending"
              ? "Pendiente"
              : ""}
          </Typography>
        </Grid>
      </Grid>
      {/* <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
      >
        <ConfirmDialog handleClose={() => setOpen(false)} />
      </Dialog> */}
    </div>
  );
};
