import axios from "axios";
import { apiUrl } from "../config/apiConfig";
import { helperSubjects } from "../services/helperSubjects";

export const helper = {};

helper.postSchool = async (data) => {
  const res = await axios.post(`${apiUrl}schools`, data);
  // console.log("res data post -->", res.data);
  return res.data.school;
};

helper.putSchool = async (id, data) => {
  const res = await axios.put(`${apiUrl}schools/${id}`, data);
  // console.log("res data put -->", res.data);
  return res.data.schoolDB;
};

helper.deleteSchool = async (id) => {
  const res = await axios.delete(`${apiUrl}schools/${id}`);
  // console.log("res data delete -->", res.data);
};

helper.getSchoolsByLevelId = async (id) => {
  const res = await axios.get(`${apiUrl}schools/BylevelId/${id}`);
  const allSchools = await res.data.schools;
  let schools = allSchools.map(async (school) => {
    {
      if (school.idLevel === 4) {
        const subjects = await helperSubjects.getSubjectsBySchoolId(school.id);
        return {
          ...school,
          subjects,
        };
      }
      return school;
    }
  });
  schools = await Promise.all(schools);
  // console.log("res data get schools by id -->", schools);
  return schools;
};
