import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Fab, Grid, Rating, Typography } from '@mui/material';
import { useUser } from '../../../../hooks/useUser';
const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '40px' },
}));
export const AdStudentItem = ({ name, rating, review, url, avatar, func }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item md={3}>
          <Avatar src={avatar} sx={{ width: 140, height: 140 }} />
        </Grid>
        <Grid item md={6}>
          <Typography>{name}</Typography>
          <br />
          <Rating value={rating} />
          <br />
          <Typography>{review}</Typography>
          <br />
          <Typography>{url}</Typography>
        </Grid>
        <Grid item md={3}>
          <Fab variant="extended" color="primary" sx={{ width: 160, height: 60 }} onClick={() => func()}>
            <Typography sx={{ textTransform: 'capitalize' }}>Ocultar</Typography>
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
};
