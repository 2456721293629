import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Typography } from '@mui/material';
import user3 from '../../../assets/image/user3.png';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    background: 'rgba(255, 236, 136, 0.4)',
    borderRadius: '16px',
    padding: '30px',
  },
}));
export const TeacherCard = ({img, name='', speciality='', university='' }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar src={img || user3} sx={{ width: '132px', height: '132px' }} alt="avatar" />
      <Typography sx={{ mt: 2 }}>{name}</Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: 600, mt: 2, color: '#6C6C6C' }}>
        {speciality} {university && `- ${university}`}
      </Typography>
    </div>
  );
};
