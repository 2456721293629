import React from 'react';
import { makeStyles } from '@mui/styles';
import { ProfessorItem } from './ProfessorItem';
import { useTeachers } from '../../../../hooks/useTeachers';
import Typography from '@mui/material/Typography';
import { Skeleton, Stack } from '@mui/material';
import { SkeletonCardTeacher } from '../../../common/SkeletonCardTeacher';

const useStyles = makeStyles((theme) => ({
  root: { padding: '10px 20px' },
  skel: { padding: 20, marginTop: 20 },
}));

export const ProfessorList = () => {
  const classes = useStyles();

  const { teachers } = useTeachers();
  

  return (
    <div className={classes.root}>
      {teachers.length > 0 ? (
        teachers.map((item, idx) => (
          <div key={idx}>
            <ProfessorItem admin review={item.starReview} desc={item.professionalDescription} university={item.university} role={item.rol} certifications={item.otherCertifications} price={item.price} status={item.status} subjects={item.levels} name={item.fullName} img={item.img} userId={item.userId} teacherTrainingId={item.teacherTrainingId} />
          </div>
        ))
      ) : (
        <SkeletonCardTeacher />
      )}
    </div>
  );
};
