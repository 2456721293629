import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { AdStudentItem } from './AdStudentItem';
import user1 from '../../../../assets/image/user1.png';
import user2 from '../../../../assets/image/user2.png';
import { EmptyState } from '../../../common/EmptyState';
import { useUser } from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const AdStudentReviewList = ({ reviewsStudet, func }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {reviewsStudet.length > 0 ? (
        reviewsStudet.map((item, idx) => (
          <div key={idx}>
            <AdStudentItem func={() => func(item.id)} name={item.teacherinfo.fullName} rating={item.starNumber} review={item.comment} url={item.videoUrl} avatar={item.teacherinfo.img} teacherId={item.teacherId} />
          </div>
        ))
      ) : (
        <EmptyState title="No existen reseñas" />
      )}
    </div>
  );
};
