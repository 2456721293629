import React from 'react';
import { Avatar, Badge, Grid } from '@mui/material';
import { DescriptionTeacher } from './DescriptionTeacher';
import { makeStyles } from '@mui/styles';

export const TeacherItem = ({ teacher }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container alignItems="center">
          <Grid item md={2} xs={12}>
            <Badge overlap="circular" badgeContent=" " 
            // color={teacher.status === 'accepted' ? 'success' : teacher.status === 'rejected' ? 'secondary' : 'warning'}
            >
              <Avatar src={teacher.img} sx={{ width: 132, height: 132 }} />
            </Badge>
          </Grid>
          <Grid item md={10} xs={12}>
            <DescriptionTeacher teacher={teacher} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
    width: '100%',
  },
}));
