import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { BotBackground } from '../../components/common/BotBackground';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getInvoicesById, getRequestClassesById } from '../../store/actions/students/resumenActions';
import { StudentResumePage } from '../../components/StudentPage/StudentResumePage/StudentResumePage';

export const StudentResumeScreen = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { emergency, invoice, requestClass } = useSelector(({ studentReducers }) => studentReducers.resumenReducer);
  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);

  useEffect(() => {
    if (state) {
      if (state.idRequest) dispatch(getRequestClassesById(state.idRequest));
      if (state.idInvoice) dispatch(getInvoicesById(state.idInvoice));
    }
  }, []);

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Resumen" />
      <StudentResumePage avatar={teacherSelected.img} emergency={emergency} name={teacherSelected.fullName} speciality={teacherSelected.speciality} university={teacherSelected.university || ''} date={requestClass.date} schedule={requestClass.schedule} total={invoice.total === 0 ? requestClass.price : invoice.total} hoursNumber={requestClass.hoursNumber} checked={invoice.payMethodId} img={invoice.imageVoucherUrl} />
      <BotBackground />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
