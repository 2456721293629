import { BotBackground } from "../../components/common/BotBackground";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { WalletPage } from "../../components/WalletPage";

export const WalletScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Billetera" />
      <WalletPage />
      <BotBackground logined />
    </div>
  );
};
