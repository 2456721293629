import React, { useEffect, useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { Avatar, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import imgUpload from '../../assets/image/Vector.png';
import * as registerActions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uploadImg } from '../../services/partials/uploadImg';
import upload from '../../assets/icon/upload.svg';
import { useUser } from '../../hooks/useUser';
import * as fuseActions from '../../store/actions/fuse';

const roles = [
  { name: 'Profesor', value: 'teacher' },
  { name: 'Estudiante', value: 'student' },
];
const genders = [
  { name: 'Masculino', value: 'male' },
  { name: 'Femenino', value: 'female' },
  { name: 'Otro', value: 'other' },
];

const defaultImg = 'https://www.weact.org/wp-content/uploads/2016/10/Blank-profile.png'

export const RegisterContent = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { postUserTraining, signUp } = useUser();

  const { country, province, city, avatar } = useSelector(({ countryReducers }) => countryReducers.countries);

  useEffect(() => {
    dispatch(registerActions.getAllCountry());
  }, []);

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const [values, setValues] = React.useState({
    password: '',
    provice: '',
    city: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    cityId: '',
    gender: '',
    genderName: '',
    country: '',
    role: '',
    roleName: '',
    dni: '',
    selectedState: null,
    mainState: 'initial',
    imageUploaded: 0,
    showPassword: false,
    specialty: '',
    selectedFile:'',
    professionalTittle: '',
    professionalAppointment: '',
    language: '',
    maxHour: 5,
    professionalDescription: '',
    otherCertifications: '',
  });

  const [error, setError] = React.useState({
    password: false,
    fullName: false,
    email: false,
    phoneNumber: false,
    dni: false,
    cityId: false,
    role: false,
  });

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const open5 = Boolean(anchorEl5);

  const onSubmit = async () => {
    const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    try {
      if (values.fullName.trim() === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Llena el campo Nombre',
            variant: 'warning',
          })
        );
      } else if (values.email.trim() === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Llena el campo Email',
            variant: 'warning',
          })
        );
      } else if (!emailValid.test(values.email.trim())) {
        dispatch(
          fuseActions.showMessage({
            message: 'Ingresa un correo válido',
            variant: 'warning',
          })
        );
      } else if (values.role === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Selecciona un tipo de usuario',
            variant: 'warning',
          })
        );
      } else if (values.password.length < 5) {
        dispatch(
          fuseActions.showMessage({
            message: 'El password debe ser mayor a 5 caracteres',
            variant: 'warning',
          })
        );
      } else if (values.gender === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Selecciona un género',
            variant: 'warning',
          })
        );
      } else if (values.dni === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Coloca tu número de identificación',
            variant: 'warning',
          })
        );
      } else if (values.cityId === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Selecciona tu pais, provicia y ciudad',
            variant: 'warning',
          })
        );
      } else if (values.phoneNumber === '') {
        dispatch(
          fuseActions.showMessage({
            message: 'Coloca tu número de contacto',
            variant: 'warning',
          })
        );
      // } else if (values.specialty.trim() === '' && values.role === 'teacher') {
      //   dispatch(
      //     fuseActions.showMessage({
      //       message: 'Ingresa tu título o especialización',
      //       variant: 'warning',
      //     })
      //   );
      // } else if (values.professionalTittle === '' && values.role === 'teacher') {
      //   dispatch(
      //     fuseActions.showMessage({
      //       message: 'Sube tu documento de titulacion o especialización',
      //       variant: 'warning',
      //     })
      //   );
      // } else if (values.professionalDescription === '' && values.role === 'teacher') {
      //   dispatch(
      //     fuseActions.showMessage({
      //       message: 'Ingresa un descripción profesional',
      //       variant: 'warning',
      //     })
      //   );
      // } else if (values.maxHour === '' && values.role === 'teacher') {
      //   dispatch(
      //     fuseActions.showMessage({
      //       message: 'Ingresa el maximo de horas de tutoría',
      //       variant: 'warning',
      //     })
      //   );
      // } else if (values.language === '' && values.role === 'teacher') {
      //   dispatch(
      //     fuseActions.showMessage({
      //       message: 'Ingresa los idiomas que hablas',
      //       variant: 'warning',
      //     })
      //   );
      } else {
        const respSingUp = await signUp(values.fullName.trim(), values.email.trim(), values.role, values.selectedFile || defaultImg, values.password, values.gender, values.dni.trim(), values.cityId, values.phoneNumber.trim());
        // console.log(values.fullName.trim(), values.email.trim(), values.role, values.selectedFile || defaultImg, values.password, values.gender, values.dni.trim(), values.cityId, values.phoneNumber.trim());
        // if (values.role === 'teacher' && respSingUp.id) {
        //   const respTeacher = await postUserTraining(respSingUp.id, values.specialty.trim(), values.professionalTittle, values.professionalAppointment, values.professionalDescription, values.otherCertifications, values.maxHour, values.language);
        // }
        history.push('/login');
      }
    } catch (error) {
      console.log(error);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, revise el formulario nuevamente',
          variant: 'error',
        })
      );
    }
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose1 = (value) => {
    setAnchorEl1(null);
    if (value.name) {
      setValues({ ...values, roleName: value.name, role: value.id });
    } else {
      setValues({ ...values, roleName: '', role: '' });
    }
  };

  const handleClose2 = (item) => {
    setAnchorEl2(null);
    setValues({ ...values, country: item.nameCountry });
    dispatch(registerActions.getProvinceById(item.id));
  };

  const handleClose3 = (item) => {
    setAnchorEl3(null);
    setValues({ ...values, provice: item.nameProvince });

    dispatch(registerActions.getCityById(item.id));
  };

  const handleClose4 = (item) => {
    setValues({ ...values, city: item.nameCity, cityId: item.id });

    setAnchorEl4(null);
  };

  const handleClose5 = (value) => {
    if (value.name) {
      setValues({ ...values, genderName: value.name, gender: value.id });
    } else {
      setValues({ ...values, genderName: '', gender: '' });
    }

    setAnchorEl5(null);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleUploadClick = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();

    f.append('file', file);

    const url = await uploadImg('user', f);

    setValues({
      ...values,
      mainState: 'uploaded',
      selectedFile: url,
      imageUploaded: 1,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e, number) => {
    if (e.target.name === 'dni' || e.target.name === 'phoneNumber' || e.target.name === 'maxHour') {
      if (isNumber) {
        if (e.target.name === 'maxHour') {
          setValues({ ...values, [e.target.name]: e.target.value });
        } else {
          if (e.target.value.trim().length <= 10) {
            setValues({ ...values, [e.target.name]: e.target.value });
          }
        }
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  var isNumber = null;

  function valideKey(evt) {
    let code = evt.key;
    if (code === 'Backspace') {
      isNumber = true;
      return true;
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      isNumber = true;
      return true;
    } else {
      isNumber = false;
      return false;
    }
  }

  const handleChangeProfTitle = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setValues({ ...values, professionalTittle: url });
  };

  const handleChangeNomb = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setValues({ ...values, professionalAppointment: url });
  };

  return (
    <div>
      <Grid container justifyContent="center" alignItems="center">
        <div className={classes.regContent}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h3">Regístrate</Typography>
              <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" onChange={handleUploadClick} />
              <label htmlFor="contained-button-file">
                <Fab component="span" color="secondary" sx={{ width: '100px', height: '100px', border: '2px solid #272727' }}>
                  {values.selectedFile ? <Avatar src={values.selectedFile} sx={{ width: 100, height: 100 }} /> : <AddPhotoAlternateIcon />}
                </Fab>
              </label>
            </Grid>

            <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h5" align="left">
                Información personal
              </Typography>
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel>Usuario</InputLabel>
              <OutlinedInput
                label="Usuario"
                sx={{ mb: 2 }}
                value={values.roleName}
                onMouseDown={handleClick1}
                className={classes.textField}
                error={error.role}
                endAdornment={
                  <InputAdornment position="end">
                    <Fab onClick={handleClick1} color="primary" size="medium" edge="end">
                      {anchorEl1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Fab>
                  </InputAdornment>
                }
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                PaperProps={{
                  sx: { width: '30%' },
                }}
              >
                {roles.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => handleClose1({ name: item.name, id: item.value })}>
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <TextField
                // variant="outlined"
                type="text"
                placeholder="Nombre"
                label="Nombre"
                required
                name="fullName"
                value={values.fullName}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel>Número de Identificación</InputLabel>
              <OutlinedInput
                // variant="outlined"
                type="text"
                placeholder="Número de identificación"
                label="Número de Identificación"
                required
                name="dni"
                onKeyDown={(e) => valideKey(e)}
                value={values.dni}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel>Número de contacto</InputLabel>
              <OutlinedInput
                // variant="outlined"
                type="text"
                placeholder="Número de contacto"
                label="Número de contacto"
                required
                name="phoneNumber"
                onKeyDown={(e) => valideKey(e)}
                value={values.phoneNumber}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <TextField
                // variant="outlined"
                type="text"
                placeholder="E-mail"
                label="E-mail"
                required
                name="email"
                value={values.email}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                type={values.showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                label="Contraseña"
                name={'password'}
                value={values.password}
                error={error.password}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
                className={classes.textField}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {values.showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel>País</InputLabel>
              <OutlinedInput
                label="País"
                sx={{ mb: 2 }}
                value={values.country}
                className={classes.textField}
                onMouseDown={handleClick2}
                endAdornment={
                  <InputAdornment position="end">
                    <Fab onClick={handleClick2} color="primary" size="medium" edge="end">
                      {anchorEl2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Fab>
                  </InputAdornment>
                }
              />
              <Menu
                id="basic-role"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                PaperProps={{
                  sx: { width: '30%' },
                }}
              >
                {country?.map((item, index) => (
                  <MenuItem onClick={() => handleClose2(item)} key={index}>
                    {item.nameCountry}
                  </MenuItem>
                ))}
              </Menu>
            </FormControl>
            {province?.length > 0 && (
              <FormControl fullWidth variant="outlined">
                <InputLabel>Provincia</InputLabel>
                <OutlinedInput
                  label="Provincia"
                  sx={{ mb: 2 }}
                  value={values.provice}
                  className={classes.textField}
                  onMouseDown={handleClick3}
                  endAdornment={
                    <InputAdornment position="end">
                      <Fab onClick={handleClick3} color="primary" size="medium" edge="end">
                        {anchorEl3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Fab>
                    </InputAdornment>
                  }
                />
                <Menu
                  id="basic-role"
                  anchorEl={anchorEl3}
                  open={open3}
                  onClose={handleClose3}
                  PaperProps={{
                    sx: { width: '30%' },
                  }}
                >
                  {province?.map((item, index) => (
                    <MenuItem onClick={() => handleClose3(item)} key={index}>
                      {item?.nameProvince}
                    </MenuItem>
                  ))}
                </Menu>
              </FormControl>
            )}
            {city?.length > 0 && (
              <FormControl fullWidth variant="outlined">
                <InputLabel>Ciudad </InputLabel>
                <OutlinedInput
                  label="Ciudad "
                  sx={{ mb: 2 }}
                  error={error.cityId}
                  className={classes.textField}
                  onMouseDown={handleClick4}
                  value={values.city}
                  endAdornment={
                    <InputAdornment position="end">
                      <Fab onClick={handleClick4} color="primary" size="medium" edge="end">
                        {anchorEl4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Fab>
                    </InputAdornment>
                  }
                />
                <Menu
                  id="basic-role"
                  anchorEl={anchorEl4}
                  open={open4}
                  onClose={handleClose4}
                  PaperProps={{
                    sx: { width: '30%' },
                  }}
                >
                  {city?.map((item, index) => (
                    <MenuItem onClick={() => handleClose4(item)} key={index}>
                      {item?.nameCity}
                    </MenuItem>
                  ))}
                </Menu>
              </FormControl>
            )}
            <FormControl fullWidth variant="outlined">
              <InputLabel>Género </InputLabel>
              <OutlinedInput
                label="Género "
                sx={{ mb: 2 }}
                className={classes.textField}
                onMouseDown={handleClick5}
                value={values.genderName}
                endAdornment={
                  <InputAdornment position="end">
                    <Fab onClick={handleClick5} color="primary" edge="end" size="medium">
                      {anchorEl5 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Fab>
                  </InputAdornment>
                }
              />
              <Menu
                id="basic-role"
                anchorEl={anchorEl5}
                open={open5}
                onClose={handleClose5}
                PaperProps={{
                  sx: { width: '30%' },
                }}
              >
                {genders.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => handleClose5({ name: item.name, id: item.value })}>
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            </FormControl>

            {/* {values.role === 'teacher' && (
              <>
                <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
                  <Typography variant="h5" align="left">
                    Formación profesional
                  </Typography>
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <TextField
                    // variant="outlined"
                    type="text"
                    placeholder="Título o Especialización"
                    label="Título o Especialización"
                    required
                    name="specialty"
                    value={values.specialty}
                    sx={{ mb: 2 }}
                    className={classes.textField}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <InputLabel>Certificado Profesional</InputLabel>
                  <OutlinedInput
                    disabled
                    helpertext="Sube el documento en PDF"
                    label="Certificado Profesional"
                    sx={{ mb: 2 }}
                    name="professionalTittle"
                    value={values.professionalTittle}
                    className={classes.textField}
                    // onChange={(e) => {
                    //   handleChangeForm(e);
                    // }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Fab color="secondary" size="small" edge="end">
                          <label htmlFor="icon-button-file-title">
                            <input accept=".pdf" id="icon-button-file-title" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeProfTitle(e)} />
                            <IconButton color="primary" aria-label="upload picture prof title" component="span">
                              <img src={upload} alt="upload" />
                            </IconButton>
                          </label>
                        </Fab>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <InputLabel>Nombramiento</InputLabel>
                  <OutlinedInput
                    disabled
                    helpertext="Sube el documento en PDF"
                    label="Nombramiento"
                    sx={{ mb: 2 }}
                    name="professionalAppointment"
                    value={values.professionalAppointment}
                    className={classes.textField}
                    endAdornment={
                      <InputAdornment position="end">
                        <Fab color="secondary" size="small" edge="end">
                          <label htmlFor="icon-button-file-appoitment">
                            <input accept=".pdf" id="icon-button-file-appoitment" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeNomb(e)} />
                            <IconButton color="primary" aria-label="upload file prof appoit" component="span">
                              <img src={upload} alt="upload" />
                            </IconButton>
                          </label>
                        </Fab>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <TextField
                    // variant="outlined"
                    type="text"
                    placeholder="Idioma"
                    label="Idioma"
                    required
                    name="language"
                    value={values.language}
                    sx={{ mb: 2 }}
                    className={classes.textField}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <TextField
                    // variant="outlined"
                    type="text"
                    placeholder="Descripción Profesional"
                    label="Descripción Profesional"
                    required
                    rows={4}
                    multiline
                    name="professionalDescription"
                    value={values.professionalDescription}
                    sx={{ mb: 2 }}
                    className={classes.textField}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    // variant="outlined"
                    type="text"
                    placeholder="Otros certificados"
                    label="Otros certificados"
                    required
                    name="otherCertifications"
                    value={values.otherCertifications}
                    sx={{ mb: 2 }}
                    className={classes.textField}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                  <TextField
                    // variant="outlined"
                    type="text"
                    placeholder="Horas máximas de tutoría"
                    label="Horas máximas de tutoría"
                    required
                    name="maxHour"
                    value={values.maxHour}
                    sx={{ mb: 2 }}
                    className={classes.textField}
                    onKeyDown={(e) => valideKey(e)}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </FormControl>
              </>
            )} */}

            <Fab
              variant="extended"
              sx={{
                textTransform: 'capitalize',
                width: '100%',
                height: '70px',
                mb: 2,
              }}
              color="primary"
              onClick={onSubmit}
            >
              <Typography variant="body1"> Registrarse</Typography>
            </Fab>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

const useStyle = makeStyles({
  regContent: {
    minWidth: '90%',
    marginTop: '30px',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
      border: '1px solid black',
    },
  },
  input: { display: 'none' },
});
