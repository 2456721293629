import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { AdminProfessorInfo } from '../AdminProfessorPage/AdminProfessorInfo';
import { AdReviewerList } from './AdReviewerList';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '-30px' },
}));
export const AdminProfessorReviewPage = () => {
  const classes = useStyles();

  const { teacherSelected } = useSelector(({ adminReducers }) => adminReducers.teachersReducer);

  return (
    <div className={classes.root}>
      <Grid container columns={16}>
        <Grid item xs={6}>
          <AdminProfessorInfo name={teacherSelected.fullName} review={teacherSelected.starReview} subject={teacherSelected.levels} price={teacherSelected.price} img={teacherSelected.img} desc={teacherSelected.professionalDescription} status={teacherSelected.status} teacherTrainingId={teacherSelected.id} university={teacherSelected.university} role={teacherSelected.role} certifications={teacherSelected.otherCertifications} userId={teacherSelected.userId} />
        </Grid>
        <Grid item xs="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={9}>
          <AdReviewerList />
        </Grid>
      </Grid>
    </div>
  );
};
