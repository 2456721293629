import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { StdPayTeacherInfo } from './StdPayTeacherInfo';
import { StdPayContent } from './StdPayContent';
import { StdBankInfo } from './StdBankInfo';
import { useSelector } from 'react-redux';
import { useDataBank } from '../../../hooks/useDataBank';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentPaymentPage = () => {
  const classes = useStyles();

  const [dataBank, setDataBank] = useState([]);
  const { getDataBankAdmin } = useDataBank();

  const { teacherSelected } = useSelector(({ studentReducers }) => studentReducers.teachersReducer);
  const { showPaymentPart } = useSelector(({ studentReducers }) => studentReducers.uiPaymentReducer);

  useEffect(() => {
    dataBankAdmin();
  }, []);

  const dataBankAdmin = async () => {
    const resp = await getDataBankAdmin();
    const dataAdmin = resp.filter((item) => item.rol === 'admin');
    setDataBank(dataAdmin);
  };

  return (
    <div className={classes.root}>
      <Grid container columns={16} spacing={8} justifyContent="center" alignItems="center">
        <Grid item md={!showPaymentPart ? 11 : 4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <StdPayTeacherInfo img={teacherSelected.img} name={teacherSelected.fullName} speciality={teacherSelected.specialty} university={teacherSelected.university} maxHours={teacherSelected.maxHour} price={Number(teacherSelected.price)} />
        </Grid>
        {showPaymentPart && (
          <>
            <Grid sx={{ display: !showPaymentPart ? 'none' : 'flex' }} item md={!showPaymentPart ? 0 : 6}>
              <StdPayContent />
            </Grid>
            <Grid item md={1} container justifyContent="center">
              <Divider orientation="vertical" />
            </Grid>
            <Grid item md={4}>
              <StdBankInfo dataBank={dataBank} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
