import React from 'react';

// const imgRoute = "assets/centLogo.png";
// const logoLetter = "assets/logoLetter.svg";
import MainLogo from '../../assets/image/mainLogo.png';

const botBackGroundStyle = {
  display: 'flex',
  width: '464px',
  flexDirection: 'column',
};

export const LogoIlustroImg = () => {
  return (
    <div style={botBackGroundStyle}>
      <img src={MainLogo} width="100%" alt="ILUSTRO LOGO" />
      {/* <img src={imgRoute} width="100%" alt="ILUSTRO LOGO"></img> */}
      {/* <img src={logoLetter} width="100%" alt="ILUSTRO LETTER"></img> */}
    </div>
  );
};
