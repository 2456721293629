import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    border: '1px solid black',
    padding: '30px',
    marginTop: '30px',
  },
}));
export const StdPayDetailCard = ({ date, hour, total, hoursNumber }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>Fecha: {moment(date).format('DD/MM/YYYY')}</Typography>
      <Typography sx={{ mt: 1 }}>Hora: {hour /* {moment(`${hour}`).format('hh:mm')} */}</Typography>
      <Typography sx={{ my: 1 }}>Hora de clase : {hoursNumber}h </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography>Total :</Typography>
        <Typography>${total}</Typography>
      </Grid>
    </div>
  );
};
