import { types } from '../../../types/types';

const initialState = {
  showPaymentPart: false,
};

export const uiPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.showPaymentScreen:
      return {
        ...state,
        showPaymentPart: action.payload.showPaymentPart,
      };

    default:
      return state;
  }
};
