import React from "react";
import { makeStyles } from "@mui/styles";
import { BenefitItem } from "./BenefitItem";
import { Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: { padding: "40px 40px" },
}));
export const ServiceBenefit = ({
  month,
  year,
  total,
  percent,
  monthSelected,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography sx={{ my: 2 }}>Ganancia por servicios realizados</Typography>
      <BenefitItem
        month={month}
        year={year}
        total={total}
        percent={percent}
        monthSelected={monthSelected}
        // price={300}
        // date="Abril 2021"
        // benefit="12 Servicios Realizados"
      />
    </div>
  );
};
