import React from 'react';
import { AgendaAvailabilityScreen } from './AgendaAvailabilityScreen';
import { AgendaScreen } from './AgendaScreen';
import { HistoryScreen } from './HistoryScreen';
import { LevelScreen } from './LevelScreen';
import { ProfessorProfileScreen } from './ProfessorProfileScreen';
import { WalletScreen } from './WalletScreen';
import { Switch, Route, Redirect } from 'react-router-dom';
import { HomeScreen } from './HomeScreen';
import { StudentChatScreen } from '../StudentScreen/StudentChatScreen';

export const ProfessorScreen = () => {
  return (
    <>
      <Switch>
        <Route path="/teacher/home" component={HomeScreen} />
        <Route path="/teacher/agenda" component={AgendaScreen} />
        <Route path="/teacher/history" component={HistoryScreen} />
        <Route path="/teacher/professor-profile" component={ProfessorProfileScreen} />
        <Route path="/teacher/level" component={LevelScreen} />
        <Route path="/teacher/wallet" component={WalletScreen} />
        <Route path="/teacher/availability" component={AgendaAvailabilityScreen} />
        <Route path="/teacher/chat" component={StudentChatScreen} />
        <Redirect from="/teacher" to="teacher/home" />
      </Switch>
    </>
  );
};
