import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiUrl } from '../config/apiConfig';
import * as fuseActions from '../store/actions/fuse';

export const useSchedule = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const getScheduleByUserId = async (userId) => {
    const resp = await axios.get(`${apiUrl}schedules/byuserid/${userId}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        setIsLoading(false);
        return resp.data.scheduleDB;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const getScheduleByDateandUserId = async (data) => {
    const resp = await axios.post(`${apiUrl}schedules/getScheduleByDateandUserId`, data);
    if (resp.status === 200) {
      if (resp.data.msg) {
        setIsLoading(false);
        return resp.data.Dates;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  return { getScheduleByUserId, getScheduleByDateandUserId, isLoading };
};
