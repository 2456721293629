import { makeStyles } from '@mui/styles';
import React from 'react';
import { BotBackground } from '../../components/common/BotBackground';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { StudentHistoryPage } from '../../components/StudentPage/StudentHistoryPage';

export const StudentHistoryScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Historial" />
      <StudentHistoryPage />
      <BotBackground />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
