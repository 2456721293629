import axios from 'axios';
import { useState } from 'react';
import { apiUrl } from '../config/apiConfig';

export const useChat = () => {
  const [isLoading, setIsLoading] = useState(true);

  const getChatList = async (userId, rol) => {
    const resp = await axios.post(`${apiUrl}listchat`, { userId, rol });
    setIsLoading(false);
    return resp.data.user;
  };

  const postChannel = async (channel) => {
    console.log({ channel });
    const resp = await axios.post(`${apiUrl}chat`, { channel });
    // const resp = await ilustroApi.post<ChannelResponse>(`/chat`,{channel});
    return resp.data.ChatNew;
  };

  const uploadListChats = async (channelArray) => {
    const resp = await axios.post(`${apiUrl}chat/chatlist`, {
      array: channelArray,
    });
    return resp.data.msg;
  };

  return { getChatList, postChannel, uploadListChats, isLoading };
};
