import React from "react";
import { makeStyles } from "@mui/styles";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { BotBackground } from "../../components/common/BotBackground";
import { AdminParamsPage } from "../../components/AdminPage/AdminParamsPage";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminParams = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Parámetros por Nivel" />
      <AdminParamsPage />
      <BotBackground logined />
    </div>
  );
};
