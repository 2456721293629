import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { UserState } from '../../common/UserState';
import empty_state from '../../../assets/image/empty_state.svg';
import { Loading } from '../../common/Loading';
import { RoleList } from './RoleList';
import { useOrders } from '../../../hooks/useOrders';
const useStyle = makeStyles({
  root: {
    margin: '20px 40px',
  },
  emptyState: {
    width: 350,
  },
});
export const AdminHomePage = () => {
  const classes = useStyle();
  const { invoices, loading, updateClass, updateHomework } = useOrders();

  // console.log(invoices);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={8}>
          {loading ? (
            <Loading titleLoading="Cargando solicitudes" />
          ) : invoices.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box>
                <img className={classes.emptyState} src={empty_state} alt="Imagen de no hay solicitudes"></img>
              </Box>
              <Box> </Box>
              <Box>
                <Typography variant="h3">No hay solicitudes pendientes</Typography>
              </Box>
            </Box>
          ) : (
            invoices.map((invoice) => {
              return (
                <div key={invoice.invoiceId}>
                  {invoice.requestType === 'class' ? (
                    <div>
                      <UserState hoursNumber={invoice.hoursNumber} price={invoice.total} name={invoice.nameStudent} date={invoice.createdAt} voucher={invoice.imageVoucherUrl} avatar={invoice.img} requestId={invoice.requestId} updateClass={updateClass} admin />
                      <Divider orientation="horizontal" flexItem sx={{ border: '1px solid #A5A5A5', mt: 4, mb: 4 }} />
                    </div>
                  ) : (
                    <div>
                      <UserState name={invoice.student} date={invoice.date} voucher={invoice.imageVoucherUrl} avatar={invoice.img} requestId={invoice.requestId} updateClass={updateHomework} admin />
                      <Divider orientation="horizontal" flexItem sx={{ border: '1px solid #A5A5A5', mt: 4, mb: 4 }} />
                    </div>
                  )}
                </div>
              );
            })
          )}
        </Grid>
        <Grid item md={1} container direction="row" justifyContent="center">
          <Divider orientation="vertical" flexItem sx={{ border: '1px solid #A5A5A5', mb: -8 }} />
        </Grid>
        <Grid item md={3}>
          {/* <HomeTool /> */}
          <RoleList />
        </Grid>
      </Grid>
    </div>
  );
};
