import React from 'react';
import { makeStyles } from '@mui/styles';
import { StdBankInfoItem } from './StdBankInfoItem';
import { EmptyState } from '../../../common/EmptyState';
const useStyles = makeStyles((theme) => ({
  root: { padding: '10px 0px 0px 30px' },
}));
export const StdBankInfo = ({ dataBank = [] }) => {
  const classes = useStyles();
  return <div className={classes.root}>{dataBank.length > 0 ? dataBank.map((item, idx) => <StdBankInfoItem key={idx} nameBank={item.nameDatabank} typeAccount={item.accountType} namePerson={item.ownerBank} numberAccount={item.accountNumber} email={item.email} />) : <EmptyState title="No existe cuentas bancarias" />}</div>;
};
