import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const CardChatSelected = ({ name, img, speciality, university }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.containerAvatar}>
          <Avatar src={img} sx={{ width: 60, height: 60 }} alt="avatar" />
        </div>
        <div className={classes.infoUser}>
          <Typography variant="h6">{name}</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#6C6C6C' }}>
            {speciality} {university && `- ${university}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F4F7F5',
    padding: 10,
    borderRadius: 20,
    position: 'relative',
    marginBottom: 30,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  containerAvatar: {
    marginLeft: 10,
    marginRight: 10,
  },
  infoUser: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: 10,
  },
}));
