import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperSchedules = {};

helperSchedules.postSchedule = async (data) => {
  const res = await axios.post(`${apiUrl}schedules`, data);
  // console.log("res data -->", res.data);
  // return res.data;
};

helperSchedules.deleteSchedule = async (id) => {
  const res = await axios.delete(`${apiUrl}schedules/${id}`);
  // console.log("res data -->", res.data);
  // return res.data;
};

helperSchedules.getSchedulesByUserId = async (id) => {
  const res = await axios.get(`${apiUrl}schedules/byuserid/${id}`);
  // console.log("res get level by id -->", res.data.levels);
  return res.data.scheduleDB;
};
