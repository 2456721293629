import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useChat } from '../../../hooks/useChat';
import { ChatMessages } from '../../common/chat/ChatMessages';
import { PeopleActiveChatList } from '../../common/chat/PeopleActiveChatList';

export const StudentChatPage = () => {
  const classes = useStyles();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const [chatList, setChatList] = useState([]);
  const [chatSearch, setChatSearch] = useState([]);
  // (chatSearch.length > 0 ? chatSearch : chatList)

  const { getChatList, isLoading } = useChat();

  useEffect(() => {
    validatedUserRolAuth();
  }, []);

  const validatedUserRolAuth = async () => {
    switch (user.rol) {
      case 'student':
        const respStudent = await getChatList(user.id, 'teacher');
        const respStudent2 = await getChatList(user.id, 'admin');
        setChatList([...respStudent, ...respStudent2]);
        break;
      case 'admin':
        const respAdmin = await getChatList(user.id, 'teacher');
        const respAdmin2 = await getChatList(user.id, 'student');
        setChatList([...respAdmin, ...respAdmin2]);
        break;
      case 'teacher':
        const respTeacher = await getChatList(user.id, 'student');
        const respTeacher2 = await getChatList(user.id, 'admin');
        setChatList([...respTeacher, ...respTeacher2]);
        break;

      default:
        break;
    }
  };

  const filterChatListByName = (name) => {
    setChatSearch(chatList.filter((chat) => chat.fullName.toLowerCase().indexOf(name.toLowerCase()) > -1));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={4}>
          <PeopleActiveChatList chatlist={chatSearch.length > 0 ? chatSearch : chatList} isLoading={isLoading} funcFilter={filterChatListByName} />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item md={7}>
          <ChatMessages />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh', marginRight: 20, marginLeft: 20 },
}));
