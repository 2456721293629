import { Grid, Divider, FormControl, Select, MenuItem } from "@mui/material";
import React from "react";
import { LevelContent } from "./LevelContent";
import { LevelDescription } from "./LevelDescription";
import { LevelSort } from "./LevelSort";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    positioin: "relative",
  },
  sort: {
    width: 200,
    height: 50,
    position: "absolute",
    top: 380,
    right: 80,
  },
  sortSelect: {
    ".MuiSelect-Outlined-root": {
      borderRadius: "10px",
    },
  },
}));
export const LevelPage = ({
  allLevels,
  level,
  setDefaultLevel,
  setLevel,
  defaultLevel,
  teacherActive,
}) => {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [defaultLevelId, setDefaultLevelId] = React.useState(1);

  return (
    <div className={classes.root}>
      <div className={classes.sort}>
        {/* <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            sx={{
              borderRadius: 6,
              background: "#F4F7F5",
              border: "none",
              height: 50,
              transform: "translateY(-7rem)",
            }}
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      <Grid container>
        <Grid item md={3}>
          <LevelSort
            allLevels={allLevels}
            defaultLevel={defaultLevel}
            setDefaultLevel={setDefaultLevel}
            teacherActive={teacherActive}
            setDefaultLevelId={setDefaultLevelId}
          />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={6}>
          <LevelDescription
            level={level}
            defaultLevel={defaultLevel}
            setDefaultLevel={setDefaultLevel}
            teacherActive={teacherActive}
            defaultLevelId={defaultLevelId}
          />
        </Grid>
        <Grid item>
          <LevelContent defaultLevelId={defaultLevelId} />
        </Grid>
      </Grid>
    </div>
  );
};
