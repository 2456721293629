import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Badge, Dialog, Fab, Grid, IconButton, Typography } from '@mui/material';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ConfirmDialog } from './ConfirmDialog';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { saveAs } from 'file-saver';
import AlertDialog from './AlertDialog';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as homeActions from '../HomePage/store/actions/homedata.action';

// import { helperReqClass } from "../../services/helperReqClass";
import { helperReqHomeworks } from '../../services/helperReqHomeworks';
import { useOffer } from '../../hooks/useOffer';

const useStyle = makeStyles({
  root: {
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: '8px',
    top: '0px',
  },
  iconButtonClose: {},
});

export const UserState = ({ avatar, voucher, requestId, updateClass, name, purchase, admin, date, hoursNumber, price, nameSubject, nameLevel, detailHomework, reqClassId, schedule, classReq, fileUrl, timeHomework, requestHomeworkId }) => {
  const [open, setOpen] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openAlertReject, setOpenAlertReject] = useState(false);

  const { postOffer, isLoading } = useOffer();

  const dispatch = useDispatch();
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const teacherTraining = useSelector(({ homeReducer }) => homeReducer.homeDataTeacherTraining.requestTeacherTraining);

  const classes = useStyle();

  const handleOpenAlertOffer = () => {
    setOpen(true);
    // setOpenAlert(true);
  };

  const handleOpenAlertReject = () => {
    setOpenAlertReject(true);
  };

  const saveImage = () => {
    saveAs(voucher, 'voucher.jpg');
  };

  const handleClickAcceptReqClass = async () => {
    setOpenAlertConfirm(true);
  };

  const updateReqHomework = async (offer) => {
    // console.log(offer);
    // const resp = await helperReqHomeworks.putHomework(requestHomeworkId, data);
    // const data = { stateRequestHomework: stateHomework };
    const resp = await postOffer(offer.priceOffer, requestHomeworkId, 'sending', user.id, offer.timeOffer);
    console.log(resp);
    dispatch(homeActions.getHomeworksPendingByTeacherTrainingId(teacherTraining.id));
  };

  const saveHomework = () => {
    if (fileUrl) {
      saveAs(fileUrl);
    } else {
      alert('Documento vacío');
    }
  };

  const StatusTimeBadge = () => {
    return (
      <div style={{ height: '58px', borderRadius: '16px', background: '#EDEDED', display: 'flex', alignItems: 'center', padding: '8px' }}>
        <QueryBuilderIcon sx={{ color: '#A5A5A5', mr: 2 }} fontSize="large" />
        <Typography sx={{ color: '#A5A5A5', fontSize: 16 }}>Tiempo de entrega {timeHomework} horas</Typography>
      </div>
    );
  };

  const StatusDownBadge = () => {
    return (
      <div style={{ height: '58px', borderRadius: '16px', background: '#EDEDED', display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '8px' }}>
        <Typography sx={{ color: '#A5A5A5', fontSize: 16, ml: 2 }}>Descarga la tarea aquí</Typography>
        <IconButton sx={{ background: '#FEB098' }} onClick={saveHomework}>
          <ArrowDownwardIcon sx={{}} fontSize="large" />
        </IconButton>
      </div>
    );
  };

  const UserStateBox = (hoursNumber) => {
    return (
      <Box sx={{ borderRadius: 2, bgcolor: '#f3f3f3', padding: '1rem .3rem', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', gap: '1rem' }}>
        <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around', alignItems: 'center', flex: 1 }}>
              <img src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png" alt="calendar" height={20} width={20} />
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>{`${moment(date).format('DD/MM')}`}</Typography>
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
              <img src="https://img.icons8.com/material-outlined/18/000000/clock.png" alt="clock" height={20} width={20} />
              <Typography sx={{ fontSize: '.9rem', fontWeight: 600 }}>{`${moment(date).format('LT')}`}</Typography>
            </div>
          </div>
        </div>

        <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Typography sx={{ fontSize: 15, fontWeight: 600, color: '#7E7E7E' }}>{hoursNumber} horas</Typography>
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
              <a href={voucher} target="_blank" rel="noreferrer">
                <IconButton sx={{ bgcolor: 'success.main', mx: 2 }}>
                  <VisibilityIcon sx={{}} />
                </IconButton>
              </a>
              <IconButton sx={{ bgcolor: 'warning.secondary' }} onClick={saveImage}>
                <ArrowDownwardIcon sx={{}} />
              </IconButton>
            </div>
          </div>
        </div>
        <AlertDialog
          open={openAlertConfirm}
          setOpen={setOpenAlertConfirm}
          funct={() => {
            updateClass(requestId, 'accepted');
          }}
          textAlert={`Está seguro de aceptar la solicitud de ${name}`}
        />
        <AlertDialog
          open={openAlertReject}
          setOpen={setOpenAlertReject}
          funct={() => {
            updateClass(requestId, 'rejected');
          }}
          textAlert={`Está seguro de rechazar la solicitud de ${name}`}
        />
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12}>
          <Grid container flexDirection="row" justifyContent="flex-end">
            {!purchase && (
              <IconButton onClick={() => handleOpenAlertReject()}>
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item md={3} container>
          <Badge overlap="circular" badgeContent=" " /* color="success" */>
            <Avatar src={avatar} sx={{ width: 146, height: 146 }} />
          </Badge>
        </Grid>
        <Grid item md={6} container direction="column" justifyContent="center" sx={{ transform: 'translateX(-2rem)' }}>
          {!admin ? (
            <>
              <Typography variant="h5" sx={{ display: 'block' }}>
                {name}
              </Typography>
              <br />
              {purchase ? (
                <div>
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    <span style={{ color: '#0AD78D', fontSize: 18 }}>{classReq !== 0 && 'Tutoría'}</span>
                    &nbsp;&nbsp;
                    {classReq !== 0 && '|'}
                    &nbsp;&nbsp;
                    <img src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png" alt="cc" />
                    &nbsp;&nbsp;
                    {moment(date).format('DD/MM/YYYY')}
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    {schedule}
                    &nbsp;&nbsp;
                  </Typography>
                  <br />
                  <Typography sx={{ display: 'block', color: '#A5A5A5' }}>
                    {nameLevel} - {nameSubject}
                  </Typography>
                  <br />
                  <Typography>Detalle: {detailHomework}</Typography>
                  <Box sx={{ display: 'flex', gap: '1rem', padding: '0' }}>
                    {StatusTimeBadge()}
                    {StatusDownBadge()}
                  </Box>
                </div>
              ) : (
                <div>
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    <Box sx={{ display: 'flex' }}>
                      <div style={{ display: 'flex', flex: '1' }}>
                        <div>
                          <img src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png" alt="gg" />
                        </div>
                        <div>
                          &nbsp;&nbsp;
                          {moment(date).format('DD/MM/YYYY')}
                          &nbsp;&nbsp;
                        </div>
                      </div>
                      <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                        <div>
                          <img src="https://img.icons8.com/material-outlined/18/000000/clock.png" alt="kk" />
                        </div>
                        <div>
                          &nbsp;&nbsp;
                          {schedule}
                          &nbsp;&nbsp;| &nbsp;&nbsp;
                          <span style={{ color: '#0AD78D' }}>${price}</span>
                        </div>
                      </div>
                    </Box>
                  </Typography>
                  <br />
                  <Typography sx={{ display: 'block', color: '#A5A5A5' }}>
                    {nameLevel} - {nameSubject}
                  </Typography>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <Typography variant="body1" sx={{ display: 'block' }}>
                {name}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ color: '#0AD78D' }}>Transferencia ${price}</span>
              </Typography>
              {UserStateBox(hoursNumber)}
            </div>
          )}
        </Grid>
        <Grid item md={3} container alignItems="flex-end">
          <Fab
            color="primary"
            variant="extented"
            sx={{
              width: 200,
              height: 70,
              borderRadius: 30,
              textTransform: 'capitalize',
            }}
            onClick={purchase ? handleOpenAlertOffer : handleClickAcceptReqClass}
          >
            {purchase ? 'Enviar Oferta' : 'Confirmar'}
          </Fab>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          style: {
            borderRadius: '20px',
          },
        }}
      >
        <ConfirmDialog handleClose={() => setOpen(false)} func={updateReqHomework} />
      </Dialog>
      <AlertDialog open={openAlertConfirm} setOpen={setOpenAlertConfirm} funct={() => updateClass(requestId, admin ? 'completed' : 'accepted')} textAlert={`Está seguro de aceptar la solicitud de ${name}`} />
      <AlertDialog open={openAlertReject} setOpen={setOpenAlertReject} funct={() => (updateClass ? updateClass(requestId, 'rejected') : updateReqHomework('rejected'))} textAlert={`Está seguro de rechazar la solicitud de ${name}`} />
    </div>
  );
};
