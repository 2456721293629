import axios from 'axios';
import moment from 'moment';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const setDateRequestClass = (date) => {
  return {
    type: types.setDateRequestClass,
    payload: { date: moment(date).format('YYYY-MM-DD hh:mm:ss'), schedule: moment(date).format('hh:mm') },
  };
};

export const setManualDateRequestClass = (date, hour) => {
  return {
    type: types.setDateRequestClass,
    payload: { date: moment(`${date} ${hour}`).format('YYYY-MM-DD hh:mm:ss'), schedule: moment(`${date} ${hour}`).format('hh:mm') },
  };
};

export const setPriceRequestClass = (price) => {
  const p = Number(price);
  return {
    type: types.setPriceRequestClass,
    payload: { priceTotal: p },
  };
};

export const setHoursNumberRequestClass = (hoursNumber) => {
  const h = Number(hoursNumber);
  return {
    type: types.setHoursNumberIdRequestClass,
    payload: { hoursNumber: h },
  };
};

export const calculatePrice = (price, hoursNumber) => {
  const h = Number(hoursNumber);
  const p = Number(price);
  const total = h * p;
  return {
    type: types.calculatePrice,
    payload: { hoursNumber: h, priceTotal: total },
  };
};

export const setIdRequestClass = (idRequestClass) => {
  return {
    type: types.setIdRequestClass,
    payload: { idRequestClass },
  };
};

const getRequestClasses = async (idRequest) => {
  const resp = await axios.get(`${apiUrl}requestClasses/${idRequest}`);
  console.log(resp);
  if (resp.status === 200) {
    if (resp.data.msg) {
      return {
        idRequestClass: resp.data.RequestClasses.id,
        date: resp.data.RequestClasses.date,
        hoursNumber: resp.data.RequestClasses.hoursNumber,
        priceTotal: resp.data.RequestClasses.price,
        schedule: resp.data.RequestClasses.schedule,
        stateRequestClass: resp.data.RequestClasses.stateRequestClass,
        studentId: resp.data.RequestClasses.studentId,
        userCourseId: resp.data.RequestClasses.userCourseId,
      };
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const setDataRequestClass = (requestClasses) => {
  return {
    type: types.setAllRequestClass,
    payload: { requestClasses },
  };
};

export const getRequestClassById = (idRequest) => {
  return async (dispatch) => {
    const request = await getRequestClasses(idRequest);
    dispatch(setDataRequestClass(request));
  };
};
export const getRequestClass = (id, date, hoursNumber, price, schedule, stateRequestClass, studentId, userCourseId) => {
  const requestClasses = {
    idRequestClass: id,
    date: date,
    hoursNumber: hoursNumber,
    priceTotal: price,
    schedule: schedule,
    stateRequestClass: stateRequestClass,
    studentId: studentId,
    userCourseId: userCourseId,
  };

  return {
    type: types.setAllRequestClass,
    payload: { requestClasses },
  };
};

export const setRequestType = (requestType) => {
  return {
    type: types.setRequestType,
    payload: { requestType },
  };
};

export const setLevelId = (levelId, offertId) => {
  return {
    type: types.setLevelId,
    payload: { levelId, offertId },
  };
};
export const setInitStateResume = () => {
  console.log('-----')
  return {
    type: types.setInitStateResume,
  };
};
