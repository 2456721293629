import React from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@mui/material";
import { AvailabilityList } from "./AvailabilityList";
import { AvailabilityDesc } from "./AvailabilityDesc";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AgendaAvailabilityPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        columns={16}
        flexitems="true"
      >
        <Grid item xs={11} flexitem="true">
          <AvailabilityList />
        </Grid>
        <Grid item xs={1} flexitem="true" justifyContent="center" container>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3} flexitem="true">
          <AvailabilityDesc />
        </Grid>
      </Grid>
    </div>
  );
};
