import { types } from '../../../types/types';

const initialState = {
  idLevel: 1,
  nameLavel: '',
  schools: [],
};

export const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getSchools:
      return {
        idLevel: action.payload.id,
        nameLavel: action.payload.nameLevel,
        schools: action.payload.schools,
      };

    case types.changeNameLevel:
      return {
        ...state,
        nameLavel: action.payload.nameLevel,
      };

    default:
      return state;
  }
};
