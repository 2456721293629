import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiUrl } from '../config/apiConfig';
import * as fuseActions from '../store/actions/fuse';

export const useInvoices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const addInvoicesBackEnd = async (requestId, payMethodId, requestType, total, imageVoucherUrl) => {
    const resp = await axios.post(`${apiUrl}invoices`, {
      requestId: requestId,
      payMethodId: payMethodId,
      requestType: requestType,
      total: total,
      imageVoucherUrl: imageVoucherUrl,
    });
    if (resp.status === 200) {
      if (resp.data.msg) {
        setIsLoading(false);
        dispatch(
          fuseActions.showMessage({
            message: 'Pago Confirmado',
            variant: 'success',
          })
        );
        return resp.data.invoices;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
  };

  const putRequestClass = async (id, stateRequestClass) => {
    const resp = await axios.put(`${apiUrl}requestClasses/${id}`, { stateRequestClass });
    return resp.data.RequestClassDB;
  };

  const getRequestClassesById = async (id) => {
    const resp = await axios.get(`${apiUrl}requestClasses/${id}`);
    setIsLoading(false);
    return resp.data.RequestClasses;
  };

  const getInvoicesById = async (id) => {
    const resp = await axios.get(`${apiUrl}invoices/${id}`);
    setIsLoading(false);
    return resp.data.invoices;
  };

  const getInvoiceByRequestAndType = async (idRequest, type) => {
    const resp = await axios.get(`${apiUrl}view/getinvoicebyrequestandtype/${idRequest}/${type}`);

    return resp.data.Invoice[0].invoiceId;
  };

  return { addInvoicesBackEnd, putRequestClass, getRequestClassesById, getInvoicesById, getInvoiceByRequestAndType, isLoading };
};
