import { makeStyles } from '@mui/styles';
import React from 'react';
import { BotBackground } from '../../components/common/BotBackground';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { StudentRateTeacherPage } from '../../components/StudentPage/StudentRateTeacherPage';

export const StudentRateTeacherScreen = () => {
  const classes = useStyles();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Calificar al tutor" />
      <StudentRateTeacherPage img="" name="Nicolas Acosta" speciality="Ingenieria en sistemas" university="Universidad tecnica" func={onSubmit} />
      <BotBackground logined />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
