import React from "react";
import { Fab, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export const LoginButton = () => {
  return (
    <Link to={`/login`}>
      <Fab
        variant="extended"
        color="secondary"
        sx={{
          width: "464px",
          height: "80px",
          borderRadius: "30px",
          textTransform: "capitalize",
          border: "1px black solid",
          mt: 2,
        }}
     
      >
        <Typography variant="body1"> Iniciar Sesión</Typography>
      </Fab>
    </Link>
  );
};
