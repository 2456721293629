import React, { useEffect, useState } from 'react';
import { Avatar, createTheme, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, ThemeProvider, Input, Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import user3 from '../../../assets/image/user3.png';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { uploadImg } from '../../../services/partials/uploadImg';
import { helperLocation } from '../../../services/helperLocation';
import { helperUser } from '../../../services/helperUser';
import * as homeActions from '../../HomePage/store/actions';
import * as authActions from '../../../components/LoginPage/store/actions';
import * as fuseActions from '../../../store/actions/fuse';
import { useSelector, useDispatch } from 'react-redux';
import { ProfessorDataBank } from "../ProfessorDataBank";
import { TabContext, TabList, TabPanel } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 100px',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '20px',
      border: '1px solid black',
    },
  },
}));

const themeDateFieldTxt = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          border: 'none',
          outline: 'none',
          // backgroundColor: "#f9f",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: 'none',
          outline: 'none',
          // backgroundColor: "#f99",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderRadius: '20px',
            // +          // the result will contain the prefix.
            // +          [`& .${outlinedInputClasses.notchedOutline}`]: {
            //              borderWidth: 1,
          },
          '&.Mui-focused': {
            borderWidth: 1,
            borderColor: '#f00',
          },
        },
        input: {
          borderColor: '#f00',
        },
      },
    },
  },
});

const initialData = {
  img: user3,
  email: '',
  fullName: '',
  rol: '',
  dni: '',
  phoneNumber: '',
  cityId: 0,
  password: '',
};

export const ProfessorInfo = () => {
  const [initialUser, setInitialUser] = useState(initialData);
  const [profileImgUrl, setProfileImgUrl] = useState(null);
  const [cityId, setCityId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [countryId, setCountryId] = useState(0);

  const dispatch = useDispatch();
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  useEffect(() => {
    // console.log(user);
    if (user) {
      setInitialUser({
        ...initialUser,
        img: user.img,
        email: user.email,
        fullName: user.fullName,
        rol: user.rol,
        dni: user.dni,
        phoneNumber: user.phoneNumber,
        cityId: user.cityId,
        password: '',
      });
    }
  }, [user]);

  const allCities = useSelector(({ homeReducer }) => homeReducer.homeDataAllCities.reqAllCities);

  const allProvinces = useSelector(({ homeReducer }) => homeReducer.homeDataAllProvinces.reqAllProvinces);
  const allCountries = useSelector(({ homeReducer }) => homeReducer.homeDataAllCountries.reqAllCountries);

  const classes = useStyles();

  useEffect(() => {
    dispatch(homeActions.getAllCities());
    dispatch(homeActions.getAllProvinces());
    dispatch(homeActions.getAllCountries());
  }, []);

  useEffect(() => {
    aux();
  }, []);

  const aux = async () => {
    // console.log(user);
    const city = await helperLocation.getCityById(user.cityId);
    if (city) {
      const response = await helperLocation.getAllLocation(user.cityId);
      setCityId(response.cityId);
      setProvinceId(response.provinceId);
      setCountryId(response.countryId);
    }
  };

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChangeCountry = (e) => {
    // console.log(e.target);
    setCountryId(e.target.value);
    setProvinceId(null);
    setCityId(null);
  };
  const handleOnChangeProvince = (e) => {
    // console.log(e.target);
    setProvinceId(e.target.value);
  };
  const handleOnChangeCity = (e) => {
    // console.log(e.target);
    setCityId(e.target.value);
    setInitialUser({ ...initialUser, cityId: e.target.value });
  };

  const handleChangeProfilePhoto = async (e) => {
    const file = e.target.files[0];
    const f = new FormData();
    f.append('file', file);
    const url = await uploadImg('user', f);
    setProfileImgUrl(url);
    setInitialUser({ ...initialUser, img: url });
  };

  var isNumber = null;

  function valideKey(evt) {
    let code = evt.key;
    if (code === 'Backspace') {
      isNumber = true;
      return true;
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      isNumber = true;
      return true;
    } else {
      isNumber = false;
      return false;
    }
  }

  const handleChangeForm = (e) => {
    if (e.target.name === 'dni' || e.target.name === 'phoneNumber') {
      if (e.target.value.trim().length <= 10) {
        if (isNumber) {
          setInitialUser({
            ...initialUser,
            [e.target.name]: e.target.value.trim(),
          });
        }
      }
    } else {
      setInitialUser({ ...initialUser, [e.target.name]: e.target.value });
    }
  };

  const handleClickSave = async (e) => {
    const update = async () => {
      const updatedUser = await helperUser.updateUserById(user.id, initialUser);
      sessionStorage.setItem('userinfo', JSON.stringify(updatedUser));
      dispatch(authActions.setUser(updatedUser));
      if (updatedUser) {
        dispatch(
          fuseActions.showMessage({
            message: '¡Datos guardados con éxito!',
            variant: 'success',
          })
        );
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Ocurrió un error al guardar los datos - ¡Check it out!',
            variant: 'error',
          })
        );
      }
    };

    if (initialUser.rol === 'teacher') {
      if (initialUser.img.trim().length > 0) {
        if (initialUser.email.trim().length > 0) {
          if (initialUser.fullName.trim().length > 0) {
            if (initialUser.rol.trim().length > 0) {
              if (initialUser.dni.trim().length > 0) {
                if (initialUser.phoneNumber.trim().length > 0) {
                  if (initialUser.cityId.toString().trim().length > 0) {
                    if (initialUser.password.trim().length > 0) {
                      update();
                    } else {
                      dispatch(
                        fuseActions.showMessage({
                          message: 'Ingrese o cree una nueva contraseña',
                          variant: 'error',
                        })
                      );
                    }
                  } else {
                    dispatch(
                      fuseActions.showMessage({
                        message: 'Seleccione una ciudad',
                        variant: 'error',
                      })
                    );
                  }
                } else {
                  dispatch(
                    fuseActions.showMessage({
                      message: 'Ingrese su número de teléfono',
                      variant: 'error',
                    })
                  );
                }
              } else {
                dispatch(
                  fuseActions.showMessage({
                    message: 'Ingrese su número de cédula',
                    variant: 'error',
                  })
                );
              }
            } else {
              dispatch(
                fuseActions.showMessage({
                  message: 'Necesita un rol',
                  variant: 'error',
                })
              );
            }
          } else {
            dispatch(
              fuseActions.showMessage({
                message: 'Ingrese su nombre completo',
                variant: 'error',
              })
            );
          }
        } else {
          dispatch(
            fuseActions.showMessage({
              message: 'Ingrese su dirección de correo electrónico',
              variant: 'error',
            })
          );
        }
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Aún no ha seleccionado ninguna imagen',
            variant: 'error',
          })
        );
      }
    }
    if (initialUser.rol === 'admin' || initialUser.rol === 'student') {
      delete initialUser.password;
      if (initialUser.img.trim().length > 0) {
        if (initialUser.email.trim().length > 0) {
          if (initialUser.fullName.trim().length > 0) {
            if (initialUser.rol.trim().length > 0) {
              if (initialUser.dni.trim().length > 0) {
                if (initialUser.phoneNumber.trim().length > 0) {
                  if (initialUser.cityId.toString().trim().length > 0) {
                    update();
                  } else {
                    dispatch(
                      fuseActions.showMessage({
                        message: 'Seleccione una ciudad',
                        variant: 'error',
                      })
                    );
                  }
                } else {
                  dispatch(
                    fuseActions.showMessage({
                      message: 'Ingrese su número de teléfono',
                      variant: 'error',
                    })
                  );
                }
              } else {
                dispatch(
                  fuseActions.showMessage({
                    message: 'Ingrese su número de cédula',
                    variant: 'error',
                  })
                );
              }
            } else {
              dispatch(
                fuseActions.showMessage({
                  message: 'Necesita un rol',
                  variant: 'error',
                })
              );
            }
          } else {
            dispatch(
              fuseActions.showMessage({
                message: 'Ingrese su nombre completo',
                variant: 'error',
              })
            );
          }
        } else {
          dispatch(
            fuseActions.showMessage({
              message: 'Ingrese su dirección de correo electrónico',
              variant: 'error',
            })
          );
        }
      } else {
        dispatch(
          fuseActions.showMessage({
            message: 'Aún no ha seleccionado ninguna imagen',
            variant: 'error',
          })
        );
      }
    }
  };

  const GeneralProfile = () => {
    return (
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <label htmlFor="icon-button-file">
            <input accept=".jpg" id="icon-button-file" type="file" style={{ display: 'none' }} onChange={(e) => handleChangeProfilePhoto(e)} />
            <IconButton color="primary" aria-label="upload picture" component="span">
              <Avatar
                src={profileImgUrl ? profileImgUrl : initialUser.img}
                sx={{
                  width: 176,
                  height: 176,
                  border: '8px solid #95F8D4',
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          </label>
        </Grid>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel>Usuario</InputLabel>
              <OutlinedInput type="text" placeholder="Usuario" disabled={true} label="Nombre" name="rol" value={initialUser.rol === 'teacher' ? 'Profesor' : initialUser.rol === 'admin' ? 'Administrador' : initialUser.rol === 'student' ? 'Estudiante' : 'Default'} sx={{ mb: 2 }} className={classes.textField} />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel>País</InputLabel>
              <Select
                label="País"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="nameCountry"
                value={countryId}
                onChange={(e) => handleOnChangeCountry(e)}
                sx={{
                  borderRadius: 6,
                }}
                // onChange={handleChange}
              >
                {allCountries
                  ? allCountries.length > 0 &&
                    allCountries.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.nameCountry}
                        </MenuItem>
                      );
                      // return null;
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel>Provincia</InputLabel>
              <Select
                label="Provincia"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="nameProvince"
                value={provinceId ? provinceId : ''}
                onChange={(e) => handleOnChangeProvince(e)}
                sx={{
                  borderRadius: 6,
                }}
                // onChange={handleChange}
              >
                {countryId
                  ? allProvinces.length > 0 &&
                    allProvinces.map((item) => {
                      if (item.countryId === countryId) {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.nameProvince}
                          </MenuItem>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <InputLabel>Ciudad</InputLabel>
              <Select
                label={'Ciudad'}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                name="nameCity"
                value={cityId ? cityId : ''}
                onChange={(e) => handleOnChangeCity(e)}
                sx={{
                  borderRadius: 6,
                }}
                // onChange={handleChange}
              >
                {provinceId
                  ? allCities.length > 0 &&
                    allCities.map((item) => {
                      if (item.provinceId === provinceId) {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.nameCity}
                          </MenuItem>
                        );
                      }
                      return null;
                    })
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}></Grid>
          <Grid item md={12}>
            <FormControl fullWidth variant="outlined">
              {/* <InputLabel>Nombre</InputLabel> */}
              {/* <ThemeProvider theme={themeDateFieldTxt}> */}
              <TextField
                // variant="outlined"
                type="text"
                placeholder="Nombre"
                label="Nombre"
                required
                name="fullName"
                value={initialUser.fullName}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleChangeForm(e);
                }}
              />
              {/* </ThemeProvider> */}
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth variant="outlined">
              {/* <InputLabel>Número de identificación</InputLabel> */}
              <TextField
                type="text"
                placeholder="Número de identificación"
                label="Número de identificación"
                required
                onKeyDown={(e) => valideKey(e)}
                name="dni"
                value={initialUser.dni}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleChangeForm(e);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth variant="outlined">
              {/* <InputLabel>Número de contacto</InputLabel> */}
              <TextField
                type="text"
                placeholder="Numero de contacto"
                label="Número de contacto"
                required
                name="phoneNumber"
                onKeyDown={(e) => valideKey(e)}
                value={initialUser.phoneNumber}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleChangeForm(e);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth variant="outlined">
              {/* <InputLabel>E-mail</InputLabel> */}
              <TextField
                type="email"
                placeholder="E-mail"
                label="E-mail"
                disabled={true}
                name="email"
                // required
                value={initialUser.email}
                sx={{ mb: 2 }}
                className={classes.textField}
                onChange={(e) => {
                  handleChangeForm(e);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {' '}
            {user.rol === 'teacher' && (
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel>Contraseña</InputLabel> */}
                <TextField
                  type={values.showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  label="Contraseña"
                  name="password"
                  required
                  sx={{ mb: 2 }}
                  value={initialUser.password}
                  onChange={(e) => {
                    handleChangeForm(e);
                  }}
                  className={classes.textField}
                  endadornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {values.showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Fab
          variant="extended"
          sx={{
            height: 70,
            width: 300,
            textTransform: 'capitalize',
            mt: 2,
            float: 'right',
          }}
          color="primary"
          onClick={(e) => handleClickSave(e)}
        >
          <Typography sx={{ fontWeight: 900 }}>Guardar</Typography>
        </Fab>
      </div>
    );
  };

  const TabsProfile = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Perfil" value="1" />
            <Tab label="Datos Bancarios" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <GeneralProfile />
        </TabPanel>
        <TabPanel value="2">
          <ProfessorDataBank />
        </TabPanel>
      </TabContext>
    </Box>
    );
  };

  return user.rol === 'admin' ? <TabsProfile /> : <GeneralProfile />;
};
