import { makeStyles } from "@mui/styles";
import React from "react";
import { useHistory } from "react-router-dom";
const topLogo = "/assets/ilustrologo60x43.svg";
const useStyle = makeStyles({
  root: {
    position: "absolute",
    top: "20px",
    left: "60px",
  },
});
export const TopLogo = () => {
  const history = useHistory();
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <img
        src={topLogo}
        onClick={() => history.push("/")}
        width="60%"
        alt="topLogo"
        style={{cursor:'pointer'}}
      />
    </div>
  );
};
