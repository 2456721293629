import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Fab, Grid, Typography } from "@mui/material";

import { useParams } from "../../../hooks/useParams";
import { Loading } from "../../common/Loading";
import { LevelParams } from "./LevelParams";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminParamsPage = () => {
  const classes = useStyles();

  const [levels, setLevels] = useState([]);
  const [formParams, setFormParams] = useState([]);

  const { getLevels, isLoading, updateLavel } = useParams();

  const getALlLevels = async () => {
    const resp = await getLevels();
    formParameter(resp);
    // setLevels(resp);
  };

  useEffect(() => {
    getALlLevels();
  }, []);

  const formParameter = (lvls) => {
    let tmp = [];
    lvls.length > 0 &&
      lvls.map((item) => {
        tmp.push({
          idLevel: item.id,
          nameLevel: item.nameLevel,
          namePercentH: `percentH${item.id}`,
          nameCostH: `costH${item.id}`,
          valuePercentH: item.percentHomework,
          valueCostH: item.costHomework,
          namePercentT: `percentT${item.id}`,
          nameCostT: `costT${item.id}`,
          valuePercentT: item.percentTutorial,
          valueCostT: item.costTutorial,
        });
      });
    setFormParams(tmp);
  };

  const onChange = (index) => (e) => {
    console.log("index: " + index);
    console.log("property name: " + e.target.name);
    let newArr = [...formParams];
    newArr[index] = {
      ...newArr[index],
      valueCostH:
        newArr[index].nameCostH === e.target.name
          ? e.target.value
          : newArr[index].valueCostH,
      valueCostT:
        newArr[index].nameCostT === e.target.name
          ? e.target.value
          : newArr[index].valueCostT,
      valuePercentH:
        newArr[index].namePercentH === e.target.name
          ? e.target.value
          : newArr[index].valuePercentH,
      valuePercentT:
        newArr[index].namePercentT === e.target.name
          ? e.target.value
          : newArr[index].valuePercentT,
    };

    setFormParams(newArr);
  };

  const updateParameters = async () => {
    console.log("update");
    console.log(formParams);
    await Promise.all(
      formParams.map(async (item) => {
        await updateLavel(
          item.idLevel,
          item.valuePercentT,
          item.valueCostT,
          item.valuePercentH,
          item.valueCostH
        );
      })
    );
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loading titleLoading="Cargando solicitudes" />
      ) : (
        <LevelParams levels={formParams} onChange={onChange} />
      )}

      <Grid container justifyContent="flex-end">
        <Fab
          color="primary"
          sx={{
            width: 200,
            height: 60,
            mr: 10,
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            margin: "auto",
            transform: 'translateY(15rem) translateX(30rem)'
          }}
          variant="extended"
          onClick={updateParameters}
        >
          <Typography sx={{ textTransform: "capitalize" }}>
            Actualizar
          </Typography>
        </Fab>
      </Grid>
    </div>
  );
};
