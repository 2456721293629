import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as authActions from "../../LoginPage/store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 315,
    height: 559,
    padding: "20px",
  },
}));
export const UserMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const switchUserRol = () => {
    switch (user.rol) {
      case "student":
        return (
          <>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push('/student/offers');
              }}
            >
              <Typography variant="body1" sx={{ fontSize: 22, display: 'block', mt: 2 }}>
                Ofertas
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/student/history");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Historial
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/student/profile");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Mi Perfil
              </Typography>
            </ListItemButton>
          </>
        );
      case "teacher":
        return (
          <>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/teacher/agenda");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Agenda
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/teacher/history");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Historial
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/teacher/professor-profile");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Perfil del Profesor
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/teacher/level");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Mis niveles
              </Typography>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/teacher/wallet");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Billetera
              </Typography>
            </ListItemButton>
          </>
        );

      case "admin":
        return (
          <>
            <ListItemButton
              onClick={() => {
                props.handleClose();
                history.push("/admin/profile");
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: 22, display: "block", mt: 2 }}
              >
                Mi Perfil
              </Typography>
            </ListItemButton>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Avatar
            sx={{ width: "68px", height: "68px" }}
            src={user?.img}
          ></Avatar>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            sx={{ fontSize: 22, display: "block", mt: 1 }}
          >
            {user.fullName}
          </Typography>

          <Typography>
            {user.rol === "admin"
              ? "Administrador"
              : user.rol === "teacher"
              ? "Profesor"
              : user.rol === "student"
              ? "Estudiante"
              : "Default"}
          </Typography>
        </Grid>
      </Grid>
      <List>
        {switchUserRol()}
        <Divider sx={{ mt: 2, mb: 2 }} />
        {/* <ListItemButton>
          <Typography
            variant="body1"
            sx={{ fontSize: 22, display: "block", mt: 2 }}
          >
            Términos y Condiciones
          </Typography>
        </ListItemButton> */}
        <ListItemButton
          onClick={() => {
            props.handleClose();
            dispatch(authActions.logout());
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: 22, display: "block", mt: 2 }}
          >
            Cerrar Sesión
          </Typography>
        </ListItemButton>
      </List>
    </div>
  );
};
