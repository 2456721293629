import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { StdMenuItem } from './StdMenuItem';
import operatorBig from '../../../assets/icon/operatorBig.svg';
import book from '../../../assets/icon/book.svg';
import books from '../../../assets/icon/books.svg';
import bladeBig from '../../../assets/icon/bladeBig.svg';
import flag from '../../../assets/icon/flag.svg';
import mic from '../../../assets/icon/mic.svg';
import earth from '../../../assets/icon/earth.svg';
import dotBig from '../../../assets/icon/dotBig.svg';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { EmptyState } from '../../common/EmptyState';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '60vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
}));
export const StudentMenuPage = () => {
  const classes = useStyles();
  const [sliceNumber, setSliceNumber] = useState(7);

  const iconsSchools = [operatorBig, book, books, bladeBig, flag, mic, earth, dotBig];

  const { schools } = useSelector((state) => state.studentReducers.studentReducer);

  const handlAllCategories = () => {
    setSliceNumber(schools.length);
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" sx={{ zIndex: 2 }}>
        {
          schools.length > 0 
          ? schools.slice(0, sliceNumber).map((item, idx) => <StdMenuItem key={idx} id={item.schoolId} icon={item.iconUrl||book} name={item.nameSchool} />)
          : <EmptyState title='No existe escuelas'/>
        }
        {schools.length > sliceNumber && <StdMenuItem icon={dotBig} black name="Todas las categorías" func={handlAllCategories} />}
      </Grid>
    </div>
  );
};
