import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { AdminProfessorsItem } from "./AdminProfessorsItem";
// import user3 from "../../../../assets/image/user3.png";
// import user1 from "../../../../assets/image/user1.png";
// import user2 from "../../../../assets/image/user2.png";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: { padding: "0px 40px", marginTop: "-30px" },
}));

export const AdminProfessorsList = ({
  AllTeachers,
  setDefaultState,
  defaultState,
}) => {
  const classes = useStyles();

  const [teachers, setTeachers] = useState(AllTeachers);

  useEffect(() => {
    if (defaultState === "pending") {
      setTeachers(AllTeachers.filter((el) => el.status === "pending"));
    } else if (defaultState === "accepted") {
      setTeachers(AllTeachers.filter((el) => el.status === "accepted"));
    } else if (defaultState === "rejected") {
      setTeachers(AllTeachers.filter((el) => el.status === "rejected"));
    }
  }, [defaultState]);


  const loadData = () => {
    return teachers.map((teacher) => {
      return (
        <div key={teacher.userId}>
          <AdminProfessorsItem
            userAvatar={teacher.img}
            role={teacher.specialty}
            name={teacher.fullName}
            description={teacher.professionalDescription}
            review={teacher.starReview}
            subject={teacher.levels}
            certifications={teacher.otherCertifications}
            status={teacher.status}
            teacher={teacher}
            teachers={teachers}
            defaultState={defaultState}
            setTeachers={setTeachers}
            setDefaultState={setDefaultState}
            userId={teacher.userId}
            teacherTrainingId={teacher.teacherTrainingId}
          />
          <Divider sx={{ my: 2 }} />
        </div>
      );
    });
  };

  return (
    <div className={classes.root}>
      {teachers.length > 0 ? (
        loadData()
      ) : (
        <AdminProfessorsItem empty={true} status={defaultState} />
      )}
    </div>
  );
};
