import { makeStyles } from "@mui/styles";
import { saveAs } from "file-saver";
import React from "react";
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
const useStyle = makeStyles({
  root: {
    position: "relative",
  },
  close: {
    position: "absolute",
    right: "8px",
    top: "0px",
  },
});

export const AgendaUserState = (props) => {
  const {
    avatar,
    name,
    purchase,
    date,
    hoursNumber,
    price,
    nameSubject,
    nameLevel,
    detailHomework,
    schedule,
    classReq,
    fileUrl,
  } = props;

  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   setDatesSelected([...datesSelected, date]);
  // }, [date]);

  const StatusTimeBadge = () => {
    return (
      <div
        style={{
          width: "200px",
          height: "58px",
          borderRadius: "16px",
          background: "#EDEDED",
          display: "flex",
          alignItems: "center",
          padding: "8px",
          margin: "20px",
        }}
      >
        {" "}
        <QueryBuilderIcon
          sx={{ color: "#A5A5A5", mr: 2 }}
          fontSize="large"
        />{" "}
        <Typography sx={{ color: "#A5A5A5", fontSize: 16 }}>
          Tiempo de entrega 24 horas
        </Typography>
      </div>
    );
  };
  const StatusDownBadge = () => {
    const handleClickDownload = () => {
      saveAs(fileUrl);
    };

    return (
      <div
        style={{
          width: "180px",
          height: "58px",
          borderRadius: "16px",
          background: "#EDEDED",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "8px",
          margin: "20px",
        }}
      >
        {" "}
        <Typography sx={{ color: "#A5A5A5", fontSize: 16, ml: 2 }}>
          Descarga la tarea aquí
        </Typography>
        <IconButton
          sx={{ background: "#FEB098" }}
          onClick={handleClickDownload}
        >
          <ArrowDownwardIcon sx={{}} fontSize="large" />{" "}
        </IconButton>
      </div>
    );
  };
  const handleClick = () => {
    setOpen(true);
  };
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {/* <CloseIcon className={classes.close} /> */}
      <Grid container>
        <Grid item md={3} container>
          <Badge
            overlap="circular"
            badgeContent=" "
            // color="success"
            // anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Avatar src={avatar} sx={{ width: 146, height: 146 }} />
          </Badge>
        </Grid>
        <Grid item md={8}>
          <Typography variant="body1" sx={{ display: "block" }}>
            {name}
          </Typography>
          <br />
          {purchase ? (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <span style={{ color: "#0AD78D", fontSize: 18 }}>
                  {classReq !== 0 && "Tutoría"}
                </span>
                &nbsp;&nbsp;
                {classReq !== 0 && "|"}
                &nbsp;&nbsp;
                <img
                  src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                  alt="cc"
                />
                &nbsp;&nbsp;
                {moment(date).format("DD/MM/YYYY")}
                &nbsp;&nbsp;
                {/* <img
                      src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                      alt="vv"
                    /> */}
                &nbsp;&nbsp;
                {schedule}
                &nbsp;&nbsp;
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel}
                {nameSubject}
              </Typography>
              <br />
              <Typography>Detalle: {detailHomework}</Typography>
              <Box
                sx={{
                  // border: "2px solid red",
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                }}
              >
                {StatusTimeBadge()}
                {StatusDownBadge()}
              </Box>
            </div>
          ) : (
            <div>
              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                <Box sx={{ display: "flex" }}>
                  <div
                    style={{
                      // border: '2px solid red',
                      display: "flex",
                      flex: "1",
                    }}
                  >
                    <div>
                      <img
                        src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
                        alt="gg"
                      />
                    </div>
                    <div>
                      &nbsp;&nbsp;
                      {moment(date).format("DD/MM/YYYY")}
                      &nbsp;&nbsp;
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <img
                        src="https://img.icons8.com/material-outlined/18/000000/clock.png"
                        alt="kk"
                      />
                    </div>
                    <div>
                      &nbsp;&nbsp;
                      {schedule}
                      &nbsp;&nbsp;| &nbsp;&nbsp;
                      <span style={{ color: "#0AD78D" }}>${price}</span>
                    </div>
                  </div>
                </Box>
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#FEB098" }}>
                Horas de clase: {hoursNumber}
              </Typography>
              <br />
              <Typography sx={{ display: "block", color: "#A5A5A5" }}>
                {nameLevel} - {nameSubject}
              </Typography>
            </div>
          )}
        </Grid>
        {/* <Grid
          item
          md={2}
          container
          justifyContent="flex-end"
          alignItems="center"
          // sx={ {border: '2px solid blue'}}
        >
          <Badge color="primary" badgeContent={badge}>
            <img
              src="https://img.icons8.com/pastel-glyph/40/000000/new-post--v2.png"
              alt="post"
            />
          </Badge>
        </Grid> */}
      </Grid>
      {/* <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
      >
        <ConfirmDialog handleClose={() => setOpen(false)} />
      </Dialog> */}
    </div>
  );
};
