import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SkeletonCardTeacher = () => {
  const classes = useStyles();
  return (
    <>
      <Stack direction="row" spacing={2} className={classes.skel}>
        <Stack spacing={2}>
          <Skeleton variant="circular" width={100} height={100} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="text" component="h1" />
          <Skeleton variant="rectangular" width={210} height={100} style={{ borderRadius: 15 }} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} className={classes.skel}>
        <Stack spacing={2}>
          <Skeleton variant="circular" width={100} height={100} />
        </Stack>
        <Stack spacing={2}>
          <Skeleton variant="text" component="h1" />
          <Skeleton variant="rectangular" width={210} height={100} style={{ borderRadius: 15 }} />
        </Stack>
      </Stack>
    </>
  );
};

export const SkeletonCardChat = () => {
  const classes = useStyles();
  return (
    <>
      <Stack direction="row" spacing={2} className={classes.skel}>
        <Stack spacing={2}>
          <Skeleton variant="circular" width={100} height={100} />
        </Stack>
        <Stack spacing={2}>
          {/* <Skeleton variant="text" component="h1" /> */}
          <Skeleton variant="rectangular" width={300} height={100} style={{ borderRadius: 15 }} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} className={classes.skel}>
        <Stack spacing={2}>
          <Skeleton variant="circular" width={100} height={100} />
        </Stack>
        <Stack spacing={2}>
          {/* <Skeleton variant="text" component="h1" /> */}
          <Skeleton variant="rectangular" width={300} height={100} style={{ borderRadius: 15 }} />
        </Stack>
      </Stack>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { padding: '10px 20px' },
  skel: { padding: 20, marginTop: 20 },
}));
