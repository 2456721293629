import React from "react";
const background = "/assets/bottom.svg";
const botback1 = "/assets/botback1.svg";

const botBackGroundStyle = {
  width: "100%",
  height: "100%",
  marginTop: "-5%",
  marginBottom: "-10%",
};
const botBack1Style = {
  marginBottom: "-10%",
  float: "right",
};

export const BotBackground = (props) => {
  const { logined } = props;
  return (
    <div>
      {!logined ? (
        <img
          src={background}
          style={botBackGroundStyle}
          alt="Fondo inferior"
        ></img>
      ) : (
        <img src={botback1} style={botBack1Style} alt="botback1" />
      )}
    </div>
  );
};
