import { HomeWork } from "@mui/icons-material";
import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperReqHomeworks = {};

// helperReqHomeworks.postHomework = async (data) => {
//   const res = await axios.post(`${apiUrl}subjects`, data);
//   // console.log("res data post -->", res.data);
//   return res.data.subjects;
// };

helperReqHomeworks.putHomework = async (id, data) => {
  const res = await axios.put(`${apiUrl}requestHomeworks/${id}`, data);
  // console.log("res data put req homework -->", res.data.RequestHomeworkDB);
  return res.data.RequestHomeworkDB;
};

// helperReqHomeworks.deleteHomework = async (id) => {
//   const res = await axios.delete(`${apiUrl}subjects/${id}`);
//   console.log("res data delete -->", res.data);
// };

// GET REQUEST HOMEWORKS PENDING BY TEACHER TRAINING ID
helperReqHomeworks.getHomeWorksPendingByTId = async (id) => {
  const res = await axios.get(`${apiUrl}view/RequestHomeworksPending/${id}`);
  // console.log("res get reqHomwork by U_teacher_T_id -->", res.data.resultH);
  return res.data.resultH;
};

helperReqHomeworks.getAllReqHomeworks = async () => {
  const res = await axios.get(`${apiUrl}requestHomeworks`);
  return res.data.RequestHomeworks;
};

helperReqHomeworks.getReqHomeworkById = async (id) => {
  const homeworks = await helperReqHomeworks.getAllReqHomeworks();
  const data = homeworks.filter((el) => el.id === id);
  return data[0];
};
