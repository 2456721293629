import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  TextField,
  createTheme,
  ThemeProvider,
  Fab,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { AvaListItem } from "./AvaListItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { PickersDay, DatePicker } from "@mui/lab";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as homeActions from "../../../HomePage/store/actions";

import { helperSchedules } from "../../../../services/helperSchedules";
import { EmptyState } from "../../../common/EmptyState";
import FormDialogNewSchedule from "./AvaListItem/Modals";
import { isWeekend } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: { padding: "10px 40px" },
}));

const bkgMonday = "#D5B8FD";
const bkgTuesday = "#A4CCDF";
const bkgWednesday = "#FEB098";
const bkgThursday = "#95F8D4";
const bkgFriday = "#FFEC88";
const bkgSaturday = "#65A2B6";
const bkgSunday = "#D8E7E4";

const weekDays = [
  { day: "Lunes", bkg: bkgMonday },
  { day: "Martes", bkg: bkgTuesday },
  { day: "Miércoles", bkg: bkgWednesday },
  { day: "Jueves", bkg: bkgThursday },
  { day: "Viernes", bkg: bkgFriday },
  { day: "Sábado", bkg: bkgSaturday },
  { day: "Domingo", bkg: bkgSunday },
];

const themeDateFieldTxt = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          border: "none",
          outline: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: "none",
          outline: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            // +          // the result will contain the prefix.
            // +          [`& .${outlinedInputClasses.notchedOutline}`]: {
            //              borderWidth: 1,
          },
        },
      },
    },
  },
});

const themePickerDay = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "green",
          },
          "&.MuiPickersDay-today": {
            backgroundColor: "#f99",
          },
        },
      },
    },
  },
});

export const AvailabilityList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const [selectedDate, setSelectedDate] = useState(moment()._d);
  const [datesToShow, setDatesToShow] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const allSchedules = useSelector(
    ({ homeReducer }) =>
      homeReducer.homeDataSchedulesByUserId.reqSchedulesByUserId
  );

  useEffect(() => {
    dispatch(homeActions.getSchedulesByUserId(user.id));
  }, []);

  // console.log(allSchedules);

  useEffect(() => {
    const getData = async () => {
      const dates = allSchedules.filter(
        (el) =>
          moment(el.startTime).format("DD") ===
            moment(selectedDate).format("DD") &&
          moment(el.startTime).format("MM") ===
            moment(selectedDate).format("MM")
      );
      // console.log(dates);
      setDatesToShow(dates);
    };
    getData();
  }, [allSchedules]);

  useEffect(() => {
    // console.log(selectedDate);
    const getData = async () => {
      const dates = allSchedules.filter(
        (el) =>
          moment(el.startTime).format("DD") ===
            moment(selectedDate).format("DD") &&
          moment(el.startTime).format("MM") ===
            moment(selectedDate).format("MM")
      );
      // console.log(dates);
      setDatesToShow(dates);
    };

    getData();
  }, [selectedDate]);

  const handleChangeCalendar = (e) => {
    setSelectedDate(e);
  };

  const handleOnDaySelect = async (e) => {
    setSelectedDate(e);
  };

  const handleClickPlusSchedule = (e) => {
    setIsOpen(true);
  };

  const getDayElement = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    let elementDay = (
      // <ThemeProvider theme={themePickerDay} key={day}>
        <PickersDay
          key={day}
          day={day}
          outsideCurrentMonth={false}
          // disableHighlightToday={false}
          // today={true }
          onDaySelect={(e) => handleOnDaySelect(e)}
          onSelectCapture={(e) => console.log(e)}
          // selected
          sx={{ fontSize: 15 }}
        ></PickersDay>
      // </ThemeProvider>
    );

    const dayPicker = moment(day).format("DD");
    const dayPickerMonth = moment(day).format("MM");
    const datos = allSchedules.map((el) => {
      const daySelected = moment(el.startTime).format("MM");
      const daySelectedDay = moment(el.startTime).format("DD");
      if (dayPickerMonth === daySelected && dayPicker === daySelectedDay) {
        elementDay = (
          <PickersDay
            key={day}
            day={day}
            outsideCurrentMonth={false}
            onDaySelect={(e) => handleOnDaySelect(e)}
            sx={{ background: bkgMonday, fontSize: 15 }}
          ></PickersDay>
        );
      }
    });

    if (day <= new Date()) {
      elementDay = (
        <PickersDay
          key={day}
          day={day}
          disabled={true}
          outsideCurrentMonth={false}
          onDaySelect={(e) => handleOnDaySelect(e)}
          sx={{ background: "#efefef", fontSize: 15 }}
        ></PickersDay>
      );
    }

    if (
      dayPicker === moment(moment()._d).format("DD") &&
      dayPickerMonth === moment(new Date()).format("MM")
    ) {
      elementDay = (
        <PickersDay
          key={day}
          day={day}
          disabled={false}
          outsideCurrentMonth={false}
          onDaySelect={(e) => handleOnDaySelect(e)}
          sx={{ background: "#007FFF", fontSize: 15 }}
        ></PickersDay>
      );
    }

    // console.log(elementDay);
    return elementDay;
  };

  const getDayName = () => {
    const day = moment(selectedDate).format("dddd");
    switch (day) {
      case "lunes":
        return "Lunes";
      case "martes":
        return "Martes";
      case "miercoles" || 'miércoles':
        return "Miércoles";
      case "jueves":
        return "Jueves";
      case "viernes":
        return "Viernes";
      case "sábado" || 'sabado':
        return "Sábado";
      case "domingo":
        return "Domingo";
      default:
        return "Default";
    }
  };

  return (
    <div className={classes.root}>
      <FormDialogNewSchedule
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={user}
        selectedDate={selectedDate}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontFamily: "Poppins", fontSize: "20px" }}>
          {/* Semana 11-18 de Octubre */}
          {getDayName()}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // label="Basic example"
            value={selectedDate}
            openTo="day"
            shouldDisableDate={isWeekend}
            onChange={(e) => handleChangeCalendar(e)}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
              getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
            }
            renderInput={(params) => (
              <ThemeProvider theme={themeDateFieldTxt}>
                <TextField {...params} contentEditable={false} />
              </ThemeProvider>
            )}
          />
        </LocalizationProvider>
        {/* <IconButton sx={{ bgcolor: "secondary.green2" }}>
          <ChevronRight />
          
        </IconButton> */}
      </Grid>
      {datesToShow.length > 0 ? (
        datesToShow.map((el) => {
          // console.log(el);
          return (
            <AvaListItem
              key={el.id}
              idSchedule={el.id}
              user={user}
              color={bkgMonday}
              startTime={el.startTime}
              endTime={el.endTime}
              frecuency={el.scheduleFrequency || "every-week"}
            />
          );
        })
      ) : (
        <EmptyState title="Ingrese un nuevo horario" />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Fab
          sx={{
            width: "50%",
            height: 50,
            bgcolor: bkgMonday,
            borderRadius: "50px",
            margin: "2rem 0rem",
          }}
          variant="extended"
          onClick={(e) => handleClickPlusSchedule(e)}
        >
          <Add />
        </Fab>
      </div>
      {/* {weekDays.map((el, index) => {
        return <AvaListItem key={index} color={el.bkg} day={el.day} />;
      })} */}
    </div>
  );
};

// day={
//   el.day === "Monday"
//     ? "Lunes"
//     : el.day === "Tuesday"
//     ? "Martes"
//     : el.day === "Wednesday"
//     ? "Miércoles"
//     : el.day === "Thursday"
//     ? "Jueves"
//     : el.day === "Friday"
//     ? "Viernes"
//     : el.day === "Saturday"
//     ? "Sábado"
//     : el.day === "Sunday"
//     ? "Domingo"
//     : "Default"
// }
