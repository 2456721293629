import React from 'react';
import { makeStyles } from '@mui/styles';
import { Fab, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const StdSubjectItem = ({ title, idSubject }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Fab
        variant="extended"
        sx={{
          textTransform: 'capitalize',
          width: '100%',
          height: '70px',
          mb: 2,
        }}
        color="secondary"
        onClick={() => {
          history.push(`/student/subject/${idSubject}`);
        }}
      >
        <Typography variant="body1"> {title}</Typography>
      </Fab>
    </div>
  );
};
