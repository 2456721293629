import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import { AdminProfessorStateItem } from "./AdminProfessorStateItem";
import { Typography, Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: { padding: '60px 0px 60px 30px' },
  styleBox: {
    background: bgkDefault,
    padding: '30px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '20px',
    cursor: 'pointer',
  },
}));

const bgkDefault = 'rgba(216, 231, 228, 1)';
const bgkAccepted = 'rgba(149, 248, 212, 1)';
const bgkRejected = 'rgba(254, 176, 152, 1)';

const states = ['Aceptado', 'Rechazado'];
export const AdminStudentState = ({ setDefaultState, defaultState }) => {
  const classes = useStyles();

  const handleClickState = (state) => {
    state == 'Aceptado' ? setDefaultState(1) : setDefaultState(0);
  };

  return (
    <div className={classes.root}>
      {states.map((state, idx) => (
        <Box
          key={state}
          sx={{
            background: defaultState && (state === 'Aceptado') == 1 ? bgkAccepted : defaultState == 0 && state === 'Rechazado' ? bgkRejected : bgkDefault,
          }}
          className={classes.styleBox}
          onClick={() => handleClickState(state)}
          key={idx}
        >
          <Typography>{state}</Typography>
        </Box>
      ))}
    </div>
  );
};
