import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const getSubjectsSchool = (idSchool, nameSubject) => {
  return async (dispatch) => {
    // const subjects = await getSubjectsByIdSchool(idSchool);
    const subjects = await getSubjectsGroupBackend(idSchool);

    dispatch(setSubjects(idSchool, nameSubject, subjects, false));
  };
};

const getUserCoursesById = async (id) => {
  const resp = await axios.get(`${apiUrl}view/viewUserCourses/byiduser/${id}`);
  return resp.data.resultUserCourse;
};

const getSubjectsGroupBackend = async (selectSchoolId) => {
  const resp = await axios.get(`${apiUrl}view/viewUserCoursesGroup`);
  const remove = resp.data.resultCourses.filter((item) => item.schoolId === selectSchoolId);
  return remove;
};

const getSubjectsByIdSchool = async (idSchool) => {
  const resp = await axios.get(`${apiUrl}subjects/schoolId/${idSchool}`);

  if (resp.status === 200) {
    if (resp.data.msg) {
      return resp.data.subjects;
    }
  } else {
    return [];
  }
};

const setSubjects = (idschool, nameSchool, subjects, isLoading) => {
  return {
    type: types.getSubjectsBySchool,
    payload: { idschool, nameSchool, subjects, isLoading },
  };
};

export const setNameLevel = (nameLevel) => {
  return {
    type: types.changeNameLevel,
    payload: { nameLevel },
  };
};
