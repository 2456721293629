import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { AdReviewerItem } from './AdReviewerItem';
import user1 from '../../../../assets/image/user1.png';
import empty_state from '../../../../assets/image/empty_state.svg';
// import user2 from "../../../../assets/image/user2.png";

import { helper } from '../../../../services/helperReviewsST';
import { useUser } from '../../../../hooks/useUser';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 10px 0px 60px' },
}));

export const AdReviewerList = () => {
  const [reviews, setReviews] = useState([]);

  const getData = async () => {
    const data = await helper.getReviewsST();
    setReviews(data);
  };

  useEffect(() => {
    getData();
  }, []);

  // console.log("--> ", reviews);

  const { deleteReviewById } = useUser();

  const deleteReview = async (idReview) => {
    await deleteReviewById(idReview);
    getData();
  };

  function EmptyData() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <img width="100%" src={empty_state} alt="Imagen de no hay solicitudes"></img>
        </Box>
        <Box> </Box>
        <Box>
          <Typography variant="h3">No hay solicitudes </Typography>
        </Box>
      </Box>
    );
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {reviews.length > 0 ? (
        reviews.map((review) => {
          const fragment = <AdReviewerItem key={review.id} userAvatar={review.studentId.img || user1} name={review.studentId.fullName} rating={review.starNumber} review={review.comment} func={() => deleteReview(review.id)} />;
          // cont++;
          return fragment;
        })
      ) : (
        <EmptyData />
      )}

      {/* {reviews.length > 0 ? (): <EmptyData/> } */}
    </div>
  );
};
