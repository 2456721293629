import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const ScheduleResumeStudent = ({ date, hour }) => {
  const classes = useStyles();

  return (
    <Box className={classes.noteResume}>
      <div>
        <Typography variant="h6" color="initial" sx={{ width: '90%' }}>
          Accede a esta clase en la fecha que agendaste
        </Typography>
      </div>
      <div className={classes.schedule}>
        <div className={classes.scheduleItem}>
          <img src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png" alt="calendar" height={20} width={20} />
          <Typography variant="h6" color="initial" sx={{ ml: 2 }}>
            {date}
          </Typography>
        </div>
        <div className={classes.scheduleItem}>
          <img src="https://img.icons8.com/material-outlined/18/000000/clock.png" alt="clock" height={20} width={20} />
          <Typography variant="h6" color="initial" sx={{ ml: 2 }}>
            {hour}
          </Typography>
        </div>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  schedule: {
    marginTop: 40,
  },
  scheduleItem: {
    display: 'flex',
    marginTop: 5,
  },
  noteResume: {
    backgroundColor: '#eff5f4',
    borderRadius: 16,
    paddingTop: 40,
    paddingLeft: 30,
    paddingRigth: 30,
    paddingBottom: 40,
    marginBottom: 30,
    width: '100%',
  },
}));
