import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { LogoCard } from '../../common/LogoCard';
import registerLogo from '../../../assets/image/registerLogo.png';
import { RequestDetail } from './RequestDetail';
import { StdHomeworkUserList } from './StdHomeworkUserList';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 40px' },
}));

export const StudentHomeworkRequestPage = ({ levels, idLevel }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={3} xs={12}>
          <LogoCard title="Ayúdame con mi tarea" image={registerLogo} />
        </Grid>
        <Grid item md={5} xs={12}>
          <RequestDetail levels={levels} idLevel={idLevel} />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>

        <Grid item md={3} xs={12}>
          <StdHomeworkUserList />
        </Grid>
      </Grid>
    </div>
  );
};
