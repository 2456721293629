import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { AdminProfessorReviewPage } from '../../components/AdminPage/AdminProfessorReviewPage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherInfoSelectedAdmin } from '../../store/actions/admin/teachersActions';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminProfessorReview = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { name } = useParams();

  const { teacherSelected } = useSelector(({ adminReducers }) => adminReducers.teachersReducer);

  useEffect(() => {
    stateTeacherSelectedReducer();
  }, []);

  const stateTeacherSelectedReducer = () => {
    teacherSelected && dispatch(getTeacherInfoSelectedAdmin(name));
  };

  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Calificaciones" />
      <AdminProfessorReviewPage />
      <BotBackground logined />
    </div>
  );
};
