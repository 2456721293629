import React from "react";
import { makeStyles } from "@mui/styles";
import logLogo from "../../../../assets/image/logLogo.png";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px 40px",
  },
}));
export const AvailabilityDesc = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={logLogo} alt="logLogo" />
      <Typography sx={{ my: 2 }}>Arma tu agenda de clase</Typography>
      <Box>
        <Typography sx={{ fontWeight: 400 }}>
          Tus clases se agendarán de <br />
          acuerdo a tu disponibilidad
        </Typography>
      </Box>
    </div>
  );
};
