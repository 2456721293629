import React from "react";

const background = "assets/top1.svg";

const styleTopImg = {
  width: "100%",
  height: "100%",
  marginTop: "-5%",
  marginBottom: "-10%",
  padding: "0%",
};

export const TopBackground1 = () => {
  return (
    <div>
      <img src={background} style={styleTopImg} alt="Fondo Superior"></img>
    </div>
  );
};
