import React from "react";
import { makeStyles } from "@mui/styles";
import waiting from "../../../../assets/icon/urgentWaiting.svg";
import { Fab, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0px 100px",
  },
}));
export const StdPreparingCancel = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={waiting} alt="waiting" />
      <Typography sx={{ textAlign: "center", my: 6 }}>
        Espera un momento mientras tu tutor se prepara
      </Typography>
      <Fab
        variant="extended"
        sx={{
          width: "100%",
          height: 60,
          backgroundColor: "warning.secondary",
          textTransform: "capitalize",
          mb: 4,
        }}
        //   onClick={() => history.push("/student/urgent/preparing")}
      >
        <Typography>Cancelar</Typography>
      </Fab>
    </div>
  );
};
