import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const setSubjectNameAndId = (idSubject, nameSubject) => {
  return {
    type: types.setNameAndIdSubject,
    payload: { idSubject, nameSubject },
  };
};

const getTeacherByIdSubject = async (idSubject) => {
  try {
    const resp = await axios.get(`${apiUrl}view/getTeacherBySubjectId/${idSubject}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        return resp.data.TeacherBySubject;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const getPriceByUserIdAndLevelId = async (userId, levelId) => {
  try {
    const resp = await axios.get(`${apiUrl}userprices/${userId}/${levelId}`);
    if (resp.status === 200) {
      if (resp.data.msg) {
        return resp.data.userUserPrices[0];
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
};

const getLevelsByUserId = async (userId) => {
  try {
    const resp = await axios.get(`${apiUrl}view/viewLevelsByUserId/ByIdUser/${userId}`);
    return resp.data.levelByUserId;
  } catch (error) {
    console.log(error);
  }
};

const getInfoUserByIdUser = async (userId) => {
  try {
    const resp = await axios.get(`${apiUrl}users/${userId}`);
    return resp.data.usuario;
  } catch (error) {
    console.log(error);
  }
};

const getTeacherInfo = async (idUserId, levelId) => {
  try {
    const resp = await axios.get(`${apiUrl}teacherTrainings/byUser/${idUserId}`);
    const { price } = await getPriceByUserIdAndLevelId(idUserId, levelId);
    const levels = await getLevelsByUserId(idUserId);
    const { fullName, img } = await getInfoUserByIdUser(idUserId);
    const dataBank = await getDataBankByUseId(idUserId);
    const teacherAverageReview = await getTeacherAverageReview(idUserId);

    if (resp.status === 200) {
      if (resp.data.msg) {
        const teacherInfo = resp.data.teacherTrainings;
        return {
          ...teacherInfo,
          fullName,
          img,
          price,
          levels,
          dataBank,
          starReview: Number(teacherAverageReview[0].star) || 0,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
  }
};

const getDataBankByUseId = async (userId) => {
  const resp = await axios.get(`${apiUrl}databanks/byiduser/${userId}`);
  if (resp.status === 200) {
    if (resp.data.msg) {
      return resp.data.DataBanks;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const getTeacherAverageReview = async (userId) => {
  try {
    const resp = await axios.get(`${apiUrl}view/reviewstar/${userId}`);
    return resp.data.star;
  } catch (error) {
    console.log(error);
  }
};

export const getTeachersByIdSubjetct = (idSubject) => {
  return async (dispatch) => {
    const resp = await getTeacherByIdSubject(idSubject);
    let allTeachers = resp.map(async (teacherInformation) => {
      const levelsByUser = await getLevelsByUserId(teacherInformation.userId);
      const { price } = await getPriceByUserIdAndLevelId(teacherInformation.userId, teacherInformation.levelId);
      const teacherAverageReview = await getTeacherAverageReview(teacherInformation.userId);
      return {
        ...teacherInformation,
        price,
        starReview: Number(teacherAverageReview[0].star) || 0,
        levels: levelsByUser,
      };
    });
    allTeachers = await Promise.all(allTeachers);
    dispatch(setTeachers(allTeachers, false));
  };
};

export const getTeacherInfoSelected = (UserId, levelId, userCourseId) => {
  return async (dispatch) => {
    const resp = await getTeacherInfo(UserId, levelId);
    dispatch(setTeacherInfo({ ...resp, userCourseId }, false));
  };
};

const setTeachers = (teachers, isLoading) => {
  return {
    type: types.setTeachers,
    payload: { teachers, isLoading },
  };
};
const setTeacherInfo = (teacher, isLoading) => {
  return {
    type: types.setTeacherSelected,
    payload: { teacher, isLoading },
  };
};
