import React from "react";
import { BotBackground } from "../components/common/BotBackground";
import { TopBackground1 } from "../components/common/TopBackground1";
import { TopLogo } from "../components/common/TopLogo";
import { RegisterPage } from "../components/RegisterPage";

export const RegisterScreen = () => {
  return (
    <div>
      <TopBackground1 />
      <TopLogo />
      <RegisterPage />
      <BotBackground />
    </div>
  );
};
