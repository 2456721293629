import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box } from "@mui/material";
import { History } from "@mui/icons-material";
import { EmptyState } from "../../../common/EmptyState";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 30px",
    position: "relative",
    borderRadius: 10,
    border: "1px solid ",
    marginBottom: "40px",
  },
  date: {
    position: "absolute",
    top: "30px",
    right: "30px",
  },
}));
export const ProfitsItem = (props) => {
  const classes = useStyles();
  const { month, year, total, percent, monthSelected } = props;
  return (
    <div className={classes.root}>
      {year > 0 ? (
        <>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                float: "right",
                display: "inline",
                fontFamily: "Poppins",
                width: "100%",
                textAlign: "right",
              }}
            >
              {monthSelected} {year}
            </Typography>

            <div
              style={{
                width: "50%",
                position: "absolute",
                right: 0,
                bottom: 0,
              }}
            >
              <History
                sx={{ width: 100, height: 100, color: "warning.main" }}
              />
            </div>
          </Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontFamily: "Poppins", fontSize: ".9rem" }}>
                Ganancia
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 24,
                  fontWeight: 700,
                  my: 1,
                }}
              >
                $ {percent.toFixed(2)}
              </Typography>

              <Typography sx={{ fontFamily: "Poppins", fontSize: ".9rem" }}>
                Total: {total.toFixed(2)}
              </Typography>
            </Grid>
            {/* <Grid item>
            <History sx={{ width: 60, height: 60, color: "warning.main" }} />
          </Grid> */}
          </Grid>
        </>
      ) : (
        <EmptyState title="" />
      )}
    </div>
  );
};
