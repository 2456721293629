import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import user from "../../../assets/image/user.svg";
import wallet from "../../../assets/image/wallet.svg";
import academy from "../../../assets/image/academy.svg";
// import { Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   stepIcon: {
//     ".MuiStepIcon-root": {
//       fontSize: "12px",
//     },
//   },
// }));

// const bkgDefaultStep = "#F9FBFA";
const bkgUpdateProfile = "#FFEC88";
const bkgDataBank = "#FEB098";
const bkgAcademy = "#D5B8FD";

const Steps = [
  {
    icon: user,
    description: `Editar Perfil `,
    background: bkgUpdateProfile,
    // background: bkgDefaultStep,
  },
  {
    icon: wallet,
    description: "Datos Bancarios",
    background: bkgDataBank,
    // background: bkgDefaultStep,
  },
  {
    icon: academy,
    description: `Formación Academica`,
    background: bkgAcademy,
    // background: bkgDefaultStep,
  },
];

export default function ProfessorTool({ setActiveSTATE }) {
  // const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  // const [bkgStep, setBkgStep] = useState(bkgDefaultStep);
  const [steps, setSteps] = useState(Steps);

  const handleActiveStep = () => {
    switch (activeStep) {
      case 0:
        setActiveSTATE(activeStep);
        break;
      case 1:
        setActiveSTATE(activeStep);
        break;
      case 2:
        setActiveSTATE(activeStep);
        break;
      case 3:
        setActiveSTATE(2);
        break;
      default:
        setActiveSTATE(0);
        break;
    }
  };

  useEffect(() => {
    handleActiveStep();
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400, mt: 10 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={step.description}
            sx={{
              cursor: "pointer",
              background: step.background,
              borderRadius: 4,
              paddingLeft: 2,
              "& .MuiStepIcon-text": {
                fontSize: "14px",
              },
              // transform: 'scale(1.1)',
            }}
          >
            <StepLabel
              sx={{
                width: 280,
                height: 70,
              }}
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption" sx={{ fontSize: ".9rem" }}>
              //       Último paso
              //     </Typography>
              //   ) : null
              // }
            >
              <div style={{ display: "flex", alignItems:'center' }}>
                <div style={{ width: "3rem", margin: "0px 1rem" }}>
                  <img src={step.icon} alt="icon" sx={{ width: "100%" }}></img>
                </div>
                {/* {step.description} */}
                <Typography>{step.description}</Typography>
              </div>
            </StepLabel>
            <StepContent>
              {/* <Typography>{step.description}</Typography> */}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Terminar" : "Continuar"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Volver
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Todos los pasos terminados</Typography>
          <Button
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
            variant="contained"
          >
            Reinicar
          </Button>
        </Paper>
      )}
      {/* <Box
        sx={{
          maxWidth: 400,
          height: 90,
          borderRadius: 4,
          background: "rgba(149, 248, 212, 0.8)",
          backdropFilter: " blur(4px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: 10,
        }}
      >
        <Typography sx={{ fontWeight: 900, textTransform: "capitalize" }}>
          Datos guardados con exito
        </Typography>
      </Box> */}
    </Box>
  );
}
