import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import { types } from '../../../types/types';

export const getRequestClassesById = (id) => {
  return async (dispatch) => {
    const requestClass = await requestClassesById(id);
    dispatch(setRequestClassInfo(requestClass));
  };
};

export const getInvoicesById = (id) => {
  return async (dispatch) => {
    const invoice = await invoicesById(id);
    dispatch(setInvoiceInfo(invoice));
  };
};

const requestClassesById = async (id) => {
  const resp = await axios.get(`${apiUrl}requestClasses/${id}`);
  return resp.data.RequestClasses;
};

const invoicesById = async (id) => {
  const resp = await axios.get(`${apiUrl}invoices/${id}`);
  return resp.data.invoices;
};

const setInvoiceInfo = (invoice) => {
  return {
    type: types.setIvoiceInfo,
    payload: { invoice },
  };
};

const setRequestClassInfo = (requestClass) => {
  return {
    type: types.setRequestClass,
    payload: { requestClass },
  };
};

const setEmergency = (emergency) => {
  return {
    type: types.setEmergency,
    payload: { emergency },
  };
};

export const setResumen = (hoursNumber, date, schedule) => {
  return {
    type: types.setResumen,
    payload: { hoursNumber, date, schedule },
  };
};

export const setResumenPrice = (price) => {
  return {
    type: types.setResumenPrice,
    payload: { price },
  };
};
