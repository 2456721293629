import React, { useEffect, useState } from 'react';
import { Fab, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CardChatSelected } from './CardChatSelected';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useSelector } from 'react-redux';
import { EmptyState } from '../EmptyState';
import { Send } from './Send';
import { Receive } from './Receive';
import { io } from 'socket.io-client';
import { urlSocket } from '../../../config/apiConfig';
import { scrollToBottom, scrollToBottomAnimated } from '../../../utils/scrollToBottom';
import { useUser } from '../../../hooks/useUser';

// const socket = io.connect('http://api.ilustrosoft.com');

const initFormMessage = {
  message: '',
};

const connectSocketServer = () => {
  const socket = io.connect(urlSocket, { transports: ['websocket'] });
  return socket;
};

export const ChatMessages = () => {
  const classes = useStyles();
  const [formMessage, setformMessage] = useState(initFormMessage);
  const [socket] = useState(connectSocketServer());
  const [online, setOnline] = useState(false);
  const [listMessage, setListMessage] = useState([]);

  const { getUserById } = useUser();

  const chat = useSelector(({ studentReducers }) => studentReducers.chatReducer.user);
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const handleInputChange = (e) => {
    setformMessage({
      ...formMessage,
      [e.target.name]: e.target.value,
    });
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    if (formMessage.message.trim()) {
      socketEmit();
      setformMessage(initFormMessage);
    }
  };

  const socketEmit = () => {
    // sendNotification();
    socket.emit('menssenger', { channel: chat.channel, chatId: chat.chatId, userEmitterId: user.id, message: formMessage.message, status: true }, (res) => {
      setformMessage(initFormMessage);
      setListMessage([
        ...listMessage,
        {
          chatId: res.chatId,
          userEmitterId: user.id,
          message: res.message,
          status: true,
          createdAt: res.createdAt,
        },
      ]);
      scrollToBottomAnimated('messages');
    });
  };

  // const sendNotification = async () => {
  //   const teacher = await getUserById(chat.id);
  //   await sendMesaage([teacher.expoToken], message);
  // };

  const loadChat = () => {
    socket.emit('loadchat', { chatId: chat.chatId, channel: chat.channel }, (res) => {
      setListMessage(res);
    });
  };

  const socketOn = () => {
    console.log('socketOn');
    socket.on(chat.channel, function (res) {
      console.log(res)
      setListMessage([...listMessage, res]);
      scrollToBottomAnimated('messages');
    });
  };

  useEffect(() => {
    setOnline(socket.connected);
    scrollToBottom('messages');
  }, [socket, chat.channel]);

  useEffect(() => {
    socket.on('connect', () => {
      setOnline(true);
    });
  }, [socket, chat.channel]);

  useEffect(() => {
    socket.on('disconnect', () => {
      setOnline(false);
    });
  }, [socket, chat.channel]);

  useEffect(() => {
    loadChat();
  }, [chat.channel]);

  useEffect(() => {
    socketOn();
  }, [socketEmit]);

  return chat.fullName ? (
    <div>
      <div>
        <CardChatSelected name={chat.fullName} img={chat.img} speciality="" university="" />
      </div>
      <div>
        <div id="messages" className={classes.containerMessages}>
          {listMessage.map((m, i) => (m.userEmitterId === user.id ? <Receive message={m.message} date={m.createdAt} key={i} /> : <Send key={i} date={m.createdAt} message={m.message} />))}
        </div>
        <div>
          <form className={classes.containerTextInputAndButton} type="submit" onSubmit={onSendMessage}>
            <TextField name="message" value={formMessage.message} onChange={handleInputChange} fullWidth className={classes.textField} placeholder="Escribir mensaje" />
            <Fab type="submit" onClick={onSendMessage} variant="extended" sx={{ ml: 4, borderRadius: 100 }} color="primary">
              <SendOutlinedIcon fontSize="small" />
            </Fab>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <EmptyState title="Selecciona un chat" />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 45,
    },
    [`& fieldset`]: {
      borderRadius: '20px',
      background: '#F4F7F5',
      zIndex: '-1000',
    },

    '&:focus': {
      outline: 'none',
    },
  },
  containerMessages: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 300,
    maxHeight: 300,
    borderRadius: 20,
    background: '#F4F7F5',
    marginBottom: 30,
    padding: 20,
    overflow: 'auto',
  },
  containerTextInputAndButton: {
    display: 'flex',
  },
}));
