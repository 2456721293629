import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Chip,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Badge,
  IconButton,
  Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { helperSchedules } from "../../../../../services/helperSchedules";
import * as homeActions from "../../../../HomePage/store/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const initialData = {
  scheduleFrequency: "every-week",
  startTime: moment()._d,
  endTime: moment()._d,
};

export const AvaListItem = (props) => {
  const classes = useStyles();
  const { color, day, startTime, endTime, frecuency, idSchedule, user } = props;

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(initialData);

  useEffect(() => {
    setSelected({
      scheduleFrequency: frecuency,
      startTime: startTime,
      endTime: endTime,
    });
  }, [endTime, frecuency, startTime]);

  const handleChangeFrecuency = (e) => {
    setSelected({
      ...selected,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickDelSchedule = async () => {
    await helperSchedules.deleteSchedule(idSchedule);
    dispatch(homeActions.getSchedulesByUserId(user.id));
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "19px",
          display: "block",
          my: 2,
        }}
      >
        {/* <b>{day}</b> */}
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Typography sx={{ fontWeight: "inherit" }}>Inicio</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography sx={{ fontWeight: "inherit" }}>Finalización</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Chip
            label={
              <Typography>{moment(selected.startTime).format("LT")}</Typography>
            }
            sx={{
              width: "100%",
              height: "50px",
              bgcolor: color,
              borderRadius: "50px",
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Badge
            // color="primary"
            badgeContent={<b>X</b>}
            sx={{
              width: "75%",
              height: "50px",
              bgcolor: color,
              borderRadius: "50px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleClickDelSchedule}
          >
            <Typography>{moment(selected.endTime).format("LT")}</Typography>
            {/* <Chip
              label={<Typography>5:00 am</Typography>}
              // sx={{ width: "100%", height: "50px", bgcolor: color }}
            /> */}
          </Badge>
        </Grid>
        <Grid item md={8}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected.scheduleFrequency}
              name="scheduleFrequency"
              sx={{
                borderRadius: 6,
                background: "#F4F7F5",
                border: "none",
                outline: "none",
                height: 50,
                fontWeight: "inherit",
              }}
              onChange={handleChangeFrecuency}
            >
              <MenuItem value={"every-week"} sx={{ fontWeight: "inherit" }}>
                Repetir cada {day} indefinidamente
              </MenuItem>
              <MenuItem value={"one-week"} sx={{ fontWeight: "inherit" }}>
                Repetir cada {day} este mes
              </MenuItem>
              <MenuItem value={"only-day"} sx={{ fontWeight: "inherit" }}>
                Repetir solo el día {day}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item md={3}>
          <Fab
            sx={{
              width: "100%",
              height: 50,
              bgcolor: color,
              borderRadius: "50px",
            }}
            variant="extended"
          >
            <Add />
          </Fab>
        </Grid> */}
      </Grid>
    </div>
  );
};
