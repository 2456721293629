import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiUrl } from '../config/apiConfig';
import * as fuseActions from '../store/actions/fuse';

export const useClass = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getClassForReview = async () => {
    const resp = await axios.get(`${apiUrl}view/getClassForReview`);
    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        return resp.data.AllClass;
      }
    } else {
      setIsLoading(false);
      dispatch(
        fuseActions.showMessage({
          message: 'Ha ocurrido un error, vuelva a intentarlo mas tarde',
          variant: 'error',
        })
      );
    }
    console.log(resp);
  };

  const postClass = async (userCourseId, studentId, date, schedule, hoursNumber, price, percentPrice, stateRequestClass) => {
    try {
      const resp = await axios.post(`${apiUrl}requestClasses`, { userCourseId, studentId, date, schedule, hoursNumber, price, percentPrice, stateRequestClass });

      return resp.data.RequestClasses;
    } catch (error) {
      console.log(error);
    }
  };

  return { getClassForReview, isLoading, postClass };
};
