import React from 'react';
import { makeStyles } from '@mui/styles';
import { AdminProfessorInfoCard } from '../../../AdminPage/AdminProfessorPage/AdminProfessorInfo/AdminProfessorInfoCard';
import { Chip, Fab, Grid, IconButton, Typography } from '@mui/material';
import urgentOnly from '../../../../assets/icon/urgentOnly.svg';
import { useHistory } from 'react-router-dom';
import { colorsList } from '../../../../utils/colorsList';
import { useDispatch } from 'react-redux';
import { setDateRequestClass, setPriceRequestClass } from '../../../../store/actions/students/requestClassActions';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 30px' },
}));
export const StdUrgentTeacherInfo = ({ professionalDescription = '', subjects = [], name = '', price, img, status, review }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const clicNow = () => {
    history.push('/student/payment');
    dispatch(setDateRequestClass(new Date()));
    dispatch(setPriceRequestClass(price));
  };

  const clicSchedule = () => {
    history.push('/student/schedule');
    // dispatch(setDateRequestClass(new Date()));
    dispatch(setPriceRequestClass(price));
  };

  return (
    <div className={classes.root}>
      <AdminProfessorInfoCard name={name} price={price} img={img} status={status} review={review} />
      <Typography variant="h5" sx={{ fontWeight: 400, my: 4 }}>
        {professionalDescription}
      </Typography>

      {subjects.length > 0 && subjects.map((item, idx) => <Chip key={idx} label={<Typography sx={{ fontSize: '14px' }}>{item.nameSubject}</Typography>} sx={{ bgcolor: colorsList[idx], margin: 0.5 }} />)}

      <Grid container sx={{ my: 6 }} alignItems="center" spacing={2}>
        <Grid item md={8}>
          <Fab variant="extended" color="primary" sx={{ width: '100%', height: 60 }} onClick={() => clicSchedule()}>
            <Typography sx={{ textTransform: 'capitalize' }}>Agenda tu clase</Typography>
          </Fab>
        </Grid>
        <Grid item md={4} justifyContent="center" alignItems="center">
          <IconButton size="large" color="primary" aria-label="upload picture" component="span" onClick={() => clicNow()} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', bgcolor: '#FEB098', width: 117, height: 115 }}>
            <img src={urgentOnly} alt="urgentOnly" />
            <Typography>Ahora</Typography>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};
