import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { helperReqHomeworks } from "../../../services/helperReqHomeworks";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdProHistoryItem = (props) => {
  const classes = useStyles();
  const {
    name,
    date,
    time,
    // price,
    url,
    period,
    avatar,
    status,
    reqHomeworkId,
  } = props;

  const [homework, setHomework] = useState({ price: 0 });

  useEffect(() => {
    const getPrice = async () => {
      const getHomework = await helperReqHomeworks.getReqHomeworkById(
        reqHomeworkId
      );
      setHomework(getHomework);
    };
    getPrice();
  }, [reqHomeworkId]);

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item md={2}>
          <Avatar src={avatar} sx={{ width: 140, height: 140 }} />
        </Grid>
        <Grid item md={7}>
          <Typography>{name}</Typography>
          <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
            <img
              src="https://img.icons8.com/material-outlined/18/000000/calendar-minus.png"
              alt="calendar-icon"
            />{" "}
            {date} &nbsp; &nbsp;{" "}
            <img
              src="https://img.icons8.com/material-outlined/18/000000/clock.png"
              alt="clock-icon"
            />
            &nbsp;&nbsp;{time} &nbsp;&nbsp;&nbsp;&nbsp; |
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ color: "#0AD78D" }}>${homework.price}</span>
          </Typography>
          <Typography>
            <span style={{ color: "#E89F88" }}>
              Hora de clase : {period} &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <a href={url} target="_blank" rel="noreferrer">
              <span style={{ color: "#787777" }}>{url.substr(0, 50)}...</span>
            </a>
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Button>
            <Typography
              sx={{
                color: status === "completed" ? "success.main" : "#E89F88",
                textTransform: "capitalize",
              }}
            >
              {status}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
