import React from 'react';
import { makeStyles } from '@mui/styles';
import { Chip, Grid, Rating, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { colorsList } from '../../../../../utils/colorsList';
import { useDispatch } from 'react-redux';
import { getTeacherInfoSelectedAdmin } from '../../../../../store/actions/admin/teachersActions';
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdProfessorsItemDescription = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { name, role, university, review, price, subject, img, desc, certifications, status, teacher, teacherTrainingId, userId } = props;
  const dispatch = useDispatch();

  const selectedTeacher = () => {
    history.push(`/admin/professor/${userId}`);
    dispatch(getTeacherInfoSelectedAdmin(userId));
  };

  const selectedTeacherReview = () => {
    history.push(`/admin/review/${userId}`);
    dispatch(getTeacherInfoSelectedAdmin(userId));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" sx={{ mb: 1, cursor: 'pointer' }} onClick={() => selectedTeacher()}>
        {name}
      </Typography>
      <Typography sx={{ color: '#9C9C9C', mb: 1 }}>
        {role} &nbsp;&nbsp;&nbsp;
        {university}
      </Typography>
      <Grid container sx={{ mb: 1 }}>
        <Rating value={review} readOnly />
        <Typography sx={{ cursor: 'pointer' }} onClick={() => selectedTeacherReview()}>
          &nbsp;&nbsp;&nbsp;({review})&nbsp;&nbsp;&nbsp;
        </Typography>
      </Grid>

      {subject.map((item, index) => (
        <Chip label={item.nameLevel} sx={{ bgcolor: colorsList[index], mx: 1 }} key={index} />
      ))}
    </div>
  );
};
