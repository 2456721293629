import { Fab, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import regLogo from "../../assets/image/registerLogo.png";
import filterIcon from "../../assets/image/filter.svg";
import introIcon from "../../assets/image/intro.svg";
const useStyle = makeStyles({
  root: {
    padding: "3rem 1rem",
  },
});
export const HomeTool = ({
  filterClasses,
  setFilterClasses,
  filterHomeworksPending,
  setFilterHomeworksPending,
}) => {
  const classes = useStyle();

  const filterByClasses = () => {
    setFilterHomeworksPending(!filterHomeworksPending);
  };
  const filterByHomeworks = () => {
    setFilterClasses(!filterClasses);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        textAlign="center"
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: 600 }}
      >
        <Grid item>
          <Typography variant="h4" sx={{ display: "block", mb: 2 }}>
            Revisa aquí tus solicitudes
          </Typography>
        </Grid>
        <Grid item>
          <img src={regLogo} alt="registerLogo" />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          sx={{ mt: 8, width: "100%" }}
        >
          <div style={{ display: "flex", gap: "4rem", flexWrap: "wrap" }}>
            <Fab onClick={filterByHomeworks}>
              {<img src={filterIcon} alt="filterIcon" />}
            </Fab>
            <Fab onClick={filterByClasses}>
              {<img src={introIcon} alt="introIcon" />}
            </Fab>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
