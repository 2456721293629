import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@mui/material";
import { WalletUserList } from "./WalletUserList";
import { ServiceBenefit } from "./ServiceBenefit";
import { useSelector, useDispatch } from "react-redux";
import { useTotalService } from "../../hooks/useTotalService";
import useForm from "../../hooks/useForm";
// import { months } from "moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const WalletPage = () => {
  const classes = useStyles();

  let user = useSelector(({ loginReducer }) => loginReducer.login.user);

  const { getTotalServiceClassByUser, getTotalServiceHomeworkByUser } =
    useTotalService();
  const [totalServices, setTotalServices] = useState({
    month: "",
    year: 0,
    total: 0,
    percent: 0,
  });

  const { month, year, total, percent } = totalServices;

  const [monthSelected, setMonthSelected] = useState("January");
  const { monthSearch, onChange } = useForm({ monthSearch: "Select" });

  const getListClassService = async (months) => {
    const classR = await getTotalServiceClassByUser(user.id);
    const homework = await getTotalServiceHomeworkByUser(user.id);
    const services = [...classR, ...homework];

    console.log(services);

    let totalM = 0;
    let totalP = 0;
    // console.log("months ==> ", months);
    const a = services
      .filter((s) => {
        return s.month === months;
      })
      .map((s) => {
        totalM = totalM + s.Total;
        totalP = totalP + s.Percent;
        return s;
      });
    // console.log("total M =>", totalM);
    if (totalM !== 0) {
      setTotalServices({
        month: a[0].month,
        year: a[0].year,
        total: totalM,
        percent: totalP,
      });
      // console.log("total P =>", totalP);
    } else {
      let now = moment().format("YYYY");
      setTotalServices({
        month: months,
        year: parseInt(now),
        total: totalM,
        percent: totalP,
      });
    }
  };

  const search = (month) => {
    let aux = "";
    if (month === "January") {
      aux = "Enero";
    } else if (month === "February") {
      aux = "Febrero";
    } else if (month === "March") {
      aux = "Marzo";
    } else if (month === "April") {
      aux = "Abril";
    } else if (month === "May") {
      aux = "Mayo";
    } else if (month === "June") {
      aux = "Junio";
    } else if (month === "July") {
      aux = "Julio";
    } else if (month === "August") {
      aux = "Agosto";
    } else if (month === "September") {
      aux = "Septiembre";
    } else if (month === "October") {
      aux = "Octubre";
    } else if (month === "November") {
      aux = "Noviembre";
    } else if (month === "December") {
      aux = "Diciembre";
    }
    setMonthSelected(aux);
    getListClassService(month);
  };

  useEffect(() => {}, [totalServices]);

  return (
    <div className={classes.root}>
      <Grid container columns={12}>
        <Grid item xs={8}>
          <WalletUserList
            monthSearch={monthSearch}
            onChange={onChange}
            search={search}
            user={user}
          />
        </Grid>
        <Grid item xs={1} justifyContent="center" container>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3}>
          <ServiceBenefit
            month={month}
            year={year}
            total={total}
            percent={percent}
            monthSelected={monthSelected}
          />
        </Grid>
      </Grid>
    </div>
  );
};
