import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperDevSchools = {};


helperDevSchools.putSchool = async (id, data) => {
  console.log('entra')
  try {
    const res = await axios.put(`${apiUrl}schools/icon/${id}`, data);
    console.log("res data put dev school-->", res.data);
    return res.data.schoolDB;
    
  } catch (error) {
    console.log('--->',error)
  }
};

