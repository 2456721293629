import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@mui/material";
import { ProfitsList } from "./ProfitsList";
import { ProfitsInfoList } from "./ProfitsInfoList";
import { FormControl, MenuItem, Select, Box } from "@mui/material";
import { useTotalService } from "../../../hooks/useTotalService";
import useForm from "../../../hooks/useForm";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminProfessorProfitsPage = ({ userId }) => {
  const classes = useStyles();

  const { getTotalServiceClassByUser, getTotalServiceHomeworkByUser } =
    useTotalService();
  const [totalServices, setTotalServices] = useState({
    month: "",
    year: 0,
    total: 0,
    percent: 0,
  });

  const { month, year, total, percent } = totalServices;

  const [monthSelected, setMonthSelected] = useState("January");
  const { monthSearch, onChange } = useForm({ monthSearch: "Select" });

  const getListClassService = async (months) => {
    const classR = await getTotalServiceClassByUser(userId);
    const homework = await getTotalServiceHomeworkByUser(userId);
    const services = [...classR, ...homework];

    console.log(services);

    let totalM = 0;
    let totalP = 0;
    // console.log("months ==> ", months);
    const a = services
      .filter((s) => {
        return s.month === months;
      })
      .map((s) => {
        totalM = totalM + s.Total;
        totalP = totalP + s.Percent;
        return s;
      });
    // console.log("total M =>", totalM);
    if (totalM !== 0) {
      setTotalServices({
        month: a[0].month,
        year: a[0].year,
        total: totalM,
        percent: totalP,
      });
      // console.log("total P =>", totalP);
    } else {
      let now = moment().format("YYYY");
      setTotalServices({
        month: months,
        year: parseInt(now),
        total: totalM,
        percent: totalP,
      });
    }
  };

  const search = (month) => {
    let aux = "";
    if (month === "January") {
      aux = "Enero";
    } else if (month === "February") {
      aux = "Febrero";
    } else if (month === "March") {
      aux = "Marzo";
    } else if (month === "April") {
      aux = "Abril";
    } else if (month === "May") {
      aux = "Mayo";
    } else if (month === "June") {
      aux = "Junio";
    } else if (month === "July") {
      aux = "Julio";
    } else if (month === "August") {
      aux = "Agosto";
    } else if (month === "September") {
      aux = "Septiembre";
    } else if (month === "October") {
      aux = "Octubre";
    } else if (month === "November") {
      aux = "Noviembre";
    } else if (month === "December") {
      aux = "Diciembre";
    }
    setMonthSelected(aux);
    getListClassService(month);
  };

  useEffect(() => {}, [totalServices]);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" columns={15}>
        <Grid item md={7}>
          <div
            style={{
              float: "right",
              width: "100%",
              height: 60,
              display: "inline",
              direction: "rtl",
              position: "relative",
            }}
          >
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={monthSearch}
                sx={{
                  borderRadius: 6,
                  background: "#F4F7F5",
                  border: "none",
                  height: 50,
                  width: "40%",
                  textAlign: 'center'
                }}
                onChange={(e) => {
                  onChange(e.target.value, "monthSearch");
                  if (e.target.value !== "Select") {
                    search(e.target.value);
                  }
                }}
              >
                <MenuItem value="Select" key="Select">
                  Seleccione un mes
                </MenuItem>
                <MenuItem value="January" key="January">
                  Enero
                </MenuItem>
                <MenuItem value="February" key="February">
                  Febrero
                </MenuItem>
                <MenuItem value="March" key="March">
                  Marzo
                </MenuItem>
                <MenuItem value="April" key="April">
                  Abril
                </MenuItem>
                <MenuItem value="May" key="May">
                  Mayo
                </MenuItem>
                <MenuItem value="June" key="June">
                  Junio
                </MenuItem>
                <MenuItem value="July" key="July">
                  Julio
                </MenuItem>
                <MenuItem value="August" key="August">
                  Agosto
                </MenuItem>
                <MenuItem value="September" key="September">
                  Septiembre
                </MenuItem>
                <MenuItem value="October" key="October">
                  Ocutubre
                </MenuItem>
                <MenuItem value="November" key="November">
                  Noviembre
                </MenuItem>
                <MenuItem value="December" key="December">
                  Diciembre
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <ProfitsList
            month={month}
            year={year}
            total={total}
            percent={percent}
            monthSelected={monthSelected}
          />
        </Grid>
        <Grid item md="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={7}>
          <ProfitsInfoList userId={userId} />
        </Grid>
      </Grid>
    </div>
  );
};
