import React from 'react';
import { makeStyles } from '@mui/styles';
import { Chip, Grid, Rating, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { colorsList } from '../../../utils/colorsList';
import { useDispatch } from 'react-redux';
import { getTeacherInfoSelected } from '../../../store/actions/students/teachersActions';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const DescriptionTeacher = ({ teacher }) => {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();

  const teacherSelected = (idUser, idLevel, userCourseId) => {
    dispatch(getTeacherInfoSelected(idUser, idLevel, userCourseId));

    history.push(`/student/urgent-teachers`);
  };

  console.log(teacher.review, teacher.starReview)

  return (
    <div className={classes.root}>
      <Typography variant="h5" sx={{ mb: 1, cursor: 'pointer' }} onClick={() => teacherSelected(teacher.userId, teacher.levelId, teacher.id)}>
        {teacher.fullName}
      </Typography>
      <Typography sx={{ color: '#9C9C9C', mb: 1 }}>
        {teacher.specialty} &nbsp;&nbsp;&nbsp;
        {teacher.university}
      </Typography>
      <Grid container sx={{ mb: 1 }}>
        <Rating value={teacher.starReview || 0} readOnly />
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            // history.push(`/admin/review/${name}`)
            console.log('clic review')
          }
        >
          &nbsp;&nbsp;&nbsp;({teacher.review ? teacher.review : 0})&nbsp;&nbsp;&nbsp;
        </Typography>
      </Grid>

      {teacher.subject > 0 && teacher.subject.length > 0 && teacher.subject.map((item, index) => <Chip label={item.nameLevel} sx={{ bgcolor: colorsList[index], mx: 1 }} key={index} />)}
    </div>
  );
};
