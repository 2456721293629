import React from "react";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { BotBackground } from "../../components/common/BotBackground";
import { HomePage } from "../../components/HomePage";

export const HomeScreen = () => {
  return (
    <div>
      <LoginedTopBackground title="Solicitudes" search />
      <HomePage />
      <BotBackground />
    </div>
  );
};
