import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperDevSubject = {};

helperDevSubject.putSubject = async (id, data) => {
  const res = await axios.put(`${apiUrl}subjects/icon/${id}`, data);
  console.log("res data put dev subject -->", res.data);
  return res.data.SubjectDB;
};
