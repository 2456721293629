import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Typography, Fab } from '@mui/material';
import bank from '../../../../../assets/icon/bank.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ' rgba(213, 184, 253, 1)',
    borderRadius: '16px',
    padding: '18px',
    marginBottom: '60px',
  },
}));
export const PaySeletorItem = ({ methodName }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={4} justifyContent="space-between">
        <Grid item>
          <Button sx={{ width: 50, height: 60, borderRadius: '10px' }} color="secondary" variant="contained">
            <img src={bank} alt="bank" />
          </Button>
        </Grid>
        <Grid item md={9}>
          <Typography> {methodName}</Typography>
        </Grid>
        <Grid item>
          <Fab
            // onClick={handleClick4}
            color="secondary"
            size="large"
            //     onClick={handleClickShowPassword}
            //     onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            <ExpandLessIcon fontSize="large" />
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
};
