import React from "react";
import { makeStyles } from "@mui/styles";
import { LoginedTopBackground } from "../../components/common/LoginedTopBackground";
import { BotBackground } from "../../components/common/BotBackground";
import { AdminHomePage } from "../../components/AdminPage/AdminHomePage";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const AdminHome = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LoginedTopBackground title="Solicitudes" />
      <AdminHomePage />
      <BotBackground />
    </div>
  );
};
