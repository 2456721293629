import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { StartScreen } from '../screens/StartScreen';
import '../styles/StartStyle.css';
import { useSelector } from 'react-redux';
import { LoginScreen } from '../screens/LoginScreen';
import { RegisterScreen } from '../screens/RegisterScreen';
import { AdminScreen } from '../screens/AdminScreen';
import { StudentScreen } from '../screens/StudentScreen';
import { PublicRoute } from './publicRoute';
import { PrivateRoute } from './privateRoute';
import { ProfessorScreen } from '../screens/ProfessorScreen';

export const AppRouter = () => {
  const user = useSelector(({ loginReducer }) => loginReducer.login.user);
  const isAuthenticated = user !== undefined;
  const userRole = user !== undefined ? user.rol : '';

  return (
    <BrowserRouter>
      <Switch>
        {/* ================== professor================== */}
        <PublicRoute isAuthenticated={isAuthenticated} path="/" exact component={StartScreen} />
        <PublicRoute isAuthenticated={isAuthenticated} path="/login" component={LoginScreen} />
        <PublicRoute path="/register" isAuthenticated={isAuthenticated} component={RegisterScreen} />

        <PrivateRoute path="/admin" role={userRole} isAuthenticated={isAuthenticated} component={AdminScreen} />
        <PrivateRoute path="/teacher" role={userRole} isAuthenticated={isAuthenticated} component={ProfessorScreen} />
        <PrivateRoute path="/student" role={userRole} isAuthenticated={isAuthenticated} component={StudentScreen} />
        {/* <Route>
          <ProfessorScreen />
        </Route> */}

        {/* <Route path="/home" component={HomeScreen} />
        <Route path="/agenda" component={AgendaScreen} />
        <Route path="/history" component={HistoryScreen} />
        <Route path="/professor-profile" component={ProfessorProfileScreen} />
        <Route path="/level" component={LevelScreen} />
        <Route path="/wallet" component={WalletScreen} />
        <Route path="/availability" component={AgendaAvailabilityScreen} /> */}

        {/* =====================Admin ============================= */}

        {/* =====================Student ============================= */}
        {/* <Route path="/student">
          <StudentScreen />
        </Route> */}
      </Switch>
    </BrowserRouter>
  );
};
