import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProfessorProfileScreen } from '../ProfessorScreen/ProfessorProfileScreen';
import { StudentChatScreen } from '../StudentScreen/StudentChatScreen';
// import { useSelector } from 'react-redux';
import { AdminHome } from './AdminHome';
import { AdminLevelScreen } from './AdminLevelScreen';
import { AdminParams } from './AdminParams';
import { AdminProfessor } from './AdminProfessor';
import { AdminProfessorHistory } from './AdminProfessorHistory';
import { AdminProfessorProfits } from './AdminProfessorProfits';
import { AdminProfessorReview } from './AdminProfessorReview';
import { AdminProfessors } from './AdminProfessors';
import { AdminStudentReview } from './AdminStudentReview';
import { AdminStudents } from './AdminStudents';
export const AdminScreen = () => {
  return (
    <>
      <Switch>
        <Route exact path="/admin/home" component={AdminHome} />
        <Route exact path="/admin/level" component={AdminLevelScreen} />
        <Route exact path="/admin/params" component={AdminParams} />
        <Route exact path="/admin/professor/:name" component={AdminProfessor} />
        <Route exact path="/admin/review/:name" component={AdminProfessorReview} />
        <Route exact path="/admin/professors" component={AdminProfessors} />
        <Route exact path="/admin/students" component={AdminStudents} />
        <Route exact path="/admin/student/:name/review" component={AdminStudentReview} />
        <Route exact path="/admin/professor/:name/history" component={AdminProfessorHistory} />
        <Route exact path="/admin/professor/:name/profits" component={AdminProfessorProfits} />
        <Route path="/admin/profile" component={ProfessorProfileScreen} />
        <Route path="/admin/chat" component={StudentChatScreen} />
        <Redirect from="/admin" to="admin/home" />
      </Switch>
    </>
  );
};
