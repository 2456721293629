import React from "react";
import { makeStyles } from "@mui/styles";
import logLogo from "../../../../assets/image/logLogo.png";
import { Grid, Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: { padding: "100px 20px" },
}));
export const StdHomeworkUserList = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <img src={logLogo} alt="logLogo" />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 10 }}>
        <Typography variant="caption" sx={{ fontWeight: 700 }}>
          Solicitar Videoclase{" "}
        </Typography>
        <Typography>Añade el valor de la hora de clase al total </Typography>
      </Grid>
    </div>
  );
};
