import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    border: '1px solid black',
    padding: '30px',
    marginTop: '30px',
  },
}));
export const StdBankInfoItem = ({ nameBank, typeAccount, namePerson, numberAccount, email }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5">{nameBank}</Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography>Titular: {namePerson}</Typography>
      <Typography sx={{ mt: 1 }}>Cuenta: {numberAccount}</Typography>
      <Typography sx={{ mt: 1 }}>Cuenta: {typeAccount}</Typography>
      <Typography sx={{ my: 1 }}>E-mail: {email} </Typography>
    </div>
  );
};
