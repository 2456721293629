import axios from 'axios';
import { useState } from 'react';
import { apiUrl } from '../config/apiConfig';

export const useSubjects = () => {
  const [isLoading, setIsLoading] = useState(true);

  const getTeacherByIdSubject = async (idSubject) => {
    const resp = await axios.get(`${apiUrl}view/getTeacherBySubjectId/${idSubject}`);
    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        let allTeachers = resp.data.TeacherBySubject.map(async (teacherInformation) => {
          const teacherAverageReview = await getTeacherAverageReview(teacherInformation.userId);
          return {
            ...teacherInformation,
            starReview: Number(teacherAverageReview[0].star) || 0,
          };
        });
        allTeachers = await Promise.all(allTeachers);
        return allTeachers;
      } else {
        setIsLoading(false);
        return [];
      }
    }
  };

  const getTeacherAverageReview = async (userId) => {
    const resp = await axios.get(`${apiUrl}view/reviewstar/${userId}`);
    return resp.data.star;
  };

  const getSubjectsById = async (idSubject) => {
    const resp = await axios.get(`${apiUrl}subjects/${idSubject}`);

    if (resp.status === 200) {
      setIsLoading(false);
      if (resp.data.msg) {
        return resp.data.subjects;
      }
    } else {
      setIsLoading(false);
      return [];
    }
  };

  const getTeacherBackend = async (subjectId) => {
    const resp = await axios.get(`${apiUrl}view/getTeacherBySubjectId/${subjectId}`);
    setIsLoading(false);
    return resp.data.TeacherBySubject;
  };

  return { getSubjectsById, getTeacherByIdSubject, getTeacherBackend, isLoading };
};
