import * as homeActions from "../actions";
const initailState = { requestClasses: [] };
export const homeData = (state = initailState, action) => {
  switch (action.type) {
    case homeActions.GET_VIEW_REQUEST_CLASS: {
      return {
        ...state,
        requestClasses: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateReqHomeworks = { requestTeacherTraining: {} };
export const homeDataTeacherTraining = (
  state = initailStateReqHomeworks,
  action
) => {
  switch (action.type) {
    case homeActions.GET_TEACHER_TRAINING_BY_USER_ID: {
      return {
        ...state,
        requestTeacherTraining: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateHomeworksPending = { reqHomeworksPending: [] };
export const homeDataHomeworksPending = (
  state = initailStateHomeworksPending,
  action
) => {
  switch (action.type) {
    case homeActions.GET_HOMEWORKS_PENDING_BY_TEACHER_TRAINING_ID: {
      return {
        ...state,
        reqHomeworksPending: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateViewHomeworks = { reqViewHomeworks: [] };
export const homeDataViewHomeworks = (
  state = initailStateViewHomeworks,
  action
) => {
  switch (action.type) {
    case homeActions.GET_VIEW_HOMEWORKS_BY_TEACHER_TRAINING_ID: {
      return {
        ...state,
        reqViewHomeworks: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateHomeworks = { reqHomeworks: [] };
export const homeDataHomeworks = (state = initailStateHomeworks, action) => {
  switch (action.type) {
    case homeActions.GET_ALL_REQ_HOMEWORKS: {
      return {
        ...state,
        reqHomeworks: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateAllCities = { reqAllCities: [] };
export const homeDataAllCities = (state = initailStateAllCities, action) => {
  switch (action.type) {
    case homeActions.GET_ALL_CITIES: {
      return {
        ...state,
        reqAllCities: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateAllProvinces = { reqAllProvinces: [] };
export const homeDataAllProvinces = (
  state = initailStateAllProvinces,
  action
) => {
  switch (action.type) {
    case homeActions.GET_ALL_PROVINCES: {
      return {
        ...state,
        reqAllProvinces: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateAllCountries = { reqAllCountries: [] };
export const homeDataAllCountries = (
  state = initailStateAllCountries,
  action
) => {
  switch (action.type) {
    case homeActions.GET_ALL_COUNTRIES: {
      return {
        ...state,
        reqAllCountries: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateViewCourseByUser = { reqViewCourseByUser: [] };
export const homeDataViewCourseByUser = (
  state = initailStateViewCourseByUser,
  action
) => {
  switch (action.type) {
    case homeActions.GET_VIEW_COURSES_BY_USER: {
      return {
        ...state,
        reqViewCourseByUser: action.payload,
      };
    }

    default:
      return state;
  }
};

const initailStateSchedulesByUserId = { reqSchedulesByUserId: [] };
export const homeDataSchedulesByUserId = (
  state = initailStateSchedulesByUserId,
  action
) => {
  switch (action.type) {
    case homeActions.GET_SCHEDULES_BY_USER_ID: {
      return {
        ...state,
        reqSchedulesByUserId: action.payload,
      };
    }

    default:
      return state;
  }
};

