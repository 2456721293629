import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";

import { helper } from "../../../services/helperSchools";
import { helperSubjects } from "../../../services/helperSubjects";
import * as fuseActions from "../../../store/actions/fuse";

const initialDataSchool = {
  nameSchool: "",
  idLevel: null,
  // iconUrl: null,
};

const initialDataSubject = {
  nameSubject: "",
  schoolId: null,
  // iconUrl: null,
};

export default function FormDialog({
  selectedRow,
  newSchool,
  newSubject,
  isOpen,
  idLevel,
  setOpenModal,
  setSubjects,
  subjects,
  // defaultLevel,
  // setDefaultLevel,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState({});
  // const [dLevel, setDLevel] = useState(defaultLevel);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
      if (selectedRow) {
        setSelected(selectedRow);
      } else {
        if (newSubject) {
          setSelected(initialDataSubject);
        } else {
          setSelected(initialDataSchool);
        }
      }
    } else {
      setOpen(false);
    }
  }, [isOpen, newSubject, selectedRow]);

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
  };

  // console.log("id level, ==>", idLevel);

  const handleClickSave = async () => {
    async function newSchoolVallidate() {
      // console.log("new school");
      selected.idLevel = idLevel;
      if (selected.nameSchool.trim() !== "") {
        // console.log("selected ==>", selected);
        const newData = await helper.postSchool(selected);
        // if (idLevel !== 4) {
        const newSubject = initialDataSubject;
        newSubject.nameSubject = selected.nameSchool;
        newSubject.schoolId = newData.id;
        const response = await helperSubjects.postSubject(newSubject);
        // }
        const rows = [...subjects, newData];
        setSubjects(rows);
      } else {
        dispatch(
          fuseActions.showMessage({
            message: "¡No puede guardar campos vacios!",
            variant: "error",
          })
        );
      }
    }

    async function updateSchoolVallidate() {
      // console.log("update school");
      if (selected.nameSchool.trim() !== "") {
        const id = selectedRow.id;
        // console.log("id ==>", id);
        delete selectedRow.id;
        console.log("selected ==>", selected);
        const newData = await helper.putSchool(id, selected);
        const getSubjects = await helperSubjects.getSubjectsBySchoolId(id);
        const subject = getSubjects[0];
        await helperSubjects.putSubject(subject.id, {
          nameSubject: selected.nameSchool,
        });
        let rows = [...subjects, newData];
        rows = rows.filter((el) => el.id !== id);
        rows = [...rows, newData];
        if (idLevel === 4 || subjects[0].subjects) {
          const allSubjects = await helperSubjects.getSubjectsBySchoolId(
            selected.id
          );
          rows = rows.map(async (el) => {
            if (el.id === selected.id) {
              return {
                ...el,
                subjects: allSubjects,
              };
            } else {
              return el;
            }
          });
          rows = await Promise.all(rows);
        } else {
          // console.log("selected ==>", selected);
        }
        setSubjects(rows);
      } else {
        dispatch(
          fuseActions.showMessage({
            message: "¡No puede guardar campos vacios!",
            variant: "error",
          })
        );
      }
    }

    async function newSubjectVallidate() {
      console.log("new subject");
      selected.nameSubject = selected.nameSchool;
      selected.schoolId = newSubject.id;
      delete selected.nameSchool;
      delete selected.idLevel;
      if (selected.nameSubject.trim() !== "") {
        const newRow = await helperSubjects.postSubject(selected);
        let rows = subjects.map(async (subject) => {
          if (subject.id === newSubject.id) {
            return {
              ...subject,
              subjects: [...(subject.subjects || []), newRow],
            };
          } else {
            return subject;
          }
        });

        rows = await Promise.all(rows);
        console.log('rows ==>', rows)
        setSubjects(rows);
      } else {
        dispatch(
          fuseActions.showMessage({
            message: "¡No puede guardar campos vacios!",
            variant: "error",
          })
        );
      }
    }

    async function updateSubjectVallidate() {
      // console.log("update subject");
      if (selected.nameSubject.trim() !== "") {
        const id = selected.id;
        delete selected.schoolId;
        delete selected.id;
        const newData = await helperSubjects.putSubject(id, selected);
        let newRows = subjects.map(async (subject) => {
          if (subject.id === selectedRow.schoolId) {
            let rows = subject.subjects.filter((el) => el.id !== id);
            return {
              ...subject,
              subjects: [...rows, newData],
            };
          } else {
            return subject;
          }
        });
        newRows = await Promise.all(newRows);
        setSubjects(newRows);
      } else {
        dispatch(
          fuseActions.showMessage({
            message: "¡No puede guardar campos vacios!",
            variant: "error",
          })
        );
      }
    }

    if (idLevel !== 4) {
      if (newSchool) {
        newSchoolVallidate();
      } else {
        if (selected.nameSubject) {
          updateSubjectVallidate();
        } else {
          updateSchoolVallidate();
        }
      }
    } else {
      if (newSchool) {
        if (newSubject) {
          newSubjectVallidate();
        } else {
          newSchoolVallidate();
        }
      } else {
        if (selected.nameSubject) {
          updateSubjectVallidate();
        } else {
          updateSchoolVallidate();
        }
      }
    }
    handleClose();
  };

  const handleChange = (e) => {
    setSelected({ ...selected, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {idLevel !== 4
          ? newSchool
            ? "Nueva materia"
            : "Editar materia"
          : newSchool
          ? newSubject
            ? "Nueva materia"
            : "Nueva escuela"
          : selected.nameSubject
          ? "Editar materia"
          : "Editar escuela"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={
            idLevel === 4
              ? selectedRow
                ? selected.nameSubject
                  ? ""
                  : "Ingrese una escuela"
                : newSubject
                ? ""
                : "Ingrese una escuela"
              : ""
          }
          type="text"
          fullWidth
          variant="standard"
          name={
            selectedRow
              ? selectedRow.nameSubject
                ? "nameSubject"
                : "nameSchool"
              : "nameSchool"
          }
          defaultValue={selected.nameSchool || selected.nameSubject}
          onChange={(e) => handleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="error">
          Cancelar
        </Button> */}
        <Button onClick={handleClickSave} variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
