import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLevels } from '../../../hooks/useLevels';
import { colorsList } from '../../../utils/colorsList';
import { Loading } from '../../common/Loading';
import { EmptyState } from '../../common/EmptyState';
import { useDispatch } from 'react-redux';

import { selectLevel } from '../../../store/actions/students/studentActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
export const StudentHomePage = ({ allLevels, isLoading }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const chooseLevel = (level) => {
    dispatch(selectLevel(level.id, level.nameLevel, level.schools));
    history.push('/student/main', { idLevel: level.id, nameLevel: level.nameLevel, schools: level.schools });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Escoge tu Nivel</Typography>
      <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: 300, mt: 2 }}>
        Elige un nivel, lo puedes cambiar en el futuro
      </Typography>
      <Grid container justifyContent="center" alignItems="center" sx={{ mt: 10, zIndex: 2, width: { xs: '100%', sm: '100%', md: '70%' } }}>
        {isLoading ? (
          <Loading titleLoading="Cargando niveles" />
        ) : allLevels.length > 0 ? (
          allLevels.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                width: '385px',
                height: '94px',
                bgcolor: colorsList[idx],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px',
                mx: 2,
                my: 2,
                cursor: 'pointer',
              }}
              onClick={() => chooseLevel(item)}
            >
              <Typography>{item.nameLevel}</Typography>
            </Box>
          ))
        ) : (
          <EmptyState title="No existe niveles" />
        )}
      </Grid>
    </div>
  );
};
