import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

export const useLevels = () => {
  const [AllLevels, setAllLevels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [apiUrl]);

  const loadData = async () => {
    const levels = await getLevels();
    let allLevels = levels.map(async (level) => {
      const schools = await getSchoolsByLevel(level.id);
      return {
        ...level,
        schools: schools,
      };
    });

    allLevels = await Promise.all(allLevels);
    // console.log('all levels', allLevels);
    setIsLoading(false);
    setAllLevels(allLevels);
  };

  const getLevels = async () => {
    const resp = await axios.get(`${apiUrl}levels`);
    return resp.data.LevelsDB;
  };

  const getSchools = async () => {
    const resp = await axios.get(`${apiUrl}schools`);
    return resp.data.school;
  };

  const getSchoolsByLevel = async (id) => {
    const AllSchools = await getSchools();
    const schools = AllSchools.filter((school) => school.idLevel === id);
    return schools;
  };

  const getPricesByUserIdAndLevelId = async (userId, levelId) => {
    const resp = await axios.get(`${apiUrl}userprices/${userId}/${levelId}`);
    return resp.data.userUserPrices;
  };

  const getLevelById = async (id) => {
    const resp = await axios.get(`${apiUrl}levels/${id}`);
    return resp.data.levels;
  };

  return {
    AllLevels,
    isLoading,
    getLevels,
    getPricesByUserIdAndLevelId,
    getLevelById
  };
};
