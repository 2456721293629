import React from "react";
import { makeStyles } from "@mui/styles";
import { RoleCard } from "../../common/RoleCard";
import user from "../../../assets/image/user.svg";
import academy from "../../../assets/image/academy.svg";
import level from "../../../assets/image/level.svg";
import param from "../../../assets/image/param.svg";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
export const RoleList = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <RoleCard
        icon={user}
        title="Profesores"
        role="professor"
        route="professors"
      />
      <RoleCard
        icon={academy}
        title="Estudiantes"
        reverse
        role="student"
        route="students"
      />
      <RoleCard icon={level} title="Niveles" role="level" route="level" />
      <RoleCard
        icon={param}
        title="Parámetros"
        reverse
        role="parameter"
        route="params"
      />
    </div>
  );
};
