import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { LoginedTopBackground } from '../../components/common/LoginedTopBackground';
import { BotBackground } from '../../components/common/BotBackground';
import { StudentHomePage } from '../../components/StudentPage/StudentHomePage';
import { getLevels } from '../../store/actions/students/levelsActions';
import { useDispatch, useSelector } from 'react-redux';
import { setInitStateResume } from '../../store/actions/students/requestClassActions';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const StudentHomeScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, levels } = useSelector((state) => state.studentReducers.levelsReducer);

  useEffect(() => {
    getAllLevels();
    dispatch(setInitStateResume());
  }, []);

  const getAllLevels = async () => {
    await dispatch(getLevels());
  };

  return (
    <div className={classes.root}>
      <LoginedTopBackground />
      <StudentHomePage allLevels={levels} isLoading={isLoading} />
      <BotBackground />
    </div>
  );
};
