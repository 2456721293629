import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { DetailOfferStudent } from './DetailOfferStudent';
import { OfferTeacher } from './OfferTeacher';
import { useLocation } from 'react-router-dom';
import { useOffer } from '../../../../hooks/useOffer';
import { EmptyState } from '../../../common/EmptyState';
import { useHistory } from 'react-router-dom';
import { showPayment } from '../../../../store/actions/students/uiPaymentAction';
import { getTeacherInfoSelected } from '../../../../store/actions/students/teachersActions';
import { getRequestClass, setLevelId, setRequestType } from '../../../../store/actions/students/requestClassActions';
import moment from 'moment';

export const StudentOfferPage = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const [offers, setOffers] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const { getDetailOffer } = useOffer();

  const user = useSelector(({ loginReducer }) => loginReducer.login.user);

  useEffect(() => {
    getOffer();
  }, []);

  const getOffer = async () => {
    if (state.idRequest) {
      const resp = await getDetailOffer(state.idRequest, user.id);
      setOffers(resp);
    }
  };

  const updateHomeWork = (offer) => {
    dispatch(getTeacherInfoSelected(offer.teacherOffer, offer.levelId, 1));
    dispatch(showPayment(true));
    dispatch(getRequestClass(offer.requestId, offer.date, offer.hourOffer, offer.priceOffer, moment(offer.date).format('HH:mm'), offer.statusRequestTeacher, offer.studentId, 0));
    dispatch(setRequestType('homework'));
    dispatch(setLevelId(offer.levelId, offer.offertId));

    history.push('/student/payment');
  };

  return (
    <div className={classes.root}>
      <Grid container columns={16} spacing={4} justifyContent="center" alignItems="flex-start">
        <Grid item className={classes.containerCenter} md={10} xs={12}>
          {offers.length > 0 ? offers.map((item, idx) => <OfferTeacher key={idx} avatar={item.imgTeacherOffer} nameTeacher={item.nameTeacherOffer} PriceOffer={item.priceOffer} hoursOffer={item.hourOffer} func={() => updateHomeWork(item)} date={item.date} />) : <EmptyState title="No existen ofertas" />}
        </Grid>
        <Grid item className={classes.containerCenter} md={6} xs={12}>
          <DetailOfferStudent nameSchool={state.nameLevel} nameSubject={state.titleNameSchool} numHours={3} url={state.fileUrl} />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 20,
    marginRight: 20,
  },
  containerCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}));
