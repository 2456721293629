import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";

export const Receive = ({ message, date }) => {
  const classes = useStyles();
  return (
    <div className={classes.containerMessage}>
      <div className={classes.recieve}>
        <Typography variant="body2" color="initial">
          {message}
        </Typography>
      </div>
      <Typography className={classes.hour} sx={{ fontSize: 10 }}>
        {moment(date).format("LLL")}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  containerMessage: {
    display: "flex",
    flexDirection: "column",
  },
  recieve: {
    backgroundColor: "#D5B8FD",
    width: "60%",
    alignSelf: "flex-end",
    minHeight: 20,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 6,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    marginTop: 8,
    marginBottom: 1,
  },
  hour: {
    alignSelf: "flex-end",
    justifySelf: "center",
    color: "#333333",
    fontSize: 11,
    left: "51%",
    // marginTop: '0%',
  },
}));
