import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";

import * as fuseActions from "../../../../store/actions/fuse";

const initialDataSchool = {
  name: "",
  id: 0,
  // iconUrl: null,
};

export default function FormDialog({
  openModal,
  setOpenModal,
  certificates,
  setCertificates,
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(initialDataSchool);
  // const [higherId, setHigherId] = useState(0);
  // const [dLevel, setDLevel] = useState(defaultLevel);

  const dispatch = useDispatch();

  useEffect(() => {
    let pivote = 0;
    const aux = certificates.map((el) => {
      if (el.id > selected.id) {
        pivote = el.id;
      }
      return el;
    });
    // setHigherId(pivote);
    setSelected({ ...selected, id: pivote + 1 });
    // console.log(selected);
  }, []);

  useEffect(() => {
    if (openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
  };

  const handleClickSave = async () => {
    if (selected.name.trim().length > 0) {
      setCertificates([...certificates, selected]);
    } else {
      dispatch(
        fuseActions.showMessage({
          message: "¡No puede guardar campos vacios!",
          variant: "error",
        })
      );
    }

    handleClose();
  };

  const handleChange = (e) => {
    setSelected({ ...selected, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Nuevo Certificado</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Ingrese un certificado"
          type="text"
          fullWidth
          variant="standard"
          name="name"
          defaultValue={selected.name}
          onChange={(e) => handleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="error">
          Cancelar
        </Button> */}
        <Button onClick={handleClickSave} variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
