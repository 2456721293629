import axios from "axios";
import { apiUrl } from "../config/apiConfig";

export const helperSubjects = {};

helperSubjects.postSubject = async (data) => {
  const res = await axios.post(`${apiUrl}subjects`, data);
  // console.log("res data post -->", res.data);
  return res.data.subjects;
};

helperSubjects.putSubject = async (id, data) => {
  const res = await axios.put(`${apiUrl}subjects/${id}`, data);
  // console.log("res data put -->", res.data);
  return res.data.SubjectDB;
};

helperSubjects.deleteSubject = async (id) => {
  const res = await axios.delete(`${apiUrl}subjects/${id}`);
  // console.log("res data delete -->", res.data);
};

helperSubjects.getSubjectsBySchoolId = async (id) => {
  const res = await axios.get(`${apiUrl}subjects/schoolId/${id}`);
  // console.log("res get subjects by school id -->", res.data.subjects);
  return res.data.subjects;
};
