import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { LogoCard } from "../common/LogoCard";
import { LoginContent } from "./LoginContent";
import { LoginDescription } from "./LoginDescription";
import logLogo from "../../assets/image/logLogo.png";

const useStyle = makeStyles({
  root: {
    margin: "20px",
  },
});
export const LoginPage = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item md={9}>
          <Grid container justifyContent="space-around">
            <Grid item md={5}>
              <LogoCard image={logLogo} title=" Bienvenido  de Vuelta" />
            </Grid>
            <Grid item md={5}>
              <LoginContent />
            </Grid>
            <Divider
              orientation="vertical"
              sx={{ border: "1px solid #A5A5A5", mb: -10 }}
              flexItem
            />
          </Grid>
        </Grid>
        <Grid item md={3}>
          <LoginDescription />
        </Grid>
      </Grid>
    </div>
  );
};
