import React from 'react';
import { makeStyles } from '@mui/styles';
import { AdminProfessorInfoCard } from '../../AdminProfessorPage/AdminProfessorInfo/AdminProfessorInfoCard';
const useStyles = makeStyles((theme) => ({
  root: { padding: '0px 60px' },
}));
export const AdStudentInfo = ({ infoUser }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AdminProfessorInfoCard admin name={infoUser.fullName} img={infoUser.img} status={infoUser.status} />
    </div>
  );
};
