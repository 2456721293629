import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import user3 from '../../assets/image/user3.png';

export const TeacherCardHorizontal = ({ img, name = '', speciality = '', university = '', background = 'rgba(255, 236, 136, 0.4)' }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor: background }}>
      <div className={classes.container}>
        <div>
          <Avatar src={img || user3} sx={{ width: '80px', height: '80px' }} alt="avatar" />
        </div>
        <div className={classes.infoUser}>
          <Typography variant="h3">{name}</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, mt: 1, color: '#6C6C6C' }}>
            {speciality} {university && `- ${university}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    borderRadius: 16,
    padding: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoUser: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: 10,
  },
}));
