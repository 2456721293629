import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { LevelSort } from "../../LevelPage/LevelSort";
import { LevelDescription } from "../../LevelPage/LevelDescription";
import { useLevels } from "../../../hooks/useLevels";

const useStyles = makeStyles((theme) => ({
  root: { padding: "20px 30px", display: "relative"},
  sort: {
    width: 200,
    height: 50,
    position: "absolute",
    top: 300,
    right: 80,
  },
  sortSelect: {
    ".MuiSelect-Outlined-root": {
      borderRadius: "10px",
    },
  },
}));
export const AdminLevelPage = () => {
  const classes = useStyles();

  const { AllLevels } = useLevels();

  const [age, setAge] = useState("");
  const [defaultLevel, setDefaultLevel] = useState("Educación Básica");
  const [level, setLevel] = useState({});
  // const [AllLevels, setAllLevels] = useState(AllLevelsList);

  useEffect(() => {
    const getLevel = AllLevels.filter((el) => el.nameLevel === defaultLevel);
    setLevel(getLevel);
  }, [defaultLevel, AllLevels]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className={classes.root}>
      <div className={classes.sort}>
        <FormControl fullWidth>
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            sx={{
              borderRadius: 6,
              background: "#F4F7F5",
              border: "none",
              height: 50,
            }}
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select> */}
        </FormControl>
      </div>
      <Grid container>
        <Grid item md={3} sx={{ px: 3 }}>
          <LevelSort
            allLevels={AllLevels}
            defaultLevel={defaultLevel}
            setDefaultLevel={setDefaultLevel}
          />
        </Grid>
        <Grid item md={1} container justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item md={6}>
          <LevelDescription
            level={level}
            defaultLevel={defaultLevel}
            // setDefaultLevel={setDefaultLevel}
          />
        </Grid>
      </Grid>
    </div>
  );
};
